import {
  ASSESSMENT_RESPONSES_SEARCH_LOADING,
  ASSESSMENT_RESPONSES_SEARCH_SUCCESS,
  ASSESSMENT_RESPONSES_SEARCH_FAILURE,
  SET_ASSESSMENT_RESPONSES,
  SEARCH_ASSESSMENT_LOADING,
  SEARCH_ASSESSMENT_SUCCESS,
  SEARCH_ASSESSMENT_FAILURE,
  SET_ASSESSMENT_DATA,
} from 'actions/assessmentResponses';
import { ADEQUATE } from 'pages/AssessmentResults/constants';
import { isNil, omit } from 'lodash';
import { convertToLocalDateTime } from 'utils/dateAndTimeUtils';
import { getDisplayControlName } from 'utils/stringUtils';

const initialState = {
  loading: false,
  assessmentResponses: [],
  filteredAssessmentResponses: [],
  assessmentData: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ASSESSMENT_RESPONSES_SEARCH_LOADING:
    case SEARCH_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ASSESSMENT_RESPONSES_SEARCH_SUCCESS: {
      const omittedResponseData = payload?.Data?.Rows?.map((item) => {
        return omit(item, 'KeyControlId', 'Outcome');
      });
      // Grouping the objects based on "ControlName" using Array.reduce
      const groupedArray = omittedResponseData?.reduce((acc, obj) => {
        const key = getDisplayControlName(obj);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      // Creating an array of objects with ControlName as keys and nested arrays as questionList
      const resultArray = Object.entries(groupedArray).map(([key, value]) => {
        return {
          ControlName: key,
          QuestionList: value.map((question, index) => {
            return {
              ...question,
              IsMet: isNil(question?.AdequacyName) || question?.AdequacyName === 'N/A' ? null : question?.AdequacyName?.toLowerCase() === ADEQUATE,
              Id: index + 1,
              LastUpdated: !isNil(question.LastUpdated) && convertToLocalDateTime(question.LastUpdated, 'MM/dd/yyyy'),
            };
          }),
        };
      });
      const uniqueRecordsMap = new Map();
      // Loop through resultArray to find unique records based on ControlName
      resultArray.forEach(obj => {
        obj.QuestionList.forEach(item => {
          const controlName = item.ControlName;
          if (!uniqueRecordsMap.has(controlName)) {
            uniqueRecordsMap.set(controlName, item);
          }
        });
      });
      return {
        ...state,
        loading: false,
        assessmentResponses: resultArray,
        originalAssessmentResponses: resultArray.flatMap((response) => response.QuestionList),
        uniqueRecords : [...uniqueRecordsMap.values()],
      };
    }

    case ASSESSMENT_RESPONSES_SEARCH_FAILURE:
    case SEARCH_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_ASSESSMENT_RESPONSES: {
      return {
        ...state,
        filteredAssessmentResponses: payload,
      };
    }

    case SEARCH_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentData: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0],
      };
    }

    case SET_ASSESSMENT_DATA: {
      return {
        ...state,
        assessmentData: payload,
      }
    }

    default:
      return state;
  }
}
