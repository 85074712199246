import { useEffect } from 'react';

const useLocalStorageListener = (key) => {
  const handleStorageChange = (event) => {
    if (event.key === key) {
      window.location.reload();
    }
  };
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);
};

export default useLocalStorageListener;
