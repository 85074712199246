import { isArray, isEmpty, isString, omit } from 'lodash';
import { ASSESSMENT_REQUEST_STATUS_SUBMITTED, ASSESSMENT_REQUEST_TYPE_CORL_CLEARED, ASSESSMENT_TYPE_CORL_CLEARED, CORL_CLEARED_PARTICIPANT_STATUS_ID, CORL_CLEARED_STATUS_ID } from 'pages/Dashboard/constants';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: 'startswith',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

const getAssessmentSortBody = (sortModel) => {
  let sort = [];
  sortModel?.forEach((item) => {
    let obj = {};
    if (item?.field === 'LastUpdated') {
      obj = {
        field: 'LastUpdated',
        order: item?.sort?.toUpperCase(),
      };
    } else {
      obj = {
        ...item,
        order: item?.sort?.toUpperCase(),
      };
    }
    const omittedData = omit(obj, 'sort');
    sort.push(omittedData);
  });

  return sort;
};

export const getSort = (sortModel) => {
  let sort = [];
  if (sortModel && sortModel?.length > 0) {
    const updatedSort = getAssessmentSortBody(sortModel);
    updatedSort &&
      updatedSort.forEach((it) => {
        sort.push(it);
      });
  }
  return sort;
};

export const getAssessmentListBody = (searchData = {}, page, pageSize, sortModel) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  let sort = getSort(sortModel);
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ClientId',
      'ClientName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'MasterStepName',
      'AssessmentDisplayStatus',
      'AssessmentStage',
      'Owner',
      'LastUpdated',
      'ParentAssessmentId',
    ],
    Criterion: [],
    sort: [{
            Field: 'LastUpdated',
            Order: 'DESC',
          },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentListCountBody = (searchData = {}) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: ['CountOfAssessment', 'ClientName'],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getCORLCLearedAssessmentListBody = (page, pageSize, sortModel) => {
  let sort = getSort(sortModel);
  return {
    intent: '',
    fields: [
      'AssessmentRequestId',
      'AssessmentName',
      'ReleasedToClientId',
      'CORLClearedStatus',
      'ProductVendorId',
      'ProductName',
      'VendorOrgId',
      'VendorOrgName',
      'ClientOrgId',
      'ClientOrgName',
      'AssessmentRequestInsertUserId',
      'InsertUserName',
      'AssessmentRequestInsertTimestamp',
      'IsCorlClient',
      "ParentAssessmentId",
      "ChildAssessmentId"
    ],
    Criterion: [
      {
        Field: 'CORLClearedStatusId',
        Operator: 'in',
        Value: `${CORL_CLEARED_STATUS_ID}, ${CORL_CLEARED_PARTICIPANT_STATUS_ID}`,
      },
      {
        Field: 'AssessmentStatusId',
        Operator: '=',
        Value: `${ASSESSMENT_REQUEST_STATUS_SUBMITTED}`,
      },
      {
        Field: 'AssessmentRequestTypeId',
        Operator: '=',
        Value: `${ASSESSMENT_REQUEST_TYPE_CORL_CLEARED}`,
      },
      {
        Field: 'AssessmentTypeId',
        Operator: '=',
        Value: `${ASSESSMENT_TYPE_CORL_CLEARED}`,
      }
    ],
    sort: [
      sort?.length > 0
        ? sort[0]
        : {
            Field: 'ReleasedToClientId',
            Order: 'ASC',
          },
          {
            Field: 'AssessmentRequestInsertTimestamp',
            Order: 'DESC',
          },
    ],
    page: page,
    pageSize: pageSize,
  };
};

export const getCORLClearedAssessmentListCountBody = () => {
  return {
    intent: '',
    fields: [
      'AssessmentRequestId',
      'CountOfAssessment'
    ],
    Criterion: [
    {
      Field: 'CORLClearedStatusId',
      Operator: 'in',
      Value: `${CORL_CLEARED_STATUS_ID}, ${CORL_CLEARED_PARTICIPANT_STATUS_ID}`,
    },
    {
      Field: 'AssessmentStatusId',
      Operator: '=',
      Value: `${ASSESSMENT_REQUEST_STATUS_SUBMITTED}`,
    },
    {
      Field: 'AssessmentRequestTypeId',
      Operator: '=',
      Value: `${ASSESSMENT_REQUEST_TYPE_CORL_CLEARED}`,
    },
    {
      Field: 'AssessmentTypeId',
      Operator: '=',
      Value: `${ASSESSMENT_TYPE_CORL_CLEARED}`,
    }
  ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

