import axios from "axios";
import { getErrorObject } from "utils/apiUtils";
import { dismissEventNotificationBody, getEventNotificationListCountSearchBody, getEventNotificationListSearchBody, markReadEventNotificationBody } from "utils/userProfileUtils";import { setErrors } from "./errorHandler";
import { NO_DATA_FOUND } from "constants/errorMessage";

export const SET_USER_DATA = 'SET_USER_DATA';

export const SEARCH_EVENT_NOTIFICATION_LIST_COUNT_LOADING = '@@userProfile/SEARCH_EVENT_NOTIFICATION_LIST_COUNT_LOADING';
export const SEARCH_EVENT_NOTIFICATION_LIST_COUNT_SUCCESS = '@@userProfile/SEARCH_EVENT_NOTIFICATION_LIST_COUNT_SUCCESS';
export const SEARCH_EVENT_NOTIFICATION_LIST_COUNT_FAILURE = '@@userProfile/SEARCH_EVENT_NOTIFICATION_LIST_COUNT_FAILURE';

export const searchEventNotificationListCount = () => async (dispatch) => {
  dispatch({
    type: SEARCH_EVENT_NOTIFICATION_LIST_COUNT_LOADING,
  });

  const body = getEventNotificationListCountSearchBody();
  try {
    const res = await axios.post(`/communicationpreference/eventnotification/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: SEARCH_EVENT_NOTIFICATION_LIST_COUNT_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_EVENT_NOTIFICATION_LIST_COUNT_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVENT_NOTIFICATION_LIST_COUNT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_EVENT_NOTIFICATION_LIST_LOADING = '@@userProfile/SEARCH_EVENT_NOTIFICATION_LIST_LOADING';
export const SEARCH_EVENT_NOTIFICATION_LIST_SUCCESS = '@@userProfile/SEARCH_EVENT_NOTIFICATION_LIST_SUCCESS';
export const SEARCH_EVENT_NOTIFICATION_LIST_FAILURE = '@@userProfile/SEARCH_EVENT_NOTIFICATION_LIST_FAILURE';

export const searchEventNotificationList = () => async (dispatch) => {
  dispatch({
    type: SEARCH_EVENT_NOTIFICATION_LIST_LOADING,
  });

  const body = getEventNotificationListSearchBody();
  try {
    const res = await axios.post(`/communicationpreference/eventnotification/search`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: SEARCH_EVENT_NOTIFICATION_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_EVENT_NOTIFICATION_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
    dispatch(searchEventNotificationListCount());
  } catch (err) {
    dispatch({
      type: SEARCH_EVENT_NOTIFICATION_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const MARK_AS_READ_EVENT_NOTIFICATION_LOADING = '@@userProfile/MARK_AS_READ_EVENT_NOTIFICATION_LOADING';
export const MARK_AS_READ_EVENT_NOTIFICATION_SUCCESS = '@@userProfile/MARK_AS_READ_EVENT_NOTIFICATION_SUCCESS';
export const MARK_AS_READ_EVENT_NOTIFICATION_FAILURE = '@@userProfile/MARK_AS_READ_EVENT_NOTIFICATION_FAILURE';

export const markAsReadNotification = (id = null, callback) => async (dispatch) => {
  dispatch({
    type: MARK_AS_READ_EVENT_NOTIFICATION_LOADING,
  });

  const body = markReadEventNotificationBody(id);
  try {
    const res = await axios.post(`/communicationpreference/eventnotification/action/updatestatus`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: MARK_AS_READ_EVENT_NOTIFICATION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: MARK_AS_READ_EVENT_NOTIFICATION_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      dispatch(searchEventNotificationList());
      if(callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: MARK_AS_READ_EVENT_NOTIFICATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DISMISS_EVENT_NOTIFICATION_LOADING = '@@userProfile/DISMISS_EVENT_NOTIFICATION_LOADING';
export const DISMISS_EVENT_NOTIFICATION_SUCCESS = '@@userProfile/DISMISS_EVENT_NOTIFICATION_SUCCESS';
export const DISMISS_EVENT_NOTIFICATION_FAILURE = '@@userProfile/DISMISS_EVENT_NOTIFICATION_FAILURE';

export const dismissEventNotification = (id = null, callback) => async (dispatch) => {
  dispatch({
    type: DISMISS_EVENT_NOTIFICATION_LOADING,
  });

  const body = dismissEventNotificationBody(id);
  try {
    const res = await axios.post(`/communicationpreference/eventnotification/action/updatestatus`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors))
      dispatch({
        type: DISMISS_EVENT_NOTIFICATION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: DISMISS_EVENT_NOTIFICATION_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      dispatch(searchEventNotificationList());
      if(callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: DISMISS_EVENT_NOTIFICATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};