import { faCircleCheck, faCircleQuestion, faRectangleList } from '@fortawesome/free-regular-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faRepeat1, faTable } from '@fortawesome/pro-regular-svg-icons';
import { faGrid2 } from '@fortawesome/pro-solid-svg-icons';

export const REQUIREMENT_QUESTIONS_TAB_OPTIONS = {
  ALL: 'ALL',
  UNANSWERED: 'UNANSWERED',
  ANSWERED: 'ANSWERED',
};

export const REQUIREMENT_QUESTIONS_TAB_LIST = [
  {
    label: REQUIREMENT_QUESTIONS_TAB_OPTIONS.ALL,
    value: REQUIREMENT_QUESTIONS_TAB_OPTIONS.ALL,
    icon: faList,
  },
  {
    label: REQUIREMENT_QUESTIONS_TAB_OPTIONS.UNANSWERED,
    value: REQUIREMENT_QUESTIONS_TAB_OPTIONS.UNANSWERED,
    icon: faCircleQuestion,
  },
  {
    label: REQUIREMENT_QUESTIONS_TAB_OPTIONS.ANSWERED,
    value: REQUIREMENT_QUESTIONS_TAB_OPTIONS.ANSWERED,
    icon: faCircleCheck,
  },
];

export const ANSWER_COMPONENT_TAB_OPTIONS = {
  ALL: 'ANSWER FOR ALL COMPONENTS',
  COMPONENTS: 'ANSWER BY COMPONENT',
};

export const ANSWER_COMPONENT_TAB_LIST = [
  {
    label: ANSWER_COMPONENT_TAB_OPTIONS.ALL,
    value: ANSWER_COMPONENT_TAB_OPTIONS.ALL,
    icon: faRepeat1,
  },
  {
    label: ANSWER_COMPONENT_TAB_OPTIONS.COMPONENTS,
    value: ANSWER_COMPONENT_TAB_OPTIONS.COMPONENTS,
    icon: faRectangleList,
  },
];

export const SINGLE_SELECT_RESPONSE_ID = 1;
export const MULTI_SELECT_RESPONSE_ID = 2;
export const DATE_TYPE_RESPONSE_ID = 3;
export const FREE_TEXT_RESPONSE_ID = 4;
export const FILE_UPLOAD_RESPONSE_ID = 5;

export const QUESTION_RESPONSE_SUBMITTED_SUCCESSFULLY = 'Response submitted successfully';
export const PARENT_QUESTION_ID_DEFAULT_VALUE = 0;

export const RESPONSE_TOASTER_MESSAGE = 'Saving...';

export const FILE_UPLOAD_REQUIRED = 1;
export const FILE_UPLOAD_OPTIONAL = 2;

export const UNANSWERED_QUESTION_RESPONSE_CATEGORY_ID = 0;
export const ANSWERED_QUESTION_RESPONSE_CATEGORY_ID = 3;

export const MANUAL_QUESTION_RESPONSE_MODE_ID = 1;
export const DRU_QUESTION_RESPONSE_MODE_ID = 2;
export const COPILOT_QUESTION_RESPONSE_MODE_ID = 3;

export const COPILOT_RESPONSE_LIKE = 1;
export const COPILOT_RESPONSE_DISLIKE = 2;
export const PARENT_MODE = 'parent';
export const CHILD_MODE = 'child';

export const ASSESSMENT_STATUS_SUBMITTED = 22;

export const NORMAL_ASSESSMENT_FLOW = 'Normal Assessment Flow';

export const NORMAL_QUESTIONNAIRE_REVIEW_SUMMARY = 'Normal Questionnaire Review Summary Flow';

export const NO_RESPONSE_TEXT = 'N/A';

export const ORG_CANNOT_UPLOAD_FILE = [{ ResponseOptionText: 'Our Organization cannot upload evidence' }];

export const REQUIREMENTS_SUMMARY_TAB_OPTIONS = {
  GRID: 'CATEGORY GRID',
  TABLE: 'QUESTION TABLE',
};

export const REQUIREMENTS_SUMMARY_TAB_LIST = [
  {
    label: REQUIREMENTS_SUMMARY_TAB_OPTIONS.GRID,
    value: REQUIREMENTS_SUMMARY_TAB_OPTIONS.GRID,
    icon: faGrid2,
  },
  {
    label: REQUIREMENTS_SUMMARY_TAB_OPTIONS.TABLE,
    value: REQUIREMENTS_SUMMARY_TAB_OPTIONS.TABLE,
    icon: faTable,
  },
];

export const CORL_CLEARED_QUESTIONNAIRE_ID = 1;
export const CONTROL_BASED_QUESTIONNAIRE_ID = 2;
export const SCOPING_BASED_QUESTIONNAIRE_ID = 8;
export const SUPPLEMENTAL_QUESTIONNAIRE_ID = 7;

export const REVIEW_SUMMARY_TAB_OPTIONS = {
  ALL: 'ALL',
  WITH_MISSING: 'WITH MISSING',
  COMPLETED: 'COMPLETED',
};

export const REVIEW_SUMMARY_TAB_LIST = [
  {
    label: REVIEW_SUMMARY_TAB_OPTIONS.ALL,
    value: REVIEW_SUMMARY_TAB_OPTIONS.ALL,
    icon: faList,
  },
  {
    label: REVIEW_SUMMARY_TAB_OPTIONS.WITH_MISSING,
    value: REVIEW_SUMMARY_TAB_OPTIONS.WITH_MISSING,
    icon: faCircleQuestion,
  },
  {
    label: REVIEW_SUMMARY_TAB_OPTIONS.COMPLETED,
    value: REVIEW_SUMMARY_TAB_OPTIONS.COMPLETED,
    icon: faCircleCheck,
  },
];

export const DEFAULT_FIND_RETURN_VALUE = -1;
export const IS_COPILOT_DISABLE = '1';

export const COPILOT = 'pilot';

export const EVIDENCE_ATTACH = 'attach';
export const EVIDENCE_DELETE = 'delete';

export const PARENT_QUESTION = 1;

export const MODE_COMPANION = "Mode Companion";