import { CORL_CLEARED_PROCESS_STARTED_ID, CORL_CLEARED_STATUS_ID } from 'pages/Dashboard/constants';

export const searchCompanyProfilePercentageBody = () => {
  return {
    intent: '',
    fields: ['VendorId', 'TPIRProfileCompleteness', 'CollaborationScoreForVendors'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
