export const getPreferencesListBody = () => {
  let criterion = [];

  return {
    intent: '',
    fields: ['CommunicationEventId', 'AppId', 'CommunicationName', 'CommunicationPreferenceList', 'DisplayEventName'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
