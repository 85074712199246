import { NEW_NOTIFICATION_STATUS_ID, READ_NOTIFICATION_STATUS_ID, DISMISS_NOTIFICATION_STATUS_ID } from "constants/constants"

export const getEventNotificationListCountSearchBody = () => {
    return {
      "intent": "",
      "fields": [
        "UserId",
        "CountOfUnreadNotifications"
      ],
      "criterion": [
        {
        "Field": "InAppStatusId",
        "Operator": "=",
        "Value": `${NEW_NOTIFICATION_STATUS_ID}`
        }
      ],
      "sort": [],
      "page": 0,
      "pageSize": 0
    }
}

export const getEventNotificationListSearchBody = () => {
    return {
      "intent": "",
      "fields": [
        "EventNotificationInstanceId",
        "EventNotificationTemplateId",
        "CommunicationEventInstanceId",
        "EventNotificationDate",
        "AppId",
        "UserId",
        "TextMessage",
        "TextSent",
        "InAppStatus",
        "IsNew",
        "Active",
        "InsertUserId",
        "InsertTimestamp",
        "UpdateUserId",
        "UpdateTimestamp",
        "LinkToPortalPage"
      ],
      "criterion": [
        {
        "Field": "InAppStatusId",
        "Operator": "<>",
        "Value": `${DISMISS_NOTIFICATION_STATUS_ID}`
        }
       ],
      "sort": [
        {
          Field: 'EventNotificationDate',
          Order: 'DESC',
        }
      ],
      "page": 0,
      "pageSize": 0
    }
}

export const markReadEventNotificationBody = (id) => {
  return {
    "EventNotificationInstanceId": id ?? 0,
    "InAppStatusId" : READ_NOTIFICATION_STATUS_ID,
  }
}

export const dismissEventNotificationBody = (id) => {
  return {
    "EventNotificationInstanceId": id ?? 0,
    "InAppStatusId" : DISMISS_NOTIFICATION_STATUS_ID,
  }
}