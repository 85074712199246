import axios from 'axios';
import { setErrors } from './errorHandler';
import { getErrorObject } from 'utils/apiUtils';
import { addEvidenceClearCommentLogBody, getEvidenceDetailsBody, getEvidenceEndDateLookupBody, getEvidenceStartDateLookupBody } from 'utils/evidenceManagementUtils';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { ESCAPE_QUOTE_REGEX } from 'constants/constants';
import { isNil } from 'lodash';

export const UPLOAD_EVIDENCE_FILE_LOADING = '@@evidenceManagement/UPLOAD_EVIDENCE_FILE_LOADING';
export const UPLOAD_EVIDENCE_FILE_SUCCESS = '@@evidenceManagement/UPLOAD_EVIDENCE_FILE_SUCCESS';
export const UPLOAD_EVIDENCE_FILE_FAILURE = '@@evidenceManagement/UPLOAD_EVIDENCE_FILE_FAILURE';

export const uploadEvidenceFile = (data, errorCallback) => async (dispatch) => {
  dispatch({ type: UPLOAD_EVIDENCE_FILE_LOADING });

  try {
    let body = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'UploadedFile') {
        body.append(key, data[key]);
      } else if(key === 'ValidStartDate' || key === 'ValidExpiryDate'){
        if(!isNil(data[key])) {
          body.append(key, data[key] ? `"${data[key]?.replace(/"/g, ESCAPE_QUOTE_REGEX)}"` : null)
        }
      } else if(key === 'ProductVendorEvidenceList'){
        body.append(key, JSON.stringify(data[key]));         
      } else {
        if(!isNil(data[key])) {
          body.append(key, JSON.stringify(data[key]?.toString()));
        }
      }
    });

    const response = await axios.post(`/vendor/evidence`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPLOAD_EVIDENCE_FILE_FAILURE,
        payload: {},
      });
      if(errorCallback) {
        errorCallback(true);
      }
    } else {
      dispatch({
        type: UPLOAD_EVIDENCE_FILE_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: UPLOAD_EVIDENCE_FILE_FAILURE,
      payload: getErrorObject(err),
    });
    if(errorCallback) {
      errorCallback(true);
    }
  }
};

export const SEARCH_EVIDENCE_DETAILS_LOADING = '@@evidenceManagement/SEARCH_EVIDENCE_DETAILS_LOADING';
export const SEARCH_EVIDENCE_DETAILS_SUCCESS = '@@evidenceManagement/SEARCH_EVIDENCE_DETAILS_SUCCESS';
export const SEARCH_EVIDENCE_DETAILS_FAILURE = '@@evidenceManagement/SEARCH_EVIDENCE_DETAILS_FAILURE';

export const searchEvidenceDetails = (searchData) => async (dispatch) => {
  dispatch({ type: SEARCH_EVIDENCE_DETAILS_LOADING });

  let body = getEvidenceDetailsBody(searchData);
  try {
    const response = await axios.post(`/vendor/evidence/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_EVIDENCE_DETAILS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_EVIDENCE_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVIDENCE_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_EVIDENCE_DOCUMENTS_LOADING = '@@evidenceManagement/DELETE_EVIDENCE_DOCUMENTS_LOADING';
export const DELETE_EVIDENCE_DOCUMENTS_SUCCESS = '@@evidenceManagement/DELETE_EVIDENCE_DOCUMENTS_SUCCESS';
export const DELETE_EVIDENCE_DOCUMENTS_FAILURE = '@@evidenceManagement/DELETE_EVIDENCE_DOCUMENTS_FAILURE';

export const deleteEvidenceDocument = (evidenceId, callback) => async (dispatch) => {
  dispatch({ type: DELETE_EVIDENCE_DOCUMENTS_LOADING });

  try {
    const response = await axios.delete(`/vendor/evidence/${evidenceId}`);

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_EVIDENCE_DOCUMENTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_EVIDENCE_DOCUMENTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(searchEvidenceDetails());
      if(callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: DELETE_EVIDENCE_DOCUMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING = '@@evidenceManagement/ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING';
export const ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS = '@@evidenceManagement/ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS';
export const ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE = '@@evidenceManagement/ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE';

export const attachEvidenceToRequirement = (combinedList, assessmentQuestionnaireQuestionResponseId, callback) => async (dispatch) => {
  dispatch({
    type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING,
  });

  let body = new FormData();

  combinedList.forEach((item, index) => {
    Object.keys(item).forEach((key) => {
      if (key === 'UploadedFile') {
        body.append(`modelList[${index}].${key}`, item[key]);
      } else {
        body.append(`modelList[${index}].${key}`, JSON.stringify(item[key].toString()));
      }
    });
  });

  try {
    const res = await axios.post(
      `vendor/product/assessment/assessmentquestionnairequestionresponse/${assessmentQuestionnaireQuestionResponseId}/attachement`,
      body,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }
    );
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS,
        payload: res?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_EVIDENCE_CLEAR_COMMENT_LOG_LOADING = '@@evidenceManagement/POST_EVIDENCE_CLEAR_COMMENT_LOG_LOADING';
export const POST_EVIDENCE_CLEAR_COMMENT_LOG_SUCCESS = '@@evidenceManagement/POST_EVIDENCE_CLEAR_COMMENT_LOG_SUCCESS';
export const POST_EVIDENCE_CLEAR_COMMENT_LOG_FAILURE = '@@evidenceManagement/POST_EVIDENCE_CLEAR_COMMENT_LOG_FAILURE';

export const addEvidenceClearCommentLog = (addBody, callback) => async (dispatch) => {
  dispatch({ type: POST_EVIDENCE_CLEAR_COMMENT_LOG_LOADING });

  let body = addEvidenceClearCommentLogBody(addBody);
  try {
    const response = await axios.post(
      `/vendor/product/assessment/assessmentquestionnairequestionresponse/${addBody?.AssessmentQuestionnaireQuestionResponseId}/action/comment`,
      body
    );

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: POST_EVIDENCE_CLEAR_COMMENT_LOG_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_EVIDENCE_CLEAR_COMMENT_LOG_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_EVIDENCE_CLEAR_COMMENT_LOG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_EVIDENCE_START_DATE_LOADING = '@@evidenceManagement/SEARCH_EVIDENCE_START_DATE_LOADING';
export const SEARCH_EVIDENCE_START_DATE_SUCCESS = '@@evidenceManagement/SEARCH_EVIDENCE_START_DATE_SUCCESS';
export const SEARCH_EVIDENCE_START_DATE_FAILURE = '@@evidenceManagement/SEARCH_EVIDENCE_START_DATE_FAILURE';

export const searchEvidenceStartDateLookup = () => async (dispatch) => {
  dispatch({ type: SEARCH_EVIDENCE_START_DATE_LOADING });

  let body = getEvidenceStartDateLookupBody();
  try {
    const response = await axios.post(`/vendor/evidence/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_EVIDENCE_START_DATE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_EVIDENCE_START_DATE_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVIDENCE_START_DATE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_EVIDENCE_END_DATE_LOADING = '@@evidenceManagement/SEARCH_EVIDENCE_END_DATE_LOADING';
export const SEARCH_EVIDENCE_END_DATE_SUCCESS = '@@evidenceManagement/SEARCH_EVIDENCE_END_DATE_SUCCESS';
export const SEARCH_EVIDENCE_END_DATE_FAILURE = '@@evidenceManagement/SEARCH_EVIDENCE_END_DATE_FAILURE';

export const searchEvidenceEndDateLookup = () => async (dispatch) => {
  dispatch({ type: SEARCH_EVIDENCE_END_DATE_LOADING });

  let body = getEvidenceEndDateLookupBody();
  try {
    const response = await axios.post(`/vendor/evidence/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_EVIDENCE_END_DATE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_EVIDENCE_END_DATE_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_EVIDENCE_END_DATE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_EVIDENCE = '@@evidenceManagement/SET_SELECTED_EVIDENCE';

export const setSelectedEvidence =
  (value = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_EVIDENCE,
      payload: value,
    });
  };