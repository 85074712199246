export const retryFunc = async (func, retries = 3) => {
  try {
    return await func();
  } catch (error) {
    if (retries > 0) {
      return retryFunc(func, retries - 1);
    } else {
      throw error;
    }
  }
};
