import { isNil, isUndefined, round } from 'lodash';

export const getPercentage = (num, den) => {
  const numerator = Number(num);
  const denominator = Number(den);

  if (!isUndefined(numerator) && !isUndefined(denominator) && !isNil(numerator) && !isNil(denominator) && denominator !== 0) {
    return round(numerator / denominator, 2) * 100;
  }

  return 0;
};
