import { format } from 'date-fns';
import { isArray, isEmpty, isString } from 'lodash';
import { COMPLETION_DATE } from 'pages/Remediation/constants';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (key === COMPLETION_DATE) {
      let dateRange = JSON.parse(searchData[key]).map((date) => format(new Date(date), 'yyyy/MM/dd hh:mm:ss'));
      criterion.push({
        Field: key,
        Operator: 'between',
        Value: dateRange?.toString(),
      });
    }
    if (isString(searchData[key]) && key !== COMPLETION_DATE) {
      criterion.push({
        Field: key,
        Operator: 'contains',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

export const getRemediationObjectiveListBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientOrgName',
      'AssessmentId',
      'RequirementId',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'ControlNumber',
      'DefaultTier',
      'Severity',
      'Expiration',
      'ExpirationInDays',
      'AssessmentCount',
      'ProductName',
      'InadequacyReasonId',
      'InadequacyReasonName',
      'RemediationSubmittedDate',
      'RemediationSubmittedBy',
      'TotalDueDays',
      'CompletionDate',
      'CompletionDays',
      'AssessmentQuestionnaireRequirementId',
      'RemediationObjective',
      'RequirementId',
      'RemediationObjectiveStatus',
      'RemediationObjectiveLastUpdated',
      'DisplayStatus',
      'DisplayStatusId',
      'AssessmentIdList',
      'RemediationGuidanceDetailIdList'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRemediationObjectiveDetailsListBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'Name',
      'Description',
      'DueDays',
      'ExtensionDays',
      'AllowComment',
      'IsEvidenceRequired',
      'IsAuditReviewRequired',
      'InadequacyReasonIPIId',
      'InadequacyReasonId',
      'DisplayOrder',
      'RemediationGuidanceDetailId',
      'RemediationResponseIPIComment',
      'RemediationResponseIPIStatus',
      'ExternalTicketId',
      'AuditorStatus',
      'RemediationResponseIPIId',
      'RemediationObjectiveIPIDate',
      'RemediationObjectiveIPIDateFlag',
      'RemediationResponseIPIEvidenceList',
      'AllowComment',
      'CumulativeIPIDueDays',
      'RemediationGuidanceSubmittedDate',
      'RemediationResponseIPICompletionStatusId',
      'AssessmentIdList',
      'RemediationObjective'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'DisplayOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
