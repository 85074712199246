import { isNil } from 'lodash';

export const searchCustomerReqBody = () => {
  return {
    intent: '',
    fields: ['CustomerVendorId', 'CustomerOrgName'],
    criterion: [],
    sort: [
      {
        field: 'CustomerOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchCustomerWithFirstNameReqBody = (searchValue) => {
  return {
    intent: '',
    fields: ['CustomerVendorId', 'CustomerOrgName'],
    criterion: [
      {
        field: 'CustomerOrgName',
        operator: 'startswith',
        value: searchValue,
      },
    ],
    sort: [
      {
        field: 'CustomerOrgName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductGroupReqBody = () => {
  return {
    intent: '',
    fields: ['ProductGroupName'],
    criterion: [],
    sort: [
      {
        field: 'ProductGroupName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductListReqBody = (productGroupId = null) => {
  let criterion = [];
  if (!isNil(productGroupId)) {
    criterion.push({
      field: 'ProductGroupId',
      operator: '=',
      value: productGroupId,
    });
  }
  return {
    intent: '',
    fields: ['ProductName', 'ProductType', 'ProductVendorRole'],
    criterion: criterion,
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchCustomerProductsReqBody = (customerVendorId) => {
  return {
    intent: '',
    fields: ['CustomerVendorProductId', 'ProductName'],
    criterion: [
      {
        field: 'CustomerVendorId',
        operator: '=',
        value: customerVendorId,
      },
    ],
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getCustomerSearchBody = (searchText) => {
  return {
    Intent: '',
    Fields: ['CustomerOrgName', 'RegisteredBusinessAddressCity', 'RegisteredBusinessAddressState', 'RegisteredBusinessAddressZip'],
    Criterion: [
      {
        Field: 'CustomerOrgName',
        Operator: 'startswith',
        Value: searchText,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};
