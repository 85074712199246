import { COPILOT_LABEL } from "constants/constants";

export const ERROR_MESSAGE = 'Something Went Wrong.';
export const SAVE_SUCCESSFULLY_MESSAGE = 'Saved Successfully.';
export const CUSTOMER_ADDED_SUCCESSFULLY_MESSAGE = 'Added Customer Successfully.';
export const CUSTOMER_DELETED_SUCCESSFULLY_MESSAGE = 'Deleted Customer Successfully.';
export const PRODUCT_ADDED_SUCCESSFULLY_MESSAGE = 'Added Product Successfully.';
export const PRODUCT_REMOVED_SUCCESSFULLY_MESSAGE = 'Removed Product Successfully.';
export const UNASSIGN_USER_ROLE_CONFIRMATION_MESSAGE = 'This action will unassign the selected role of this user. Are you sure?';
export const USER_ROLE_ADDED_SUCCESSFULLY = 'User Role Assigned Successfully';
export const USER_ROLE_REMOVED_SUCCESSFULLY = 'User Role Removed Successfully';
export const UPDATED_SUCCESSFULLY_MESSAGE = 'Updated Successfully.';
export const RESEND_INVITE_SUCCESSFULLY = 'Invite Sent Successfully.';
export const FILE_UPLOADED_SUCCESSFULLY_MESSAGE = 'File Uploaded Successfully.';
export const FILE_ALREADY_UPLOADED_MESSAGE = 'File already uploaded.';
export const NO_OF_FILES_TO_UPLOAD_LIMIT_MESSAGE = 'Exceeded the limit for uploading files here. ';
export const FILE_UPLOAD_SIZE_ERROR_MESSAGE = `File upload size exceeded`;
export const CREATE_SUPPORT_TICKET_SUCCESSFULLY = 'Support Ticket Created Successfully';
export const COMMENTS_ADDED_SUCCESSFULLY = 'Comments Added Successfully';
export const CLOSE_SUPPORT_TICKET_SUCCESSFULLY = 'Support Ticket Closed Successfully';
export const ASSESSMENT_SHARED_STATUS_UPDATED_SUCCESSFULLY = 'Assessment Shared Status Updated Successfully';
export const ASSESSMENT_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE = 'Assessment Help Ticket Created Successfully.';
export const REMEDIATION_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE = 'Remediation Help Ticket Created Successfully.';

export const NOTIFICATION_STATUS_UPDATED_SUCCESSFULLY_MESSAGE = "Notification Status Updated Successfully";
export const ASSESSMENT_RESULT_SUCCESSFULLY_SHARED_TO_RECIPIENTS = "Assessment Result shared to recipients";

//Vendor Copliot Alert Messages
export const COPILOT_PROCESS_STARTED_SUCCESSFULLY_MESSAGE = `CORL ${COPILOT_LABEL} Process Initiated Successfully.`;
export const FILE_DELETED_SUCCESSFULLY_MESSAGE = "File has been removed Successfully."
export const FILE_DESCRIPTION_EDITED_SUCCESSFULLY_MESSAGE = "File Description has been updated Successfully.";
export const COPILOT_RESPONSE_GENERATED_SUCCESSFULLY_MESSAGE = "Responses Generated Successfully."; 
export const COPILOT_STATUS_UPDATED_SUCCESSFULLY_MESSAGE = "Status Updated Successfully."; 
export const ORG_LEVEL_ASSESSMENT_CREATION_MESSAGE = `Org Level Assessment Created Successfully.`;