import { cloneDeep, isEmpty, isNil } from 'lodash';
import {
  SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_LOADING,
  SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS,
  SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_FAILURE,
  SEARCH_QUESTIONNAIRE_LIST_DATA_LOADING,
  SEARCH_QUESTIONNAIRE_LIST_DATA_FAILURE,
  SEARCH_QUESTIONNAIRE_LIST_DATA_SUCCESS,
  PUT_QUESTIONNAIRE_LOADING,
  PUT_QUESTIONNAIRE_SUCCESS,
  PUT_QUESTIONNAIRE_FAILURE,
  POST_QUESTIONNAIRE_STATUS_LOADING,
  POST_QUESTIONNAIRE_STATUS_SUCCESS,
  POST_QUESTIONNAIRE_STATUS_FAILURE,
  POST_FINAL_QUESTIONNAIRE_STATUS_LOADING,
  POST_FINAL_QUESTIONNAIRE_STATUS_SUCCESS,
  POST_FINAL_QUESTIONNAIRE_STATUS_FAILURE,
  SET_SELECTED_QUESTIONNAIRE_LIST_LOADING,
  SET_SELECTED_QUESTIONNAIRE_LIST_SUCCESS,
  SET_SELECTED_QUESTIONNAIRE_LIST_FAILURE,
  SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_LOADING,
  SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_SUCCESS,
  SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_FAILURE,
  SET_FINAL_SUBMIT_REDIRECTION_LOADING,
  SET_FINAL_SUBMIT_REDIRECTION_SUCCESS,
  SET_FINAL_SUBMIT_REDIRECTION_FAILURE,
} from '../actions/incidentResponse';

const initialState = {
  loading: false,
  questionnaireDetails: {},
  list: [],
  answersList: {},
  selectedQuestionnaire: {},
  searchQuestions: true,
  finalSubmitSuccess: false,
  answerQuestionsSuccess: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  let answersCountValue = cloneDeep(state.answerQuestionsSuccess);

  switch (type) {
    case SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        questionnaireDetails: payload?.Data?.Rows,
        searchQuestions: false,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_SUCCESS: {
      let savedAnswers = {};
      payload?.Data?.Rows.forEach((obj, index) => {
        if (!isEmpty(obj['QuestionResponseText'])) {
          savedAnswers[`QuestionResponseText${index}`] = obj['QuestionResponseText'].split('|');
        }
        if (!isEmpty(obj['QuestionResponseComment'])) {
          savedAnswers[`QuestionResponseComment${index}`] = obj['QuestionResponseComment'];
        }
        delete obj['QuestionResponseText'];
        delete obj['AssessmentQuestionnaireQuestionResponseId'];
      });
      return {
        ...state,
        loading: false,
        answersList: savedAnswers,
      };
    }

    case SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_QUESTIONNAIRE_LIST_DATA_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_QUESTIONNAIRE_LIST_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload?.Data?.Rows.sort((firstItem, lastItem) => new Date(lastItem.ResponseLastUpdated) - new Date(firstItem.ResponseLastUpdated)),
        searchQuestions: true,
        answerQuestionsSuccess: 0,
      };
    }

    case SEARCH_QUESTIONNAIRE_LIST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case PUT_QUESTIONNAIRE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case PUT_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        loading: false,
        answerQuestionsSuccess: answersCountValue + 1,
      };
    }

    case PUT_QUESTIONNAIRE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_QUESTIONNAIRE_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_QUESTIONNAIRE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_QUESTIONNAIRE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_FINAL_QUESTIONNAIRE_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_FINAL_QUESTIONNAIRE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        finalSubmitSuccess: true,
      };
    }

    case POST_FINAL_QUESTIONNAIRE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_SELECTED_QUESTIONNAIRE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_SELECTED_QUESTIONNAIRE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedQuestionnaire: payload,
      };
    }

    case SET_SELECTED_QUESTIONNAIRE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_FINAL_SUBMIT_REDIRECTION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_FINAL_SUBMIT_REDIRECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        finalSubmitSuccess: payload,
      };
    }

    case SET_FINAL_SUBMIT_REDIRECTION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
