export const getRDSConfigurationDetailsSearchBody = () => {
  return {
    Intent: '',
    Fields: ['RDSConfigurationId', 'VendorOrgId', 'VendorOrgName', 'ConnectName', 'ConnectionDescription', 'Token', 'CORLCorelationId'],
    Criterion: [],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const addNewRDSConnectionBody = (formData) => {
  return {
    ConnectName: formData?.ConnectName,
    ConnectionDescription: formData?.ConnectionDescription,
  };
};

export const saveRDSConnetionDetailsBody = (formData) => {
  return {
    RDSConfigurationId: formData?.RDSConfigurationId,
    ConnectName: formData?.ConnectName,
    ConnectionDescription: formData?.ConnectionDescription,
    Token: formData?.Token,
    CORLCorelationId: formData?.CORLCorelationId,
  };
};
