const hexToRgb = (hex) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export const vendorProfileContainer = (theme) => {
  return {
    padding: '40px',
  };
};

export const modalStyle = () => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 12,
    p: 4,
    overflow: 'auto',
  };
};

export const buttonStyle = () => {
  return {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    paddingTop: '16px',
    gap: '10px',
  };
};

export const buttonRightMargin = () => {
  return {
    marginRight: '10px',
  };
};

export const sidebarWrapper = (theme) => {
  return {
    padding: '5px 0px',
    boxShadow: '-4px -4px 40px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginRight: '40px',
    width: '100px',
  };
};

export const generalInformationTitle = (theme) => {
  return {
    fontFamily: `${theme.typography.fontFamily}`,
  };
};

export const customersAndProductBox = () => {
  return {
    marginBottom: '8px',
    marginTop: '0px',
  };
};

export const customersBox = (theme) => {
  return {
    width: '200px',
    marginBottom: '8px',
    display: 'flex',
    padding: '10px 0 5px 0',
    borderRadius: '8px',
    '&.active': {
      background: `${theme.palette.grey[300]}`,
    },
    cursor: 'pointer',
  };
};

export const productsBox = (theme) => {
  return {
    width: '200px',
    margin: '-4px 0 4px 56px',
    padding: '10px',
    marginBottom: '8px',
    borderRadius: '8px',
    '&.active': {
      background: `${theme.palette.grey[300]}`,
    },
    cursor: 'pointer',
  };
};

export const iconstyle = () => {
  return {
    '& svg': {
      '& path': {
        margin: '1rem',
      },
    },
  };
};

export const contactContainer = (theme) => {
  return {
    marginBottom: '15px',
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.1)',
  };
};

export const contactData = (theme) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    mr: '25px',
  };
};

export const headerDivider = (theme) => {
  return {
    margin: '29px 0px',
  };
};

export const footerDivider = (theme) => {
  return {
    margin: '25px 0px',
  };
};

export const buttonWrapper = (theme) => {
  return {
    textAlign: 'right',
    marginTop: '29px',
  };
};

export const contactsCancelButtonStyle = () => {
  return {
    marginRight: '8px',
  };
};

export const responseTypeSelect = (theme, ownerState) => {
  var color = hexToRgb(theme.palette.text.primary);
  var colorRgb = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.5)`;
  return {
    color: ownerState.responseType === 'selectValue' ? colorRgb : theme.palette.text.primary,
  };
};

export const flexWrapper = (theme, ownerState) => {
  return {
    display: 'flex',
  };
};

export const rightSidePanelWrapper = (theme) => {
  return {
    width: 'calc(100% - 160px)',
  };
};

export const optionWrapper = (theme, ownerState) => {
  return {
    textAlign: 'center',
    borderRadius: '8px',
    padding: '27px 15px',
    color: ownerState.selectedOption === ownerState.index ? theme.palette.primary.contrastText : theme.palette.grey[700],
    backgroundColor: ownerState.selectedOption === ownerState.index ? theme.palette.grey[300] : '#FFF',
    cursor: 'pointer',
  };
};

export const optionIcon = (theme) => {
  return {
    display: 'block',
    margin: 'auto',
    marginBottom: '10px',
  };
};

export const textFieldResponseStyle = (theme) => {
  return {
    width: '600px',
  };
};
export const fileuploadStyles = (theme) => {
  return {
    backgroundColor: 'white',
    border: 'unset',
    borderRadius: '6px',
    height: '62px',
    width: '220px',
    '&:hover': {
      backgroundColor: 'white',
      border: 'unset',
    },
  };
};

export const cancelButton = (theme) => {
  return {
    marginRight: '16px',
    backgroundColor: `${theme.palette.grey[700]} !important`,
  };
};

export const deleteButton = (theme) => {
  return {
    backgroundColor: `${theme.palette.error.main} !important`,
  };
};

export const companyFormWrapper = () => {
  return {
    display: 'grid',
    '.headQuartersInformationFieldsStyle' : {
      marginTop: '27px !important',
    },

    '& .textWrapStyle .MuiTypography-root':{
      maxWidth : '99%',
      whiteSpace: 'nowrap !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
      position :'absolute',
      marginBottom : '20px !important'
    },

    '& .textWrapStyle' : {
      marginTop : '20px !important',
    },
    gridTemplateColumns: 'auto auto auto',
    columnGap: '40px',
  };
};
export const companyFormWrapperForBasicInformation = () => {
  return {
    display: 'grid',
    gridTemplateColumns: 'auto 21% 35%',
    columnGap: '40px',
  };
};

export const companyBasicInfoContainer = (theme) => {
  return {
    width: '100%',
    display: 'flex',
    gap: '36px',
    [theme.breakpoints.down('lg')]: {
      gap: '24px',
    },
    
  };
};
export const companyBasicInfoPrimaryFields = (theme) => {
  return {
    width: '60%',
    display: 'grid',
    gridTemplateColumns: '65.5% 27%',
    columnGap: '48px',
    [theme.breakpoints.down('lg')]: {
      width: '45%',
      display: 'grid',
      gridTemplateColumns: '50% 40%',
    },
  };
};

export const companyBasicInfoSecondaryFields = (theme) => {
  return {
    width: '40%',
    display: 'grid',
    gridTemplateColumns: '152px auto 112px',
    columnGap: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '55%',
    },
    '& .textWrapStyle .MuiTypography-root':{
      maxWidth : '97%',
      whiteSpace: 'nowrap !important',
      overflow: 'hidden !important',
      textOverflow: 'ellipsis !important',
    },
    '& .MuiInputBase-root': {
      '&:first-of-type': {  
        margin: '4px',
      },
    },
  };
};

export const companyFormWrapperForPhone = () => {
  return {
    display: 'grid',
    gridTemplateColumns: '152px auto 100px',
    columnGap: '12px',
  };
};

export const companySubmitButton = () => {
  return {
    fontSize: '12px',
    marginLeft: '10px',
  };
};

export const spaceTop = (ownerState) => {
  return {
    marginTop: `${ownerState}px`,
  };
};

export const spaceBottom = (ownerState) => {
  return {
    marginBottom: `${ownerState}px`,
  };
};

export const spaceLeft = (ownerState) => {
  return {
    marginLeft: `${ownerState}px`,
  };
};

export const spaceRight = (ownerState) => {
  return {
    marginRight: `${ownerState}px`,
  };
};

export const rightPanelList = () => {
  return {
    width: '75%',
    margin: 'auto',
    marginTop: '20px',
  };
};

export const popupInputField = () => {
  return {
    width: '350px',
  };
};

export const buttonsWrapper = () => {
  return {
    padding: '16px 24px',
  };
};

export const contactNameWrapper = (theme) => {
  return {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  };
};

export const contactFieldsWrapper = (theme) => {
  return {
    display: 'grid',
    gridTemplateColumns: '152px auto 148px',
    columnGap: '28px',
  };
};

export const contactCellPhoneFieldsWrapper = () => {
  return {
    display: 'grid',
    gridTemplateColumns: '152px calc(100% - 356px)',
    columnGap: '28px',
  };
};

export const contactOfficePhoneFieldsWrapper = () => {
  return {
    display: 'grid',
    gridTemplateColumns: '152px auto 148px',
    columnGap: '28px',
  };
};

export const contactForm = (theme) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  };
};

export const deleteIconStyle = (theme) => {
  return {
    marginLeft: '5px',
  };
};

export const searchBarStyle = () => {
  return {
    marginLeft: '0px !important',
  };
};
export const customersListStyle = () => {
  return {
    height: '300px',
  };
};

export const commonListItemStyle = () => {
  return {
    paddingRight: '15px',
  };
};

export const dataGridStyles = {
  '&.MuiDataGrid-root': {
    border: 'none',
  },
  '.MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '.MuiDataGrid-virtualScrollerContent': {
    backgroundColor: 'white',
    border: 1,
    borderWidth: '1px',
    borderColor: '#D8DEE4',
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },
  '.MuiDataGrid-columnHeaders': {
    fontSize: '11px',
    fontWeight: '700',
    color: '#747679',
  },
  '.MuiDataGrid-row': {
    padding: '0px',
  },
  '.MuiSvgIcon-root.MuiSelect-icon': {
    right: '0px',
  },
  '.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-cell': {
    whiteSpace: 'normal !important',
  },
};

export const productRightPanel = () => {
  return {
    marginLeft: '20px',
  };
};
export const productsListStyle = () => {
  return {
    marginTop: '15px',
  };
};

export const addProductDialog = () => {
  return {
    paddingTop: '0px',
    width: '500px',
  };
};

export const popupAlertStyle = () => {
  return {
    marginTop: '0px',
    marginBottom: '0px',
    alignItems: 'center',
  };
};
export const listScrollBarHeight = () => {
  return {
    height: 270,
  };
};

export const snackbarStyle = () => {
  return {
    marginTop: '16px',
    marginRight: '16px',
    fontWeight: 500,
  };
};

export const questionstree = (theme, ownerState) => {
  const { disabled = false } = ownerState;
  return {
    width: '100%',
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-block',
    opacity: () => {
      if (ownerState) {
        return 0.5;
      } else {
        return 'unset';
      }
    },
    pointerEvents: () => {
      if (ownerState) {
        return 'none';
      } else {
        return 'unset';
      }
    },
  };
};

export const formBoxBorder = () => {
  return {
    padding: '10px',
    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)',
  };
};

export const IEContactsList = () => {
  return {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '303px',
    whiteSpace: 'nowrap',
    marginLeft: '5px',
  };
};

export const menuListIcon = () => {
  return {
    height: 24,
    width: 20,
    marginRight: '3px',
  };
};

export const autoCompleteFieldStyle = () => {
  return {
    margin: '0px 0px 12px 0px',
  };
};

export const deleteProductButtonStyles = (theme, isDisabled = false) => {

  let opacity = 1;
  let backgroundColor = `${theme.palette.error.main}`;


  if(isDisabled) {
    opacity = 0.5;
    backgroundColor = `${theme.palette.error.light}`;
  }

  return {
    marginTop: `16px`,
    opacity: opacity,
    backgroundColor: backgroundColor,
    '&:hover' : {
      opacity: opacity,
      backgroundColor: backgroundColor
    }
  };
};

export const companyFormWrapperForYearEmployee = () => {
  return {
    display: 'grid',
    columnGap: '40px',
    gridTemplateColumns: '38% 38% ',
    '& > *:last-child': {
      marginLeft : '2%',
      marginTop: '20px'
    },
  };
};
