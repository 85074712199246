export const VENDORS_PRODUCTS_TAB_OPTIONS = {
  PRODUCTS: 'PRODUCTS',
  PRODUCTGROUPS: 'PRODUCT GROUPS',
};

export const VENDORS_PRODUCTS_TAB_LIST = [
  {
    label: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
    value: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
  },
  {
    label: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTGROUPS,
    value: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTGROUPS,
  },
];

export const PRODUCT_GROUP_ADDED_SUCCESSFULLY = 'Product Group Added Successfully';
export const PRODUCT_GROUP_UPDATED_SUCCESSFULLY = 'Product Group Updated Successfully';
export const PRODUCT_GROUP_DELETED_SUCCESSFULLY = 'Product Group Deleted Successfully';

export const FORCE_DELETE_PRODUCT_CONFIRMATION_MESSAGE = 'Are you sure you want to delete all associated data? Please confirm.';
export const FORCE_DELETE_PRODUCTGROUP_CONFIRMATION_MESSAGE = 'This product group has products associated with it. Are you sure to delete this product group?';
export const NO_PRODUCT_GROUP_ASSOCIATED = 0;

export const DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE = 'deassociate products from productgroup';

export const NO_PRODUCT_GROUPS_FOUND = 'No Product Groups Found';

export const NO_PRODUCT_GROUPS_FOUND_OPTION = {
  value: 0,
  label: NO_PRODUCT_GROUPS_FOUND,
  disabled: true,
};

export const PRODUCT_GET_CLEARED = "Get Cleared";
export const PRODUCT_CORL_CLEARED_REQUEST_SUBMITTED = "Request Submitted";
export const PRODUCT_CORL_CLEARED_PARTICIPANT = "CORL Cleared Participant";
export const PRODUCT_CORL_CLEARED = "CORL Cleared";
export const PRODUCT_NOT_CORL_CLEARED = "Not CORL Cleared";

export const ASSESSMENT_SHARED_STATUS_UPDATED_SUCCESSFULLY = 'Assessment Shared Status Updated Successfully';


export const ASSESSMENT_SHARED_STATUS_SHARED = 1;
export const ASSESSMENT_SHARED_STATUS_REJECTED = 2;

//Product Preferences Wizard Constants
export const DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX = 0;
export const PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE = 1;
export const PRODUCT_PREFERENCES_COMPLETED_STATUS_ID = 3;
export const PRODUCT_PREFERENCES_DRAFT_STATUS_ID = 1;
export const PREFERENCES_SAVED_SUCCESSFULLY = 'Preferences Saved Successfully';
export const SAVE_AND_CLOSE_TOOLTIP_MESSAGE = "Save all responses and close the wizard.";
export const COMPLETE_TOOLTIP_MESSAGE = "Save all responses and mark the wizard as completed.";
export const UNSAVED_PRODUCT_PREFERENCES_QUESTIONS_MESSAGE = "There are unsaved changes. Do you wish to save?";
export const PRODUCT_PREFERENCES_SET_SUCCESSFULLY = "Product Preferences set successfully";
export const PRODUCT_PREFERENCES_WIZARD_TYPE_ID = 1;

export const PRODUCT_PROFILE_WIZARD_TAB_OPTIONS = {
  PRODUCT_CATALOG_PREFERENCES: 'PRODUCT CATALOG PREFERENCES',
  PRODUCT_INFORMATION: 'PRODUCT INFORMATION',
};

export const ASSESSMENT_REQUEST_STATUS_SUBMITTED = 4;

export const ASSESSMENT_REQUEST_TYPE_CORL_CLEARED = 1;

export const ASSESSMENT_TYPE_CORL_CLEARED = 2;
//Product Information Wizard Constants
export const PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE = 2;
export const SINGLE_SELECT_DROPDOWN_RESPONSE_ID = 9;
export const DROPDOWN_LOOKUP_RESPONSE_ID = 11;
export const PRODUCT_INFORMATION_COMPLETED_STATUS_ID = 3;
export const PRODUCT_INFORMATION_WIZARD_TYPE_ID = 2;
export const DEFAULT_PRODUCT_INFORMATION_QUESTION_INDEX = 0;
export const PRODUCT_INFORMATION_UNSAVED_CHANGES_MESSAGE = "Leaving this page may result in lost progress. Are you sure you want to navigate away?";
export const IS_REQUIRED = 1;
export const PRODUCT_ALREADY_EXISTS = "Association of the Product 'XXXXX' already exists with this Vendor.";

//Outcome Constants
export const OUTCOME_INADEQUATE = 1;
export const OUTCOME_ADEQUATE = 2;
export const OUTCOME_NOT_APPLICABLE = 3;

export const PRODUCT_RELATED_ASSESSMENT_INITIATED_TOOLTIP_MESSAGE = "CORLCleared Assessment for this Product has started, hence the Product cannot be deleted.";

export const VENDOR_PROFILE_ASSESSMENT_TYPE_ID = 4;

export const VENDOR_COPILOT_TAB_VALUE = 'vendor-copilot';

export const ASSESSMENT_TYPE_CORL_CLEARED_INT = 2;

export const CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY = 'CORLCleared Assessment Added Successfully.';

export const ASSESSMENT_TYPE_STATUS_INCOMPLETE = 21;