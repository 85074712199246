import { NO_DATA_FOUND } from 'constants/errorMessage';
import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';
import axios from 'axios';
import {
  getVendorOnboardingImageUrlBody,
  getVendorOnboardingStageBody,
  searchAdminUserWizardStatusBody,
  searchVendorOnboardingDetailsBody,
  searchWizardPageBody,
} from 'utils/vendorOnboardingUtils';
import { vendorOnboardingWizardTabList } from 'pages/VendorOnboarding/constants';

/* User Confirmation */

export const SEARCH_USER_CONFIRMATION_LOADING = '@@vendorOnboarding/SEARCH_USER_CONFIRMATION_LOADING';
export const SEARCH_USER_CONFIRMATION_SUCCESS = '@@vendorOnboarding/SEARCH_USER_CONFIRMATION_SUCCESS';
export const SEARCH_USER_CONFIRMATION_FAILURE = '@@vendorOnboarding/SEARCH_USER_CONFIRMATION_FAILURE';

export const searchUserConfirmationDetails = (wizardId, pageId) => async (dispatch) => {
  dispatch({ type: SEARCH_USER_CONFIRMATION_LOADING });

  let body = searchVendorOnboardingDetailsBody(wizardId, pageId);
  try {
    const response = await axios.post(`/vendor/${wizardId}/onboardingwizard/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_USER_CONFIRMATION_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_USER_CONFIRMATION_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_USER_CONFIRMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_USER_CONFIRMATION_DETAILS_LOADING = '@@vendorOnboarding/POST_USER_CONFIRMATION_DETAILS_LOADING';
export const POST_USER_CONFIRMATION_DETAILS_SUCCESS = '@@vendorOnboarding/POST_USER_CONFIRMATION_DETAILS_SUCCESS';
export const POST_USER_CONFIRMATION_DETAILS_FAILURE = '@@vendorOnboarding/POST_USER_CONFIRMATION_DETAILS_FAILURE';

export const saveUserConfirmationDetails = (formData, callback) => async (dispatch) => {
  const { WizardId } = formData;
  dispatch({ type: POST_USER_CONFIRMATION_DETAILS_LOADING });
  const body = JSON.stringify(formData);

  try {
    const response = await axios.post(`/vendor/${WizardId}/wizardinstance`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_USER_CONFIRMATION_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: POST_USER_CONFIRMATION_DETAILS_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_USER_CONFIRMATION_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ABOUT_YOURSELF_DETAILS_LOADING = '@@vendorOnboarding/SEARCH_ABOUT_YOURSELF_DETAILS_LOADING';
export const SEARCH_ABOUT_YOURSELF_DETAILS_SUCCESS = '@@vendorOnboarding/SEARCH_ABOUT_YOURSELF_DETAILS_SUCCESS';
export const SEARCH_ABOUT_YOURSELF_DETAILS_FAILURE = '@@vendorOnboarding/SEARCH_ABOUT_YOURSELF_DETAILS_FAILURE';

export const searchAboutYourselfDetails =
  (wizardId = null, pageNumber = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ABOUT_YOURSELF_DETAILS_LOADING });

      let body = searchVendorOnboardingDetailsBody(wizardId, pageNumber);
      try {
        const response = await axios.post(`/vendor/${wizardId}/onboardingwizard/search`, body);

        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_ABOUT_YOURSELF_DETAILS_FAILURE,
            payload: {},
          });
        } else {
          dispatch({
            type: SEARCH_ABOUT_YOURSELF_DETAILS_SUCCESS,
            payload: response?.data?.Data?.Rows[0],
            pageNumber: pageNumber,
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ABOUT_YOURSELF_DETAILS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const POST_ABOUT_YOURSELF_LOADING = '@@vendorOnboarding/POST_ABOUT_YOURSELF_LOADING';
export const POST_ABOUT_YOURSELF_SUCCESS = '@@vendorOnboarding/POST_ABOUT_YOURSELF_SUCCESS';
export const POST_ABOUT_YOURSELF_FAILURE = '@@vendorOnboarding/POST_ABOUT_YOURSELF_FAILURE';

export const saveAboutYourselfDetails =
  (formData, wizardId = null, callback) =>
    async (dispatch) => {
      dispatch({ type: POST_ABOUT_YOURSELF_LOADING });
      try {
        const res = await axios.post(`/vendor/${wizardId}/wizardinstance`, formData);
        if (res?.data?.ErrorCount > 0) {
          dispatch(setErrors(res?.data?.Errors));
          dispatch({
            type: POST_ABOUT_YOURSELF_FAILURE,
          });
        } else {
          dispatch({
            type: POST_ABOUT_YOURSELF_SUCCESS,
            payload: res?.data,
          });
          dispatch(searchAdminUserWizardStatus());
          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: POST_ABOUT_YOURSELF_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };
export const SEARCH_VENDOR_ONBOARDING_WHATNEXT_LOADING = '@@vendorOnboarding/SEARCH_VENDOR_ONBOARDING_LOADING';
export const SEARCH_VENDOR_ONBOARDING_WHATNEXT_SUCCESS = '@@vendorOnboarding/SEARCH_VENDOR_ONBOARDING_SUCCESS';
export const SEARCH_VENDOR_ONBOARDING_WHATNEXT_FAILURE = '@@vendorOnboarding/SEARCH_VENDOR_ONBOARDING_FAILURE';

export const searchVendorOnboardingQuestion = (wizardId = null, pageNumber = null) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_ONBOARDING_WHATNEXT_LOADING });
  try {
    const body = searchVendorOnboardingDetailsBody(wizardId, pageNumber);
    const response = await axios.post(`/vendor/${wizardId}/onboardingwizard/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_VENDOR_ONBOARDING_WHATNEXT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_ONBOARDING_WHATNEXT_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
        pageNumber: pageNumber,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_ONBOARDING_WHATNEXT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

/* Organization Identification */

export const SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_LOADING = '@@vendorOnboarding/SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_LOADING';
export const SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_SUCCESS = '@@vendorOnboarding/SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_SUCCESS';
export const SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_FAILURE = '@@vendorOnboarding/SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_FAILURE';

export const searchOrganizationIdentificationDetails =
  (wizardId = null, pageNumber = null, additionalField = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_LOADING });

      let body = searchVendorOnboardingDetailsBody(wizardId, pageNumber, additionalField);
      try {
        const response = await axios.post(`/vendor/${wizardId}/onboardingwizard/search`, body);

        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_FAILURE,
            payload: {},
          });
        } else {
          dispatch({
            type: SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_SUCCESS,
            payload: response?.data?.Data?.Rows[0],
            pageNumber: pageNumber,
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const POST_ORGANIZATION_IDENTIFICATION_LOADING = '@@vendorOnboarding/POST_ORGANIZATION_IDENTIFICATION_LOADING';
export const POST_ORGANIZATION_IDENTIFICATION_SUCCESS = '@@vendorOnboarding/POST_ORGANIZATION_IDENTIFICATION_SUCCESS';
export const POST_ORGANIZATION_IDENTIFICATION_FAILURE = '@@vendorOnboarding/POST_ORGANIZATION_IDENTIFICATION_FAILURE';

export const saveOrganizationIdentificationDetails = (formData, callback) => async (dispatch) => {
  const { WizardId } = formData;
  dispatch({ type: POST_ORGANIZATION_IDENTIFICATION_LOADING });
  const body = JSON.stringify(formData);

  try {
    const response = await axios.post(`/vendor/${WizardId}/wizardinstance`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_ORGANIZATION_IDENTIFICATION_FAILURE,
      });
    } else {
      dispatch({
        type: POST_ORGANIZATION_IDENTIFICATION_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_ORGANIZATION_IDENTIFICATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

/* Product catalog */

export const SEARCH_PRODUCT_CATALOG_DETAILS_LOADING = '@@vendorOnboarding/SEARCH_PRODUCT_CATALOG_DETAILS_LOADING';
export const SEARCH_PRODUCT_CATALOG_DETAILS_SUCCESS = '@@vendorOnboarding/SEARCH_PRODUCT_CATALOG_DETAILS_SUCCESS';
export const SEARCH_PRODUCT_CATALOG_DETAILS_FAILURE = '@@vendorOnboarding/SEARCH_PRODUCT_CATALOG_DETAILS_FAILURE';

export const searchProductCatalogDetails = (wizardId, pageId, additionalField = null) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_CATALOG_DETAILS_LOADING });

  let body = searchVendorOnboardingDetailsBody(wizardId, pageId, additionalField);
  try {
    const response = await axios.post(`/vendor/${wizardId}/onboardingwizard/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_PRODUCT_CATALOG_DETAILS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_CATALOG_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_CATALOG_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const POST_PRODUCT_CATALOG_DETAILS_LOADING = '@@vendorOnboarding/POST_PRODUCT_CATALOG_DETAILS_LOADING';
export const POST_PRODUCT_CATALOG_DETAILS_SUCCESS = '@@vendorOnboarding/POST_PRODUCT_CATALOG_DETAILS_SUCCESS';
export const POST_PRODUCT_CATALOG_DETAILS_FAILURE = '@@vendorOnboarding/POST_PRODUCT_CATALOG_DETAILS_FAILURE';

export const saveProductCatalogDetails = (formData, callback) => async (dispatch) => {
  const { WizardId } = formData;
  dispatch({ type: POST_PRODUCT_CATALOG_DETAILS_LOADING });
  const body = JSON.stringify(formData);

  try {
    const response = await axios.post(`/vendor/${WizardId}/wizardinstance`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_PRODUCT_CATALOG_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: POST_PRODUCT_CATALOG_DETAILS_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_PRODUCT_CATALOG_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

/* Get Image for Wizard */

export const GET_VENDOR_ONBOARDING_IMAGE_URL_LOADING = '@@vendorProduct/GET_VENDOR_ONBOARDING_IMAGE_URL_LOADING';
export const GET_VENDOR_ONBOARDING_IMAGE_URL_SUCCESS = '@@vendorProduct/GET_VENDOR_ONBOARDING_IMAGE_URL_SUCCESS';
export const GET_VENDOR_ONBOARDING_IMAGE_URL_FAILURE = '@@vendorProduct/VENDOR_ONBOARDING_IMAGE_URL_FAILURE';

export const getVendorOnboardingImageUrl = (ImageFileName) => async (dispatch) => {
  dispatch({ type: GET_VENDOR_ONBOARDING_IMAGE_URL_LOADING });

  try {
    const body = getVendorOnboardingImageUrlBody(ImageFileName);
    const res = await axios.post(`/vendor/wizard/image/action/geturl`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_VENDOR_ONBOARDING_IMAGE_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_VENDOR_ONBOARDING_IMAGE_URL_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_ONBOARDING_IMAGE_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

/* Check vendor onboarding stage */

export const GET_VENDOR_ONBOARDING_STAGE_LOADING = '@@vendorOnboarding/GET_VENDOR_ONBOARDING_STAGE_LOADING';
export const GET_VENDOR_ONBOARDING_STAGE_SUCCESS = '@@vendorOnboarding/GET_VENDOR_ONBOARDING_STAGE_SUCCESS';
export const GET_VENDOR_ONBOARDING_STAGE_FAILURE = '@@vendorOnboarding/GET_VENDOR_ONBOARDING_STAGE_FAILURE';

export const getVendorOnboardingStage = (userId) => async (dispatch) => {
  dispatch({ type: GET_VENDOR_ONBOARDING_STAGE_LOADING });

  let body = getVendorOnboardingStageBody(userId);
  try {
    const response = await axios.post(`/vendor/${userId}/onboardingwizard/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_VENDOR_ONBOARDING_STAGE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_VENDOR_ONBOARDING_STAGE_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_ONBOARDING_STAGE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ORGANIZATION_PROFILE_DETAILS_LOADING = '@@vendorOnboarding/SEARCH_ORGANIZATION_PROFILE_DETAILS_LOADING';
export const SEARCH_ORGANIZATION_PROFILE_DETAILS_SUCCESS = '@@vendorOnboarding/SEARCH_ORGANIZATION_PROFILE_DETAILS_SUCCESS';
export const SEARCH_ORGANIZATION_PROFILE_DETAILS_FAILURE = '@@vendorOnboarding/SEARCH_ORGANIZATION_PROFILE_DETAILS_FAILURE';

export const searchOrganizationProfileDetails =
  (wizardId = null, pageNumber = null, additionalField = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ORGANIZATION_PROFILE_DETAILS_LOADING });

      let body = searchVendorOnboardingDetailsBody(wizardId, pageNumber, additionalField);
      try {
        const response = await axios.post(`/vendor/${wizardId}/onboardingwizard/search`, body);

        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
          dispatch(setErrors(errors));
          dispatch({
            type: SEARCH_ORGANIZATION_PROFILE_DETAILS_FAILURE,
            payload: {},
          });
        } else {
          dispatch({
            type: SEARCH_ORGANIZATION_PROFILE_DETAILS_SUCCESS,
            payload: response?.data?.Data?.Rows[0],
            pageNumber: pageNumber,
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ORGANIZATION_PROFILE_DETAILS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const POST_ORGANIZATION_PROFILE_LOADING = '@@vendorOnboarding/POST_ORGANIZATION_PROFILE_LOADING';
export const POST_ORGANIZATION_PROFILE_SUCCESS = '@@vendorOnboarding/POST_ORGANIZATION_PROFILE_SUCCESS';
export const POST_ORGANIZATION_PROFILE_FAILURE = '@@vendorOnboarding/POST_ORGANIZATION_PROFILE_FAILURE';

export const saveOrganizationProfileDetails =
  (formData, wizardId = null, callback) =>
    async (dispatch) => {
      dispatch({ type: POST_ORGANIZATION_PROFILE_LOADING });
      try {
        const res = await axios.post(`/vendor/${wizardId}/wizardinstance`, formData);
        if (res?.data?.ErrorCount > 0) {
          dispatch(setErrors(res?.data?.Errors));
          dispatch({
            type: POST_ORGANIZATION_PROFILE_FAILURE,
          });
        } else {
          dispatch({
            type: POST_ORGANIZATION_PROFILE_SUCCESS,
            payload: res?.data,
          });
        if (callback) {
          callback();
        }
        }
      } catch (err) {
        dispatch({
          type: POST_ORGANIZATION_PROFILE_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_WIZARD_PAGE_LOADING = '@@vendorOnboarding/SEARCH_WIZARD_PAGE_LOADING';
export const SEARCH_WIZARD_PAGE_SUCCESS = '@@vendorOnboarding/SEARCH_WIZARD_PAGE_SUCCESS';
export const SEARCH_WIZARD_PAGE_FAILURE = '@@vendorOnboarding/SEARCH_WIZARD_PAGE_FAILURE';

export const searchWizardPage = (wizardId, currentPage) => async (dispatch) => {
  dispatch({ type: SEARCH_WIZARD_PAGE_LOADING });

  let body = searchWizardPageBody(wizardId, currentPage);
  try {
    const response = await axios.post(`/vendor/${wizardId}/onboardingwizard/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_WIZARD_PAGE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_WIZARD_PAGE_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_WIZARD_PAGE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_WIZARD_TAB_LOADING = '@@vendorOnboarding/SET_WIZARD_TAB_LOADING';

export const setWizardTab = (list = vendorOnboardingWizardTabList) => async (dispatch) => {
  dispatch({
    type: SET_WIZARD_TAB_LOADING,
    payload: list
  });
};

export const SEARCH_ADMIN_USER_WIZARD_STATUS_LOADING = '@@vendorOnboarding/SEARCH_ADMIN_USER_WIZARD_STATUS_LOADING';
export const SEARCH_ADMIN_USER_WIZARD_STATUS_SUCCESS = '@@vendorOnboarding/SEARCH_ADMIN_USER_WIZARD_STATUS_SUCCESS';
export const SEARCH_ADMIN_USER_WIZARD_STATUS_FAILURE = '@@vendorOnboarding/SEARCH_ADMIN_USER_WIZARD_STATUS_FAILURE';

export const searchAdminUserWizardStatus = () => async (dispatch) => {
  dispatch({ type: SEARCH_ADMIN_USER_WIZARD_STATUS_LOADING });

  let body = searchAdminUserWizardStatusBody();
  try {
    const response = await axios.post(`vendor/wizardinstance/search`, body);

    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_ADMIN_USER_WIZARD_STATUS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_ADMIN_USER_WIZARD_STATUS_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ADMIN_USER_WIZARD_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};