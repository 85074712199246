import { isNil } from 'lodash';

export const changeQuestionnaireStatusBody = (assessmentId, statusId) => {
  return {
    AssessmentId: assessmentId,
    NewAssessmentStatus: statusId,
  };
};
export const changeFinalQuestionnaireStatusBody = (assessmentQuestionnaireId, statusId) => {
  return {
    AssessmentQuestionnaireId: assessmentQuestionnaireId,
    NewAssessmentQuestionnaireStatus: statusId,
  };
};
export const getQuestionnaireListDataBody = (assessmentId) => {
  let criterion = [
    {
      Field: 'OutreachTypeId',
      Operator: '=',
      Value: '1',
    },
  ];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }
  return {
    Intent: '',
    Fields: [
      'AssessmentName',
      'Description',
      'OutreachId',
      'OutreachName',
      'OutreachType',
      'OutreachDescription',
      'RecipientOrgId',
      'RecipientOrgName',
      'DueDate',
      'OpenedDate',
      'CompletedDate',
      'AssessmentStatus',
      'AssessmentQuestionnaireId',
      'LastUpdated',
      'ResponseLastUpdated',
      'CountOfQuestionnaires',
      'CountOfQuestions',
      'CountOfAnswers',
      'CountOfMandatoryQuestions',
      'CountOfAnswers',
      'CountOfMandatoryAnswers',
      'CountOfPositiveAnswers',
      'CountOfNegativeAnswers',
      'SumOfPositiveScore',
      'SumOfNegativeScore',
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};
export const getQuestionnaireListDataWithNameBody = (searchValue) => {
  return {
    Intent: '',
    Fields: [
      'AssessmentName',
      'Description',
      'OutreachId',
      'OutreachName',
      'OutreachType',
      'OutreachDescription',
      'RecipientOrgId',
      'RecipientOrgName',
      'DueDate',
      'OpenedDate',
      'CompletedDate',
      'AssessmentStatus',
      'AssessmentQuestionnaireId',
      'LastUpdated',
      'ResponseLastUpdated',
      'CountOfQuestionnaires',
      'CountOfQuestions',
      'CountOfAnswers',
      'CountOfMandatoryQuestions',
      'CountOfAnswers',
      'CountOfMandatoryAnswers',
      'CountOfPositiveAnswers',
      'CountOfNegativeAnswers',
      'SumOfPositiveScore',
      'SumOfNegativeScore',
    ],
    Criterion: [
      {
        Field: 'OutreachTypeId',
        Operator: '=',
        Value: '1',
      },
      {
        Field: 'AssessmentName',
        Operator: 'contains',
        Value: searchValue,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};