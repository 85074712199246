import axios from 'axios';
import { isNil } from 'lodash';
import { getErrorObject } from 'utils/apiUtils';
import {
  getAssessmentOverviewBody,
  getEvidenceReviewBody,
  getRiskRatingControlSummaryBody,
  getRiskRatingOverviewBody,
  getRiskFindingSummaryBody,
  getAssessmentByIdBody,
  getRiskRatingOverviewBodyForPortal,
  getRiskRatingControlSummaryBodyForPortal,
  getEvidenceReviewBodyForPortal,
  getAssessmentOverviewBodyForPortal,
  getRiskFindingSummaryBodyForPortal,
} from 'utils/assessmentResultsUtils';
import { setErrors } from './errorHandler';
import { ANONYMS_URL_PATH, PORTAL_2 } from 'constants/constants';

export const SET_EXPAND_ALL_SECTIONS_SUCCESS = '@assessmentResults/SET_EXPAND_ALL_SECTIONS_SUCCESS';

export const setExpandAllSections = (payload, callback) => async (dispatch) => {
  if (!isNil(payload)) {
    dispatch({
      type: SET_EXPAND_ALL_SECTIONS_SUCCESS,
      payload,
    });
  }
  if (callback) {
    callback();
  }
};

export const SET_LOADING_EXPANDED_SECTIONS = '@assessmentResults/SET_LOADING_EXPANDED_SECTIONS';

export const setLoadingExpandedSections = (payload) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_EXPANDED_SECTIONS,
    payload,
  });
};

export const CLEAR_ASSESSMENT_RESULTS = '@assessmentResults/CLEAR_ASSESSMENT_RESULTS';

export const clearAssessmentResults = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ASSESSMENT_RESULTS,
  });
};

export const SEARCH_RISK_RATING_OVERVIEW_LOADING = '@assessmentResults/SEARCH_RISK_RATING_OVERVIEW_LOADING';
export const SEARCH_RISK_RATING_OVERVIEW_SUCCESS = '@assessmentResults/SEARCH_RISK_RATING_OVERVIEW_SUCCESS';
export const SEARCH_RISK_RATING_OVERVIEW_FAILURE = '@assessmentResults/SEARCH_RISK_RATING_OVERVIEW_FAILURE';

export const getRiskRatingOverviewData =
  (id = null, source = "", isAnonyms) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_RATING_OVERVIEW_LOADING });
      try {
        let response;
        const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : "";
        if (source === PORTAL_2) {
          const body = getRiskRatingOverviewBodyForPortal(id);
          response = await axios.post(`assessment/assessmentsummary${anonymsPath}/search`, body);
        } else {
          const body = getRiskRatingOverviewBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/riskratingoverview/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_RATING_OVERVIEW_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_RATING_OVERVIEW_SUCCESS,
            payload: response?.data || {},
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_RATING_OVERVIEW_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_OVERVIEW_LOADING = '@assessmentResults/SEARCH_ASSESSMENT_OVERVIEW_LOADING';
export const SEARCH_ASSESSMENT_OVERVIEW_SUCCESS = '@assessmentResults/SEARCH_ASSESSMENT_OVERVIEW_SUCCESS';
export const SEARCH_ASSESSMENT_OVERVIEW_FAILURE = '@assessmentResults/SEARCH_ASSESSMENT_OVERVIEW_FAILURE';

export const getAssessmentOverviewData =
  (id = null, source = "", isAnonyms) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_OVERVIEW_LOADING });
      try {
        let response;
        const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : "";
        if (source === PORTAL_2) {
          const body = getAssessmentOverviewBodyForPortal(id);
          response = await axios.post(`assessment${anonymsPath}/search`, body);
        } else {
          const body = getAssessmentOverviewBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/assessmentoverview/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_OVERVIEW_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_OVERVIEW_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING = '@assessmentResults/SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING';
export const SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS = '@assessmentResults/SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS';
export const SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE = '@assessmentResults/SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE';

export const getRiskRatingControlSummaryData =
  (id = null, source = "", isAnonyms) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_RATING_CONTROL_SUMMARY_LOADING });
      try {
        let response;
        const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : "";
        if (source === PORTAL_2) {
          const body = getRiskRatingControlSummaryBodyForPortal(id);
          response = await axios.post(`assessment/productvendor/recommendations${anonymsPath}/search`, body);
        } else {
          const body = getRiskRatingControlSummaryBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/riskratingcontrol/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_RATING_CONTROL_SUMMARY_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_RATING_CONTROL_SUMMARY_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_EVIDENCE_REVIEW_LOADING = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_LOADING';
export const SEARCH_EVIDENCE_REVIEW_SUCCESS = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_SUCCESS';
export const SEARCH_EVIDENCE_REVIEW_FAILURE = '@assessmentResults/SEARCH_EVIDENCE_REVIEW_FAILURE';

export const getEvidenceReviewData =
  (id = null, source = "", isAnonyms) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_EVIDENCE_REVIEW_LOADING });
      try {
        let response;
        const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : "";
        if (source === PORTAL_2) {
          const body = getEvidenceReviewBodyForPortal(id);
          response = await axios.post(`serviceprovider/assessmentquestionnairerequirement${anonymsPath}/search`, body);
        } else {
          const body = getEvidenceReviewBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/validationevidence/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_EVIDENCE_REVIEW_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_EVIDENCE_REVIEW_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_EVIDENCE_REVIEW_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_RISK_FINDING_SUMMARY_LOADING = '@assessmentResults/SEARCH_RISK_FINDING_SUMMARY_LOADING';
export const SEARCH_RISK_FINDING_SUMMARY_SUCCESS = '@assessmentResults/SEARCH_RISK_FINDING_SUMMARY_SUCCESS';
export const SEARCH_RISK_FINDING_SUMMARY_FAILURE = '@assessmentResults/SEARCH_RISK_FINDING_SUMMARY_FAILURE';

export const getRiskFindingSummaryData =
  (id = null, source = "", isAnonyms) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_RISK_FINDING_SUMMARY_LOADING });
      try {
        let response;
        const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : "";
        if (source === PORTAL_2) {
          const body = getRiskFindingSummaryBodyForPortal(id);
          response = await axios.post(`assessment/productvendor/recommendations${anonymsPath}/search`, body);
        } else {
          const body = getRiskFindingSummaryBody(id);
          response = await axios.post(`legacy/assessment/executivesummary/risksummary/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_RISK_FINDING_SUMMARY_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_RISK_FINDING_SUMMARY_SUCCESS,
            payload: response?.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_RISK_FINDING_SUMMARY_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SAVE_ES_FEEDBACK_LOADING = '@assessmentResults/SAVE_ES_FEEDBACK_LOADING';
export const SAVE_ES_FEEDBACK_SUCCESS = '@assessmentResults/SAVE_ES_FEEDBACK_SUCCESS';
export const SAVE_ES_FEEDBACK_FAILURE = '@assessmentResults/SAVE_ES_FEEDBACK_FAILURE';

export const saveESFeedback = (feedback) => async (dispatch) => {
  dispatch({ type: SAVE_ES_FEEDBACK_LOADING });
  try {
    const response = await axios.post('/client/executivesummary/feedback', feedback);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SAVE_ES_FEEDBACK_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: SAVE_ES_FEEDBACK_SUCCESS,
        payload: response?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ES_FEEDBACK_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_BY_ID_LOADING = '@assessmentResults/SEARCH_ASSESSMENT_BY_ID_LOADING';
export const SEARCH_ASSESSMENT_BY_ID_SUCCESS = '@assessmentResults/SEARCH_ASSESSMENT_BY_ID_SUCCESS';
export const SEARCH_ASSESSMENT_BY_ID_FAILURE = '@assessmentResults/SEARCH_ASSESSMENT_BY_ID_FAILURE';

export const getAssessmentById = (id, source = "", isAnonyms) => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_BY_ID_LOADING });
  try {
    let response;
    const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : "";
    if (source === PORTAL_2) {
      const body = getAssessmentOverviewBodyForPortal(id);
      response = await axios.post(`assessment${anonymsPath}/search`, body);
    } else {
      const body = getAssessmentByIdBody(id);
      response = await axios.post(`/legacy/assessment${anonymsPath}/search`, body);
    }
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_BY_ID_SUCCESS,
        payload: response?.data || [],
        source: source === PORTAL_2
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_BY_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_LOADING = '@assessmentResults/SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_LOADING';
export const SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_SUCCESS = '@assessmentResults/SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_SUCCESS';
export const SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_FAILURE = '@assessmentResults/SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_FAILURE';

export const shareAssessmentResultToRecipients = (formData, callback) => async (dispatch) => {
  dispatch({ type: SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_LOADING });
  try {
    const response = await axios.post(`assessment/executivesummary/action/share`, formData);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_FAILURE,
        payload: [],
      });
      setErrors(response?.data?.Errors);
    } else {
      dispatch({
        type: SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_SUCCESS,
        payload: response?.data || [],
      });
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch({
      type: SHARE_ASSESSMENT_RESULT_TO_RECIPIENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};