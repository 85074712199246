import { isEmpty, isNil } from 'lodash';

export const searchVendorOnboardingDetailsBody = (wizardId = null, pageId = null, additionalField = null) => {
  let criterion = [];
  let field =  [
    'SystemName',
    'WizardName',
    'Description',
    'OnClose',
    'WizardPageId',
    'WizardPageSystemName',
    'PageNumber',
    'PageMetadata',
    'WizardPageOnClose',
    'WizardInstanceId',
    'UserId',
    'IsUnderExecution',
    'CurrentPage',
    'WizardInstancePageId',
    'PageData',
    'UserEmail',
    'FirstName',
    'LastName',
    'Title',
    'CellPhone',
    'IsWizardCompleted',
    'WizardInstanceWizardId'
  ];

  if (!isNil(wizardId)) {
    criterion.push({
      field: 'WizardId',
      operator: '=',
      value: wizardId,
    });
  }
  if (!isNil(pageId)) {
    criterion.push({
      field: 'PageNumber',
      operator: '=',
      value: pageId,
    });
  }
  if(!isEmpty(additionalField)) {
    field = [...field, additionalField];
  }
  return {
    intent: '',
    fields: field,
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorOnboardingImageUrlBody = (imageFileName = null) => {
  let criterion = [];
  if (!isNil(imageFileName)) {
    criterion.push({
      field: 'ImageFileName',
      operator: '=',
      value: imageFileName,
    });
  }

  return {
    intent: '',
    fields: [],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorOnboardingStageBody = (userId = null) => {
  let criterion = [];
  if (!isNil(userId)) {
    criterion.push({
      field: 'UserId',
      operator: '=',
      value: userId,
    });
  }

  return {
    intent: '',
    fields: [
      'SystemName',
      'WizardName',
      'Description',
      'OnClose',
      'WizardPageId',
      'WizardPageSystemName',
      'PageNumber',
      'PageMetadata',
      'WizardPageOnClose',
      'WizardInstanceId',
      'UserId',
      'IsUnderExecution',
      'CurrentPage',
      'WizardInstancePageId',
      'PageData',
      'UserEmail',
      'FirstName',
      'LastName',
      'Title',
      'CellPhone',
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};


export const searchWizardPageBody = (currentPage = null) => {
  let criterion = [];
  if (!isNil(currentPage)) {
    criterion.push({
      field: 'WizardPageId',
      operator: '=',
      value: currentPage,
    });
  }

  return {
    intent: '',
    fields: [
      'PageNumber',
      'WizardInstanceWizardId'
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchAdminUserWizardStatusBody = () => {

  return {
    intent: '',
    fields: [
      "IsAdminCompletedWizard",
      "WizardId",
      "UserId"
    ],
    Criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};