export const SET_CUSTOM_ERRORS = '@customerrorhandler/SET_CUSTOM_ERRORS';
export const setCustomErrors = (list) => async (dispatch) => {
  dispatch({
    type: SET_CUSTOM_ERRORS,
    payload: list,
  });
};

export const CLEAR_CUSTOM_ERRORS = '@customerrorhandler/CLEAR_CUSTOM_ERRORS';
export const clearCustomErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CUSTOM_ERRORS,
  });
};
