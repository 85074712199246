export const logoutIconStyle = () => {
  return {
    marginRight: '5px',
    transform: 'translateY("1px")',
    width: '22px',
    height: '16px',
  };
};

export const loaderBackDropStyle = (theme) => {
  return {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  };
};

export const logOutStyles = () => {
  return {
    color: '#000 !important',
    '&:hover': {
      background: 'none',
    },
  };
};
