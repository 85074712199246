import axios from 'axios';
import { DEFAULT_PAGE_NO, DEFAULT_ROWS_PER_PAGE } from 'pages/Dashboard/constants';
import { getErrorObject } from 'utils/apiUtils';
import { getAssessmentListBody, getAssessmentListCountBody, getCORLCLearedAssessmentListBody, getCORLClearedAssessmentListCountBody } from 'utils/vendorAssessmentUtils';

export const SEARCH_ASSESSMENT_LIST_LOADING = '@vendorAssessment/SEARCH_ASSESSMENT_LIST_LOADING';
export const SEARCH_ASSESSMENT_LIST_SUCCESS = '@vendorAssessment/SEARCH_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_FAILURE = '@vendorAssessment/SEARCH_ASSESSMENT_LIST_FAILURE';

export const getAssessmentTableListData =
  (searchData = {}, page, rowPerPage, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_LIST_LOADING });
    try {
      const body = getAssessmentListBody(searchData, page, rowPerPage, sortModel);
      const response = await axios.post(`/vendor/dashboard/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_ASSESSMENT_LIST_COUNT_LOADING = '@vendorAssessment/SEARCH_ASSESSMENT_LIST_COUNT_LOADING';
export const SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS = '@vendorAssessment/SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_COUNT_FAILURE = '@vendorAssessment/SEARCH_ASSESSMENT_LIST_COUNT_FAILURE';

export const getAssessmentTableListCount =
  (searchData = {}) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_LIST_COUNT_LOADING });
    try {
      const body = getAssessmentListCountBody(searchData);
      const response = await axios.post(`/vendor/dashboard/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_ASSESSMENT_PAGE_NO = '@vendorAssessment/SET_ASSESSMENT_PAGE_NO';

export const setAssessmentPageNo =
  (page = 0) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_PAGE_NO,
      payload: page,
    });
  };

export const SET_ASSESSMENT_ROW_PER_PAGE = '@vendorAssessment/SET_ASSESSMENT_ROW_PER_PAGE';

export const setAssessmentRowPerPage =
  (page = DEFAULT_ROWS_PER_PAGE, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_ROW_PER_PAGE,
      payload: page,
    });
    if (callback) {
      callback(page);
    }
  };

export const SEARCH_CORL_CLEARED_ASSESSMENT_LIST_LOADING = '@vendorAssessment/SEARCH_CORL_CLEARED_ASSESSMENT_LIST_LOADING';
export const SEARCH_CORL_CLEARED_ASSESSMENT_LIST_SUCCESS = '@vendorAssessment/SEARCH_CORL_CLEARED_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_CORL_CLEARED_ASSESSMENT_LIST_FAILURE = '@vendorAssessment/SEARCH_CORL_CLEARED_ASSESSMENT_LIST_FAILURE';

export const getCORLClearedAssessmentTableListData =
  (page = DEFAULT_PAGE_NO, rowPerPage = DEFAULT_ROWS_PER_PAGE, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_LOADING });
    try {
      const body = getCORLCLearedAssessmentListBody(page, rowPerPage, sortModel);
      const response = await axios.post(`vendor/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
};

export const SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_LOADING = '@vendorAssessment/SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_LOADING';
export const SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_SUCCESS = '@vendorAssessment/SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_SUCCESS';
export const SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_FAILURE = '@vendorAssessment/SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_FAILURE';

export const getCORLClearedAssessmentTableListCount =
  () =>
  async (dispatch) => {
    dispatch({ type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_LOADING });
    try {
      const body = getCORLClearedAssessmentListCountBody();
      const response = await axios.post(`vendor/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_CORL_CLEARED_ASSESSMENT_PAGE_NO = '@vendorAssessment/SET_CORL_CLEARED_ASSESSMENT_PAGE_NO';

export const setCORLClearedAssessmentPageNo =
    (page = 0) =>
    async (dispatch) => {
      dispatch({
        type: SET_CORL_CLEARED_ASSESSMENT_PAGE_NO,
        payload: page,
      });
};
  
export const SET_CORL_CLEARED_ASSESSMENT_ROW_PER_PAGE = '@vendorAssessment/SET_CORL_CLEARED_ASSESSMENT_ROW_PER_PAGE';
  
export const setCORLClearedAssessmentRowPerPage =
    (page = DEFAULT_ROWS_PER_PAGE, callback) =>
    async (dispatch) => {
      dispatch({
        type: SET_CORL_CLEARED_ASSESSMENT_ROW_PER_PAGE,
        payload: page,
      });
      if (callback) {
        callback(page);
      }
};

export const PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_LOADING = '@@vendorAssessment/PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_LOADING';
export const PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_SUCCESS = '@@vendorAssessment/PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_SUCCESS';
export const PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_FAILURE = '@@vendorAssessment/PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_FAILURE';

export const updateCORLClearedAssessmentSharedStatus = (formData) => async (dispatch) => {
  dispatch({
    type: PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_LOADING,
  });
  let tempFormData = {
    AssessmentId: formData?.ChildAssessmentId ? formData?.ChildAssessmentId : 0,
    ParentAssessmentId: formData?.ParentAssessmentId ? formData?.ParentAssessmentId : 0,
    ReleasedToClientId: formData?.ReleasedToClient,
    AssessmentRequestId: formData?.AssessmentRequestId,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`assessment/${formData?.AssessmentId}/action/releasedtoclient`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(setCORLClearedAssessmentPageNo());
      dispatch(setCORLClearedAssessmentRowPerPage());
      dispatch(getCORLClearedAssessmentTableListData());
    }
  } catch {
    dispatch({
      type: PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_FAILURE,
    });
  }
};

