import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { ORG_ID, SEND_EMAIL, USER_ID, VENDOR_APP_ID, VENDOR_USER_ROLE_ID } from 'constants/users';
import { searchVendorUsersBody } from 'utils/userUtils';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const getUsers = () => async (dispatch) => {
  dispatch({ type: USER_LIST_REQUEST });
  try {
    const res = await axios.get('/users');
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: res.data?.data,
    });
  } catch (err) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_USERS_LOADING = '@@user/SEARCH_VENDOR_USERS_LOADING';
export const SEARCH_VENDOR_USERS_SUCCESS = '@@user/SEARCH_VENDOR_USERS_SUCCESS';
export const SEARCH_VENDOR_USERS_FAILURE = '@@user/SEARCH_VENDOR_USERS_FAILURE';

export const searchVendorUsers =
  (searchValue = '') =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_VENDOR_USERS_LOADING,
    });

    var body = searchVendorUsersBody(searchValue);

    try {
      const response = await axios.post(`/app/user/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
        dispatch({
          type: SEARCH_VENDOR_USERS_FAILURE,
        });
        dispatch(setErrors(errors));
      } else {
        dispatch({
          type: SEARCH_VENDOR_USERS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch {
      dispatch({
        type: SEARCH_VENDOR_USERS_FAILURE,
      });
    }
  };

export const SET_SELECTED_USER = '@@user/SET_SELECTED_USER';

export const setSelectedUser =
  (user = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_USER,
      payload: user,
    });
  };

export const SET_SELECTED_INDEX = '@@user/SET_SELECTED_INDEX';

export const setSelectedIndex =
  (index = null) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_INDEX,
      payload: index,
    });
  };

export const SET_VENDOR_USER_FORM_EDITABLE = '@@user/SET_VENDOR_USER_FORM_EDITABLE';

export const setVendorUserFormEditable =
  (isEditable = false) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_USER_FORM_EDITABLE,
      isEditable,
    });
  };

export const ADD_VENDOR_USER_LOADING = '@@user/ADD_VENDOR_USER_LOADING';
export const ADD_VENDOR_USER_SUCCESS = '@@user/ADD_VENDOR_USER_SUCCESS';
export const ADD_VENDOR_USER_FAILURE = '@@user/ADD_VENDOR_USER_FAILURE';

export const addVendorUser = (formData) => async (dispatch) => {
  dispatch({
    type: ADD_VENDOR_USER_LOADING,
  });

  let tempFormData = {
    UserId: USER_ID,
    UserEmail: formData?.UserEmail,
    FirstName: formData?.FirstName,
    MiddleName: formData?.MiddleName,
    LastName: formData?.LastName,
    NamePrefix: formData?.NamePrefix,
    Roles: [{ UserRoleId: VENDOR_USER_ROLE_ID }],
    AppId: VENDOR_APP_ID,
    OrgId: ORG_ID,
    SendEmail: SEND_EMAIL,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/app/user`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_VENDOR_USER_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_VENDOR_USER_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchVendorUsers());
    }
  } catch {
    dispatch({
      type: ADD_VENDOR_USER_FAILURE,
    });
  }
};

export const UPDATE_VENDOR_USER_LOADING = '@@user/UPDATE_VENDOR_USER_LOADING';
export const UPDATE_VENDOR_USER_SUCCESS = '@@user/UPDATE_VENDOR_USER_SUCCESS';
export const UPDATE_VENDOR_USER_FAILURE = '@@user/UPDATE_VENDOR_USER_FAILURE';

export const updateVendorUser = (formData) => async (dispatch) => {
  dispatch({
    type: UPDATE_VENDOR_USER_LOADING,
  });

  let tempFormData = {
    UserId: formData?.UserId,
    UserEmail: formData?.UserEmail,
    FirstName: formData?.FirstName,
    MiddleName: formData?.MiddleName,
    LastName: formData?.LastName,
    NamePrefix: formData?.NamePrefix,
    Roles: [{ UserRoleId: VENDOR_USER_ROLE_ID }],
    AppId: VENDOR_APP_ID,
    OrgId: ORG_ID,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.put(`app/user/${formData?.UserId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_VENDOR_USER_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_VENDOR_USER_SUCCESS,
        payload: {
          response: response?.data,
        },
      });
      dispatch(searchVendorUsers());
    }
  } catch {
    dispatch({
      type: UPDATE_VENDOR_USER_FAILURE,
    });
  }
};

export const REMOVE_VENDOR_USER_LOADING = '@@user/REMOVE_VENDOR_USER_LOADING';
export const REMOVE_VENDOR_USER_SUCCESS = '@@user/REMOVE_VENDOR_USER_SUCCESS';
export const REMOVE_VENDOR_USER_FAILURE = '@@user/REMOVE_VENDOR_USER_FAILURE';

export const removeVendorUser = (userId, userRoleId) => async (dispatch) => {
  dispatch({
    type: REMOVE_VENDOR_USER_LOADING,
  });

  let tempFormData = {
    UserId: userId,
    Roles: [{ UserRoleId: userRoleId }],
    AppId: VENDOR_APP_ID,
    OrgId: ORG_ID,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`/app/user/${userId}/action/unassign`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: REMOVE_VENDOR_USER_FAILURE,
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: REMOVE_VENDOR_USER_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchVendorUsers());
    }
  } catch {
    dispatch({
      type: REMOVE_VENDOR_USER_FAILURE,
    });
  }
};

export const POST_RESEND_INVITE_TO_USER_LOADING = '@@users/POST_RESEND_INVITE_TO_USER_LOADING';
export const POST_RESEND_INVITE_TO_USER_SUCCESS = '@@users/POST_RESEND_INVITE_TO_USER_SUCCESS';
export const POST_RESEND_INVITE_TO_USER_FAILURE = '@@users/POST_RESEND_INVITE_TO_USER_FAILURE';

export const resendInviteToUser = (reqBody) => async (dispatch) => {
  dispatch({ type: POST_RESEND_INVITE_TO_USER_LOADING });

  const body = JSON.stringify(reqBody);
  try {
    let res = await axios.post(`/app/user/${reqBody.UserId}/action/sendemailinvite`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_RESEND_INVITE_TO_USER_FAILURE,
        payload: [],
      });
      dispatch(setErrors(res?.data?.Errors));
    } else {
      dispatch({
        type: POST_RESEND_INVITE_TO_USER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_RESEND_INVITE_TO_USER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
