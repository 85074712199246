import axios from 'axios';
import { getVendorIncidentContactBody, constructIncidentResponseContactFormData, getCustomerProductContactBody } from '../utils/vendorIncidentUtils';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';

export const GET_VENDOR_INCIDENT_CUSTOMER_DATA_LOADING = '@@vendorIncidentEscalation/GET_VENDOR_INCIDENT_CUSTOMER_DATA_LOADING';
export const GET_VENDOR_INCIDENT_CUSTOMER_DATA_SUCCESS = '@@vendorIncidentEscalation/GET_VENDOR_INCIDENT_CUSTOMER_DATA_SUCCESS';
export const GET_VENDOR_INCIDENT_CUSTOMER_DATA_FAILURE = '@@vendorIncidentEscalation/GET_VENDOR_INCIDENT_CUSTOMER_DATA_FAILURE';

export const getCustomerProductList = () => async (dispatch) => {
  dispatch({ type: GET_VENDOR_INCIDENT_CUSTOMER_DATA_LOADING });
  try {
    const response = await axios.get('app/vendor/customerproduct');
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_VENDOR_INCIDENT_CUSTOMER_DATA_FAILURE,
      });
    } else {
      dispatch({
        type: GET_VENDOR_INCIDENT_CUSTOMER_DATA_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_VENDOR_INCIDENT_CUSTOMER_DATA_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_INCIDENT_CONTACT_LIST_LOADING = '@@vendorIncidentEscalation/SEARCH_VENDOR_INCIDENT_CONTACT_LIST_LOADING';
export const SEARCH_VENDOR_INCIDENT_CONTACT_LIST_FAILURE = '@@vendorIncidentEscalation/SEARCH_VENDOR_INCIDENT_CONTACT_LIST_FAILURE';
export const SEARCH_VENDOR_INCIDENT_CONTACT_LIST_SUCCESS = '@@vendorIncidentEscalation/SEARCH_VENDOR_INCIDENT_CONTACT_LIST_SUCCESS';

export const searchVendorIncidentContactList =
  (searchText = '') =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_VENDOR_INCIDENT_CONTACT_LIST_LOADING,
    });

    const body = getVendorIncidentContactBody(searchText);

    try {
      const response = await axios.post(`/vendor/contact/search`, body);
      dispatch({
        type: SEARCH_VENDOR_INCIDENT_CONTACT_LIST_SUCCESS,
        payload: response?.data,
      });
    } catch {
      dispatch({
        type: SEARCH_VENDOR_INCIDENT_CONTACT_LIST_FAILURE,
      });
    }
  };

export const POST_VENDOR_INCIDENT_FORM_LOADING = '@@vendorIncidentEscalation/POST_VENDOR_INCIDENT_FORM_LOADING';
export const POST_VENDOR_INCIDENT_FORM_SUCCESS = '@@vendorIncidentEscalation/POST_VENDOR_INCIDENT_FORM_SUCCESS';
export const POST_VENDOR_INCIDENT_FORM_FAILURE = '@@vendorIncidentEscalation/POST_VENDOR_INCIDENT_FORM_FAILURE';

export const postVendorIncidentFormData = (data, customerVendorId, productVendorId) => async (dispatch) => {
  dispatch({
    type: POST_VENDOR_INCIDENT_FORM_LOADING,
  });

  const body = constructIncidentResponseContactFormData(data);

  try {
    const response = await axios.post(`/vendor/incidentresponsecontact`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_VENDOR_INCIDENT_FORM_FAILURE,
      });
    } else {
      dispatch({
        type: POST_VENDOR_INCIDENT_FORM_SUCCESS,
        payload: response?.data,
      });
      setTimeout(() => {
        dispatch(searchCustomerProductContactList(customerVendorId, productVendorId));
      }, 500);
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_INCIDENT_FORM_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING = '@@vendorIncidentEscalation/SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING';
export const SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS = '@@vendorIncidentEscalation/SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS';
export const SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE = '@@vendorIncidentEscalation/SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE';

export const searchCustomerProductContactList = (customerVendorId, productVendorId) => async (dispatch) => {
  dispatch({
    type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING,
  });

  const body = getCustomerProductContactBody(customerVendorId, productVendorId);

  try {
    const response = await axios.post(`/vendor/incidentresponsecontact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
      dispatch(clearCustomerProductContactList());
    } else {
      dispatch({
        type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_INCIDENT_RESPONSE_CONTACT_LOADING = '@@vendorIncidentEscalation/DELETE_INCIDENT_RESPONSE_CONTACT_LOADING';
export const DELETE_INCIDENT_RESPONSE_CONTACT_SUCCESS = '@@vendorIncidentEscalation/DELETE_INCIDENT_RESPONSE_CONTACT_SUCCESS';
export const DELETE_INCIDENT_RESPONSE_CONTACT_FAILURE = '@@vendorIncidentEscalation/DELETE_INCIDENT_RESPONSE_CONTACT_FAILURE';

export const deleteIncidentResponseContact = (id, customerVendorId, productVendorId) => async (dispatch) => {
  dispatch({
    type: DELETE_INCIDENT_RESPONSE_CONTACT_LOADING,
  });

  try {
    const response = await axios.delete(`vendor/incidentresponsecontact/${id}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_INCIDENT_RESPONSE_CONTACT_FAILURE,
      });
    } else {
      dispatch({
        type: DELETE_INCIDENT_RESPONSE_CONTACT_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchCustomerProductContactList(customerVendorId, productVendorId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_INCIDENT_RESPONSE_CONTACT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST = '@@vendorIncidentEscalation/CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST';

export const clearCustomerProductContactList = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST,
  });
};
