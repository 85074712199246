import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';

export const GET_CUSTOMERS_PRODUCTS_LIST_LOADING = '@@customersAndProducts/GET_CUSTOMERS_PRODUCTS_LIST_LOADING';
export const GET_CUSTOMERS_PRODUCTS_LIST_SUCCESS = '@@customersAndProducts/GET_CUSTOMERS_PRODUCTS_LIST_SUCCESS';
export const GET_CUSTOMERS_PRODUCTS_LIST_FAILURE = '@@customersAndProducts/GET_CUSTOMERS_PRODUCTS_LIST_FAILURE';

export const getCustomersAndProductsList = () => async (dispatch) => {
  dispatch({ type: GET_CUSTOMERS_PRODUCTS_LIST_LOADING });
  try {
    const response = await axios.get('app/vendor/customerproduct');
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_CUSTOMERS_PRODUCTS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_CUSTOMERS_PRODUCTS_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CUSTOMERS_PRODUCTS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
