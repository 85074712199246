import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';
import { getAppLookupBody, getStageOwnerLookupBody } from 'utils/lookupUtils';

export const GET_RESPONSE_CATEGORY_LIST_LOADING = '@@slaInformation/GET_RESPONSE_CATEGORY_LIST_LOADING';
export const GET_RESPONSE_CATEGORY_LIST_SUCCESS = '@@slaInformation/GET_RESPONSE_CATEGORY_LIST_SUCCESS';
export const GET_RESPONSE_CATEGORY_LIST_FAILURE = '@@slaInformation/GET_RESPONSE_CATEGORY_LIST_FAILURE';

export const getResponseCategoryList = () => async (dispatch) => {
  dispatch({ type: GET_RESPONSE_CATEGORY_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=YesNo`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_RESPONSE_CATEGORY_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_RESPONSE_CATEGORY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_PRODUCT_TYPE_LIST_LOADING = '@@lookup/GET_PRODUCT_TYPE_LIST_LOADING';
export const GET_PRODUCT_TYPE_LIST_SUCCESS = '@@lookup/GET_PRODUCT_TYPE_LIST_SUCCESS';
export const GET_PRODUCT_TYPE_LIST_FAILURE = '@@lookup/GET_PRODUCT_TYPE_LIST_FAILURE';

export const getProductTypesList = () => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_TYPE_LIST_LOADING,
  });
  const body = getAppLookupBody(['ProductType']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_PRODUCT_TYPE_LIST_SUCCESS,
      payload: res?.data,
    });
  } catch {
    dispatch({
      type: GET_PRODUCT_TYPE_LIST_FAILURE,
    });
  }
};

export const GET_VENDOR_ROLE_LIST_LOADING = '@@lookup/GET_VENDOR_ROLE_LIST_LOADING';
export const GET_VENDOR_ROLE_LIST_SUCCESS = '@@lookup/GET_VENDOR_ROLE_LIST_SUCCESS';
export const GET_VENDOR_ROLE_LIST_FAILURE = '@@lookup/GET_VENDOR_ROLE_LIST_FAILURE';

export const getVendorRolesList = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_ROLE_LIST_LOADING,
  });
  const body = getAppLookupBody(['ProductVendorRole']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_VENDOR_ROLE_LIST_SUCCESS,
      payload: res?.data,
    });
  } catch {
    dispatch({
      type: GET_VENDOR_ROLE_LIST_FAILURE,
    });
  }
};

export const GET_EVIDENCE_LOOKUP_LOADING = '@@lookup/GET_EVIDENCE_LOOKUP_LOADING';
export const GET_EVIDENCE_LOOKUP_SUCCESS = '@@lookup/GET_EVIDENCE_LOOKUP_SUCCESS';
export const GET_EVIDENCE_LOOKUP_FAILURE = '@@lookup/GET_EVIDENCE_LOOKUP_FAILURE';

export const getEvidenceLookup = () => async (dispatch) => {
  dispatch({
    type: GET_EVIDENCE_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(['EvidenceType', 'EvidenceSubType', 'FileType']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_EVIDENCE_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_EVIDENCE_LOOKUP_FAILURE,
    });
  }
};

export const GET_REQUIREMENT_STATUS_LOOKUP_LOADING = '@@lookup/GET_REQUIREMENT_STATUS_LOOKUP_LOADING';
export const GET_REQUIREMENT_STATUS_LOOKUP_SUCCESS = '@@lookup/GET_REQUIREMENT_STATUS_LOOKUP_SUCCESS';
export const GET_REQUIREMENT_STATUS_LOOKUP_FAILURE = '@@lookup/GET_REQUIREMENT_STATUS_LOOKUP_FAILURE';

export const getRequirementStatusLookup = () => async (dispatch) => {
  dispatch({
    type: GET_REQUIREMENT_STATUS_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(['RequirementStatus']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_REQUIREMENT_STATUS_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_REQUIREMENT_STATUS_LOOKUP_FAILURE,
    });
  }
};

export const GET_ACCEPTANCE_STATUS_LOOKUP_LOADING = '@@lookup/GET_ACCEPTANCE_STATUS_LOOKUP_LOADING';
export const GET_ACCEPTANCE_STATUS_LOOKUP_SUCCESS = '@@lookup/GET_ACCEPTANCE_STATUS_LOOKUP_SUCCESS';
export const GET_ACCEPTANCE_STATUS_LOOKUP_FAILURE = '@@lookup/GET_ACCEPTANCE_STATUS_LOOKUP_FAILURE';

export const getAcceptanceStatusLookup = () => async (dispatch) => {
  dispatch({
    type: GET_ACCEPTANCE_STATUS_LOOKUP_LOADING,
  });
  const body = getAppLookupBody(['CCAssessmentResultAcceptanceStatus']);
  try {
    const res = await axios.post(`app/lookup/search`, body);
    dispatch({
      type: GET_ACCEPTANCE_STATUS_LOOKUP_SUCCESS,
      payload: res?.data?.Data,
    });
  } catch {
    dispatch({
      type: GET_ACCEPTANCE_STATUS_LOOKUP_FAILURE,
    });
  }
};

export const GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING = '@@lookup/GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING';
export const GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS = '@@lookup/GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS';
export const GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE = '@@lookup/GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE';

export const getSupportTicketRequestType = () => async (dispatch) => {
  dispatch({
    type: GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=RequestType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const HELP_TICKET_TYPE_LOADING = '@@lookup/HELP_TICKET_TYPE_LOADING';
export const HELP_TICKET_TYPE_SUCCESS = '@@lookup/HELP_TICKET_TYPE_SUCCESS';
export const HELP_TICKET_TYPE_FAILURE = '@@lookup/HELP_TICKET_TYPE_FAILURE';

export const getHelpTicketType = () => async (dispatch) => {
  dispatch({
    type: HELP_TICKET_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentTicketType`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: HELP_TICKET_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: HELP_TICKET_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: HELP_TICKET_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_UPDATE_TYPE_LOADING = '@@lookup/ASSESSMENT_UPDATE_TYPE_LOADING';
export const ASSESSMENT_UPDATE_TYPE_SUCCESS = '@@lookup/ASSESSMENT_UPDATE_TYPE_SUCCESS';
export const ASSESSMENT_UPDATE_TYPE_FAILURE = '@@lookup/ASSESSMENT_UPDATE_TYPE_FAILURE';

export const getAssessmentUpdateType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_UPDATE_TYPE_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentUpdate`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_UPDATE_TYPE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_UPDATE_TYPE_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_UPDATE_TYPE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const ASSESSMENT_STATUS_LOADING = '@@lookup/ASSESSMENT_STATUS_LOADING';
export const ASSESSMENT_STATUS_SUCCESS = '@@lookup/ASSESSMENT_STATUS_SUCCESS';
export const ASSESSMENT_STATUS_FAILURE = '@@lookup/ASSESSMENT_STATUS_FAILURE';

export const getAssessmentStatusType = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_STATUS_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentStatus`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_STATUS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_STATUS_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING = '@@lookup/GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING';
export const GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS = '@@lookup/GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS';
export const GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE = '@@lookup/GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE';

export const getAssessmentStageOwnerList = () => async (dispatch) => {
  dispatch({ type: GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING });
  try {
    const body = getStageOwnerLookupBody();
    const response = await axios.post(`/legacy/lookup/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ASSESSMENT_REQUIREMENT_IPI_STATUS_LOADING = '@@lookup/ASSESSMENT_REQUIREMENT_IPI_STATUS_LOADING';
export const ASSESSMENT_REQUIREMENT_IPI_STATUS_SUCCESS = '@@lookup/ASSESSMENT_REQUIREMENT_IPI_STATUS_SUCCESS';
export const ASSESSMENT_REQUIREMENT_IPI_STATUS_FAILURE = '@@lookup/ASSESSMENT_REQUIREMENT_IPI_STATUS_FAILURE';

export const getAssessmentRequirementIPIStatus = () => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_REQUIREMENT_IPI_STATUS_LOADING,
  });
  try {
    const res = await axios.get(`/app/lookup/e=AssessmentRequirementIPIStatus`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: ASSESSMENT_REQUIREMENT_IPI_STATUS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ASSESSMENT_REQUIREMENT_IPI_STATUS_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_REQUIREMENT_IPI_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REMEDIATION_PLAN_LOOKUP_LOADING = '@@lookup/SEARCH_REMEDIATION_PLAN_LOOKUP_LOADING';
export const SEARCH_REMEDIATION_PLAN_LOOKUP_SUCCESS = '@@lookup/SEARCH_REMEDIATION_PLAN_LOOKUP_SUCCESS';
export const SEARCH_REMEDIATION_PLAN_LOOKUP_FAILURE = '@@lookup/SEARCH_REMEDIATION_PLAN_LOOKUP_FAILURE';

export const getRemediationPlanLookup = () => async (dispatch) => {
  dispatch({
    type: SEARCH_REMEDIATION_PLAN_LOOKUP_LOADING,
  });
  try {
    const body = getAppLookupBody(['RemediationGuidanceDisplayStatus', 'RemediationPreferences']);
    const res = await axios.post(`/app/lookup/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SEARCH_REMEDIATION_PLAN_LOOKUP_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_PLAN_LOOKUP_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_PLAN_LOOKUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_LOADING = '@@lookup/SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_LOADING';
export const SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_SUCCESS = '@@lookup/SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_SUCCESS';
export const SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_FAILURE = '@@lookup/SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_FAILURE';

export const getRemediationObjectiveStatusLookup = () => async (dispatch) => {
  dispatch({
    type: SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_LOADING,
  });
  try {
    const body = getAppLookupBody(['RemediationObjectiveStatus']);
    const res = await axios.post(`/app/lookup/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
