import { VENDOR_APP_ID } from 'constants/users';
import { isEmpty } from 'lodash';

export const searchUserRolesListBody = (searchItem) => {
  const criterion = [
    {
      field: 'AppId',
      operator: '=',
      value: VENDOR_APP_ID,
    },
  ];
  if (!isEmpty(searchItem)) {
    criterion.push({
      field: 'UserRoleName',
      operator: 'startswith',
      value: searchItem,
    });
  }
  return {
    intent: '',
    fields: ['UserRoleId', 'AppId', 'UserRoleName', 'AppName'],
    criterion: [
      ...criterion,
      {
        field: 'Active',
        operator: '=',
        value: 1,
      },
    ],
    sort: [],
  };
};

export const searchUserRoleListOfUserBody = (userId) => {
  return {
    intent: '',
    fields: ['UserRoleId', 'AppId', 'UserRoleName', 'AppName'],
    criterion: [
      {
        field: 'UserId',
        operator: '=',
        value: userId,
      },
    ],
    sort: [],
  };
};

export const addUserRoleToUserBody = (userRoleId, userId, oldUserRoleId) => {
  return {
    UserRoleId: userRoleId,
    UserId: userId,
    OldUserRoleId: oldUserRoleId,
  };
};
