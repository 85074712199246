import {
  GET_REQUIREMENTS_COUNT_FAILURE,
  GET_REQUIREMENTS_COUNT_LOADING,
  SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_LOADING,
  SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_SUCCESS,
  SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_FAILURE,
  PUT_PRODUCT_REQUIREMENTS_RESPONSES_LOADING,
  PUT_PRODUCT_REQUIREMENTS_RESPONSES_SUCCESS,
  PUT_PRODUCT_REQUIREMENTS_RESPONSES_FAILURE,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
  PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
  GET_REQUIREMENTS_COUNT_SUCCESS,
  SEARCH_PRODUCT_REQUIREMENTS_LIST_FAILURE,
  SEARCH_PRODUCT_REQUIREMENTS_LIST_LOADING,
  SEARCH_PRODUCT_REQUIREMENTS_LIST_SUCCESS,
  UPDATE_PRODUCT_CLEARED_STATUS_FAILURE,
  UPDATE_PRODUCT_CLEARED_STATUS_LOADING,
  UPDATE_PRODUCT_CLEARED_STATUS_SUCCESS,
  SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_LOADING,
  SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_SUCCESS,
  SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_FAILURE,
  SHOW_SAVED_PRODUCT_REQUIREMENT_STATUS,
  DELETE_EVIDENCE_ATTACHMENTS_LOADING,
  DELETE_EVIDENCE_ATTACHMENTS_SUCCESS,
  DELETE_EVIDENCE_ATTACHMENTS_FAILURE,
  SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_LOADING,
  SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_SUCCESS,
  SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_FAILURE,
  RESET_PRODUCT_REQUIREMENT_ANSWERS,
  SHOW_PRODUCT_REQUIREMENT_ANSWERS,
} from 'actions/vendorProductRequirement';
import { DELETED_SUCCESSFULLY, ERROR_MESSAGE, SAVED_SUCCESSFULLY, SUBMITTED_SUCCESSFULLY } from 'constants/errorMessage';
import { cloneDeep, isEmpty } from 'lodash';
import { MULTI_SELECT_RESPONSE_ID, SINGLE_SELECT_RESPONSE_ID } from 'pages/AnswerQuestionnaire/constants';
import { REQUIREMENT_STATUSES, TRUE } from 'pages/ProductDetails/constants';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  loadingRequirements: false,
  loadingCount: false,
  CountOfRequirements: 0,
  RequirementResponseCount: 0,
  isClearedProcessStarted: false,
  productRequirements: [],
  productRequirementQuestionnaireDetails: [],
  answersList: {},
  answerQuestionsSuccess: 0,
  selectedProductRequirements: {},
  productEvidenceAttachments: [],
  showProductRequirementAnswers: false,
};

export default function vendorProductRequirement(state = initialState, action) {
  const { type, payload } = action;
  let answersCountValue = cloneDeep(state.answerQuestionsSuccess);

  switch (type) {
    case UPDATE_PRODUCT_CLEARED_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_PRODUCT_CLEARED_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        isClearedProcessStarted: payload,
      };
    }

    case UPDATE_PRODUCT_CLEARED_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_LIST_LOADING: {
      return {
        ...state,
        loadingRequirements: true,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_LIST_SUCCESS: {
      return {
        ...state,
        loadingRequirements: false,
        productRequirements: payload,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_LIST_FAILURE: {
      return {
        ...state,
        loadingRequirements: false,
        productRequirements: [],
      };
    }
    case SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_LOADING: {
      return {
        ...state,
        loadingRequirements: true,
      };
    }

    case SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_SUCCESS: {
      return {
        ...state,
        loadingRequirements: false,
        selectedProductRequirements: payload?.length > 0 ? payload[0] : {},
      };
    }

    case SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_FAILURE: {
      return {
        ...state,
        loadingRequirements: false,
        selectedProductRequirements: {},
      };
    }

    case GET_REQUIREMENTS_COUNT_LOADING: {
      return {
        ...state,
        loadingCount: true,
      };
    }

    case GET_REQUIREMENTS_COUNT_SUCCESS: {
      return {
        ...state,
        loadingCount: false,
        CountOfRequirements: payload.TotalCountOfRequirements,
        RequirementResponseCount: payload.TotalRequirementResponseCount,
      };
    }

    case GET_REQUIREMENTS_COUNT_FAILURE: {
      return {
        ...state,
        loadingCount: false,
        CountOfRequirements: 0,
        RequirementResponseCount: 0,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_SUCCESS: {
      let savedAnswers = {};
      payload?.Data?.Rows.forEach((obj, index) => {
        if (!isEmpty(obj['QuestionResponseText'])) {
          savedAnswers[`QuestionResponseText${index}`] = obj['QuestionResponseText'].split('|');
        } else if (obj['QuestionResponseTypeId'] === SINGLE_SELECT_RESPONSE_ID || obj['QuestionResponseTypeId'] === MULTI_SELECT_RESPONSE_ID) {
          obj?.ResponseOptionList.forEach((item) => {
            if (item?.IsDefault === TRUE) {
              savedAnswers[`QuestionResponseText${index}`] = item['ResponseOptionText'].split('|');
            }
          });
        }
        if (!isEmpty(obj['QuestionResponseComment'])) {
          savedAnswers[`QuestionResponseComment${index}`] = obj['QuestionResponseComment'];
        }
        if (obj['QuestionResponseTypeId'] === SINGLE_SELECT_RESPONSE_ID || obj['QuestionResponseTypeId'] === MULTI_SELECT_RESPONSE_ID) {
          obj.ResponseOptionList.forEach((responseOptionItem, responseOptionIndex) => {
            responseOptionItem.QuestionLogicRuleList.length > 0 &&
              responseOptionItem.QuestionLogicRuleList[0].ChildQuestionList.map((childResponse, childIndex) => {
                if (!isEmpty(childResponse['QuestionResponseText'])) {
                  savedAnswers[`QuestionResponseText${index}_${responseOptionIndex}_${childIndex}`] = childResponse['QuestionResponseText'].split('|');
                } else if (
                  childResponse['QuestionResponseTypeId'] === SINGLE_SELECT_RESPONSE_ID ||
                  childResponse['QuestionResponseTypeId'] === MULTI_SELECT_RESPONSE_ID
                ) {
                  childResponse?.QuestionResponseOptionList.forEach((item) => {
                    if (item?.IsDefault === TRUE) {
                      savedAnswers[`QuestionResponseText${index}_${responseOptionIndex}_${childIndex}`] = item['ResponseOptionText'].split('|');
                    }
                  });
                }
                if (!isEmpty(childResponse['QuestionResponseComment'])) {
                  savedAnswers[`QuestionResponseComment${index}_${responseOptionIndex}_${childIndex}`] = childResponse['QuestionResponseComment'];
                }
              });
          });
        }
      });
      return {
        ...state,
        loading: false,
        productRequirementQuestionnaireDetails: payload?.Data?.Rows,
        productEvidenceAttachments: payload?.Data?.Rows,
        answersList: savedAnswers,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        productRequirementQuestionnaireDetails: [],
        answersList: {},
        productEvidenceAttachments: [],
      };
    }

    case PUT_PRODUCT_REQUIREMENTS_RESPONSES_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case PUT_PRODUCT_REQUIREMENTS_RESPONSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        answerQuestionsSuccess: answersCountValue + 1,
      };
    }

    case PUT_PRODUCT_REQUIREMENTS_RESPONSES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS: {
      if (payload === REQUIREMENT_STATUSES.ALL_RESPONDED) {
        toast.success(SUBMITTED_SUCCESSFULLY);
      }
      return {
        ...state,
        loading: false,
      };
    }

    case PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_EVIDENCE_ATTACHMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_EVIDENCE_ATTACHMENTS_SUCCESS: {
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_EVIDENCE_ATTACHMENTS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        productEvidenceAttachments: payload?.Data?.Rows,
      };
    }

    case SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        productEvidenceAttachments: [],
      };
    }

    case SHOW_SAVED_PRODUCT_REQUIREMENT_STATUS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        answerQuestionsSuccess: 0,
      };
    }

    case RESET_PRODUCT_REQUIREMENT_ANSWERS: {
      return {
        ...state,
        loading: false,
        productRequirementQuestionnaireDetails: [],
        answersList: {},
        productEvidenceAttachments: [],
        selectedProductRequirements: {},
      };
    }

    case SHOW_PRODUCT_REQUIREMENT_ANSWERS: {
      return {
        ...state,
        loading: false,
        showProductRequirementAnswers: payload,
      };
    }

    default:
      return state;
  }
}
