import { toast } from 'react-toastify';
import {
  SEARCH_USER_ROLE_LIST_LOADING,
  SEARCH_USER_ROLE_LIST_FAILURE,
  SEARCH_USER_ROLE_LIST_SUCCESS,
  SEARCH_USER_ROLE_LIST_OF_USER_LOADING,
  SEARCH_USER_ROLE_LIST_OF_USER_FAILURE,
  SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS,
  ADD_USER_ROLE_TO_USER_LOADING,
  ADD_USER_ROLE_TO_USER_FAILURE,
  ADD_USER_ROLE_TO_USER_SUCCESS,
} from '../actions/userRoles';
import { ERROR_MESSAGE, USER_ROLE_ADDED_SUCCESSFULLY, USER_ROLE_REMOVED_SUCCESSFULLY } from '../components/Common/AlertMessages';

const initialState = {
  loading: false,
  userRoleListOfUserLoading: false,
  userRoleList: [],
  userRoleListOfUser: [],
};

export const userRoles = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_USER_ROLE_LIST_LOADING:
    case ADD_USER_ROLE_TO_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_USER_ROLE_LIST_OF_USER_LOADING: {
      return {
        ...state,
        userRoleListOfUserLoading: true,
      };
    }
    case SEARCH_USER_ROLE_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_USER_ROLE_TO_USER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_USER_ROLE_LIST_OF_USER_FAILURE: {
      return {
        ...state,
        userRoleListOfUserLoading: false,
        userRoleListOfUser: [],
      };
    }
    case SEARCH_USER_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        userRoleList: payload?.Data?.Rows,
      };
    }
    case SEARCH_USER_ROLE_LIST_OF_USER_SUCCESS: {
      return {
        ...state,
        userRoleListOfUserLoading: false,
        userRoleListOfUser: payload?.Data?.Rows,
      };
    }
    case ADD_USER_ROLE_TO_USER_SUCCESS: {
      toast.success(USER_ROLE_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
