import { ASSESSMENT_COMPLETE_NO_ACTION_REQUIRED, ASSESSMENT_COMPLETE_TERMINATED, YES_VALUE } from 'constants/constants';
import { SECURITY_PROCESSING_VENDOR_RESPONSE } from 'pages/AssessmentsOverview/constants';
import { isValidJSON } from './jsonUtils';
import isNil from 'lodash/isNil';

export const shouldBlockUserRespondQuestion = (assessmentInfo, questionnaireSubmitted) => {
  if (!assessmentInfo) return true; // If no assessment info, should disabled form for safety

  // If assessment is completed, terminated, or paused, should block user from responding
  const shouldBlockFromAssessmentStatus =
    [ASSESSMENT_COMPLETE_NO_ACTION_REQUIRED, ASSESSMENT_COMPLETE_TERMINATED, SECURITY_PROCESSING_VENDOR_RESPONSE].includes(assessmentInfo.AssessmentStatusId) ||
    assessmentInfo.IsPaused;
  if (shouldBlockFromAssessmentStatus) return true;

  return questionnaireSubmitted === YES_VALUE;
};

// Use case for this function
// When using DRU with assessment in portal 1, the date response is text. Ex: "Yes"
// So this function is to check if the response is a valid string with timestamp format or not before parsing it
export const isValidDateResponse = (questionResponseText) => {
  if (isNil(questionResponseText)) return false;
  return isValidJSON(questionResponseText);
};
