import { YES_VALUE } from 'constants/constants';
import { isArray, isEmpty, isNil, isString } from 'lodash';
import { TOTAL_COUNT_OF_RISK_FINDINGS } from 'pages/AssessmentDetails/constants';
import { ASSESSMENT_LABEL_TYPE } from 'pages/AssessmentsOverview/AssessmentLabel';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: 'contains',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

export const searchActiveAssessmentsBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientName',
      'AssessmentName',
      'AssessmentLabel',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'ProductVendorId',
      'AssessmentStage',
      'MasterStepName',
      'PortalId',
      'AssessmentStatusName',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'CountOfActiveAssessments',
      'QuestionnaireSummary',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatusName',
      'DeliveryStatus',
      'AssessmentStatusId',
      'AssignedContactName',
      'AssessmentRequestId',
      'ClientRequestorName',
      'AllAssessment',
      'IsEscalated',
      'IsExpedite',
      'IsPaused',
      'LatestAssessmentActionLog'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchCompletedAssessmentsBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientName',
      'AssessmentName',
      'AssessmentLabel',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'ProductVendorId',
      'AssessmentStage',
      'MasterStepName',
      'PortalId',
      'AssessmentStatusName',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'CountOfCompletedAssessments',
      'QuestionnaireSummary',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatusName',
      'DeliveryStatus',
      'AssessmentStatusId',
      'AssignedContactName',
      'AssessmentRequestId',
      'ClientRequestorName',
      'AllAssessment',
      'IsEscalated',
      'IsExpedite',
      'IsPaused',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchRemediationAssessmentsBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientName',
      'AssessmentName',
      'AssessmentLabel',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'ProductVendorId',
      'AssessmentStage',
      'MasterStepName',
      'PortalId',
      'AssessmentStatusName',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'CountOfRemediationAssessments',
      'QuestionnaireSummary',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatusName',
      'DeliveryStatus',
      'AssessmentStatusId',
      'AssignedContactName',
      'AssessmentRequestId',
      'ClientRequestorName',
      'AllAssessment',
      'IsEscalated',
      'IsExpedite',
      'IsPaused',
      'LatestAssessmentActionLog'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
export const getAssessmentConfigurationBody = () => {
  return {
    intent: '',
    fields: ['MasterStepName', 'AssessmentDisplayStatus', 'Status', 'AssessmentDisplayStatusDropDown'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
export const searchAssessmentsListBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientName',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStage',
      'AssessmentLabel',
      'ProductVendorId',
      'MasterStepName',
      'PortalId',
      'AssessmentStatusName',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'QuestionnaireSummary',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatusName',
      'DeliveryStatus',
      'AllAssessment',
      'IsEscalated',
      'LatestAssessmentActionLog'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentOverviewPortalAssessmentDetailsSearchBody = (assessmentId, assessmentRequestId, assessmentQuestionnaireId = null) => {

  const criterion = [
  {
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    },
    {
      Field: 'AssessmentRequestId',
      Operator: '=',
      Value: assessmentRequestId,
    },
  ]

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    },)
  }

  return {
    intent: '',
    fields: [
      'AssessmentName',
      'DueDate',
      'ProductId',
      'ProductName',
      'AssessmentStatus',
      'MasterStepName',
      'AssessmentStage',
      'LastUpdated',
      'CountOfMandatoryQuestions',
      'CountOfMandatoryAnswers',
      'CountOfRequirement',
      'CountOfSubmittedRequirement',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'Source',
      'AssessmentQuestionnaireId',
      'VendorOrgName',
      'ClientOrgName',
      'ClientOrgId',
      'InsertUserName',
      'AssessmentRequestId',
      'VendorContactFullName',
      'VendorContactEmail',
      'QuestionnaireTemplateTypeId',
      'QuestionnaireTemplateType',
      'AssessmentRequestFirstQuestionResponse',
      'AssessmentQuestionnaireTemplateList',
      'AssessmentQuestionnaireQuestionResponseMode',
      'InsertTimestamp',
      'AssessmentDueDate',
      'AssessmentType',
      'IsEscalated',
      'LatestAssessmentActionLog',
      'AssessmentIsEscalated',
      'IsExpedite',
      'IsPaused',
      'AssessmentDisplayStatus',
      'UserEmail',
      'ProductVendorId',
      'ResumedTimestamp',
      "SecCertProgress",
      "AllowSecCert",
      "AnswerBySecCert",
      "AcceptedSecCertTypes",
      'VendorOrgId',
      'CompletedDate'
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentOverviewDynamicAssessmentDetailsSearchBody = (assessmentId) => {
  return {
    intent: '',
    fields: [
      'ClientId',
      'ClientName',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'VendorContactFullName',
      'VendorContactEmail',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'AssessmentStatusName',
      'AssessmentStatusId',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'QuestionnaireSummary',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'LastUpdated',
      'Source',
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: assessmentId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentNotesBody = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    intent: '',
    fields: ['StartDate', 'NewActionDetailsComments', 'NoteComments', 'Name', 'ModifiedOn', 'MaxLastUpdated'],
    Criterion: criterion,
    sort: [
      {
        field: 'CreatedOn',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getQuestionnaireInfoBodyForAssessment = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    Intent: '',
    Fields: [
      'AssessmentName',
      'ProductId',
      'ProductVendorId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'QuestionnaireSummary',
      'DeliveryStatus',
      'DeliveryStatusName',
      'LastUpdated',
      'CountOfMandatoryQuestions',
      'CountOfMandatoryAnswers',
      'QuestionnaireTemplateId',
      'QuestionnaireTemplateType',
      'AssessmentStatus',
      'QuestionnaireTemplateTypeId',
      'QuestionnaireTemplateName',
      'QuestionnaireTemplateStatus',
      'CountOfRequirement',
      'CountOfSubmittedRequirement',
      'Source',
      'AssessmentQuestionnaireId',
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getQuestionnaireInfoBody = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    Intent: '',
    Fields: [
      'ClientOrgId',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'QuestionnaireSummary',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'LastUpdated',
      'Source',
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentSearchBody = (id) => {
  return {
    Intent: '',
    Fields: ['AssessmentId', 'AssessmentQuestionnaireId', 'Source', 'DisplayExecutiveSummaryLink'],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentPortal1RedirectBody = () => {
  return {
    Intent: '',
    Fields: ['LegacySettings_AssessmentQuestionnaireQuestionUrl'],
    Criterion: [],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const searchRemediationGuidanceBody = (assessmentId = null, clientOrgId = null, fieldName = TOTAL_COUNT_OF_RISK_FINDINGS) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      field: 'AssessmentId',
      operator: '=',
      value: assessmentId,
    });
  }

  if (!isNil(clientOrgId)) {
    criterion.push({
      field: 'ClientOrgId',
      operator: '=',
      value: clientOrgId,
    });
  }

  let field = ['RemediationGuidanceId', 'AssessmentId']

  if (!isNil(field)) {
    field = [...field, fieldName];
  }

  return {
    intent: '',
    fields: field,
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchRemediationGuidanceDetailsBody = (assessmentId = null, clientOrgId = null, searchData = {}) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      field: 'AssessmentId',
      operator: '=',
      value: assessmentId,
    });
  }

  if (!isNil(clientOrgId)) {
    criterion.push({
      field: 'ClientOrgId',
      operator: '=',
      value: clientOrgId,
    });
  }

  criterion.push({
    field: 'AdequacyId',
    operator: '=',
    value: 1,
  });

  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }

  return {
    intent: '',
    fields: [
      'RemediationGuidanceId',
      'AssessmentQuestionnaireRequirementId',
      'AssessmentId',
      'ClientOrgId',
      'RequirementId',
      'RequirementStatus',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'RequirementGroup',
      'ControlNumber',
      'DefaultTier',
      'QuestionnaireTemplateRequirementId',
      'RecommendationStatus',
      'Severity',
      'AssessmentId',
      'DisplayStatus',
      //"InadequecyReasonIPIList",
      'CompleteDate',
      'InsertTimestamp',
      //"LatestRiskFindingResponse",
      'RequiresRemediation',
      'DisplayStatusId',
      'InadequacyReasonId',
      'InadequacyReasonName',
      'InadequecyReasonDescription',
      'InadequacyReasonRecommendation',
      'AssessmentInsertTimestamp',
      'LatestRiskFindingResponse',
      "ProductVendorId",
      "RemediationResponseTrackerId",
      "RemediationDueDate",
      "InadequecyReasonIPIList"
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchRemediationListBody = (assessmentId = null, clientId = null) => {
  let criterion = [{
    "field": "AdequacyId",
    "operator": "=",
    "value": 1
  }];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }
  if (!isNil(clientId)) {
    criterion.push({
      Field: 'ClientOrgId',
      Operator: '=',
      Value: clientId,
    });
  }
  return {
    intent: '',
    fields: [
      "RemediationGuidanceId",
      "AssessmentQuestionnaireRequirementId",
      "AssessmentId",
      "ClientOrgId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "QuestionnaireTemplateRequirementId",
      "RecommendationStatus",
      "Severity",
      "AssessmentId",
      "DisplayStatus",
      "CompleteDate",
      "InsertTimestamp",
      "RequiresRemediation",
      "DisplayStatusId",
      "InadequacyReasonId",
      "InadequacyReasonName",
      "InadequecyReasonDescription",
      "InadequacyReasonRecommendation",
      "AssessmentInsertTimestamp",
      "LatestRiskFindingResponse",
      "ProductVendorId",
      "RemediationResponseTrackerId",
      "RemediationDueDate",
      "InadequecyReasonIPIList"
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
}
export const searchRemediationDetailsBody = (assessmentId = null, clientId = null, assessmentQuestionnaireRequirementId = null) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }
  if (!isNil(clientId)) {
    criterion.push({
      Field: 'ClientOrgId',
      Operator: '=',
      Value: clientId,
    });
  }
  if (!isNil(assessmentQuestionnaireRequirementId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireRequirementId',
      Operator: '=',
      Value: assessmentQuestionnaireRequirementId,
    });
  }
  return {
    intent: '',
    fields: [
      "RemediationGuidanceId",
      "RemediationGuidanceDetailId",
      "AssessmentQuestionnaireRequirementId",
      "AssessmentId",
      "ClientOrgId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "QuestionnaireTemplateRequirementId",
      "RecommendationStatus",
      "Severity",
      "AssessmentId",
      "DisplayStatus",
      "InadequecyReasonIPIList",
      "CompleteDate",
      "InsertTimestamp",
      "LatestRiskFindingResponse",
      "RequiresRemediation",
      "DisplayStatusId",
      "InadequacyReasonId",
      "InadequacyReasonName",
      "InadequecyReasonDescription",
      "InadequacyReasonRecommendation",
      "AssessmentInsertTimestamp",
      "ProductVendorId",
      "RemediationResponseTrackerId",
      "IsRemResponseSubmitted",
      "NotesToVendor"
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
}

export const getDeclineRiskFindingLookupBody = (remediationResponseReasonTypeId = null) => {
  let criterion = [];

  if (!isNil(remediationResponseReasonTypeId)) {
    criterion.push({
      Field: 'RemediationResponseReasonTypeId',
      Operator: '=',
      Value: remediationResponseReasonTypeId,
    });
  }

  return {
    intent: '',
    fields: [
      "RemediationResponseReasonId",
      "RemediationResponseReasonType",
      "Reason",
      "DisplayOrder"
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
}

export const getVendorCopilotPortalAssessmentDetailsSearchBody = (assessmentId = null, assessmentQuestionnaireId = null) => {
  const criterion = [];

  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    },)
  }

  return {
    intent: '',
    fields: [
      'AssessmentName',
      'DueDate',
      'ProductId',
      'ProductName',
      'AssessmentStatus',
      'MasterStepName',
      'AssessmentStage',
      'LastUpdated',
      'CountOfMandatoryQuestions',
      'CountOfMandatoryAnswers',
      'CountOfRequirement',
      'CountOfSubmittedRequirement',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'Source',
      'AssessmentQuestionnaireId',
      'QuestionnaireTemplateTypeId',
      'QuestionnaireTemplateType',
      'AssessmentRequestFirstQuestionResponse',
      'AssessmentQuestionnaireTemplateList',
      'AssessmentQuestionnaireQuestionResponseMode',
      'InsertTimestamp',
      'AssessmentType',
      'LatestAssessmentActionLog',
      'AssessmentDisplayStatus',
      'VendorId',
      'VendorName',
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVerifyCopilotAssessmentStatusBody = (assessmentId = null, assessmentQuestionnaireId = null) => {
  const criterion = [];

  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    },)
  }

  return {
    intent: '',
    fields: [
      'AssessmentQuestionnaireTemplateList',
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentLabel = (assessment) => {
  let label = null;
  if (assessment?.IsEscalated === YES_VALUE) {
    label = ASSESSMENT_LABEL_TYPE.ESCALATED;
  } else if (assessment?.IsExpedite === YES_VALUE) {
    label = ASSESSMENT_LABEL_TYPE.EXPEDITED;
  } else if (assessment?.IsPaused === YES_VALUE) {
    label = ASSESSMENT_LABEL_TYPE.PAUSED;
  }
  return label;
};