import { getPreferencesListBody } from 'utils/communicationPreferencesUtils';
import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';

export const SEARCH_PREFERENCES_LIST_LOADING = '@communicationPreferences/SEARCH_PREFERENCES_LIST_LOADING';
export const SEARCH_PREFERENCES_LIST_SUCCESS = '@communicationPreferences/SEARCH_PREFERENCES_LIST_SUCCESS';
export const SEARCH_PREFERENCES_LIST_FAILURE = '@communicationPreferences/SEARCH_PREFERENCES_LIST_FAILURE';

export const getCommunicationPreferencesList = () => async (dispatch) => {
  dispatch({ type: SEARCH_PREFERENCES_LIST_LOADING });
  try {
    const body = getPreferencesListBody();
    const response = await axios.post(`/communicationpreference/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_PREFERENCES_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_PREFERENCES_LIST_SUCCESS,
        payload: response.data?.Data?.Rows || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PREFERENCES_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_COMMUNICATION_PREFERENCE_LOADING = '@communicationPreferences/SET_COMMUNICATION_PREFERENCE_LOADING';
export const SET_COMMUNICATION_PREFERENCE_SUCCESS = '@communicationPreferences/SET_COMMUNICATION_PREFERENCE_SUCCESS';
export const SET_COMMUNICATION_PREFERENCE_FAILURE = '@communicationPreferences/SET_COMMUNICATION_PREFERENCE_FAILURE';

export const setCommunicationPreference = (body) => async (dispatch) => {
  dispatch({ type: SET_COMMUNICATION_PREFERENCE_LOADING });
  try {
    const response = await axios.post(
      `communicationpreference/${body?.CommunicationEventId}/usercommunicationpreference/action/updatenotification`,
      body
    );
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SET_COMMUNICATION_PREFERENCE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SET_COMMUNICATION_PREFERENCE_SUCCESS,
        payload: response.data || [],
      });
      dispatch(getCommunicationPreferencesList());
    }
  } catch (err) {
    dispatch({
      type: SET_COMMUNICATION_PREFERENCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
