export const REVIEW_SUMMARY_TAB_OPTIONS = {
  ALL: 'ALL',
  WITH_MISSING: 'WITH MISSING',
  COMPLETED: 'COMPLETED'
};

export const REVIEW_SUMMARY_TAB_LIST = [
  {
    label: REVIEW_SUMMARY_TAB_OPTIONS.ALL,
    value: REVIEW_SUMMARY_TAB_OPTIONS.ALL,
  },
  {
    label: REVIEW_SUMMARY_TAB_OPTIONS.WITH_MISSING,
    value: REVIEW_SUMMARY_TAB_OPTIONS.WITH_MISSING,
  },
  {
    label: REVIEW_SUMMARY_TAB_OPTIONS.COMPLETED,
    value: REVIEW_SUMMARY_TAB_OPTIONS.COMPLETED,
  },
];

export const REMOVE_HTML_TAGS_REGEX = /<\/?[^>]+(>|$)/g ;

export const SELECTION_TYPE_COPILOT = 'Copilot Selection';
export const SELECTION_TYPE_DRU = 'DRU Selection';
export const SELECTION_TYPE_COPILOT_PLUS_DRU = 'Copilot Plus DRU Selection';

export const RESET_DRU_WITH_COMPANION = '2';