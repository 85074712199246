export const PRODUCT_CATALOG_FIRST_PAGE = 1;
export const PRODUCT_CATALOG_FOURTH_PAGE = 4;
export const PRODUCT_CATALOG_SEVENTH_PAGE = 7;
export const PRODUCT_CATALOG_NINTH_PAGE = 9;
export const PRODUCT_CATALOG_THIRTEENTH_PAGE = 13;
export const ORG_PROFILE_TWELFTH_PAGE = 12;
export const ORG_PROFILE_TENTH_PAGE = 10;
export const ORG_PROFILE_FOURTEENTH_PAGE = 14;
export const PRODUCT_CATALOG_SIXTEENTH_PAGE = 16;
export const PRODUCT_CATALOG_LAST_PAGE = 5;
export const ORG_PROFILE_LAST_PAGE = 17;

export const COVERAGE_ID = 'CoverageId';
export const SECURITY_CERTIFICATION_LIST = 'SecurityCertificationList';
export const SECURITY_CERTIFICATION_ID = 'SecurityCertificationId';

export const SAVE = 'Save';
export const NEXT = 'Next';

export const PRODUCT_DATA_TYPES = '9'