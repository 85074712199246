import { isArray, isEmpty, isNil, isString } from 'lodash';
import { PARENT_QUESTION_ID_DEFAULT_VALUE } from 'pages/AssessmentQuestionnaire/constants';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: 'startswith',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

export const getAssessmentQuestionnaireRequirementSearchBody = (assessmentQuestionnaireId) => {
  return {
    Intent: '',
    Fields: [
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementGroup',
      'RequirementName',
      'AssessmentId',
      'QuestionnaireTemplateId',
      'UpdateTimestamp',
      'CountOfMandatoryAnswers',
      'CountOfMandatoryQuestions',
      'CountOfAnswers',
      'CountOfQuestions',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'RequirementNumber',
      'AssessmentName',
      'AssessmentStatus',
      'RequirementStatus',
      'IsMet',
      'LastUpdated',
      'AssessmentQuestionnaireRequirementId',
      'DynRequirementId',
      'RequirementMetUnMetStatus',
      'ExternalTicketId',
      'Adequacy',
      'IsSystem',
      'IsValidated',
    ],
    Criterion: [
      {
        Field: 'AssessmentQuestionnaireId',
        Operator: '=',
        Value: assessmentQuestionnaireId,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentQuestionnaireResponseBody = (requirementId, excludeCanOrgUploadEvidence, requirementGroupId) => {
  let fields = [
    'AssessmentQuestionnaireId',
    'QuestionnaireTemplateQuestionId',
    'QuestionId',
    'QuestionResponseCategory',
    'QuestionResponseText',
    'QuestionResponseComment',
    'ResponseScore',
    'DisplayOrder',
    'IsRequired',
    'QuestionText',
    'AllowCommentInResponse',
    'QuestionType',
    'QuestionResponseType',
    'ResponseOptionList',
    'ChildQuestionList',
    'QuestionLogicRuleList',
    'UpdateTimestamp',
    'CountOfMandatoryQuestionsAndAnswers',
    'AllowFileUpload',
    'RequirementId',
    'QuestionPlainText',
    'QuestionResponseMode',
    'AiGeneratedResponseText',
    'AiGeneratedResponseComment',
    'DruResponseText',
    'DruResponseComment',
    'AssessmentQuestionnaireQuestionResponseMode',
    'AssessmentName'
  ];
  if (!excludeCanOrgUploadEvidence) {
    fields.push('CanOrgUploadEvidence')
  }
  return {
    Intent: '',
    Fields: fields,
    Criterion: [
      {
        Field: requirementGroupId ? 'RequirementGroupId' : 'RequirementId',
        Operator: '=',
        Value: requirementGroupId || requirementId,
      },
      {
        Field: 'ParentQuestionId',
        Operator: '=',
        Value: PARENT_QUESTION_ID_DEFAULT_VALUE,
      },
    ],
    Sort: [
      {
        Field: requirementGroupId ? 'RequirementId' : 'DisplayOrder',
        Order: 'ASC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};

export const getEvidenceDetailsBody = (searchData) => {
  let criterion = [];
  
  if (!isEmpty(searchData)) {
    let filteredSearchData = { ...searchData };
    criterion = [...criterion, ...getMappedSearchCriterion(filteredSearchData)];
  }

  return {
    intent: '',
    fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DocumentName',
      'EvidenceType',
      'SubType',
      'FileType',
      'VendorFileUploadLogId',
      'VendorComments',
      'InsertTimestamp',
      'UUID'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'DocumentName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getShowMeRedirectionModallDetailsBody = (searchData) => {
  return {
    intent: '',
    criterion: [{ field: 'QuestionnaireTemplateId', operator: '=', value: searchData }],
    fields: ['ShowMe', 'LegacyAssessmentQuestionnaireId'],
    sort: [{ field: 'LegacyAssessmentQuestionnaireId', order: 'DESC' }],
    page: 0,
    pageSize: 0,
  };
};

export const searchAssessmentByIdBody = (assessmentId) => {
  let criterion = [];
  if (!isEmpty(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }
  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'ClientName',
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStage',
      'MasterStepName',
      'PortalId',
      'AssessmentStatusName',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'DisplayExecutiveSummaryLink',
      'LastUpdated',
      'AssessmentTypeId',
      'CapabilityGroup',
      'Domain',
      'Source',
      'AssessmentRequestId',
      'QuestionnaireSummary',
      'DisplayExecutiveSummaryLink',
      'DeliveryStatusName',
      'DeliveryStatus',
      'LatestAssessmentActionLog'
    ],
    criterion: criterion,
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getResetQuestionnaireBody = (assessmentQuestionnaireId, questionnaireQuestionResponseResetTypeId, assessmentId) => {

  const payload = isNil(assessmentQuestionnaireId) && !isNil(assessmentId) ? {
    AssessmentId: assessmentId,
    QuestionnaireQuestionResponseResetTypeId: questionnaireQuestionResponseResetTypeId,
  }
    :
    {
      AssessmentQuestionnaireId: assessmentQuestionnaireId,
      QuestionnaireQuestionResponseResetTypeId: questionnaireQuestionResponseResetTypeId,
    };

  return payload;
}

export const getAssessmentQuestionnaireRequirementGroupSearchBody = (assessmentQuestionnaireId = null) => {

  let criterion = [];
  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }

  return {
    Intent: '',
    Fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'RequirementStatus',
      'RequirementGroup',
      'ControlNumber',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};