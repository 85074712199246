import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import {
  searchCustomerProductsReqBody,
  searchCustomerReqBody,
  searchCustomerWithFirstNameReqBody,
  searchProductGroupReqBody,
  searchProductListReqBody,
  getCustomerSearchBody,
} from '../utils/customerUtils';
import { setCustomErrors } from './customErrorHandler';
import { setErrors } from './errorHandler';

export const SEARCH_CUSTOMER_LIST_LOADING = '@@customer/SEARCH_CUSTOMER_LIST_LOADING';
export const SEARCH_CUSTOMER_LIST_SUCCESS = '@@customer/SEARCH_CUSTOMER_LIST_SUCCESS';
export const SEARCH_CUSTOMER_LIST_FAILURE = '@@customer/SEARCH_CUSTOMER_LIST_FAILURE';

export const searchCustomerList = (searchValue, callback) => async (dispatch) => {
  let body = {};
  if (searchValue) {
    body = JSON.stringify(searchCustomerWithFirstNameReqBody(searchValue));
  } else {
    body = JSON.stringify(searchCustomerReqBody());
  }

  dispatch({
    type: SEARCH_CUSTOMER_LIST_LOADING,
  });

  try {
    const response = await axios.post('/vendor/customer/search', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: SEARCH_CUSTOMER_LIST_FAILURE,
        payload: getErrorObject(),
      });
    } else {
      dispatch({
        type: SEARCH_CUSTOMER_LIST_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CUSTOMER_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CUSTOMER_TO_LIST_LOADING = '@@customer/ADD_CUSTOMER_TO_LIST_LOADING';
export const ADD_CUSTOMER_TO_LIST_SUCCESS = '@@customer/ADD_CUSTOMER_TO_LIST_SUCCESS';
export const ADD_CUSTOMER_TO_LIST_FAILURE = '@@customer/ADD_CUSTOMER_TO_LIST_FAILURE';

export const addCustomerToList = (reqBody, callback) => async (dispatch) => {
  const body = JSON.stringify(reqBody);

  dispatch({
    type: ADD_CUSTOMER_TO_LIST_LOADING,
  });

  try {
    const response = await axios.post('/vendor/customer', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CUSTOMER_TO_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ADD_CUSTOMER_TO_LIST_SUCCESS,
        payload: response,
      });
      dispatch(searchCustomerList());
      if (callback) {
        callback(response?.data?.Data?.Success?.CustomerOrgName);
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_CUSTOMER_TO_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_CUSTOMER_FROM_LIST_LOADING = '@@customer/DELETE_CUSTOMER_FROM_LIST_LOADING';
export const DELETE_CUSTOMER_FROM_LIST_SUCCESS = '@@customer/DELETE_CUSTOMER_FROM_LIST_SUCCESS';
export const DELETE_CUSTOMER_FROM_LIST_FAILURE = '@@customer/DELETE_CUSTOMER_FROM_LIST_FAILURE';

export const deleteCustomerFromList = (customerId, index, callback) => async (dispatch) => {
  dispatch({
    type: DELETE_CUSTOMER_FROM_LIST_LOADING,
  });

  try {
    const response = await axios.delete(`/vendor/customer/${customerId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_CUSTOMER_FROM_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_CUSTOMER_FROM_LIST_SUCCESS,
        payload: { response, index },
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: DELETE_CUSTOMER_FROM_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_CUSTOMER_INDEX = '@@customer/SET_SELECTED_CUSTOMER_INDEX';

export const setSelectedCustomerIndex = (index, callback) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_CUSTOMER_INDEX,
    payload: {
      index,
    },
  });
  if (callback) {
    callback();
  }
};

export const SEARCH_PRODUCT_GROUP_LIST_LOADING = '@@productGroup/SEARCH_PRODUCT_GROUP_LIST_LOADING';
export const SEARCH_PRODUCT_GROUP_LIST_SUCCESS = '@@productGroup/SEARCH_PRODUCT_GROUP_LIST_SUCCESS';
export const SEARCH_PRODUCT_GROUP_LIST_FAILURE = '@@productGroup/SEARCH_PRODUCT_GROUP_LIST_FAILURE';

export const searchProductGroupList = () => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_GROUP_LIST_LOADING });
  let body = JSON.stringify(searchProductGroupReqBody());
  try {
    const response = await axios.post('vendor/productgroup/search', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_LIST_LOADING = '@@productGroup/SEARCH_PRODUCT_LIST_LOADING';
export const SEARCH_PRODUCT_LIST_SUCCESS = '@@productGroup/SEARCH_PRODUCT_LIST_SUCCESS';
export const SEARCH_PRODUCT_LIST_FAILURE = '@@productGroup/SEARCH_PRODUCT_LIST_FAILURE';

export const searchProductList = (productGroupId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_LIST_LOADING });
  let body = JSON.stringify(searchProductListReqBody(productGroupId));
  try {
    const response = await axios.post('vendor/product/search', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: SEARCH_PRODUCT_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CUSTOMER_PRODUCTS_LOADING = '@@customer/SEARCH_CUSTOMER_PRODUCTS_LOADING';
export const SEARCH_CUSTOMER_PRODUCTS_SUCCESS = '@@customer/SEARCH_CUSTOMER_PRODUCTS_SUCCESS';
export const SEARCH_CUSTOMER_PRODUCTS_FAILURE = '@@customer/SEARCH_CUSTOMER_PRODUCTS_FAILURE';

export const searchCustomerProducts = (customerOrgId, customerVendorId) => async (dispatch) => {
  const body = JSON.stringify(searchCustomerProductsReqBody(customerVendorId));

  dispatch({
    type: SEARCH_CUSTOMER_PRODUCTS_LOADING,
  });

  try {
    const response = await axios.post(`vendor/customer/${customerOrgId}/product/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: SEARCH_CUSTOMER_PRODUCTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_CUSTOMER_PRODUCTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CUSTOMER_PRODUCTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_PRODUCT_TO_CUSTOMER_LOADING = '@@customer/ADD_PRODUCT_TO_CUSTOMER_LOADING';
export const ADD_PRODUCT_TO_CUSTOMER_SUCCESS = '@@customer/ADD_PRODUCT_TO_CUSTOMER_SUCCESS';
export const ADD_PRODUCT_TO_CUSTOMER_FAILURE = '@@customer/ADD_PRODUCT_TO_CUSTOMER_FAILURE';

export const addProductToCustomer = (customerId, productId, productName, callback) => async (dispatch) => {
  let reqBody = {
    CustomerVendorId: customerId,
    ProductVendorId: productId,
  };

  const body = JSON.stringify(reqBody);

  dispatch({
    type: ADD_PRODUCT_TO_CUSTOMER_LOADING,
  });

  try {
    const response = await axios.post(`vendor/customer/${customerId}/product`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_PRODUCT_TO_CUSTOMER_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: ADD_PRODUCT_TO_CUSTOMER_SUCCESS,
        payload: {
          response: response?.data,
          productName: productName,
        },
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_PRODUCT_TO_CUSTOMER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_PRODUCT_FROM_CUSTOMER_LOADING = '@@customer/DELETE_PRODUCT_FROM_CUSTOMER_LOADING';
export const DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS = '@@customer/DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS';
export const DELETE_PRODUCT_FROM_CUSTOMER_FAILURE = '@@customer/DELETE_PRODUCT_FROM_CUSTOMER_FAILURE';

export const removeProductFromCustomer = (productId, customerId, productIndex, forcedState, callback) => async (dispatch) => {
  dispatch({
    type: DELETE_PRODUCT_FROM_CUSTOMER_LOADING,
  });

  let url = `vendor/customer/${customerId}/product/${productId}`;
  if (forcedState) {
    url = `${url}?forceDelete=true`;
  }

  try {
    const response = await axios.delete(url);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setCustomErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_PRODUCT_FROM_CUSTOMER_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS,
        payload: { productIndex },
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: DELETE_PRODUCT_FROM_CUSTOMER_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CUSTOMER_LIST_IN_PROFILE_LOADING = '@@customer/SEARCH_CUSTOMER_LIST_IN_PROFILE_LOADING';
export const SEARCH_CUSTOMER_LIST_IN_PROFILE_SUCCESS = '@@customer/SEARCH_CUSTOMER_LIST_IN_PROFILE_SUCCESS';
export const SEARCH_CUSTOMER_LIST_IN_PROFILE_FAILURE = '@@customer/SEARCH_CUSTOMER_LIST_IN_PROFILE_FAILURE';

export const getCustomerList =
  (searchText = '') =>
  async (dispatch) => {
    dispatch({ type: SEARCH_CUSTOMER_LIST_IN_PROFILE_LOADING });

    const body = getCustomerSearchBody(searchText);

    try {
      const res = await axios.post(`/customer/search`, body);
      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_CUSTOMER_LIST_IN_PROFILE_FAILURE,
          payload: {},
        });
      } else {
        dispatch({
          type: SEARCH_CUSTOMER_LIST_IN_PROFILE_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CUSTOMER_LIST_IN_PROFILE_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };
