import {
  GET_VENDOR_INCIDENT_CUSTOMER_DATA_LOADING,
  GET_VENDOR_INCIDENT_CUSTOMER_DATA_FAILURE,
  GET_VENDOR_INCIDENT_CUSTOMER_DATA_SUCCESS,
  SEARCH_VENDOR_INCIDENT_CONTACT_LIST_LOADING,
  SEARCH_VENDOR_INCIDENT_CONTACT_LIST_FAILURE,
  SEARCH_VENDOR_INCIDENT_CONTACT_LIST_SUCCESS,
  POST_VENDOR_INCIDENT_FORM_LOADING,
  POST_VENDOR_INCIDENT_FORM_FAILURE,
  POST_VENDOR_INCIDENT_FORM_SUCCESS,
  SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING,
  SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE,
  SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS,
  DELETE_INCIDENT_RESPONSE_CONTACT_LOADING,
  DELETE_INCIDENT_RESPONSE_CONTACT_FAILURE,
  DELETE_INCIDENT_RESPONSE_CONTACT_SUCCESS,
  CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST,
} from '../actions/vendorIncidentEscalation';

const initialState = {
  loading: false,
  customerList: [],
  customerProductContactList: [],
  vendorContactList: [],
  formDataList: [],
};

export function vendorIncidentEscalation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VENDOR_INCIDENT_CUSTOMER_DATA_LOADING:
    case SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_LOADING:
    case POST_VENDOR_INCIDENT_FORM_LOADING:
    case SEARCH_VENDOR_INCIDENT_CONTACT_LIST_LOADING:
    case DELETE_INCIDENT_RESPONSE_CONTACT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_VENDOR_INCIDENT_CUSTOMER_DATA_FAILURE:
    case SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_FAILURE:
    case POST_VENDOR_INCIDENT_FORM_FAILURE:
    case SEARCH_VENDOR_INCIDENT_CONTACT_LIST_FAILURE:
    case DELETE_INCIDENT_RESPONSE_CONTACT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_VENDOR_INCIDENT_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerList: payload?.Data,
      };
    }

    case SEARCH_INCIDENT_CUSTOMER_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerProductContactList: payload?.Data?.Rows,
      };
    }

    case POST_VENDOR_INCIDENT_FORM_SUCCESS: {
      return {
        ...state,
        loading: false,
        formDataList: {
          ...state.formDataList,
          formDataList: payload,
        },
      };
    }

    case SEARCH_VENDOR_INCIDENT_CONTACT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorContactList: payload?.Data?.Rows,
      };
    }

    case DELETE_INCIDENT_RESPONSE_CONTACT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_CUSTOMER_PRODUCT_CONTACT_LIST: {
      return {
        ...state,
        customerProductContactList: [],
      };
    }

    default:
      return state;
  }
}
