import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styledInputBase } from './searchBarStyle';
import { searchBarStyle } from '../VendorProfile/vendorProfileStyles';
import { escapeSpecialCharacters } from 'utils/specialCharactersUtils';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '12px',
  backgroundColor: '#FFFFFF',
  marginRight: theme.spacing(),
  marginLeft: 0,
  height: '54px',
  marginTop: '40px',
  marginBottom: '24px',
  boxShadow: '-4px -4px 40px rgba(0,0,0,0.1)',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: '13px 13px 13px 20px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const SearchBar = ({ onKeyUp, clearFieldState, placeholder = 'Search', className = '' }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (clearFieldState) {
      setValue('');
    }
  }, [clearFieldState]);

  const handleKeyUp = (event) => {
    if (onKeyUp) {
      const escapedValue = escapeSpecialCharacters(event.target.value);
      onKeyUp({ ...event, target: { ...event.target, value: escapedValue } });
    }
  };

  return (
    <>
      <Search sx={searchBarStyle} className={className}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          sx={styledInputBase}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search' }}
          onKeyUp={handleKeyUp}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Search>
    </>
  );
};
export default SearchBar;
