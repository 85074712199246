export const getValueLabelList = (list, keyPair = 'valueLabel') => {
  if (keyPair === 'label') {
    return list.map((it) => {
      return {
        label: it.Label,
        value: it.Label,
      };
    });
  } else if (keyPair === 'value') {
    return list.map((it) => {
      return {
        label: it.Value,
        value: it.Value,
      };
    });
  } else {
    return list.map((it) => {
      return {
        label: it.Label,
        value: it.Value,
      };
    });
  }
};

export const getSelectOptionsList = (list, label, value) => {
  const optionsList =
    list?.length > 0 &&
    list?.map((it) => {
      return {
        label: it[label],
        value: it[value],
      };
    });
  return optionsList;
};
