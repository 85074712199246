import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { quickToggleBoxWrapper, quickToggleButtonStyle, quickToggleItem } from './NavBarStyles';

const QuickToggles = (props) => {

  const { list, count } = props;
  const badgeContent = count > 9 ? '9+' : count || '';

  return (
    <Box sx={quickToggleBoxWrapper}>
      {list.map((item, index) => (
        <Box key={index} sx={quickToggleItem}>
          <Button sx={quickToggleButtonStyle} color="secondary" onClick={item.onClick} disableRipple>
            <FontAwesomeIcon icon={item.icon} style={{fontSize: '24px'}} />
          </Button>
          {item?.showBadge && badgeContent && <Badge badgeContent={badgeContent} color="primary" />}
        </Box>
      ))}
    </Box>
  );
};

export default QuickToggles;
