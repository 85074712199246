import { faCheck, faGlobe, faListDots, faMagnifyingGlass, faScrewdriverWrench, faShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SCOPING_QUESTIONNAIRE = 'Scoping Questionnaire';
export const CC_REQUIREMENT_RESPONSE = 'CORLCleared Requirement Response';
export const REQUIREMENTS_RESPONSE = 'Requirements Response';
export const CC_MAINTENANCE = 'CORLCleared Maintenance';

export const PREFERENCE_ACCEPT_AUTOMATICALLY_RELEASE_RESULTS = 1;
export const PREFERENCE_DENY = 2;
export const PREFERENCE_ACCEPT_APPROVE_CLIENT_REQUESTS = 3;
export const TRUE = 1;
export const FALSE = 0;

export const ACCEPTABLE_PREFERENCE_STATUS_IDS = [
  PREFERENCE_ACCEPT_AUTOMATICALLY_RELEASE_RESULTS,
  PREFERENCE_ACCEPT_APPROVE_CLIENT_REQUESTS,
  PREFERENCE_DENY
];

export const REQUIREMENTS = {
  ORGANIZATIONAL: 1,
  PRODUCT: 2,
};

export const REQUIREMENT_STATUSES = {
  AWAITING_RESPONSE: 1,
  ALL_RESPONDED: 2,
  CORL_PROCESSING: 3,
  MET_REQUIREMENT: 4,
  PARTALLY_MET_REQUIREMENT: 6,
  UNMET_REQUIREMENT: 5,
  NOT_APPLICABLE: 7,
};

export const CORL_CLEARED_IN_PROGRESS_SECTIONS = [
  {
    id: 1,
    name: SCOPING_QUESTIONNAIRE,
    icon: <FontAwesomeIcon icon={faMagnifyingGlass} size="2x" />,
  },
  {
    id: 2,
    name: REQUIREMENTS_RESPONSE,
    icon: <FontAwesomeIcon icon={faListDots} size="2x" />,
  },
  {
    id: 3,
    name: CC_MAINTENANCE,
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} size="2x" />,
  },
];

export const CC_ASSESSMENT_READY = 1;
export const DENIED_SUCCESSFULLY = 'Denied Successully';
export const ACCEPTED_SUCCESSFULLY = 'Accepted Successully';
export const ASSESSMENT_ACCEPTED = 3;
export const DELETE_SELECTED_EVIDENCE = 'The selected file will be deleted. Are you sure?';
export const PRODUCT_RECOMMENDATIONS_UPDATED_SUCCESSFULLY = 'Recommendation Updated Successfully';
export const ASSESSMENT_STATUS_REMEDIATION_PLAN_REVIEW = 26;
export const ASSESSMENT_STATUS_CORL_CLEARED_MAINTENANCE = 28;

export const CORL_CLEARED_STATUSES = {
  NOT_CORL_CLEARED: 1,
  CORL_CLEARED_PARTICIPANT: 2,
  CORL_CLEARED: 3,
};

export const ACCEPT_CC_MESSAGE =
  'By accepting these assessment results, CORL will use them for assessment requests from our clients. You will also be able to provide a copy of the CORLCleared Credential to your other customers who may not be a CORL client. Please confirm below.';
export const DENY_CC_MESSAGE = 'It appears you selected to deny the assessment results. Please provide additional comments below as to why.';

export const PRODUCT_DETAILS_SIDEBAR = [
  {
    icon: <FontAwesomeIcon icon={faGlobe} />,
    label: 'OVERVIEW',
  },
  {
    icon: <FontAwesomeIcon icon={faCheck} />,
    label: 'REQUIREMENTS',
  },
  {
    icon: <FontAwesomeIcon icon={faShield} />,
    label: 'RECS. FOR IMPROVEMENT',
  },
  {
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    label: 'UPCOMING MAINTENANCE',
  },
];

export const PROGRESS_STEPS = ['PRODUCT SCOPING', 'REQUIREMENT RESPONSES', 'CORLCLEARED MAINTENANCE'];
export const REQUIREMENT_RESPONSES = 'REQUIREMENT RESPONSES';
export const PRODUCT_DETAILS_SIDEBAR_OVERVIEW_TAB = 0;
export const PRODUCT_DETAILS_SIDEBAR_REQUIREMENTS_TAB = 1;
export const PRODUCT_DETAILS_SIDEBAR_RECOMMENDATIONS_TAB = 2;
export const PRODUCT_DETAILS_UPCOMING_MAINTENANCE_TAB = 3;
export const NOT_APPLICABLE = 'NOT APPLICABLE';

export const ACCEPT_RECOMMENDATION = 1;
export const DECLINE_RECOMMENDATION = 2;
export const PROVIDE_FEEDBACK = 3;
export const PROVIDE_FEEDBACK_TEXT = "Provide Feedback";

export const PENDING_RECOMMENDATION_TAB = 0;
export const ACCEPTED_RECOMMENDATIONS_TAB = 1;
export const REJECTED_RECOMMENDATIONS_TAB = 2;

export const OVERDUE = 'OVERDUE';
export const NO_EXPIRATION = 'NO EXPIRATION';
export const TODAY = 'TODAY';
export const OVERVIEW_DAYS = -1;
export const TODAY_DUE_DAYS = 0;

export const PRODUCT_RECOMMENDATION_ACCEPT_CONFIRMATION_MESSAGE = 'Are you sure you want to accept this recommendation?';
export const PRODUCT_RECOMMENDATION_REJECT_CONFIRMATION_MESSAGE = 'Are you sure you want to decline this recommendation?';
export const PRODUCT_RECOMMENDATION_PROVIDE_FEEDBACK_CONFIRMATION_MESSAGE = 'Are you sure you want to provide feedback to this recommendation?';


export const PRODUCT_DETAILS_RECOMMENDATIONS_SIDEBAR = [
  {
    label: 'PENDING RESPONSE',
  },
  {
    label: 'ACCEPTED RECOMMENDATIONS',
  },
  {
    label: 'REJECTED RECOMMENDATIONS',
  },
];

export const NOW = 'NOW';
export const JUST_NOW = 'JUST NOW';

export const PENDING_RECOMMENDATION_GUIDE_TEXT = `Below are a list of recommendations that will improve your assessment risk ratings. Please review each recommendation's associated milestones and either (a) agree to implement the recommendations or (b) disagree to implement the recommendations.`;
export const ACCEPTED_RECOMMENDATION_GUIDE_TEXT = `Below are a list of recommendations that you committed to implement. Each recommendation has a set of milestones that track your incremental progress of meeting the requirement. Please provide us updates on each milestone by its due date.`;
export const REJECTED_RECOMMENDATION_GUIDE_TEXT = `Below are a list of recommendations that you rejected. We will let you know if a client asks you to remediate the unmet requirement and you can update your commitment in the future.`;

export const ASSESSMENT_RESULTS_READY_STATUS_ID = 25;

export const RECS_FOR_IMPROVEMENT = 'RECS. FOR IMPROVEMENT';
export const UPCOMING_MAINTENANCE = 'UPCOMING MAINTENANCE';
export const UPDATE = 'update';
export const REMOVE_SELECTED_FILE_FROM_HELP_REQUEST = 'This action will remove the selected file from request form. Are you sure?';


export const NO_RECOMMENDATIONS_FOUND_TEXT = "No Recommendations found.";
export const NO_PENDING_RECOMMENDATIONS_TEXT = "No Pending Recommendations.";
export const COMPLETED_STATUS_TEXT = 'completed';
export const COMPLETED_STATUS_RAW_TEXT = 'Completed';
export const COMPLETED_AUDITOR_TOOLTIP = "This IPI has already been completed, awaiting CORL Auditor confirmation.";