export const HIGH = 'high';
export const LOW = 'low';
export const MEDIUM = 'medium';
export const OVERDUE = 'overdue';
export const PENDING = 'pending';
export const IN_PROGRESS = 'in progress';
export const COMPLETED = 'complete';

export const NO_REMEDIATION_AVAILABLE = 'No Remediations Available';

export const COMPLETION_DATE = 'CompletionDate';
export const COMPLETE_INDEX = 3;

export const REMEDIATION_EXPORT_FILE_NAME = "Remediation Objectives";

export const DUE_IN_NEXT_30_DAYS = 'Due in Next 30 Days';
export const DUE_IN_NEXT_90_DAYS = 'Due in Next 90 Days';

export const OVERDUE_DATE = 'overdue date';
export const DUE_DATE = 'due date';
export const COMPLETED_DATE = 'completed';

export const DUE_IN_NEXT_YEAR = 'Due in Next Year';
export const DUE_IN_OVER_YEAR = 'Due in Over a Year';

export const REMEDIATION_OBJECTIVE_STATUS_COMPLETED = 3;