import { isArray, isEmpty, isString, isNil } from 'lodash';
import { ESCAPE_QUOTE_REGEX } from 'constants/constants';
const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      if(key === 'ValidStartDate' || key === 'ValidExpiryDate') {
        criterion.push({
          Field: key,
          Operator: '=',
          Value: searchData[key],
        });
      } else {
        criterion.push({
          Field: key,
          Operator: 'startswith',
          Value: searchData[key],
        });
      }
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

export const getUploadEvidenceBody = (data) => {
  let body = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'UploadedFile') {
      body.append(key, data[key]);
    } else if(key === 'ValidStartDate' || key === 'ValidExpiryDate'){
      if(!isNil(data[key])) {
        body.append(key, data[key] ? `"${data[key]?.replace(/"/g, ESCAPE_QUOTE_REGEX)}"` : null)
      }
    } else if(key === 'ProductVendorEvidenceList'){
      body.append(key, JSON.stringify(data[key]));         
    } else {
      if(!isNil(data[key])) {
        body.append(key, JSON.stringify(data[key]?.toString()));
      }
    }
  });
  return body;
};

export const getEvidenceDetailsBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DocumentName',
      'EvidenceType',
      'SubType',
      'FileType',
      'VendorFileUploadLogId',
      'VendorComments',
      'InsertTimestamp',
      'ValidStartDate',
      'UUID',
      'ValidExpiryDate',
      'ProductVendorEvidenceList',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'DocumentName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const addEvidenceClearCommentLogBody = (body) => {
  return {
    Comment: body?.Comment,
    AssessmentQuestionnaireQuestionResponseId: body?.AssessmentQuestionnaireQuestionResponseId,
  };
};

export const getEvidenceStartDateLookupBody = () => {
  return {
    intent: '',
    fields: [
      'DistinctValidStartDate',
      'ValidStartDate'
    ],
    criterion: [],
    sort: [
      {
        field: 'ValidStartDate',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getEvidenceEndDateLookupBody = () => {
  return {
    intent: '',
    fields: [
      'DistinctValidExpiryDate',
      'ValidExpiryDate'
    ],
    criterion: [],
    sort: [
      {
        field: 'ValidExpiryDate',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
