import { isArray, isEmpty, isNil, isString } from 'lodash';
import { DEFAULT_PARENT_QUESTIONNAIREID, ORG_EVIDENCE_CATEGORY_TYPE_ID, PRODUCT_EVIDENCE_CATEGORY_TYPE_ID } from 'pages/Products/VendorCoPilot/constants';
import { VENDOR_PROFILE_ASSESSMENT_TYPE_ID } from 'pages/Products/constants';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key])) {
      criterion.push({
        Field: key,
        Operator: 'contains',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

export const copilotAssessmentDetailsSearchBody = () => {
  return {
    intent: '',
    fields: [
      'AssessmentName',
      'RecipientOrgId',
      'AssessmentType',
      'InsertTimestamp',
      'AssessmentQuestionnaireId',
      'AssessmentSetupQuestionnaireTemplate',
      'AssessmentSetupQuestionnaireSystemName',
      'AssessmentQuestionnaireTemplateList',
      'AssessmentQuestionnaireQuestionResponseMode',
    ],
    criterion: [
      {
        Field: 'AssessmentTypeId',
        Operator: '=',
        Value: VENDOR_PROFILE_ASSESSMENT_TYPE_ID,
      },
    ],
    sort: [
      {
        Field: 'InsertTimestamp',
        Order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const updateCopilotPageBody = (page) => {
  return {
    InitialVendorProfileSetupCurrentPage: page,
  };
};

export const initialiseCopilotProcessBody = () => {
  return {
    AssessmentTypeId: VENDOR_PROFILE_ASSESSMENT_TYPE_ID,
  };
};

export const getOrganisationalSecurityEvidenceRequirementListBody = (assessmentId) => {
  let criterion = [{
    Field: 'EvidenceCategoryTypeId',
    Operator: '=',
    Value: ORG_EVIDENCE_CATEGORY_TYPE_ID,
  }];

  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  return {
    intent: '',
    fields: [
      "AssessmentSetupQuestionnaireTemplateId",
      "EvidenceCategoryType",
      "SystemName",
      "EvidenceCategoryName",
      "Description",
      "DisplayOrder",
      "AssessmentQuestionnaireEvidenceCategoryEvidenceInstanceList"
    ],
    criterion: criterion,
    sort: [
      {
        "Field": "DisplayOrder",
         "Order": "ASC"
      }
    ],
    page: 0,
    pageSize: 0,
  };
};

export const updateOrganisationalFileDescriptionBody = (comment, evidenceId) => {
  return {
    VendorComments: comment,
    EvidenceId: evidenceId,
  };
};

export const updateProductFileDescriptionBody = (comment, evidenceId) => {
  return {
    VendorComments: comment,
    EvidenceId: evidenceId,
  };
};

export const getProductSecurityEvidenceRequirementListBody = (productVendorId) => {
  let criterion = [{
    Field: 'EvidenceCategoryTypeId',
    Operator: '=',
    Value: PRODUCT_EVIDENCE_CATEGORY_TYPE_ID,
  }];

  if (!isNil(productVendorId)) {
    criterion.push({
      Field: 'ProductVendorId',
      Operator: '=',
      Value: productVendorId,
    });
  }

  return {
    intent: '',
    fields: [
      "AssessmentSetupQuestionnaireTemplateId",
      "EvidenceCategoryType",
      "SystemName",
      "EvidenceCategoryName",
      "Description",
      "DisplayOrder",
      "AssessmentQuestionnaireEvidenceCategoryEvidenceInstanceList"
    ],
    criterion: criterion,
    sort: [
      {
        "Field": "DisplayOrder",
         "Order": "ASC"
      }
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getProductSpecificRequirementProductListBody = (searchData) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }
  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'VendorOrgId',
      'VendorOrgName',
      'VendorUrl',
      'ProductName',
      'ProductType',
      'ProductVendorRole',
      'ProductId',
      'ProductGroup',
      'ProductNameForVendor',
      'IsClearedProcessStarted',
      'ExternalTicketId',
      'CCDynVRASId',
      'CORLClearedStatus',
      'ProductVendorAssessment',
      'ProductCategory',
      'CountOfConfirmAssociatedClient',
      'CountOfPendingApprovalAssociatedClient',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchOrganisationalReviewSummaryQuestionsBody = () => {
  return {
    intent: '',
    fields: ['AssessmentQuestionnaireId', 'QuestionId', 'QuestionText'],
    criterion: [],
    sort: [
      {
        Field: 'DisplayOrder',
        Order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getProductSpecificRequirementSummaryQuestionListBody = (type = null, assessmentQuestionnaireId = null) => {

  let criterion = [
    {
      Field: 'ParentQuestionId',
      Operator: '=',
      Value: DEFAULT_PARENT_QUESTIONNAIREID,
    },
  ];

  if (!isNil(type)) {
    criterion.push({
      Field: 'QuestionResponseCategoryId',
      Operator: '=',
      Value: type,
    });
  }

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }

  return {
    intent: '',
    fields: [
      'AssessmentQuestionnaireId',
      'QuestionId',
      'QuestionText',
      'QuestionResponseText',
      'QuestionResponseComment',
      'QuestionResponseMode',
      'QuestionResponseCategoryId',
      'QuestionResponseCategory',
      'AssessmentId',
      'ParentQuestionId',
      'TotalCountOfQuestionsAndAnswers',
      'IsRequired',
      'RequirementId',
      'RequirementType',
      'AssessmentQuestionnaireName',
      'VendorOrgName',
      'AssessmentName',
      "QuestionType",
      "QuestionResponseType",
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchOrganisationalReviewSummaryRequirementsBody = (assessmentId, assessmentQuestionnaireId) => {
  let criterion = [];

  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }

  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementGroup',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'ControlNumber',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'AssessmentQuestionnaireQuestionResponseList',
      'ProductName',
      'AssessmentName'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getSearchVendorCopilotImages = (imageName = null) => {
  let criterion = [];

  criterion.push({
    Field: 'ImageFileName',
    Operator: '=',
    Value: imageName,
  });

  return {
    intent: '',
    fields: [],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductReviewSummaryRequirementsBody = (assessmentId, assessmentQuestionnaireId) => {
  let criterion = [];

  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }

  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementGroup',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'ControlNumber',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'AssessmentQuestionnaireQuestionResponseList',
      'AssessmentId',
      'ProductName'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductReviewSummaryQuestionsBody = () => {
  return {
    intent: '',
    fields: ['AssessmentQuestionnaireId', 'QuestionId', 'QuestionText'],
    criterion: [],
    sort: [
      {
        Field: 'DisplayOrder',
        Order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getSearchCopilotDru = (assessmentId) => {
  const intent = '';
  const fields = ['AssessmentId', 'AssessmentQuestionnaireId', 'QuestionId', 'QuestionText', 'IncludeVendorAssessment'];
  const criterion = [];

  const sort = [];
  const page = 0;
  const pageSize = 0;

  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  return {
    intent,
    fields,
    criterion,
    sort,
    page,
    pageSize,
  };
};

export const updateCopilotStatusBody = (assessmentId, assessmentQuestionnaireId) => {
  return {
    "AssessmentId": assessmentId,
    "AssessmentQuestionnaireId": assessmentQuestionnaireId
  };
};

export const searchGeneratedResponseStatusBody = (assessmentId) => {
  let criterion = [];

  if (!isNil(assessmentId)) {
    criterion.push({
      "Field": 'AssessmentId',
      "Operator": '=',
      "Value": assessmentId,
    });
  }

  return {
    "Intent": '',
    "Fields": [
      "AssessmentId",
      "ParentQuestionId",
      "AssessmentQuestionnaireId",
      "AiGeneratedResponseText",
      "DruResponseText",
      "TotalCountOfCoPilotGeneratedQuestionsAndAnswers",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0,
  };
};