import axios from "axios";
import { getSupportTicketBody } from "utils/supportUtils";
import { setErrors } from "./errorHandler";
import { getErrorObject } from "utils/apiUtils";
import { NO_DATA_FOUND } from "constants/errorMessage";

export const SEARCH_SUPPORT_TICKET_DETAILS_LOADING = '@@supportTicket/SEARCH_SUPPORT_TICKET_DETAILS_LOADING';
export const SEARCH_SUPPORT_TICKET_DETAILS_SUCCESS = '@@supportTicket/SEARCH_SUPPORT_TICKET_DETAILS_SUCCESS';
export const SEARCH_SUPPORT_TICKET_DETAILS_FAILURE = '@@supportTicket/SEARCH_SUPPORT_TICKET_DETAILS_FAILURE';

export const searchSupportTicketDetails = (supportTicketID, searchStatus) => async (dispatch) => {
  dispatch({ type: SEARCH_SUPPORT_TICKET_DETAILS_LOADING });
  let body = getSupportTicketBody(supportTicketID, searchStatus);
  try {
    const response = await axios.post(`app/vendor/supportticket/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter(
        (errorItem) => errorItem.Message !== NO_DATA_FOUND
      );
      dispatch({
        type: SEARCH_SUPPORT_TICKET_DETAILS_FAILURE,
        payload: [],
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_SUPPORT_TICKET_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_SUPPORT_TICKET_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CREATE_SUPPORT_TICKET_LOADING = '@@supportTicket/CREATE_SUPPORT_TICKET_LOADING';
export const CREATE_SUPPORT_TICKET_SUCCESS = '@@supportTicket/CREATE_SUPPORT_TICKET_SUCCESS';
export const CREATE_SUPPORT_TICKET_FAILURE = '@@supportTicket/CREATE_SUPPORT_TICKET_FAILURE';

export const createSupportTicket = (formData, callback) => async (dispatch) => {
  dispatch({
    type: CREATE_SUPPORT_TICKET_LOADING,
  });

  let tempFormData = {
    ...formData,
  };

  delete tempFormData["UploadedFileList"];

  let body = new FormData();
  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  formData?.UploadedFileList.forEach((supportTicketFile) => {
    body.append(`UploadedFileList`, supportTicketFile.file);
  });

  try {
    const response = await axios.post(`vendor/supportticket`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: CREATE_SUPPORT_TICKET_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: CREATE_SUPPORT_TICKET_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchSupportTicketDetails(null, 'ActiveTickets'));
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: CREATE_SUPPORT_TICKET_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_COMMENTS_SUPPORT_TICKET_DETAILS_LOADING = '@@supportTicket/ADD_COMMENTS_SUPPORT_TICKET_DETAILS_LOADING';
export const ADD_COMMENTS_SUPPORT_TICKET_DETAILS_SUCCESS = '@@supportTicket/ADD_COMMENTS_SUPPORT_TICKET_DETAILS_SUCCESS';
export const ADD_COMMENTS_SUPPORT_TICKET_DETAILS_FAILURE = '@@supportTicket/ADD_COMMENTS_SUPPORT_TICKET_DETAILS_FAILURE';

export const addCommentsSupportTicketDetails = (formData, supportTicketID, externalTicketId) => async (dispatch) => {
  dispatch({ type: ADD_COMMENTS_SUPPORT_TICKET_DETAILS_LOADING });
  let tempFormData = {};
  tempFormData = {
    ...formData,
    SupportTicketId: supportTicketID,
    ExternalTicketId: externalTicketId,
  };
  let body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`vendor/supportticket/${supportTicketID}/action/comment`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter(
        (errorItem) => errorItem.Message !== NO_DATA_FOUND
      );
      dispatch({
        type: ADD_COMMENTS_SUPPORT_TICKET_DETAILS_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: ADD_COMMENTS_SUPPORT_TICKET_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(searchSupportTicketDetails(supportTicketID, ''));
    }
  } catch (err) {
    dispatch({
      type: ADD_COMMENTS_SUPPORT_TICKET_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CLOSE_SUPPORT_TICKET_DETAILS_LOADING = '@@supportTicket/CLOSE_SUPPORT_TICKET_DETAILS_LOADING';
export const CLOSE_SUPPORT_TICKET_DETAILS_SUCCESS = '@@supportTicket/CLOSE_SUPPORT_TICKET_DETAILS_SUCCESS';
export const CLOSE_SUPPORT_TICKET_DETAILS_FAILURE = '@@supportTicket/CLOSE_SUPPORT_TICKET_DETAILS_FAILURE';

export const closeSupportTicketDetails = (supportTicketID, callback) => async (dispatch) => {
  dispatch({ type: CLOSE_SUPPORT_TICKET_DETAILS_LOADING });
  try {
    const response = await axios.post(`vendor/supportticket/${supportTicketID}/action/close`);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: CLOSE_SUPPORT_TICKET_DETAILS_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: CLOSE_SUPPORT_TICKET_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: CLOSE_SUPPORT_TICKET_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SUPPORT_TICKET_DETAILS = '@@supportTicket/SET_SUPPORT_TICKET_DETAILS';

export const setSupportTicketDetails =
  (supportTicketDetails = {}) =>
    async (dispatch) => {
      dispatch({
        type: SET_SUPPORT_TICKET_DETAILS,
        payload: supportTicketDetails,
      });
    };