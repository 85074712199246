import { Box } from '@mui/material';
import { status } from './statusStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { spaceRight } from '../VendorProfile/vendorProfileStyles';
import { isNil } from 'lodash';

const Status = ({ title, color, icon }) => {
  if (isNil(icon)) {
    return <Box sx={(theme) => status(theme, { color })}>{title}</Box>;
  } else {
    return (
      <Box sx={(theme) => status(theme, { color })}>
        <FontAwesomeIcon icon={icon} style={spaceRight('5')} />
        {title}
      </Box>
    );
  }
};

export default Status;
