import { setErrors } from './errorHandler';
import { getErrorObject } from 'utils/apiUtils';
import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { getAssessmentResponsesSearchBody, getAssessmentBody, getLegacyAssessmentBody, getLegacyAssessmentResponsesSearchBody } from 'utils/assessmentResponsesUtils';
import { PORTAL_2 } from 'constants/constants';

export const ASSESSMENT_RESPONSES_SEARCH_LOADING = '@@assessmentResponses/ASSESSMENT_RESPONSES_SEARCH_LOADING';
export const ASSESSMENT_RESPONSES_SEARCH_SUCCESS = '@@assessmentResponses/ASSESSMENT_RESPONSES_SEARCH_SUCCESS';
export const ASSESSMENT_RESPONSES_SEARCH_FAILURE = '@@assessmentResponses/ASSESSMENT_RESPONSES_SEARCH_FAILURE';

export const getAssessmentResponses = (assessmentData) => async (dispatch) => {
  dispatch({
    type: ASSESSMENT_RESPONSES_SEARCH_LOADING,
  });

  try {
    let response;
    if (assessmentData?.Source === PORTAL_2) {
      const body = getAssessmentResponsesSearchBody(assessmentData?.AssessmentId);
      response = await axios.post(`assessment/${assessmentData?.AssessmentId}/questionnaire/${assessmentData?.AssessmentQuestionnaireId}/response/search`, body);
    } else {
        const body = getLegacyAssessmentResponsesSearchBody(assessmentData?.AssessmentId);
        response = await axios.post(`legacy/assessment/executivesummary/questionresponse/search`, body);
    }
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: ASSESSMENT_RESPONSES_SEARCH_FAILURE,
        payload: {},
      });
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: ASSESSMENT_RESPONSES_SEARCH_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_RESPONSES_SEARCH_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_LOADING = '@@assessmentResponses/SEARCH_ASSESSMENT_LOADING';
export const SEARCH_ASSESSMENT_SUCCESS = '@@assessmentResponses/SEARCH_ASSESSMENT_SUCCESS';
export const SEARCH_ASSESSMENT_FAILURE = '@@assessmentResponses/SEARCH_ASSESSMENT_FAILURE';

export const getAssessmentData = (id, source = "") => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_LOADING });
  try {
    let response;
    if (source === PORTAL_2) {
      const body = getAssessmentBody(id);
      response = await axios.post(`/assessment/search`, body);
    } else {
        const body = getLegacyAssessmentBody(id);
        response = await axios.post(`legacy/assessment/search`, body);
    }
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_SUCCESS,
        payload: response?.data || {},
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ASSESSMENT_DATA = '@@assessmentResponses/SET_ASSESSMENT_DATA';

export const setAssessmentData =
  (val = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_DATA,
      payload: val,
    });
  };

export const SET_ASSESSMENT_RESPONSES = '@@assessmentResponses/SET_ASSESSMENT_RESPONSES';

export const setAssessmentResponses =
  (val = []) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_RESPONSES,
        payload: val,
      });
    };
