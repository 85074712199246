import {
  GET_SUPPORT_SECTIONS_LOADING,
  GET_SUPPORT_SECTIONS_SUCCESS,
  GET_SUPPORT_SECTIONS_FAILURE,
  GET_SUPPORT_ARTICLES_LOADING,
  GET_SUPPORT_ARTICLES_SUCCESS,
  GET_SUPPORT_ARTICLES_FAILURE,
  GET_SPECIFIC_SUPPORT_ARTICLES_LOADING,
  GET_SPECIFIC_SUPPORT_ARTICLES_SUCCESS,
  GET_SPECIFIC_SUPPORT_ARTICLES_FAILURE,
  SET_SUPPORT_SUBSECTION_SUCCESS,
  SET_SUPPORT_SUBSECTION_MORE_SUCCESS,
  CLEAR_SUPPORT_ARTICLE_DETAILS,
} from 'actions/supportArticles';
import { cloneDeep, isEmpty, isNil } from 'lodash';

const initialState = {
  loading: false,
  supportArticlesHelpGuide: [],
  supportArticleSections: [],
  selectedSubSection: {},
  moreSubSectionSelected: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  let supportSectionsClone = isEmpty(state?.supportArticlesHelpGuide) ? cloneDeep(state?.supportArticleSections) : cloneDeep(state?.supportArticlesHelpGuide);
  let supportArticleSectionClone = cloneDeep(state?.supportArticleSections);

  switch (type) {
    case GET_SUPPORT_ARTICLES_LOADING:
    case GET_SPECIFIC_SUPPORT_ARTICLES_LOADING:
    case GET_SUPPORT_SECTIONS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SUPPORT_SECTIONS_SUCCESS: {
      let parentSections = [];
      if (!isEmpty(payload) && payload?.length > 0) {
        const sectionsList = payload?.filter((it) => it?.name !== 'Client Portal Video Walkthroughs' && it?.name !== 'Client Portal Getting Started Guide');
        sectionsList.forEach((sections) => {
          if (isNil(sections.parent_section_id)) {
            parentSections.push({ ...sections, subSections: [], articles: [] });
          }
        });
        sectionsList.forEach((subSections) => {
          if (!isEmpty(parentSections)) {
            parentSections.forEach((section) => {
              if (section.id === subSections.parent_section_id) {
                section.subSections.push({ ...subSections, articles: [] });
              }
            });
          }
        });
      }

      return {
        ...state,
        loading: false,
        supportArticleSections: parentSections,
      };
    }

    case GET_SUPPORT_SECTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SUPPORT_ARTICLES_SUCCESS: {
      const { subSectionId, parentId } = action;
      if (!isNil(subSectionId)) {
        supportSectionsClone.forEach((element, parentIndex) => {
          if (element?.id === parentId) {
            element?.subSections.forEach((item, index) => {
              if (item?.id === subSectionId) {
                supportSectionsClone[parentIndex].subSections[index].articles = payload;
              }
            });
          }
        });
      } else {
        supportSectionsClone.forEach((element, index) => {
          if (element?.id === parentId) {
            supportSectionsClone[index].articles = payload;
          }
        });
      }
      return {
        ...state,
        loading: false,
        supportArticlesHelpGuide: supportSectionsClone,
      };
    }

    case GET_SUPPORT_ARTICLES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SPECIFIC_SUPPORT_ARTICLES_SUCCESS: {
      payload.forEach((item) => {
        supportArticleSectionClone.forEach((element, sectionIndex) => {
          element?.subSections.forEach((subsections, subsectionIndex) => {
            if (item.section_id === subsections.id) {
              supportArticleSectionClone[sectionIndex].subSections[subsectionIndex].articles.push({ ...item });
            }
          });
          if (item.section_id === element.id) {
            supportArticleSectionClone[sectionIndex].articles.push({ ...item });
          }
        });
      });
      return {
        ...state,
        loading: false,
        supportArticlesHelpGuide: isEmpty(payload) ? [] : supportArticleSectionClone,
      };
    }

    case GET_SPECIFIC_SUPPORT_ARTICLES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_SUPPORT_SUBSECTION_SUCCESS: {
      return {
        ...state,
        selectedSubSection: payload,
      };
    }

    case SET_SUPPORT_SUBSECTION_MORE_SUCCESS: {
      return {
        ...state,
        moreSubSectionSelected: payload,
      };
    }

    case CLEAR_SUPPORT_ARTICLE_DETAILS: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
