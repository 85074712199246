import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { faNotes } from '@fortawesome/pro-solid-svg-icons';

export const ASSESSMENT_OVERVIEW_TAB_OPTIONS = {
  BOARD: 'BOARD',
  LIST: 'LIST',
};

export const ASSESSMENT_OVERVIEW_TAB_LIST = [
  {
    label: ASSESSMENT_OVERVIEW_TAB_OPTIONS.BOARD,
    value: ASSESSMENT_OVERVIEW_TAB_OPTIONS.BOARD,
  },
  {
    label: ASSESSMENT_OVERVIEW_TAB_OPTIONS.LIST,
    value: ASSESSMENT_OVERVIEW_TAB_OPTIONS.LIST,
  },
];

export const REMOVE_SELECTED_FILE_FROM_CREATE_ASSESSMENT_REQUEST = 'This action will remove the selected file from assessment request form. Are you sure?';
export const REMEDIATION_STAGE = 'Remediation';
export const ACTIVE_STAGE = 'Active Assessments';
export const COMPLETED_STAGE = 'Completed Assessments';
export const ACTIVE = 'Active';
export const COMPLETED = 'Completed';

export const ACTIVE_STAGE_ID = 1;
export const COMPLETED_STAGE_ID = 2;
export const REMEDIATION_STAGE_ID = 3;

export const ASSESSMENT_LIST_STEPS = [
  {
    AssessmentStageId: ACTIVE_STAGE_ID,
    AssessmentStageName: ACTIVE_STAGE,
  },
  {
    AssessmentStageId: REMEDIATION_STAGE_ID,
    AssessmentStageName: REMEDIATION_STAGE,
  },
  {
    AssessmentStageId: COMPLETED_STAGE_ID,
    AssessmentStageName: COMPLETED_STAGE,
  },
];
export const ESCALATED_ASSESSMENT = 'Escalated-IS';
export const ASSESSMENT_COMPLETED = 'completed';

export const AWAITING_VENDOR_RESPONSE = 21;
export const QUEUED_FOR_AUDIT = 22;
export const CORL_AUDITOR_VALIDATION = 23;
export const CORL_AUDITOR_GRADING = 24;
export const ASSESSMENSTS_RESULTS_READY = 25;
export const REMEDIATION_PLAN_REVIEW = 26;
export const PENDING_REMEDIATION_UPDATES = 27;
export const CORL_CLEARED_MAINTENACE = 28;
export const REMEDIATION_PROCESSING_VENDOR_RESPONSE = 31;
export const SECURITY_PROCESSING_VENDOR_RESPONSE = 24;
export const COMPANION_ASSESSMENT_IN_PROGRESS = 2;
export const COMPANION_ASSESSMENT_COMPLETED = 5;

export const QUICK_DETAILS_TOGGLE_DETAILS_VALUE = '1';
export const QUICK_DETAILS_TOGGLE_NOTES_VALUE = '2';

export const ASSESSMENT_OVERVIEW_BOARD_DEFAULT_CSV_FILENAME = 'AssessmentsBoard';
export const ASSESSMENT_OVERVIEW_LIST_DEFAULT_CSV_FILENAME = 'AssessmentsList';
export const ASSESSMENT_OVERVIEW_BOARD_DEFAULT_PDF_FILENAME = 'AssessmentsBoard';
export const ASSESSMENT_OVERVIEW_LIST_DEFAULT_PDF_FILENAME = 'AssessmentsList';

export const DETAILS_STRING_CONSTANT = 'DETAILS';
export const NOTES_STRING_CONSTANT = 'NOTES';

export const QUICK_DETAILS_TOGGLES = [
  {
    label: DETAILS_STRING_CONSTANT,
    value: QUICK_DETAILS_TOGGLE_DETAILS_VALUE,
    icons: faCircleInfo,
  },
  {
    label: NOTES_STRING_CONSTANT,
    value: QUICK_DETAILS_TOGGLE_NOTES_VALUE,
    icons: faNotes,
    flip: 'vertical',
  },
];

export const REQUESTED = 'requested';
export const SUBMITTED = 'submitted';
export const IN_PROGRESS = 'in_progress';

export const REMEDIATION_STAGE_STRING_CONSTANT = "REMEDIATION";
export const ACTIVE_STAGE_STRING_CONSTANT = "ACTIVE";
export const COMPLETED_STAGE_STRING_CONSTANT = "COMPLETED";