import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavBarStyle.scss';
import { menuListWrapper, menuLinkWrapper, menuWrapper, menuLink, linkWrapper } from './NavBarStyles';
import MenuPanel from './MenuPanel';
import { getIcon } from './util';
import { setMenuPanelId } from 'actions/navbar';

const Menu = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const menuPanelId = useSelector((state) => state.navbar.menuPanelId);

  const menuList = useSelector((state) => state.navbar.menuList);
  const location = useLocation();

  const getActiveNavClass = (subMenuList) => {
    return subMenuList?.filter((it) => it.ButtonRoute === `${location.pathname + location?.search}` || it.MenuRoute === `${location.pathname + location?.search}`).length > 0 ? 'activeNavBar' : '';
  };


  const onClickMenu = (subMenuList) => {
    if (subMenuList?.length > 0) {
      history.push(subMenuList[0].MenuRoute);
    }
  };

  return (
    <Box sx={menuListWrapper}>
      {menuList &&
        menuList.map((menu, index) => {
          const { AppMenuId, AppMenuName, MenuRoute, MenuIcon, SubMenuList = [] } = menu;
          const externalUrlValue = MenuRoute.indexOf('https://');
          return SubMenuList?.length === 0 ? (
            <Box sx={linkWrapper}>
              <NavLink
                to={{ pathname: `${MenuRoute}` }}
                key={index}
                target={externalUrlValue !== -1 ? '_blank' : '_self'}
                onMouseOver={() => {
                  dispatch(setMenuPanelId(0));
                }}
              >
                <Box sx={menuLinkWrapper} className={`${location.pathname + location?.search}` === MenuRoute ? 'activeNavBar' : 'hoverIcon'}>
                  <FontAwesomeIcon icon={getIcon(MenuIcon)} />
                  <Typography sx={menuLink}>{AppMenuName}</Typography>
                </Box>
              </NavLink>
            </Box>
          ) : (
            <Box
              key={index}
              onMouseOver={() => {
                dispatch(setMenuPanelId(AppMenuId));
              }}
              sx={menuWrapper}
              onClick={() => onClickMenu(SubMenuList)}
            >
              <Box sx={menuLinkWrapper} className={getActiveNavClass(SubMenuList)}>
                <FontAwesomeIcon icon={getIcon(MenuIcon)} />
                <Typography sx={menuLink} onClick={() => onClickMenu(SubMenuList)}>
                  {AppMenuName}
                </Typography>
                {menuPanelId === AppMenuId && <MenuPanel subMenuList={SubMenuList} />}
              </Box>
            </Box>
          );
        })}
      {/* 
      will be used for portal 1.0 navigation confirmation from assessments page
      <AssessmentModal
        openModal={!isNil(assessmentRoute)}
        closeModal={() => {
          setAssessmentRoute(null);
        }}
        assessmentRoute={assessmentRoute}
      /> */}
    </Box>
  );
};

export default Menu;
