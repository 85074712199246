export const RESPONSE_TYPE = {
  SINGLE_SELECT: { value: 1, label: 'Single select' },
  MULTIPLE_SELECT: { value: 2, label: 'Multiple select' },
  DATE: { value: 3, label: 'Date' },
  FREE_TEXT: { value: 4, label: 'Free text' },
  FILE_UPLOAD: { value: 5, label: 'File upload' },
};

export const RESPONSE_TYPE_ID = Object.values(RESPONSE_TYPE).reduce((acc, { value, label }) => {
  acc[label.toUpperCase()] = value;
  return acc;
}, {});

export const RESPONSE_TYPE_LABEL = Object.values(RESPONSE_TYPE).reduce((acc, { value, label }) => {
  acc[value] = label;
  return acc;
}, {});

export const DEFAULT_RESPONSE_OPTION_VALUES = ['Yes', 'No', 'Partial', 'N/A'];

export const CSV_HEADER_FIELDS = ['Question Text', 'Control Number', 'Response Type', 'Response Option Values', 'File Upload Required'];

export const CSV_HEADER_FIELDS_TABLE = ['Control Number', 'Response Type'];

export const CSV_HEADER = {
  QUESTION_TEXT: 'Question Text',
  CONTROL_NUMBER: 'Control Number',
  RESPONSE_TYPE: 'Response Type',
  RESPONSE_OPTION_VALUES: 'Response Option Values',
  FILE_UPLOAD_REQUIRED: 'File Upload Required',
};

export const RESPONSE_OPTION_VALUES_SPLITTER = '|';

export const ASSESSMENT_STATUS = {
  IN_PROGRESS: 2,
  COMPLETED: 5,
};

export const ASSESSMENT_TYPE = {
  COMPANION: 7,
};

export const QUESTIONNAIRE_TEMPLATE_TYPE = {
  COMPANION: 6,
};

export const ADD_NEW_ORGANIZATION = {
  value: -1,
  label: 'NO ORGANIZATION FOUND (will be added)',
};

export const CATAGORY = {
  NO_CATAGORY: 'No Category',
};

export const DATE_FORMAT = 'MM/dd/yyyy';

export const EXTRACT_DOCUMENT_STATUS = {
  SUBMITTED: 'submitted',
  ERRORED: 'errored',
};

export const PULLING_STATUS = {
  COMPLETED: 'completed',
  PROCESSING: 'processing',
  ERRORED: 'errored',
};
