import { isEmpty } from 'lodash';

export const searchVendorUsersBody = (searchItem) => {
  const criterion = [];
  if (!isEmpty(searchItem)) {
    criterion.push({
      field: 'FirstName',
      operator: 'startswith',
      value: searchItem,
    });
  }
  return {
    intent: '',
    fields: [
      'UserId',
      'UserEmail',
      'FirstName',
      'MiddleName',
      'LastName',
      'NamePrefix',
      'NameSuffix',
      'ProfilePictureUrl',
      'ExternalIdpId',
      'ServiceProviderUserList',
      'ServiceProviderOrgName',
      'Title',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'FirstName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
