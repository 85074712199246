import {
  POST_PRODUCT_CATALOG_DETAILS_FAILURE,
  POST_PRODUCT_CATALOG_DETAILS_LOADING,
  POST_PRODUCT_CATALOG_DETAILS_SUCCESS,
  POST_USER_CONFIRMATION_DETAILS_FAILURE,
  POST_USER_CONFIRMATION_DETAILS_LOADING,
  POST_USER_CONFIRMATION_DETAILS_SUCCESS,
  SEARCH_PRODUCT_CATALOG_DETAILS_FAILURE,
  SEARCH_PRODUCT_CATALOG_DETAILS_LOADING,
  SEARCH_PRODUCT_CATALOG_DETAILS_SUCCESS,
  SEARCH_USER_CONFIRMATION_FAILURE,
  SEARCH_USER_CONFIRMATION_LOADING,
  SEARCH_USER_CONFIRMATION_SUCCESS,
  POST_ABOUT_YOURSELF_FAILURE,
  POST_ABOUT_YOURSELF_LOADING,
  POST_ABOUT_YOURSELF_SUCCESS,
  SEARCH_ABOUT_YOURSELF_DETAILS_FAILURE,
  SEARCH_ABOUT_YOURSELF_DETAILS_LOADING,
  SEARCH_VENDOR_ONBOARDING_WHATNEXT_FAILURE,
  SEARCH_VENDOR_ONBOARDING_WHATNEXT_LOADING,
  SEARCH_VENDOR_ONBOARDING_WHATNEXT_SUCCESS,
  SEARCH_ABOUT_YOURSELF_DETAILS_SUCCESS,
  GET_VENDOR_ONBOARDING_IMAGE_URL_LOADING,
  GET_VENDOR_ONBOARDING_IMAGE_URL_SUCCESS,
  GET_VENDOR_ONBOARDING_IMAGE_URL_FAILURE,
  POST_ORGANIZATION_IDENTIFICATION_LOADING,
  POST_ORGANIZATION_IDENTIFICATION_SUCCESS,
  POST_ORGANIZATION_IDENTIFICATION_FAILURE,
  SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_FAILURE,
  SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_LOADING,
  SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_SUCCESS,
  GET_VENDOR_ONBOARDING_STAGE_LOADING,
  GET_VENDOR_ONBOARDING_STAGE_SUCCESS,
  GET_VENDOR_ONBOARDING_STAGE_FAILURE,
  SEARCH_ORGANIZATION_PROFILE_DETAILS_LOADING,
  SEARCH_ORGANIZATION_PROFILE_DETAILS_SUCCESS,
  SEARCH_ORGANIZATION_PROFILE_DETAILS_FAILURE,
  POST_ORGANIZATION_PROFILE_SUCCESS,
  POST_ORGANIZATION_PROFILE_FAILURE,
  POST_ORGANIZATION_PROFILE_LOADING,
  SEARCH_WIZARD_PAGE_LOADING,
  SEARCH_WIZARD_PAGE_SUCCESS,
  SEARCH_WIZARD_PAGE_FAILURE,
  SEARCH_ADMIN_USER_WIZARD_STATUS_LOADING,
  SEARCH_ADMIN_USER_WIZARD_STATUS_SUCCESS,
  SEARCH_ADMIN_USER_WIZARD_STATUS_FAILURE
} from 'actions/vendorOnboarding';
import { getValueLabelList } from 'utils/arrayUtils';
import {
  WhatNextPageNumber,
  ORGANIZATION_PROFILE_FIRST_PAGE,
  ORGANIZATION_PROFILE_FOURTH_PAGE,
  ORGANIZATION_PROFILE_SECOND_PAGE,
  ORGANIZATION_PROFILE_THIRD_PAGE,
  VENDOR_ONBOARDING_WIZARD_TAB_VALUES,
  aboutYourselfPageNumber,
  organizationIdentificationPageNumber,
  ORGANIZATION_PROFILE_FIFTH_PAGE,
  ORGANIZATION_PROFILE_SIXTH_PAGE,
} from 'pages/VendorOnboarding/constants';
import {
  COVERAGE_ID,
  ORG_PROFILE_FOURTEENTH_PAGE,
  ORG_PROFILE_TWELFTH_PAGE,
  PRODUCT_CATALOG_FIRST_PAGE,
  PRODUCT_CATALOG_NINTH_PAGE,
  PRODUCT_CATALOG_SEVENTH_PAGE,
  SECURITY_CERTIFICATION_ID,
  SECURITY_CERTIFICATION_LIST,
} from 'pages/VendorOnboarding/ProductCatalog/constant';
import { isEmpty } from 'lodash';

const initialState = {
  loading: false,
  userConfirmationDetails: {},
  headerDetails: {},
  aboutYourselfPage1: {},
  aboutYourselfPage1RawData: {},
  aboutYourselfPage2RawData: {},
  aboutYourselfPage2: {},
  aboutYourselfPage3: {},
  aboutYourselfPage3RawData: {},
  organisationIdentificationPage1: {},
  organisationIdentificationPage1RawData: {},
  productCatalogDetails: {},
  productCatalogSectionList: [],
  imageUrl: '',
  organizationIdentificationDetails: {},
  whatnextPage1: {},
  whatnextPage2RawData: {},
  whatnextPage2: {},
  whatnextPage1RawData: {},
  organizationProfilePage1: {},
  rawData: {},
  organizationProfilePage1RawData: {},
  organizationProfilePage2: {},
  organizationProfilePage2RawData: {},
  organizationProfilePage3: {},
  organizationProfilePage3RawData: {},
  organizationProfilePage4: {},
  organizationProfilePage4RawData: {},
  organizationProfilePage5: {},
  organizationProfilePage5RawData: {},
  organizationProfilePage6: {},
  organizationProfilePage6RawData: {},
  coverageLookup: [],
  contactData: {},
  contactCount: 0,
  domainData: {},
  domainCount: 0,
  vendorOnboardingCurrentStage: {},
  incidentContactData: {},
  incidentContactCount: 0,
  vendorOnboardingAdminStatus: {},
};

export default function (state = initialState, action) {
  const { type, payload, pageNumber } = action;

  switch (type) {
    case SEARCH_USER_CONFIRMATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_USER_CONFIRMATION_SUCCESS: {
      let questions = JSON.parse(payload.PageMetadata);
      return {
        ...state,
        loading: false,
        userConfirmationDetails: payload,
        headerDetails: questions,
        rawData: payload,
      };
    }

    case SEARCH_USER_CONFIRMATION_FAILURE: {
      return {
        ...state,
        loading: false,
        userConfirmationDetails: {},
        headerDetails: {},
        rawData: {},
      };
    }

    case POST_USER_CONFIRMATION_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_USER_CONFIRMATION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_USER_CONFIRMATION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PRODUCT_CATALOG_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_PRODUCT_CATALOG_DETAILS_SUCCESS: {
      let sectionList = [];
      let headerDetails = {};
      let securityCertificationList = [];
      let coverageLookup = [];
      if (payload?.WizardId === Number(VENDOR_ONBOARDING_WIZARD_TAB_VALUES.ORGANIZATION_PROFILE) && payload?.PageNumber === PRODUCT_CATALOG_SEVENTH_PAGE) {
        sectionList = JSON.parse(payload.PageMetadata)?.Inputs[0]?.Options;
        headerDetails = JSON.parse(payload.PageMetadata);
      } else if (payload?.WizardId === Number(VENDOR_ONBOARDING_WIZARD_TAB_VALUES.ORGANIZATION_PROFILE) && payload?.PageNumber === ORG_PROFILE_TWELFTH_PAGE) {
        JSON.parse(payload.PageMetadata)?.Inputs.map((input) => {
          if (input.ObjectField === COVERAGE_ID) {
            coverageLookup = getValueLabelList(input?.Options);
            headerDetails = {
              ...JSON.parse(payload.PageMetadata),
              CoverageLabel: input?.Label,
            };
          }
        });
      } else if (payload?.WizardId === Number(VENDOR_ONBOARDING_WIZARD_TAB_VALUES.ORGANIZATION_PROFILE) && payload?.PageNumber === ORG_PROFILE_FOURTEENTH_PAGE) {
        JSON.parse(payload.PageMetadata)?.Inputs.map((input) => {
          if (input.ObjectField === SECURITY_CERTIFICATION_LIST) {
            securityCertificationList = input?.Options?.map((it) => {
              return {
                ResponseOptionText: it.Label,
              };
            });
          }
          if (input.ObjectField === SECURITY_CERTIFICATION_ID) {
            headerDetails = {
              ...JSON.parse(payload.PageMetadata),
              SecurityCertificationLabel: input?.Label,
            };
          }
        });
      } else {
        headerDetails = JSON.parse(payload.PageMetadata);
      }

      return {
        ...state,
        loading: false,
        productCatalogDetails: payload,
        headerDetails: headerDetails,
        productCatalogSectionList: getValueLabelList(sectionList),
        coverageLookup: coverageLookup,
        securityCertificationList: securityCertificationList,
        rawData: payload,
      };
    }
    case SEARCH_PRODUCT_CATALOG_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        productCatalogDetails: {},
        headerDetails: {},
        productCatalogSectionList: [],
        coverageLookup: [],
        securityCertificationList: [],
        rawData: {},
      };
    }

    case POST_PRODUCT_CATALOG_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_PRODUCT_CATALOG_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_PRODUCT_CATALOG_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_ABOUT_YOURSELF_DETAILS_LOADING:
    case POST_ABOUT_YOURSELF_LOADING:
    case SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_LOADING:
    case SEARCH_ORGANIZATION_PROFILE_DETAILS_LOADING:
    case POST_ORGANIZATION_PROFILE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_ABOUT_YOURSELF_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        aboutYourselfPage1: Number(pageNumber) === Number(aboutYourselfPageNumber) ? JSON.parse(payload?.PageMetadata) : { ...state?.aboutYourselfPage1 },
        aboutYourselfPage1RawData: Number(pageNumber) === Number(aboutYourselfPageNumber) ? payload : { ...state?.aboutYourselfPage1RawData },
        aboutYourselfPage2: Number(pageNumber) === Number(aboutYourselfPageNumber) + 1 ? JSON.parse(payload?.PageMetadata) : { ...state?.aboutYourselfPage2 },
        aboutYourselfPage2RawData: Number(pageNumber) === Number(aboutYourselfPageNumber) + 1 ? payload : { ...state?.aboutYourselfPage2RawData },
        aboutYourselfPage3: Number(pageNumber) === Number(aboutYourselfPageNumber) + 2 ? JSON.parse(payload?.PageMetadata) : { ...state?.aboutYourselfPage3 },
        aboutYourselfPage3RawData: Number(pageNumber) === Number(aboutYourselfPageNumber) + 2 ? payload : { ...state?.aboutYourselfPage3RawData },
        rawData: payload,
        headerDetails: JSON.parse(payload?.PageMetadata),
      };
    }
    case SEARCH_ABOUT_YOURSELF_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        aboutYourselfPage1: {},
        aboutYourselfPage1RawData: {},
        aboutYourselfPage2RawData: {},
        aboutYourselfPage2: {},
        aboutYourselfPage3: {},
        aboutYourselfPage3RawData: {},
        rawData: {},
        headerDetails: {},
      };
    }
    case POST_ABOUT_YOURSELF_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_ABOUT_YOURSELF_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        organisationIdentificationPage1:
          Number(pageNumber) === Number(organizationIdentificationPageNumber)
            ? JSON.parse(payload?.PageMetadata)
            : { ...state?.organisationIdentificationPage1 },
        organisationIdentificationPage1RawData:
          Number(pageNumber) === Number(organizationIdentificationPageNumber) ? payload : { ...state?.organisationIdentificationPage1 },
        headerDetails: JSON.parse(payload?.PageMetadata),
        organizationIdentificationDetails: payload,
        rawData: payload,
      };
    }
    case SEARCH_ORGANIZATION_IDENTIFICATION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        organizationIdentificationDetails: {},
        headerDetails: {},
        rawData: {},
      };
    }

    case GET_VENDOR_ONBOARDING_IMAGE_URL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_VENDOR_ONBOARDING_IMAGE_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        imageUrl: payload?.DownloadUrl ? payload?.DownloadUrl : '',
      };
    }

    case GET_VENDOR_ONBOARDING_IMAGE_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        imageUrl: '',
      };
    }

    case POST_ORGANIZATION_IDENTIFICATION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_ORGANIZATION_IDENTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_ORGANIZATION_IDENTIFICATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_VENDOR_ONBOARDING_STAGE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_VENDOR_ONBOARDING_STAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        stage: !isEmpty(payload) ? payload.find((item) => item?.WizardPageId === item.CurrentPage) : {},
      };
    }

    case GET_VENDOR_ONBOARDING_STAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        stage: {},
      };
    }
    case SEARCH_ORGANIZATION_PROFILE_DETAILS_SUCCESS: {
      let tempContactData = {};
      let tempDomainData = {};
      let tempContactCount = 0;
      let tempDomainCount = 0;
      let tempIncidentContactCount = 0;
      let tempCorlVendorContactDescription ={}
      let tempIncidentContactData ={}
      if (pageNumber === ORGANIZATION_PROFILE_SECOND_PAGE && !isEmpty(payload?.PageData)) {
        JSON.parse(payload?.PageData)?.forEach((obj, index) => {
          let tempData = {};
          Object.entries(obj).forEach((item) => {
            const [key, value] = item;
            tempData[`${key}${index + 1}`] = value;
          });
          tempContactData = { ...tempData, ...tempContactData };
          tempContactCount += 1;
        });
      }
      if (pageNumber === ORGANIZATION_PROFILE_SIXTH_PAGE && !isEmpty(payload?.PageData)) {
        JSON.parse(payload?.PageData)?.forEach((obj, index) => {
          let tempData = {};
          Object.entries(obj).forEach((item) => {
            const [key, value] = item;
            tempData[`${key}${index + 1}`] = value;
          });
          tempIncidentContactData = { ...tempData, ...tempIncidentContactData };
          tempIncidentContactCount += 1;
        });
      }
      if (pageNumber === ORGANIZATION_PROFILE_THIRD_PAGE && !isEmpty(payload?.PageData)) {
        let tempData = JSON.parse(payload?.PageData)
          
          tempCorlVendorContactDescription = {...tempData["CorlVendorContactDescription"],...tempCorlVendorContactDescription};
        };
      
      if (pageNumber === ORGANIZATION_PROFILE_FOURTH_PAGE && !isEmpty(payload?.PageData)) {
        JSON.parse(payload?.PageData)?.DomainName?.forEach((obj, index) => {
          let tempData = {};
          tempData[`DomainName${index + 1}`] = obj;
          tempDomainData = { ...tempData, ...tempDomainData };
          tempDomainCount += 1;
        });
      }
      return {
        ...state,
        loading: false,
        organizationProfilePage1: pageNumber === ORGANIZATION_PROFILE_FIRST_PAGE ? JSON.parse(payload?.PageMetadata) : { ...state?.organizationProfilePage1 },
        organizationProfilePage1RawData: pageNumber === ORGANIZATION_PROFILE_FIRST_PAGE ? payload : { ...state?.organizationProfilePage1RawData },
        organizationProfilePage2: pageNumber === ORGANIZATION_PROFILE_SECOND_PAGE ? JSON.parse(payload?.PageMetadata) : { ...state?.organizationProfilePage2 },
        organizationProfilePage2RawData: pageNumber === ORGANIZATION_PROFILE_SECOND_PAGE ? payload : { ...state?.organizationProfilePage2RawData },
        organizationProfilePage3: pageNumber === ORGANIZATION_PROFILE_THIRD_PAGE ? JSON.parse(payload?.PageMetadata) : { ...state?.organizationProfilePage3 },
        organizationProfilePage3RawData: pageNumber === ORGANIZATION_PROFILE_THIRD_PAGE ? payload : { ...state?.organizationProfilePage3RawData },
        organizationProfilePage4: pageNumber === ORGANIZATION_PROFILE_FOURTH_PAGE ? JSON.parse(payload?.PageMetadata) : { ...state?.organizationProfilePage4 },
        organizationProfilePage4RawData: pageNumber === ORGANIZATION_PROFILE_FOURTH_PAGE ? payload : { ...state?.organizationProfilePage4RawData },
        organizationProfilePage5: pageNumber === ORGANIZATION_PROFILE_FIFTH_PAGE ? JSON.parse(payload?.PageMetadata) : { ...state?.organizationProfilePage5 },
        organizationProfilePage5RawData: pageNumber === ORGANIZATION_PROFILE_FIFTH_PAGE ? payload : { ...state?.organizationProfilePage5RawData },
        organizationProfilePage6: pageNumber === ORGANIZATION_PROFILE_SIXTH_PAGE ? JSON.parse(payload?.PageMetadata) : { ...state?.organizationProfilePage6 },
        organizationProfilePage6RawData: pageNumber === ORGANIZATION_PROFILE_SIXTH_PAGE ? payload : { ...state?.organizationProfilePage6RawData },
        rawData: payload,
        headerDetails: JSON.parse(payload?.PageMetadata),
        contactData: tempContactData,
        contactCount: tempContactCount,
        domainData: tempDomainData,
        domainCount: tempDomainCount,
        corlVendorContactDescription :tempCorlVendorContactDescription,
        incidentContactData: tempIncidentContactData,
        incidentContactCount: tempIncidentContactCount,
      };
    }
    case SEARCH_ORGANIZATION_PROFILE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        rawData: {},
        headerDetails: {},
      };
    }

    case POST_ORGANIZATION_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_ORGANIZATION_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_VENDOR_ONBOARDING_WHATNEXT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_ONBOARDING_WHATNEXT_SUCCESS: {
      return {
        ...state,
        loading: false,
        headerDetails: JSON.parse(payload?.PageMetadata),
        whatnextPage1: Number(pageNumber) === Number(WhatNextPageNumber) ? JSON.parse(payload?.PageMetadata) : { ...state?.whatnextPage1 },
        whatnextPage1RawData: Number(pageNumber) === Number(WhatNextPageNumber) ? payload : { ...state?.whatnextPage1 },
        whatnextPage2: Number(pageNumber) === Number(WhatNextPageNumber) + 1 ? JSON.parse(payload?.PageMetadata) : { ...state?.whatnextPage2 },
        whatnextPage2RawData: Number(pageNumber) === Number(WhatNextPageNumber) + 1 ? payload : { ...state?.whatnextPage2 },
        rawData: payload
      };
    }
    case SEARCH_VENDOR_ONBOARDING_WHATNEXT_FAILURE: {
      return {
        ...state,
        loading: false,
        headerDetails: {},
        whatnextPage1: {},
        whatnextPage1RawData: {},
        whatnextPage2: {},
        whatnextPage2RawData: {},
        rawData: {}
      }
    }

    case SEARCH_WIZARD_PAGE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_WIZARD_PAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorOnboardingCurrentStage: payload
      };
    }

    case SEARCH_WIZARD_PAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorOnboardingCurrentStage: {}
      };
    }

    case SEARCH_ADMIN_USER_WIZARD_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ADMIN_USER_WIZARD_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorOnboardingAdminStatus: payload
      };
    }

    case SEARCH_ADMIN_USER_WIZARD_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorOnboardingAdminStatus: {}
      };
    }

    default:
      return state;
  }
}
