import { isNil } from 'lodash';
import {
  SESSION_REQUEST,
  SESSION_SUCCESS,
  SESSION_FAIL,
  DELETE_SESSION,
  SET_SESSION_DETAILS,
  POST_TERMS_AND_CONDITIONS_FLAG_LOADING,
  POST_TERMS_AND_CONDITIONS_FLAG_SUCCESS,
  POST_TERMS_AND_CONDITIONS_FLAG_FAILURE,
  VERIFY_SESSION_FAIL,
  VERIFY_SESSION_REQUEST,
  VERIFY_SESSION_SUCCESS,
} from '../actions/session';
import { setSessionDetails, getIsAuthenticated, removeSession, getSessionDetails } from '../utils/localStorage';

const initialState = {
  loading: false,
  user: {},
  details: getSessionDetails(),
  error: '',
  isAuthenticated: getIsAuthenticated(),
  authError: false,
  hasAcceptedTerms: true,
  currentSessionPayload: '',
};

const setSessionDetailsResult = (state, payload) => {
  return {
    ...state,
    details: payload,
    hasAcceptedTerms: !isNil(payload?.HasAcceptedTerms) ? payload?.HasAcceptedTerms : true,
  };
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SESSION_DETAILS:
      return setSessionDetailsResult(state, payload);
    case SESSION_REQUEST: {
      window.localStorage.setItem('sessionInProgress', 'yes');
      return {
        ...state,
        isAuthenticated: false,
        loading: true,
        authError: false,
        hasAcceptedTerms: true,
      };
    }

    case SESSION_SUCCESS: {
      window.localStorage.removeItem('sessionInProgress');
      setSessionDetails(payload);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        details: payload,
        hasAcceptedTerms: payload?.HasAcceptedTerms,
      };
    }

    case SESSION_FAIL: {
      window.localStorage.removeItem('sessionInProgress');
      removeSession();
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        authError: action?.authError || false,
      };
    }

    case DELETE_SESSION: {
      removeSession();
      return {
        ...initialState,
        user: {},
        loading: false,
        isAuthenticated: false,
      };
    }

    case POST_TERMS_AND_CONDITIONS_FLAG_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_TERMS_AND_CONDITIONS_FLAG_SUCCESS: {
      const data = {
        ...state.details,
        HasAcceptedTerms: payload?.Data?.model?.HasAcceptedTerms,
      };
      setSessionDetails(data);
      return {
        ...state,
        loading: false,
        hasAcceptedTerms: payload?.Data.model.HasAcceptedTerms,
      };
    }

    case POST_TERMS_AND_CONDITIONS_FLAG_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case VERIFY_SESSION_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VERIFY_SESSION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case VERIFY_SESSION_SUCCESS: {
      //Get details stored in localstorage
      let sessionDetailsString = window.localStorage.getItem('CORL_sessionDetails');
      let sessionDetails = JSON.parse(sessionDetailsString);

      //Get the details obtained in api response
      // If there is a difference in two, update the localstorage
      if (payload?.OrgName !== sessionDetails['OrgName']) {
        sessionDetails.OrgName = payload?.OrgName;
        sessionDetails.OrgLogoUrl = payload?.OrgLogoUrl;
        sessionDetails.MenuClaimList = payload?.MenuClaimList;
        localStorage.setItem('CORL_sessionDetails', JSON.stringify(sessionDetails));
        window.location.reload();
      }

      return {
        ...state,
        loading: false,
        currentSessionPayload: payload,
      };
    }

    default:
      return state;
  }
}
