import {
  GET_RESPONSE_CATEGORY_LIST_LOADING,
  GET_RESPONSE_CATEGORY_LIST_SUCCESS,
  GET_RESPONSE_CATEGORY_LIST_FAILURE,
  GET_PRODUCT_TYPE_LIST_LOADING,
  GET_PRODUCT_TYPE_LIST_SUCCESS,
  GET_PRODUCT_TYPE_LIST_FAILURE,
  GET_VENDOR_ROLE_LIST_LOADING,
  GET_VENDOR_ROLE_LIST_SUCCESS,
  GET_VENDOR_ROLE_LIST_FAILURE,
  GET_EVIDENCE_LOOKUP_LOADING,
  GET_EVIDENCE_LOOKUP_SUCCESS,
  GET_EVIDENCE_LOOKUP_FAILURE,
  GET_REQUIREMENT_STATUS_LOOKUP_LOADING,
  GET_REQUIREMENT_STATUS_LOOKUP_SUCCESS,
  GET_REQUIREMENT_STATUS_LOOKUP_FAILURE,
  GET_ACCEPTANCE_STATUS_LOOKUP_SUCCESS,
  GET_ACCEPTANCE_STATUS_LOOKUP_LOADING,
  GET_ACCEPTANCE_STATUS_LOOKUP_FAILURE,
  GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING,
  GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS,
  GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE,
  HELP_TICKET_TYPE_LOADING,
  HELP_TICKET_TYPE_FAILURE,
  HELP_TICKET_TYPE_SUCCESS,
  ASSESSMENT_UPDATE_TYPE_LOADING,
  ASSESSMENT_UPDATE_TYPE_FAILURE,
  ASSESSMENT_UPDATE_TYPE_SUCCESS,
  GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING,
  GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS,
  GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE,
  ASSESSMENT_REQUIREMENT_IPI_STATUS_LOADING,
  ASSESSMENT_REQUIREMENT_IPI_STATUS_FAILURE,
  ASSESSMENT_REQUIREMENT_IPI_STATUS_SUCCESS,
  ASSESSMENT_STATUS_LOADING,
  ASSESSMENT_STATUS_FAILURE,
  ASSESSMENT_STATUS_SUCCESS,
  SEARCH_REMEDIATION_PLAN_LOOKUP_LOADING,
  SEARCH_REMEDIATION_PLAN_LOOKUP_SUCCESS,
  SEARCH_REMEDIATION_PLAN_LOOKUP_FAILURE,
  SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_SUCCESS,
  SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_FAILURE,
} from 'actions/lookup';
import { isEmpty } from 'lodash';
import { getValueLabelList } from 'utils/arrayUtils';
import { SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_LOADING } from '../actions/lookup';

const initialState = {
  loading: false,
  responseCategoryType: [],
  vendorRoles: [],
  productTypes: [],
  evidenceType: [],
  subType: [],
  fileType: [],
  requirementStatus: [],
  acceptanceStatus: [],
  supportTicketRequestType: [],
  assessmentUpdate: [],
  ticketType: [],
  assessmentRequirementIPIStatus: [],
  assessmentStage: [],
  owner: [],
  assessmentStatus: [],
  yesNoList: [],
  riskFindingStatusLookup: [],
  riskLevelLookup: [],
  remediationObjectiveStatusLookup: [],
};

export const lookup = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RESPONSE_CATEGORY_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_RESPONSE_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        responseCategoryType: payload?.Data,
        yesNoList: getValueLabelList(payload?.Data),
      };
    }

    case GET_RESPONSE_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_PRODUCT_TYPE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PRODUCT_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productTypes: getValueLabelList(payload?.Data?.ProductType),
      };
    }
    case GET_PRODUCT_TYPE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_VENDOR_ROLE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDOR_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        vendorRoles: getValueLabelList(payload?.Data?.ProductVendorRole),
      };
    }
    case GET_VENDOR_ROLE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_EVIDENCE_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EVIDENCE_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        evidenceType: getValueLabelList(payload.EvidenceType),
        subType: getValueLabelList(payload.EvidenceSubType),
        fileType: getValueLabelList(payload.FileType),
      };
    }
    case GET_EVIDENCE_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        evidenceType: [],
        subType: [],
        fileType: [],
      };
    }
    case GET_ACCEPTANCE_STATUS_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ACCEPTANCE_STATUS_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        acceptanceStatus: payload.CCAssessmentResultAcceptanceStatus.map((it) => {
          return {
            label: it.Label,
            value: it.Value.toString(),
          };
        }),
      };
    }
    case GET_ACCEPTANCE_STATUS_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        acceptanceStatus: [],
      };
    }

    case GET_REQUIREMENT_STATUS_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_REQUIREMENT_STATUS_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        requirementStatus: getValueLabelList(payload.RequirementStatus),
      };
    }

    case GET_REQUIREMENT_STATUS_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SUPPORT_TICKET_REQUEST_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SUPPORT_TICKET_REQUEST_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SUPPORT_TICKET_REQUEST_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        supportTicketRequestType: payload,
      };
    }
    case HELP_TICKET_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HELP_TICKET_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case HELP_TICKET_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        ticketType: getValueLabelList(payload),
      };
    }
    case ASSESSMENT_UPDATE_TYPE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_UPDATE_TYPE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_UPDATE_TYPE_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentUpdate: getValueLabelList(payload),
      };
    }
    case ASSESSMENT_REQUIREMENT_IPI_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_REQUIREMENT_IPI_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_REQUIREMENT_IPI_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentRequirementIPIStatus: getValueLabelList(payload,'label'),
      };
    }
    case ASSESSMENT_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSESSMENT_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ASSESSMENT_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentStatus: getValueLabelList(payload),
      };
    }
    case GET_ASSESSMENT_STAGE_OWNER_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ASSESSMENT_STAGE_OWNER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentStage: getValueLabelList(payload?.Data?.AssessmentStage),
        owner: getValueLabelList(payload?.Data?.Owner),
      };
    }
    case GET_ASSESSMENT_STAGE_OWNER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_REMEDIATION_PLAN_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_REMEDIATION_PLAN_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        riskFindingStatusLookup: !isEmpty(payload?.Data?.RemediationGuidanceDisplayStatus) ?  getValueLabelList(payload?.Data?.RemediationGuidanceDisplayStatus) : [],
        riskLevelLookup: !isEmpty(payload?.Data?.RemediationPreferences) ?  getValueLabelList(payload?.Data?.RemediationPreferences) : []
      };
    }

    case SEARCH_REMEDIATION_PLAN_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        riskFindingStatusLookup: [],
        riskLevelLookup: [],
      };
    }

    case SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        remediationObjectiveStatusLookup: !isEmpty(payload?.Data?.RemediationObjectiveStatus) ?  getValueLabelList(payload?.Data?.RemediationObjectiveStatus) : [],
      };
    }

    case SEARCH_REMEDIATION_OBJECTIVE_STATUS_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        remediationObjectiveStatusLookup: [],
      };
    }

    default:
      return state;
  }
};
