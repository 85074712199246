import {
  SEARCH_ASSESSMENT_LIST_LOADING,
  SEARCH_ASSESSMENT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_LIST_FAILURE,
  SEARCH_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
  SET_ASSESSMENT_PAGE_NO,
  SET_ASSESSMENT_ROW_PER_PAGE,
  SEARCH_CORL_CLEARED_ASSESSMENT_LIST_LOADING,
  SEARCH_CORL_CLEARED_ASSESSMENT_LIST_SUCCESS,
  SEARCH_CORL_CLEARED_ASSESSMENT_LIST_FAILURE,
  SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_LOADING,
  SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_SUCCESS,
  SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_FAILURE,
  SET_CORL_CLEARED_ASSESSMENT_PAGE_NO,
  SET_CORL_CLEARED_ASSESSMENT_ROW_PER_PAGE,
  PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_LOADING,
  PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_SUCCESS,
  PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_FAILURE,
} from 'actions/vendorAssessment';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { toast } from 'react-toastify';
import { intlFormatDistance, formatDistance } from 'date-fns';
import { isEmpty } from 'lodash';
import { ASSESSMENT_SHARED_STATUS_UPDATED_SUCCESSFULLY } from 'components/Common/AlertMessages';
import { convertUTCDateToLocalDate } from 'utils/dateAndTimeUtils';

const initialState = {
  loading: false,
  assessmentsList: [],
  assessmentsListCount: 0,
  assessmentPageNo: 1,
  assessmentRowsPerPage: 1,
  corlClearedAssessmentsList: [],
  corlClearedAssessmentsListCount: 0,
  corlClearedAssessmentPageNo: 1,
  corlCLearedAssessmentRowsPerPage: 1
};

export const vendorAssessment = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_ASSESSMENT_LIST_LOADING:
    case SEARCH_ASSESSMENT_LIST_COUNT_LOADING: 
    case SEARCH_CORL_CLEARED_ASSESSMENT_LIST_LOADING: 
    case SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentsList: [],
      };
    }
    case SEARCH_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentsListCount: 0,
      };
    }
    case SEARCH_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentsList: payload?.Data?.Rows?.map((item, index) => {
          return {
            ...item,
            id: index,
            LastUpdatedWithDistance:
              !isEmpty(item) && !isEmpty(item?.LastUpdated)
                ? intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())?.includes('quarter')
                  ? formatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date(), { addSuffix: true })
                  : intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())
                : null,
          };
        }),
      };
    }
    case SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentsListCount: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]?.CountOfAssessment,
      };
    }
    case SET_ASSESSMENT_PAGE_NO: {
      return {
        ...state,
        assessmentPageNo: payload,
      };
    }
    case SET_ASSESSMENT_ROW_PER_PAGE: {
      return {
        ...state,
        assessmentRowsPerPage: payload,
      };
    }
    case SEARCH_CORL_CLEARED_ASSESSMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        corlClearedAssessmentsList: [],
      };
    }
    case SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        corlClearedAssessmentsListCount: 0,
      };
    }
    case SEARCH_CORL_CLEARED_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        corlClearedAssessmentsList: payload?.Data?.Rows?.map((item, index) => {
          return {
            ...item,
            id: index,
          };
        }),
      };
    }
    case SEARCH_CORL_CLEARED_ASSESSMENT_LIST_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        corlClearedAssessmentsListCount: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0]?.CountOfAssessment,
      };
    }
    case SET_CORL_CLEARED_ASSESSMENT_PAGE_NO: {
      return {
        ...state,
        corlClearedAssessmentPageNo: payload,
      };
    }
    case SET_CORL_CLEARED_ASSESSMENT_ROW_PER_PAGE: {
      return {
        ...state,
        corlCLearedAssessmentRowsPerPage: payload,
      };
    }
    case PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_SUCCESS: {
      toast.success(ASSESSMENT_SHARED_STATUS_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_CORL_CLEARED_ASSESSMENT_SHARED_STATUS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
