import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';

export const GET_DECRYPTED_TEXT_LOADING = '@@publicApi/GET_DECRYPTED_TEXT_LOADING';
export const GET_DECRYPTED_TEXT_SUCCESS = '@@publicApi/GET_DECRYPTED_TEXT_SUCCESS';
export const GET_DECRYPTED_TEXT_FAILURE = '@@publicApi/GET_DECRYPTED_TEXT_FAILURE';

export const decryptText = (text = "") => async (dispatch) => {
    dispatch({ type: GET_DECRYPTED_TEXT_LOADING });
    try {
        const body = { text }
        const response = await axios.post(`/public/api/action/decrypt`, body);
        if (response?.data?.ErrorCount > 0) {
            dispatch(setErrors(response?.data?.Errors));
            dispatch({
                type: GET_DECRYPTED_TEXT_FAILURE,
                payload: '',
            });
        } else {
            dispatch({
                type: GET_DECRYPTED_TEXT_SUCCESS,
                payload: response?.data,
            });
        }
    } catch (err) {
        dispatch({
            type: GET_DECRYPTED_TEXT_FAILURE,
            payload: getErrorObject(err),
        });
    }
};