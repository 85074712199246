import axios from 'axios';
import { toast } from 'react-toastify';
import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  SET_SELECTED_USER,
  SET_SELECTED_INDEX,
  SET_VENDOR_USER_FORM_EDITABLE,
  SEARCH_VENDOR_USERS_LOADING,
  SEARCH_VENDOR_USERS_FAILURE,
  SEARCH_VENDOR_USERS_SUCCESS,
  ADD_VENDOR_USER_LOADING,
  ADD_VENDOR_USER_FAILURE,
  ADD_VENDOR_USER_SUCCESS,
  UPDATE_VENDOR_USER_LOADING,
  UPDATE_VENDOR_USER_FAILURE,
  UPDATE_VENDOR_USER_SUCCESS,
  REMOVE_VENDOR_USER_LOADING,
  REMOVE_VENDOR_USER_FAILURE,
  REMOVE_VENDOR_USER_SUCCESS,
  POST_RESEND_INVITE_TO_USER_LOADING,
  POST_RESEND_INVITE_TO_USER_FAILURE,
  POST_RESEND_INVITE_TO_USER_SUCCESS,
} from 'actions/user';
import { ERROR_MESSAGE, RESEND_INVITE_SUCCESSFULLY, UPDATED_SUCCESSFULLY_MESSAGE } from '../components/Common/AlertMessages';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY } from 'constants/errorMessage';

const initialState = {
  loading: false,
  error: '',
  users: [],
  selectedIndex: null,
  selectedUser: {},
  isUserFormEditable: false,
  userToBeSelected: null,
};

export default function (state = initialState, action) {
  const { type, payload, isEditable } = action;

  switch (type) {
    case USER_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case USER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: payload,
      };
    }

    case USER_LIST_FAIL: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_VENDOR_USERS_LOADING: {
      return {
        ...state,
        loading: false,
        users: [],
        selectedIndex: null,
        selectedUser: {},
      };
    }
    case ADD_VENDOR_USER_LOADING:
    case UPDATE_VENDOR_USER_LOADING:
    case REMOVE_VENDOR_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_VENDOR_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        users: [],
      };
    }

    case ADD_VENDOR_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_VENDOR_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case REMOVE_VENDOR_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_VENDOR_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: payload?.Data?.Rows,
      };
    }

    case ADD_VENDOR_USER_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        isUserFormEditable: false,
        userToBeSelected: payload?.Data?.UserId,
      };
    }

    case UPDATE_VENDOR_USER_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        userToBeSelected: null,
      };
    }

    case REMOVE_VENDOR_USER_SUCCESS: {
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: payload || {},
      };
    }

    case SET_SELECTED_INDEX: {
      return {
        ...state,
        selectedIndex: payload,
      };
    }

    case SET_VENDOR_USER_FORM_EDITABLE: {
      return {
        ...state,
        isUserFormEditable: isEditable,
      };
    }

    case POST_RESEND_INVITE_TO_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_RESEND_INVITE_TO_USER_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_RESEND_INVITE_TO_USER_SUCCESS: {
      toast.success(RESEND_INVITE_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
