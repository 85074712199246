export const DRU_ONLY_MODAL = {
  greetingMsg: 'Good news! ',
  title: 'Data-Reuse is available for this Questionnaire.',
  subtitle: 'We have past answers that we can apply.',
  description:
    "Our records show that you have responded before to this questionnaire. Using a previous response set to pre-populate your answers will help expedite your response time. You'll also have the opportunity to edit any reuse response before submitting.",
  showSelection: true,
  buttonLabel: 'USE DATA REUSE',
  color: 'secondary',
  skipDRUText: `That's ok. I'll Answer Each Question Independently.`,
};

import { faGlobe, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COPILOT_LABEL } from 'constants/constants';

export const OVERVIEW = 0;
export const REMEDIATION_PLAN = 1;

export const ASSESSMENT_DETAILS_SIDEBAR_MENU = [
  { label: 'OVERVIEW', value: OVERVIEW, icon: <FontAwesomeIcon icon={faGlobe} /> },
  { label: 'REMEDIATION PLAN', value: REMEDIATION_PLAN, icon: <FontAwesomeIcon icon={faScrewdriverWrench} /> },
];
export const ACCEPT_ALL_FINDINGS_MODAL_MESSAGE =
  'By choosing "Accept All", you are committing to address all necessary risk findings, along with their associated milestones and the schedule established by the Client. If you prefer to review each item individually, please navigate away otherwise, feel free to proceed.';

export const REMEDIATION_PLAN_READY_STATUS = 25;
export const REMEDIATION_PENDING = 'pending';
export const REMEDIATION_ACCEPTED = 'accepted';
export const REMEDIATION_DECLINED = 'declined';
export const REMEDIATION_COMPLETED = 'completed';
export const RISK_FINDING_ACCEPTED_SUCCESSFULLY = 'Risk findings accepted successfully';
export const REMEDIATION_PLAN_SUBMITTED_SUCCESSFULLY = 'Remediation plan submitted successfully';

export const REMEDIATION_RESPONSE_REASON_WITHOUT_MILESTONE = 1;
export const REMEDIATION_RESPONSE_REASON_WITH_MILESTONE = 2;
export const DECLINE_REMEDIATION_RESPONSE = 2;

export const COPILOT_ONLY_MODAL = {
  greetingMsg: 'Good news! ',
  title: `${COPILOT_LABEL} is available for this Questionnaire.`,
  subtitle: `Upload your evidence and let ${COPILOT_LABEL} do the answering.`,
  description:
    `Reduce the time it takes to respond to this questionnaire by leveraging our AI-powered ${COPILOT_LABEL} feature. ${COPILOT_LABEL} scans your evidence to generate answers to the questionnaire's questions. Once generated, you'll have the opportunity to adjust any ${COPILOT_LABEL} answer.`,
  showSelection: false,
  buttonLabel: `USE ${COPILOT_LABEL?.toUpperCase()}`,
  color: 'secondary',
  skipDRUText: `That's ok. I'll Answer Each Question Independently.`,
};

export const COPILOT_WITH_DRU_FOR_COPILOT_MODAL = {
  greetingMsg: 'Good news! ',
  title: `Data-Reuse and ${COPILOT_LABEL} are available `,
  titleSubPart: 'for this Questionnaire.',
  subtitle: `You can combine answers from a previous questionnaire and use ${COPILOT_LABEL} to leverage AI to answer remaining questions.`,
  showStep: true,
  showStepName: `Step 2: Upload your evidence and let ${COPILOT_LABEL} do the answering.`,
  description:
    `Reduce the time it takes to respond to this questionnaire by leveraging our AI-powered ${COPILOT_LABEL} feature. ${COPILOT_LABEL} scans your evidence to generate answers to the questionnaire's questions. Once generated, you'll have the opportunity to adjust any ${COPILOT_LABEL} answer.`,
  showSelection: false,
  showTabs: true,
  tab: 2,
  buttonLabel: `USE ${COPILOT_LABEL?.toUpperCase()}`,
  color: 'secondary',
  skipDRUText: `That's ok. I'll Answer Each Question Independently.`,
  showDivider: true,
};

export const COPILOT_WITH_DRU_FOR_DRU_MODAL = {
  greetingMsg: 'Good news! ',
  title: `Data-Reuse and ${COPILOT_LABEL} are available `,
  titleSubPart: 'for this Questionnaire.',
  subtitle: `You can combine answers from a previous questionnaire and use ${COPILOT_LABEL} to leverage AI to answer remaining questions.`,
  showStep: true,
  showStepName: 'Step 1: Which previous Questionnaire would you like to use?',
  description:
    "Our records show that you have responded before to this questionnaire. Using a previous response set to pre-populate your answers will help expedite your response time. You'll also have the opportunity to edit any reuse response before submitting.",
  showSelection: true,
  showTabs: true,
  tab: 1,
  buttonLabel: 'USE DATA REUSE',
  color: 'secondary',
  skipDRUText: `THAT'S OK. SKIP DATA REUSE.`,
  showDivider: true,
};

export const DRU_ENABLED = '2';
export const COPILOT_ENABLED = '2';

export const STANDARD_DELAY = 2000;
export const ASSESSMENT_RESPONSE_MODE_NOT_GIVEN = 0;
export const IS_VENDOR_COPILOT_ENABLED = '2';

export const STANDARD_ASSESSMENT_TYPE = 'Standard';
export const CORL_CLEARED_CLIENT_INITIATIONED_ASSESSMENT_TYPE = 'CORL Cleared Client Initiated';
export const IMPORTED_COMPANION_ASSESSMENT_TYPE = 'Companion Assessment';

export const REGURLAR_ASSESSMENT_TYPE_LIST = [STANDARD_ASSESSMENT_TYPE, CORL_CLEARED_CLIENT_INITIATIONED_ASSESSMENT_TYPE, IMPORTED_COMPANION_ASSESSMENT_TYPE];
export const CORL_CLEARED_ASSESSMENT_TYPE_ID = "CORL Cleared";

export const TOTAL_COUNT_OF_RISK_FINDINGS = 'TotalCountOfRiskFindings';
export const COUNT_OF_HIGH_RISK_FINDINGS = 'CountOfHighRistFindings';
export const IS_REM_RESPONSE_SUBMITTED = 'IsRemResponseSubmitted';

export const ASSESSMENT_SUBMITTED_STATUS = 'submitted';