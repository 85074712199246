import { toast } from 'react-toastify';
import {
  SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING,
  SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS,
  SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE,
  ADD_RDS_CONNECTION_LOADING,
  ADD_RDS_CONNECTION_SUCCESS,
  ADD_RDS_CONNECTION_FAILURE,
  UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING,
  UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS,
  UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE,
} from 'actions/rdsConfiguration';
import { SAVE_SUCCESSFULLY_MESSAGE, UPDATED_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { ERROR_MESSAGE } from 'constants/errorMessage';

const initialState = {
  loading: false,
  rdsConfigurationConnectionList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        rdsConfigurationConnectionList: [],
      };
    }

    case SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        rdsConfigurationConnectionList: payload?.Data?.Rows,
      };
    }

    case ADD_RDS_CONNECTION_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_RDS_CONNECTION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_RDS_CONNECTION_SUCCESS: {
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS: {
      toast.success(UPDATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
