import axios from 'axios';
import { ASSESSMENT_OVERVIEW_TAB_OPTIONS } from 'pages/AssessmentsOverview/constants';
import { getErrorObject } from 'utils/apiUtils'
import {
  getAssessmentConfigurationBody, getAssessmentNotesBody, getAssessmentSearchBody, getQuestionnaireInfoBody,
  getQuestionnaireInfoBodyForAssessment, searchActiveAssessmentsBody, searchAssessmentsListBody, searchCompletedAssessmentsBody, searchRemediationAssessmentsBody, getAssessmentOverviewAssessmentDetailsSearchBody, getAssessmentPortal1RedirectBody, getAssessmentOverviewPortalAssessmentDetailsSearchBody, getAssessmentOverviewDynamicAssessmentDetailsSearchBody, searchRemediationListBody, searchRemediationDetailsBody, searchRemediationGuidanceBody, searchRemediationGuidanceDetailsBody, getDeclineRiskFindingLookupBody, getVendorCopilotPortalAssessmentDetailsSearchBody, getVerifyCopilotAssessmentStatusBody
} from 'utils/assessmentsOverviewUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { ANONYMS_URL_PATH, PORTAL_2 } from 'constants/constants';
import { isNil } from 'lodash';
import { TOTAL_COUNT_OF_RISK_FINDINGS } from 'pages/AssessmentDetails/constants';

export const SET_ASSESSMENTS_OVERVIEW_TAB = '@assessmentsOverview/SET_ASSESSMENTS_OVERVIEW_TAB';

export const setAssessmentsOverviewTab = (value = ASSESSMENT_OVERVIEW_TAB_OPTIONS.BOARD) => async (dispatch) => {
  dispatch({
    type: SET_ASSESSMENTS_OVERVIEW_TAB,
    payload: value,
  });
};


export const SEARCH_ACTIVE_ASSESSMENTS_LOADING = '@@assessmentsOverview/SEARCH_ACTIVE_ASSESSMENTS_LOADING';
export const SEARCH_ACTIVE_ASSESSMENTS_SUCCESS = '@@assessmentsOverview/SEARCH_ACTIVE_ASSESSMENTS_SUCCESS';
export const SEARCH_ACTIVE_ASSESSMENTS_FAILURE = '@@assessmentsOverview/SEARCH_ACTIVE_ASSESSMENTS_FAILURE';

export const searchActiveAssessments = (searchData) => async (dispatch) => {
  dispatch({
    type: SEARCH_ACTIVE_ASSESSMENTS_LOADING,
  });

  const body = searchActiveAssessmentsBody(searchData);
  try {
    const res = await axios.post(`/legacy/assessment/active/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ACTIVE_ASSESSMENTS_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_ACTIVE_ASSESSMENTS_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ACTIVE_ASSESSMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_COMPLETED_ASSESSMENTS_LOADING = '@@assessmentsOverview/SEARCH_COMPLETED_ASSESSMENTS_LOADING';
export const SEARCH_COMPLETED_ASSESSMENTS_SUCCESS = '@@assessmentsOverview/SEARCH_COMPLETED_ASSESSMENTS_SUCCESS';
export const SEARCH_COMPLETED_ASSESSMENTS_FAILURE = '@@assessmentsOverview/SEARCH_COMPLETED_ASSESSMENTS_FAILURE';

export const searchCompletedAssessments = (searchData) => async (dispatch) => {
  dispatch({
    type: SEARCH_COMPLETED_ASSESSMENTS_LOADING,
  });

  const body = searchCompletedAssessmentsBody(searchData);
  try {
    const res = await axios.post(`/legacy/assessment/completed/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_COMPLETED_ASSESSMENTS_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_COMPLETED_ASSESSMENTS_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COMPLETED_ASSESSMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REMEDIATION_ASSESSMENTS_LOADING = '@@assessmentsOverview/SEARCH_REMEDIATION_ASSESSMENTS_LOADING';
export const SEARCH_REMEDIATION_ASSESSMENTS_SUCCESS = '@@assessmentsOverview/SEARCH_REMEDIATION_ASSESSMENTS_SUCCESS';
export const SEARCH_REMEDIATION_ASSESSMENTS_FAILURE = '@@assessmentsOverview/SEARCH_REMEDIATION_ASSESSMENTS_FAILURE';

export const searchRemediationAssessments = (searchData) => async (dispatch) => {
  dispatch({
    type: SEARCH_REMEDIATION_ASSESSMENTS_LOADING,
  });

  const body = searchRemediationAssessmentsBody(searchData);
  try {
    const res = await axios.post(`/legacy/assessment/remediation/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REMEDIATION_ASSESSMENTS_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_ASSESSMENTS_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_ASSESSMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SEARCH_ASSESSMENT_CONFIG_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_CONFIG_LOADING';
export const SEARCH_ASSESSMENT_CONFIG_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_CONFIG_SUCCESS';
export const SEARCH_ASSESSMENT_CONFIG_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_CONFIG_FAILURE';

export const getAssessmentConfiguration = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_CONFIG_LOADING });
  try {
    const body = getAssessmentConfigurationBody();
    const response = await axios.post(`/legacy/assessment/configuration/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_OPEN_ASSESSMENT_HELP_MODAL = '@assessmentsOverview/SET_OPEN_ASSESSMENT_HELP_MODAL';

export const setOpenAssessmentHelpModal = (value) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_ASSESSMENT_HELP_MODAL,
    payload: value,
  });
};

export const SET_SELECTED_ASSESSMENT_DETAILS_CARD = '@assessmentsOverview/SET_SELECTED_ASSESSMENT_DETAILS_CARD';

export const setSelectedAssessmentCardValue = (card) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ASSESSMENT_DETAILS_CARD,
    payload: card,
  });
};

export const POST_ASSESSMENT_TICKET_LOADING = '@assessmentsOverview/POST_ASSESSMENT_TICKET_LOADING';
export const POST_ASSESSMENT_TICKET_SUCCESS = '@assessmentsOverview/POST_ASSESSMENT_TICKET_SUCCESS';
export const POST_ASSESSMENT_TICKET_FAILURE = '@assessmentsOverview/POST_ASSESSMENT_TICKET_FAILURE';

export const addAssessmentTicket = (formData, assessmentName, assessmentId) => async (dispatch) => {
  dispatch({
    type: POST_ASSESSMENT_TICKET_LOADING,
  });

  let tempFormData = {
    ...formData,
    AssessmentName: assessmentName,
    AssessmentId: assessmentId
  };

  delete tempFormData['UploadedFileList'];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  formData?.UploadedFileList.forEach((assessmentTicketFile) => {
    body.append('UploadedFileList', assessmentTicketFile.file);
  });

  try {
    const res = await axios.post(`/assessmentticket`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_ASSESSMENT_TICKET_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_ASSESSMENT_TICKET_SUCCESS,
        payload: res?.data?.Data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_ASSESSMENT_TICKET_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_LOADING_EXPANDED_SECTIONS = '@assessmentsOverview/SET_LOADING_EXPANDED_SECTIONS';

export const setLoadingExpandedSections = (payload) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_EXPANDED_SECTIONS,
    payload,
  });
};

export const SEARCH_ASSESSMENTS_LIST_LOADING = '@@assessmentsOverview/SEARCH_ASSESSMENTS_LIST_LOADING';
export const SEARCH_ASSESSMENTS_LIST_SUCCESS = '@@assessmentsOverview/SEARCH_ASSESSMENTS_LIST_SUCCESS';
export const SEARCH_ASSESSMENTS_LIST_FAILURE = '@@assessmentsOverview/SEARCH_ASSESSMENTS_LIST_FAILURE';

export const searchAssessmentsList = (searchData) => async (dispatch) => {
  dispatch({
    type: SEARCH_ASSESSMENTS_LIST_LOADING,
  });

  const body = searchAssessmentsListBody(searchData);
  try {
    const res = await axios.post(`/legacy/assessment/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENTS_LIST_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_ASSESSMENTS_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENTS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET = '@assessmentsOverview/SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET';

export const setOpenAssessmentDetailsSidesheet = (value) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
    payload: value,
  });
};

export const GET_ASSESSMENT_NOTES_LOADING = '@assessmentsOverview/GET_ASSESSMENT_NOTES_LOADING';
export const GET_ASSESSMENT_NOTES_SUCCESS = '@assessmentsOverview/GET_ASSESSMENT_NOTES_SUCCESS';
export const GET_ASSESSMENT_NOTES_FAILURE = '@assessmentsOverview/GET_ASSESSMENT_NOTES_FAILURE';

export const getAssessmentNotes =
  (searchData = {}) =>
    async (dispatch) => {
      dispatch({ type: GET_ASSESSMENT_NOTES_LOADING });
      try {
        const body = getAssessmentNotesBody(searchData);
        const response = await axios.post(`/legacy/assessment/notes/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GET_ASSESSMENT_NOTES_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: GET_ASSESSMENT_NOTES_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: GET_ASSESSMENT_NOTES_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const GET_QUESTIONNAIRE_INFO_LOADING = '@assessmentsOverview/GET_QUESTIONNAIRE_INFO_LOADING';
export const GET_QUESTIONNAIRE_INFO_SUCCESS = '@assessmentsOverview/GET_QUESTIONNAIRE_INFO_SUCCESS';
export const GET_QUESTIONNAIRE_INFO_FAILURE = '@assessmentsOverview/GET_QUESTIONNAIRE_INFO_FAILURE';

export const getQuestionnaireInformation =
  (searchData = null, source = "", callback) =>
    async (dispatch) => {
      dispatch({ type: GET_QUESTIONNAIRE_INFO_LOADING });
      try {
        let response;
        if (source === PORTAL_2) {
          const body = getQuestionnaireInfoBodyForAssessment(searchData);
          response = await axios.post(`/assessment/search`, body);
        } else {
          const body = getQuestionnaireInfoBody(searchData);
          response = await axios.post(`/legacy/assessment/search`, body);
        }
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GET_QUESTIONNAIRE_INFO_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: GET_QUESTIONNAIRE_INFO_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: GET_QUESTIONNAIRE_INFO_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_ASSESSMENT_NOTES = '@@assessmentsOverview/SET_ASSESSMENT_NOTES';

export const setAssessmentNotes =
  (data = []) =>
    async (dispatch) => {
      dispatch({
        type: SET_ASSESSMENT_NOTES,
        payload: data,
      });
    };

export const SEARCH_ASSESSMENT_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_LOADING';
export const SEARCH_ASSESSMENT_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_SUCCESS';
export const SEARCH_ASSESSMENT_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_FAILURE';

export const getAssessment =
  (id = null, isAnonyms) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_LOADING });
      try {
        const body = getAssessmentSearchBody(id);
        const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : "";
        const response = await axios.post(`/legacy/assessment${anonymsPath}/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_FAILURE,
            payload: {},
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_SUCCESS,
            payload: response.data || {},
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_SELECTED_CARD_DETAILS = '@assessmentsOverview/SET_SELECTED_CARD_DETAILS';

export const setSelectedCardDetails = (selectedCard = {}, callback) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_CARD_DETAILS,
    payload: selectedCard,
  });
  if (callback) {
    callback();
  }
};

export const SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_LOADING';
export const SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_FAILURE';

export const searchAssessmentOverviewPortalAssessmentDetails = (assessmentId = null, assessmentRequestId = null, assessmentQuestionnaireId = null, isVendorCopilot, rest) => async (dispatch) => {
  if (!rest?.hideLoading) dispatch({ type: SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_LOADING });
  try {
    const body = isVendorCopilot ?
      getVendorCopilotPortalAssessmentDetailsSearchBody(assessmentId, assessmentQuestionnaireId) :
      getAssessmentOverviewPortalAssessmentDetailsSearchBody(assessmentId, assessmentRequestId, assessmentQuestionnaireId);
    const response = await axios.post(`assessment/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_SUCCESS,
        payload: response.data?.Data?.Rows || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_LOADING = '@assessmentsOverview/SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_LOADING';
export const SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_SUCCESS = '@assessmentsOverview/SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_SUCCESS';
export const SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_FAILURE = '@assessmentsOverview/SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_FAILURE';

export const searchAssessmentPortal1RedirectionURL = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_LOADING });
  try {
    const body = getAssessmentPortal1RedirectBody();
    const response = await axios.post(`/appsettings/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_SUCCESS,
        payload: response.data?.Data?.LegacySettings_AssessmentQuestionnaireQuestionUrl,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_LOADING = '@@assessmentsOverview/SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_LOADING';
export const SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_SUCCESS = '@@assessmentsOverview/SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_FAILURE = '@@assessmentsOverview/SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_FAILURE';

export const searchAssessmentOverviewDynamicAssessmentDetails = (assessmentId = null) => async (dispatch) => {
  dispatch({
    type: SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_LOADING,
  });

  const body = getAssessmentOverviewDynamicAssessmentDetailsSearchBody(assessmentId);
  try {
    const res = await axios.post(`/legacy/assessment/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const RESET_ASSESSMENT_DETAILS = '@assessmentsOverview/RESET_ASSESSMENT_DETAILS';

export const resetAssessmentDetails = () => async (dispatch) => {
  dispatch({
    type: RESET_ASSESSMENT_DETAILS,
  });
};

export const SEARCH_REMEDIATION_GUIDANCE_LOADING = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_LOADING';
export const SEARCH_REMEDIATION_GUIDANCE_SUCCESS = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_SUCCESS';
export const SEARCH_REMEDIATION_GUIDANCE_FAILURE = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_FAILURE';

export const searchRemediationGuidance = (assessmentId = null, clientOrgId = null, fieldName = TOTAL_COUNT_OF_RISK_FINDINGS) => async (dispatch) => {
  dispatch({
    type: SEARCH_REMEDIATION_GUIDANCE_LOADING,
  });

  const body = searchRemediationGuidanceBody(assessmentId, clientOrgId, fieldName);
  try {
    const res = await axios.post(`/assessment/${assessmentId}/remediationguidnace/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_SUCCESS,
        payload: res?.data?.Data?.Rows,
        fieldName: fieldName,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_GUIDANCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_OPEN_REMEDIATION_HELP_MODAL = '@assessmentsOverview/SET_OPEN_REMEDIATION_HELP_MODAL';

export const setOpenRemediationHelpModal = (val = false, isGuidance = false) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_REMEDIATION_HELP_MODAL,
    payload: {
      open: val,
      isGuidance,
    },
  })
}

export const POST_REMEDIATION_HELP_TICKET_LOADING = '@vendorProduct/POST_REMEDIATION_HELP_TICKET_LOADING';
export const POST_REMEDIATION_HELP_TICKET_SUCCESS = '@vendorProduct/POST_REMEDIATION_HELP_TICKET_SUCCESS';
export const POST_REMEDIATION_HELP_TICKET_FAILURE = '@vendorProduct/POST_REMEDIATION_HELP_TICKET_FAILURE';

export const submitRemediationHelpTicket = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_REMEDIATION_HELP_TICKET_LOADING,
  });

  let tempFormData = {
    ...formData,
  };

  delete tempFormData['UploadedFileList'];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  formData?.UploadedFileList.forEach((helpTicketFile) => {
    body.append('UploadedFileList', helpTicketFile.file);
  });

  try {
    const res = await axios.post(`/assessment/remediationguidancedetail/${formData?.RemediationGuidanceDetailId}/action/feedback`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_REMEDIATION_HELP_TICKET_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_REMEDIATION_HELP_TICKET_SUCCESS,
        payload: res?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_REMEDIATION_HELP_TICKET_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_LOADING = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_LOADING';
export const SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_SUCCESS = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_SUCCESS';
export const SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_FAILURE = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_FAILURE';

export const searchRemediationGuidanceDetailsList = (assessmentId = null, clientOrgId = null, searchData = {}) => async (dispatch) => {
  dispatch({
    type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_LOADING,
  });

  const body = searchRemediationGuidanceDetailsBody(assessmentId, clientOrgId, searchData);
  try {
    const res = await axios.post(`/assessment/remediationguidancedetail/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
}
export const ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_LOADING = '@assessmentsOverview/ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_LOADING';
export const ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_SUCCESS = '@assessmentsOverview/ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_SUCCESS';
export const ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_FAILURE = '@assessmentsOverview/ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_FAILURE';

export const acceptRiskFindingsWithoutMilestones = (formData, callback) => async (dispatch) => {
  dispatch({ type: ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_LOADING });
  let body = new FormData();

  Object.keys(formData).forEach((key) => {
    body.append(`modelList[0].${key}`, JSON.stringify(formData[key].toString()));
  });

  try {
    const response = await axios.post(`/assessment/remediationguidancedetail/${formData?.RemediationGuidanceDetailId}/action/acceptaction`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_FAILURE,
        payload: [],
      });
      setErrors(response?.data?.Errors);
    } else {
      dispatch({
        type: ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_SUCCESS,
        payload: response?.data || [],
      });
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch({
      type: ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ACCEPT_RISK_FINDINGS_WITH_MILESTONE_LOADING = '@assessmentsOverview/ACCEPT_RISK_FINDINGS_WITH_MILESTONE_LOADING';
export const ACCEPT_RISK_FINDINGS_WITH_MILESTONE_SUCCESS = '@assessmentsOverview/ACCEPT_RISK_FINDINGS_WITH_MILESTONE_SUCCESS';
export const ACCEPT_RISK_FINDINGS_WITH_MILESTONE_FAILURE = '@assessmentsOverview/ACCEPT_RISK_FINDINGS_WITH_MILESTONE_FAILURE';

export const acceptRiskFindingsWithMilestones = (formData, callback, assessmentId = null, clientId = null) => async (dispatch) => {
  dispatch({ type: ACCEPT_RISK_FINDINGS_WITH_MILESTONE_LOADING });
  let body = new FormData();

  Object.keys(formData).forEach((key) => {
    body.append(`modelList[0].${key}`, JSON.stringify(formData[key].toString()));
  });

  try {
    const response = await axios.post(`assessment/remediationguidancedetail/${formData?.RemediationGuidanceDetailId}/response`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: ACCEPT_RISK_FINDINGS_WITH_MILESTONE_FAILURE,
        payload: [],
      });
      setErrors(response?.data?.Errors);
    } else {
      dispatch({
        type: ACCEPT_RISK_FINDINGS_WITH_MILESTONE_SUCCESS,
        payload: response?.data || [],
      });
      dispatch(searchRemediationGuidanceDetailsList(assessmentId, clientId))
    }
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch({
      type: ACCEPT_RISK_FINDINGS_WITH_MILESTONE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const ACCEPT_ALL_RISK_FINDING_LOADING = '@@assessmentsOverview/ACCEPT_ALL_RISK_FINDING_LOADING';
export const ACCEPT_ALL_RISK_FINDING_SUCCESS = '@@assessmentsOverview/ACCEPT_ALL_RISK_FINDING_SUCCESS';
export const ACCEPT_ALL_RISK_FINDING_FAILURE = '@@assessmentsOverview/ACCEPT_ALL_RISK_FINDING_FAILURE';

export const acceptAllRiskFinding = (formData = {}, remediationGuidanceId = null, assessmentId = null, clientId = null) => async (dispatch) => {
  dispatch({
    type: ACCEPT_ALL_RISK_FINDING_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    const res = await axios.post(`assessment/remediationguidance/${remediationGuidanceId}/action/acceptall`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: ACCEPT_ALL_RISK_FINDING_FAILURE,
        payload: [],
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: ACCEPT_ALL_RISK_FINDING_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      dispatch(searchRemediationGuidanceDetailsList(assessmentId, clientId))
    }
  } catch (err) {
    dispatch({
      type: ACCEPT_ALL_RISK_FINDING_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SUBMIT_REMEDIATION_PLAN_LOADING = '@@assessmentsOverview/SUBMIT_REMEDIATION_PLAN_LOADING';
export const SUBMIT_REMEDIATION_PLAN_SUCCESS = '@@assessmentsOverview/SUBMIT_REMEDIATION_PLAN_SUCCESS';
export const SUBMIT_REMEDIATION_PLAN_FAILURE = '@@assessmentsOverview/SUBMIT_REMEDIATION_PLAN_FAILURE';

export const submitRemediationPlan = (formData = {}, assessmentId = null, clientId = null, fieldName = null) => async (dispatch) => {
  dispatch({
    type: SUBMIT_REMEDIATION_PLAN_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    const res = await axios.post(`/assessment/remediationguidance/${formData?.RemediationGuidanceId}/action/submitremediationplan`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SUBMIT_REMEDIATION_PLAN_FAILURE,
        payload: [],
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SUBMIT_REMEDIATION_PLAN_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      dispatch(searchRemediationGuidanceDetailsList(assessmentId, clientId))
      if (!isNil(fieldName)) {
        dispatch(searchRemediationGuidance(assessmentId, clientId, fieldName));
      }
    }
  } catch (err) {
    dispatch({
      type: SUBMIT_REMEDIATION_PLAN_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REMEDIATION_GUIDANCE_LIST_LOADING = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_LIST_LOADING';
export const SEARCH_REMEDIATION_GUIDANCE_LIST_SUCCESS = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_LIST_SUCCESS';
export const SEARCH_REMEDIATION_GUIDANCE_LIST_FAILURE = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_LIST_FAILURE';

export const searchRemediationGuidanceList = (assessmentId = null, clientId = null) => async (dispatch) => {
  dispatch({
    type: SEARCH_REMEDIATION_GUIDANCE_LIST_LOADING,
  });

  const body = searchRemediationListBody(assessmentId, clientId);
  try {
    const res = await axios.post(`/assessment/remediationguidancedetail/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_LIST_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_LIST_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_GUIDANCE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
}

export const SEARCH_REMEDIATION_GUIDANCE_DETAILS_LOADING = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_DETAILS_LOADING';
export const SEARCH_REMEDIATION_GUIDANCE_DETAILS_SUCCESS = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_DETAILS_SUCCESS';
export const SEARCH_REMEDIATION_GUIDANCE_DETAILS_FAILURE = '@@assessmentsOverview/SEARCH_REMEDIATION_GUIDANCE_DETAILS_FAILURE';

export const searchRemediationGuidanceDetails = (assessmentId = null, clientId = null, assessmentQuestionnaireRequirementId = null) => async (dispatch) => {
  dispatch({
    type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_LOADING,
  });

  const body = searchRemediationDetailsBody(assessmentId, clientId, assessmentQuestionnaireRequirementId);
  try {
    const res = await axios.post(`/assessment/remediationguidancedetail/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_GUIDANCE_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
}

export const GET_DECLINE_RISK_FINDING_LOOKUP_LOADING = '@@assessmentsOverview/GET_DECLINE_RISK_FINDING_LOOKUP_LOADING';
export const GET_DECLINE_RISK_FINDING_LOOKUP_SUCCESS = '@@assessmentsOverview/GET_DECLINE_RISK_FINDING_LOOKUP_SUCCESS';
export const GET_DECLINE_RISK_FINDING_LOOKUP_FAILURE = '@@assessmentsOverview/GET_DECLINE_RISK_FINDING_LOOKUP_FAILURE';

export const getDeclineRiskFindingLookup = (remediationResponseReasonTypeId = null) => async (dispatch) => {
  dispatch({
    type: GET_DECLINE_RISK_FINDING_LOOKUP_LOADING,
  });

  const body = getDeclineRiskFindingLookupBody(remediationResponseReasonTypeId);
  try {
    const res = await axios.post(`/assessment/remediationresponsereason/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_DECLINE_RISK_FINDING_LOOKUP_FAILURE,
        payload: [],
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_DECLINE_RISK_FINDING_LOOKUP_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_DECLINE_RISK_FINDING_LOOKUP_FAILURE,
      payload: getErrorObject(err),
    });
  }
}

export const POST_REMEDIATION_SUBMIT_FOR_APPROVAL_LOADING = '@vendorProduct/POST_REMEDIATION_SUBMIT_FOR_APPROVAL_LOADING';
export const POST_REMEDIATION_SUBMIT_FOR_APPROVAL_SUCCESS = '@vendorProduct/POST_REMEDIATION_SUBMIT_FOR_APPROVAL_SUCCESS';
export const POST_REMEDIATION_SUBMIT_FOR_APPROVAL_FAILURE = '@vendorProduct/POST_REMEDIATION_SUBMIT_FOR_APPROVAL_FAILURE';

export const submitRemediationForApproval = (formData, isDispute, callback, assessmentId, clientId) => async (dispatch) => {
  dispatch({
    type: POST_REMEDIATION_SUBMIT_FOR_APPROVAL_LOADING,
  });

  let tempFormData = {
    ...formData,
  };

  const tempFileList = formData?.UploadedFileList;

  delete tempFormData['UploadedFileList'];

  let body = new FormData();

  tempFileList.forEach((item, index) => {
    Object.keys(item).forEach((key) => {
      body.append(`modelList[${index}].${key}`, item[key]);
    });
  });

  tempFileList.forEach((item, index) => {
    Object.keys(tempFormData).forEach((key) => {
      body.append(`modelList[${index}].${key}`, JSON.stringify(tempFormData[key].toString()));
    });
  });

  if (tempFileList.length === 0) {
    Object.keys(tempFormData).forEach((key) => {
      body.append(`modelList[0].${key}`, JSON.stringify(tempFormData[key].toString()));
    });
  }


  try {
    let res;
    if (isDispute) {
      res = await axios.post(`assessment/remediationguidancedetail/${formData?.RemediationGuidanceDetailId}/action/acceptaction`, body, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });
    }
    else {
      res = await axios.post(`assessment/remediationguidancedetail/${formData?.RemediationGuidanceDetailId}/response`, body, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      });
    }

    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_REMEDIATION_SUBMIT_FOR_APPROVAL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_REMEDIATION_SUBMIT_FOR_APPROVAL_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(searchRemediationGuidanceDetailsList(assessmentId, clientId))
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_REMEDIATION_SUBMIT_FOR_APPROVAL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const RESET_REMEDIATION_GUIDANCE_DETAILS_LIST = '@assessmentsOverview/RESET_REMEDIATION_GUIDANCE_DETAILS_LIST';

export const resetRemediationGuidanceDetailsList = () => async (dispatch) => {
  dispatch({
    type: RESET_REMEDIATION_GUIDANCE_DETAILS_LIST,
  });
};

export const SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_LOADING = '@assessmentsOverview/SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_LOADING';
export const SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_SUCCESS = '@assessmentsOverview/SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_SUCCESS';
export const SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_FAILURE = '@assessmentsOverview/SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_FAILURE';

export const verifyCopilotAssessmentStatus = (assessmentId = null, assessmentQuestionnaireId = null, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_LOADING });
  try {
    const body = getVerifyCopilotAssessmentStatusBody(assessmentId, assessmentQuestionnaireId);
    const response = await axios.post(`assessment/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_SUCCESS,
        payload: response.data?.Data?.Rows || [],
      });
      if (callback) {
        callback(response.data?.Data?.Rows);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VERIFY_ASSESSMENT_STATUS_FOR_COPILOT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
