export const IN_PROGRESS = '2';
export const SUBMITTED = '3';
export const ISSUED = '1';
export const IN_REVIEW = '4';
export const COMPLETED = '5';
export const NO_QUESTIONNAIRES = 'NO OUTREACH LAUNCHED';
export const STATUS_IN_PROGRESS = 'IN-PROGRESS';
export const STATUS_COMPLETED = 'SUBMITTED';
export const STATUS_UNOPENED = 'UNOPENED';
export const WARNING_MESSAGE =
  'You will not be able to edit your responses after you submit. If you need to provide an update after submitting, please use the "Help" button to notify us.';
export const CONFIRMATION_MESSAGE = 'Are you sure you want to submit the responses for ';
export const ASSESSMENT_ISSUED_COLOR = '#37246B';
