import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import {
  ADD_CUSTOMER_TO_LIST_FAILURE,
  ADD_CUSTOMER_TO_LIST_LOADING,
  ADD_CUSTOMER_TO_LIST_SUCCESS,
  ADD_PRODUCT_TO_CUSTOMER_FAILURE,
  ADD_PRODUCT_TO_CUSTOMER_LOADING,
  ADD_PRODUCT_TO_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FROM_LIST_FAILURE,
  DELETE_CUSTOMER_FROM_LIST_LOADING,
  DELETE_CUSTOMER_FROM_LIST_SUCCESS,
  SEARCH_CUSTOMER_LIST_FAILURE,
  SEARCH_CUSTOMER_LIST_LOADING,
  SEARCH_CUSTOMER_LIST_SUCCESS,
  DELETE_PRODUCT_FROM_CUSTOMER_FAILURE,
  DELETE_PRODUCT_FROM_CUSTOMER_LOADING,
  DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS,
  SET_SELECTED_CUSTOMER_INDEX,
  SEARCH_CUSTOMER_PRODUCTS_LOADING,
  SEARCH_CUSTOMER_PRODUCTS_SUCCESS,
  SEARCH_CUSTOMER_PRODUCTS_FAILURE,
  SEARCH_PRODUCT_GROUP_LIST_LOADING,
  SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
  SEARCH_PRODUCT_GROUP_LIST_FAILURE,
  SEARCH_PRODUCT_LIST_LOADING,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  SEARCH_CUSTOMER_LIST_IN_PROFILE_LOADING,
  SEARCH_CUSTOMER_LIST_IN_PROFILE_SUCCESS,
  SEARCH_CUSTOMER_LIST_IN_PROFILE_FAILURE,
} from '../actions/customer';
import {
  CUSTOMER_ADDED_SUCCESSFULLY_MESSAGE,
  CUSTOMER_DELETED_SUCCESSFULLY_MESSAGE,
  PRODUCT_ADDED_SUCCESSFULLY_MESSAGE,
  PRODUCT_REMOVED_SUCCESSFULLY_MESSAGE,
} from '../components/Common/AlertMessages';

const initialState = {
  customerList: [],
  customerProductsList: [],
  selectedCustomerIndex: null,
  productNameList: [],
  loading: false,
  productsGroupNameList: [],
  customerListData: [],
};

export function customerProfile(state = initialState, action) {
  const { type, payload } = action;
  let customerList = cloneDeep(state.customerList);
  switch (type) {
    case SEARCH_CUSTOMER_LIST_LOADING:
    case SEARCH_CUSTOMER_LIST_IN_PROFILE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerList: payload?.Data?.Rows || [],
      };
    }

    case SEARCH_CUSTOMER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        customerList: [],
        selectedCustomerIndex: -1,
      };
    }

    case SEARCH_CUSTOMER_LIST_IN_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerListData: payload?.Data?.Rows || [],
      };
    }

    case SEARCH_CUSTOMER_LIST_IN_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        customerListData: [],
      };
    }

    case ADD_CUSTOMER_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_CUSTOMER_TO_LIST_SUCCESS: {
      const customer = payload?.data?.Data?.Success;
      customerList.push({
        CustomerOrgId: customer?.CustomerOrgId,
        CustomerOrgName: customer?.CustomerOrgName,
        CustomerVendorId: customer?.CustomerVendorId,
      });
      toast.success(CUSTOMER_ADDED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        customerList: customerList,
      };
    }

    case ADD_CUSTOMER_TO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_CUSTOMER_FROM_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_CUSTOMER_FROM_LIST_SUCCESS: {
      let index = payload.index;
      customerList.splice(index, 1);
      toast.success(CUSTOMER_DELETED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        customerList: customerList,
        selectedCustomerIndex: 0,
      };
    }

    case DELETE_CUSTOMER_FROM_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_SELECTED_CUSTOMER_INDEX: {
      return {
        ...state,
        selectedCustomerIndex: payload.index,
      };
    }

    case SEARCH_CUSTOMER_PRODUCTS_LOADING: {
      return {
        ...state,
        loading: true,
        customerProductsList: [],
      };
    }

    case SEARCH_CUSTOMER_PRODUCTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        customerProductsList: payload?.Data?.Rows || [],
      };
    }

    case SEARCH_CUSTOMER_PRODUCTS_FAILURE: {
      return {
        ...state,
        loading: false,
        customerProductsList: [],
      };
    }

    case ADD_PRODUCT_TO_CUSTOMER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ADD_PRODUCT_TO_CUSTOMER_SUCCESS: {
      let list = cloneDeep(state?.customerProductsList);
      let product = {
        CustomerVendorProductId: payload?.response?.Data?.CustomerVendorProductId,
        ProductId: payload?.response?.Id,
        ProductVendorId: payload?.response?.Data?.ProductVendorId,
        ProductName: payload?.productName,
      };
      list.push(product);
      toast.success(PRODUCT_ADDED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        customerProductsList: list,
      };
    }

    case ADD_PRODUCT_TO_CUSTOMER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_PRODUCT_FROM_CUSTOMER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_PRODUCT_FROM_CUSTOMER_SUCCESS: {
      let list = cloneDeep(state?.customerProductsList);
      list.splice(payload?.productIndex, 1);
      toast.success(PRODUCT_REMOVED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        customerProductsList: list,
      };
    }

    case DELETE_PRODUCT_FROM_CUSTOMER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PRODUCT_GROUP_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_PRODUCT_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productsGroupNameList: payload?.Data?.Rows,
      };
    }

    case SEARCH_PRODUCT_GROUP_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productNameList: payload?.Data?.Rows,
      };
    }

    case SEARCH_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productNameList: [],
      };
    }

    default:
      return state;
  }
}
