import {
  SEARCH_ASSESSMENT_DRU_FAILURE,
  SEARCH_ASSESSMENT_DRU_LOADING,
  SEARCH_ASSESSMENT_DRU_SUCCESS,
  SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING,
  SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS,
  SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
  DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING,
  DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS,
  DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
  UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING,
  UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS,
  UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
  SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_LOADING,
  SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_SUCCESS,
  SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_FAILURE,
  GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_LOADING,
  GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_SUCCESS,
  GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_FAILURE,
  SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_LOADING,
  SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_SUCCESS,  
  SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_FAILURE,
  SET_REVIEW_SUMMARY_TAB_VALUE,
  SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_LOADING,
  SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_SUCCESS,
  SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_FAILURE,
  SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING,
  SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS,
  SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
  SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_LOADING,
  SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_SUCCESS,
  SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_FAILURE,
  SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_LOADING,
  SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_SUCCESS,
  SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_FAILURE,
  SEARCH_REQUIREMENT_QUESTIONS_LOADING,
  SEARCH_REQUIREMENT_QUESTIONS_SUCCESS,
  SEARCH_REQUIREMENT_QUESTIONS_FAILURE,
  GENERATE_REQUIREMENT_RESPONSES_LOADING,
  GENERATE_REQUIREMENT_RESPONSES_SUCCESS,
  GENERATE_REQUIREMENT_RESPONSES_FAILURE,
  REQUIREMENT_GENERATE_IN_PROGRESS,
  CO_PILOT_UPLOAD_EVIDENCE_LOADING,
  CO_PILOT_UPLOAD_EVIDENCE_SUCCESS,
  CO_PILOT_UPLOAD_EVIDENCE_FAILURE,
  GENERATE_CC_REQUIREMENT_RESPONSES_LOADING,
  GENERATE_CC_REQUIREMENT_RESPONSES_FAILURE,
  GENERATE_CC_REQUIREMENT_RESPONSES_SUCCESS,
} from 'actions/assessmentCoPilot';
import { COPILOT_RESPONSE_GENERATED_SUCCESSFULLY_MESSAGE, FILE_DELETED_SUCCESSFULLY_MESSAGE, FILE_DESCRIPTION_EDITED_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { COPILOT_RESPONSE_GENERATED_ERROR_MESSAGE, ERROR_MESSAGE, FAILED_TO_GENERATE_RESPONSE_MESSAGE, UPLOADED_SUCCESSFULLY } from 'constants/errorMessage';
import { cloneDeep, intersection } from 'lodash';
import { REVIEW_SUMMARY_TAB_OPTIONS, SELECTION_TYPE_COPILOT } from 'pages/AssessmentDetails/AssessmentCoPilot/ReviewSummary/constants';
import { toast } from 'react-toastify';
import { getPercentage } from 'utils/numberUtils';

const initialState = {
  loading: false,
  assessmentDruList: [],
  druGeneratedResponseList: [],
  assessmentSecurityEvidenceRequirementList: [],
  reviewSummarytabValue: REVIEW_SUMMARY_TAB_OPTIONS.ALL,
  assessmentReviewRequirementList: [],
  progress: 0,
  aiFeedback:[],
  cardLoading: [],
  generateResponseLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_ASSESSMENT_DRU_LOADING:
    case SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING: 
    case DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING: 
    case UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING: 
    case SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_LOADING: 
    case SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_LOADING:
    case SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_LOADING: 
    case SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING: 
    case SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_LOADING:
    case SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_ASSESSMENT_DRU_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentDruList: payload,
      };
    }

    case SEARCH_ASSESSMENT_DRU_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentDruList: [],
      };
    }

    case SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        assessmentSecurityEvidenceRequirementList: [],
      };
    }

    case SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentSecurityEvidenceRequirementList: payload?.Data?.Rows,
      };
    }

    case DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS: {
      toast.success(FILE_DELETED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS: {
      toast.success(FILE_DESCRIPTION_EDITED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentReviewSummaryResponse: [],
      };
    }

    case SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_SUCCESS: {    
      return {
        ...state,
        loading: false,
        assessmentReviewSummaryResponse: payload?.Data?.Rows,
      };
    }
    case GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_LOADING: {
      return {
        ...state,
        loading: true,
        generateResponseLoading: true,
      }
    }

    case GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_FAILURE: {
      toast.error(FAILED_TO_GENERATE_RESPONSE_MESSAGE);
   
      return {
        ...state,
        loading: false,
        generateResponseLoading: false,
      };
    }

    case GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_SUCCESS: {
      toast.success(COPILOT_RESPONSE_GENERATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        generateResponseLoading: false,
      };
    }

    case SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentReviewRequirementList: [],
      };
    }

    case SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_SUCCESS: {

      return {
        ...state,
        loading: false,
        assessmentReviewRequirementList: payload?.Data?.Rows,
      };
    }
    

    case SET_REVIEW_SUMMARY_TAB_VALUE: {
      return {
        ...state,
        reviewSummarytabValue: payload || REVIEW_SUMMARY_TAB_OPTIONS.ALL,
      };
    }

    case SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_SUCCESS: {
      return {
        ...state,
        loading: false,
        aiFeedback: {
          ...state.aiFeedback, 
          [payload.AssessmentQuestionnaireQuestionResponseId]: payload.FeedbackTypeId 
        }
      };
    }

    case SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_FAILURE :{
      return{
        ...state,
        loading: false,
        aiFeedback: {
          ...state.aiFeedback, 
        }
      }
    }

    case SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE: 
    case SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        progress: {
          totalCountOfGeneratedQuestion: 0,
          totalCountOfQuestion: 0,
          percent: 0,
        }
      }
    }

    case SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        progressAssessment: {
          totalCountOfGeneratedQuestion: 0,
          totalCountOfQuestion: 0,
          percent: 0,
        }
      }
    }

     case SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS: 
     case SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_SUCCESS: {
      
      const totalCountOfQuestion = payload?.Data?.Rows[0]?.TotalCountOfQuestion ?? 0;
      const totalCountOfGeneratedQuestion = payload?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion ?? 0;
    
      return {
        ...state,
        loading: false,
        progress: {
          totalCountOfGeneratedQuestion: totalCountOfGeneratedQuestion,
          totalCountOfQuestion: totalCountOfQuestion,
          percent: totalCountOfQuestion > 0 ? (totalCountOfGeneratedQuestion / totalCountOfQuestion).toFixed(2) * 100 : 0
        }
      };
    }

    case SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_SUCCESS: {
      const totalCountOfQuestion = payload?.Data?.Rows[0]?.TotalCountOfQuestion ?? 0;
      const totalCountOfGeneratedQuestion = payload?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion ?? 0;
    
      return {
        ...state,
        loading: false,
        progressAssessment: {
          totalCountOfGeneratedQuestion: totalCountOfGeneratedQuestion,
          totalCountOfQuestion: totalCountOfQuestion,
          percent: totalCountOfQuestion > 0 ? getPercentage(totalCountOfGeneratedQuestion, totalCountOfQuestion) : 0
        }
      };
    }

    case GENERATE_REQUIREMENT_RESPONSES_LOADING: {
      const cardLoading = cloneDeep(state.cardLoading);
      cardLoading.push(payload);
      return {
        ...state,
        cardLoading
      }
    }

    
    case GENERATE_REQUIREMENT_RESPONSES_SUCCESS:
    case GENERATE_REQUIREMENT_RESPONSES_FAILURE: {
      let cardLoading = cloneDeep(state.cardLoading);
      cardLoading = cardLoading.filter(item => item !== payload);
      
      return {
        ...state,
        cardLoading
      }
    }
    case REQUIREMENT_GENERATE_IN_PROGRESS: {
      return {
        ...state,
        cardLoading: intersection(state.cardLoading, payload)
      }
    }

    case GENERATE_CC_REQUIREMENT_RESPONSES_LOADING: {
      const cardLoading = cloneDeep(state.cardLoading);
      cardLoading.push(payload);
      return {
        ...state,
        cardLoading
      }
    }

    
    case GENERATE_CC_REQUIREMENT_RESPONSES_SUCCESS:
    case GENERATE_CC_REQUIREMENT_RESPONSES_FAILURE: {
      let cardLoading = cloneDeep(state.cardLoading);
      cardLoading = cardLoading.filter(item => item !== payload);
      
      return {
        ...state,
        cardLoading
      }
    }

    case SEARCH_REQUIREMENT_QUESTIONS_LOADING : {
      return {
        ...state,
        loading: true,
      }
    }
    case SEARCH_REQUIREMENT_QUESTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case SEARCH_REQUIREMENT_QUESTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    
    case CO_PILOT_UPLOAD_EVIDENCE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case CO_PILOT_UPLOAD_EVIDENCE_SUCCESS: {
      toast.success(UPLOADED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case CO_PILOT_UPLOAD_EVIDENCE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    

    default: {
      return state;
    }
  }
}
