import { NO_DATA_FOUND } from '../constants/errorMessage';
export const SET_ERRORS = '@errorhandler/SET_ERRORS';
export const setErrors = (list) => async (dispatch) => {
  const errors = list.filter((it) => it.Message !== NO_DATA_FOUND);
  dispatch({
    type: SET_ERRORS,
    payload: errors,
  });
};

export const CLEAR_ERRORS = '@errorhandler/CLEAR_ERRORS';
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
