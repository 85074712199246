import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import userReducer from './userProfile';
import client from './client';
import session from './session';
import navbar from './navbar';
import { vendorProfileReducer } from './vendorProfileTemplate';
import getQuestionnaireTemplate from './incidentResponse';
import { vendorProfileContact } from './vendorProfileContacts';
import { customerProfile } from './customer';
import { companyProfileData } from './companyInformation';
import { vendorProductsPage } from './vendorProduct';
import slaInformation from './slaInformation';
import { vendorIncidentEscalation } from './vendorIncidentEscalation';
import customersAndProducts from './customersAndProducts';
import user from './user';
import { userRoles } from './userRoles';
import errorHandler from './errorHandler';
import customErrorHandler from './customErrorHandler';
import supportArticles from './supportArticles';
import config from './config';
import { lookup } from './lookup';
import evidenceManagement from './evidenceManagement';
import { vendorAssessment } from './vendorAssessment';
import assessmentResults from './assessmentResults';
import vendorProductRequirement from './vendorProductRequirement';
import rdsConfiguration from './rdsConfiguration';
import assessmentResponses from './assessmentResponses';
import communicationPreferences from './communicationPreferences';
import supportTicket from './supportTicket';
import assessmentsOverview from './assessmentsOverview';
import assessmentQuestionnaire from './assessmentQuestionnaire';
import publicApi from './publicApi';
import remediation from './remediation';
import { vendorCopilot } from './vendorCopilot';
import vendorOnboarding from './vendorOnboarding';
import assessmentCoPilot from './assessmentCoPilot';
import appSettings from './appSettings';

const rootReducer = combineReducers({
  form,
  userReducer,
  session,
  client,
  navbar,
  vendorProfileReducer,
  getQuestionnaireTemplate,
  vendorProfileContact,
  customerProfile,
  companyProfileData,
  vendorProductsPage,
  slaInformation,
  //errorHandler,
  customersAndProducts,
  user,
  userRoles,
  errorHandler,
  vendorIncidentEscalation,
  customErrorHandler,
  supportArticles,
  config,
  lookup,
  evidenceManagement,
  vendorAssessment,
  assessmentResults,
  vendorProductRequirement,
  rdsConfiguration,
  assessmentResponses,
  communicationPreferences,
  supportTicket,
  assessmentsOverview,
  assessmentQuestionnaire,
  publicApi,
  remediation,
  vendorCopilot,
  vendorOnboarding,
  appSettings,
  assessmentCoPilot,
});
export default rootReducer;
