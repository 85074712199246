export default function Divider(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
    },
  };
}
