import axios from 'axios';

export const POST_VENDOR_PROFILE_TEMPLATE_LOADING = 'POST_VENDOR_PROFILE_TEMPLATE_LOADING';
export const POST_VENDOR_PROFILE_TEMPLATE_SUCCESS = 'POST_VENDOR_PROFILE_TEMPLATE_SUCCESS';
export const POST_VENDOR_PROFILE_TEMPLATE_FAILURE = 'POST_VENDOR_PROFILE_TEMPLATE_FAILURE';

export const createVendorProfileTemplate = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_VENDOR_PROFILE_TEMPLATE_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.post('/questionnairetemplate', body);
    dispatch({
      type: POST_VENDOR_PROFILE_TEMPLATE_SUCCESS,
      payload: response?.data,
    });

    if (callback) {
      callback(response?.data?.Data.QuestionnaireTemplate);
    }
  } catch {
    dispatch({
      type: POST_VENDOR_PROFILE_TEMPLATE_FAILURE,
    });
  }
};

export const POST_VENDOR_PROFILE_TEMPLATE_QUESTION_LOADING = 'POST_VENDOR_PROFILE_TEMPLATE_QUESTION_LOADING';
export const POST_VENDOR_PROFILE_TEMPLATE_QUESTION_SUCCESS = 'POST_VENDOR_PROFILE_TEMPLATE_QUESTION_SUCCESS';
export const POST_VENDOR_PROFILE_TEMPLATE_QUESTION_FAILURE = 'POST_VENDOR_PROFILE_TEMPLATE_QUESTION_FAILURE';

export const createVendorProfileTemplateQuestion = (id, formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_VENDOR_PROFILE_TEMPLATE_QUESTION_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.post(`/questionnairetemplate/${id}/question`, body);
    dispatch({
      type: POST_VENDOR_PROFILE_TEMPLATE_QUESTION_SUCCESS,
      payload: response?.data,
    });

    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: POST_VENDOR_PROFILE_TEMPLATE_QUESTION_FAILURE,
    });
  }
};

export const setVendorProfileDetails = (payload) => {
  return {
    type: 'SET_VENDOR_PROFILE',
    payload: payload,
  };
};

export const POST_VENDOR_TO_LIST_QUESTION_LOADING = 'POST_VENDOR_TO_LIST_QUESTION_LOADING';
export const POST_VENDOR_TO_LIST_QUESTION_SUCCESS = 'POST_VENDOR_TO_LIST_QUESTION_SUCCESS';
export const POST_VENDOR_TO_LIST_QUESTION_FAILURE = 'POST_VENDOR_TO_LIST_QUESTION_FAILURE';

export const PUT_VENDOR_TO_LIST_QUESTION_LOADING = 'PUT_VENDOR_TO_LIST_QUESTION_LOADING';
export const PUT_VENDOR_TO_LIST_QUESTION_SUCCESS = 'PUT_VENDOR_TO_LIST_QUESTION_SUCCESS';
export const PUT_VENDOR_TO_LIST_QUESTION_FAILURE = 'PUT_VENDOR_TO_LIST_QUESTION_FAILURE';

export const DELETE_VENDOR_FROM_LIST_QUESTION_LOADING = 'DELETE_VENDOR_FROM_LIST_QUESTION_LOADING';
export const DELETE_VENDOR_FROM_LIST_QUESTION_SUCCESS = 'DELETE_VENDOR_FROM_LIST_QUESTION_SUCCESS';
export const DELETE_VENDOR_FROM_LIST_QUESTION_FAILURE = 'DELETE_VENDOR_FROM_LIST_QUESTION_FAILURE';

export const addVendorToList = (id, formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_VENDOR_TO_LIST_QUESTION_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    dispatch({
      type: POST_VENDOR_TO_LIST_QUESTION_SUCCESS,
      payload: formData,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: POST_VENDOR_TO_LIST_QUESTION_FAILURE,
    });
  }
};

export const updateVendorInList = (id, formData, callback) => async (dispatch) => {
  dispatch({
    type: PUT_VENDOR_TO_LIST_QUESTION_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    dispatch({
      type: PUT_VENDOR_TO_LIST_QUESTION_SUCCESS,
      payload: formData,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: PUT_VENDOR_TO_LIST_QUESTION_FAILURE,
    });
  }
};

export const deleteVendorFromList = (id, formData, callback) => async (dispatch) => {
  dispatch({
    type: DELETE_VENDOR_FROM_LIST_QUESTION_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    dispatch({
      type: DELETE_VENDOR_FROM_LIST_QUESTION_SUCCESS,
      payload: formData,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: DELETE_VENDOR_FROM_LIST_QUESTION_FAILURE,
    });
  }
};
