import axios from 'axios';
import { SUBMITTED } from '../constants/incidentResponseConstants';
import { getErrorObject } from '../utils/apiUtils';
import {
  changeFinalQuestionnaireStatusBody,
  changeQuestionnaireStatusBody,
  getQuestionnaireListDataBody,
  getQuestionnaireListDataWithNameBody,
} from '../utils/incidentResponseUtils';
import { setErrors } from './errorHandler';

export const SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_LOADING = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_LOADING';
export const SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS';
export const SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_FAILURE = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_FAILURE';

export const getQuestionnaireTemplate = (assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({ type: SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_LOADING });
  try {
    const res = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/questions?includeResOptions=true`);
    dispatch({
      type: SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_SUCCESS,
      payload: res.data.Data,
    });
    dispatch(getQuestionnaireTemplateAnswers(assessmentQuestionnaireId));
  } catch (err) {
    dispatch({
      type: SEARCH_QUESTIONNAIRE_TEMPLATE_DETAILS_FAILURE,
      payload: getErrorPayload(err),
    });
  }
};

export const SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_LOADING = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_LOADING';
export const SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_SUCCESS = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_SUCCESS';
export const SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_FAILURE = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_FAILURE';

export const getQuestionnaireTemplateAnswers = (assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({ type: SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_LOADING });
  try {
    const res = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/questions`);
    dispatch({
      type: SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_SUCCESS,
      payload: res.data.Data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_QUESTIONNAIRE_TEMPLATE_ANSWERS_FAILURE,
      payload: getErrorPayload(err),
    });
  }
};

export const SEARCH_QUESTIONNAIRE_LIST_DATA_LOADING = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_LIST_DATA_LOADING';
export const SEARCH_QUESTIONNAIRE_LIST_DATA_SUCCESS = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_LIST_DATA_SUCCESS';
export const SEARCH_QUESTIONNAIRE_LIST_DATA_FAILURE = '@@vendorQuestionnaire/SEARCH_QUESTIONNAIRE_LIST_DATA_FAILURE';

export const getQuestionnaireListData = (searchValue) => async (dispatch) => {
  dispatch({ type: SEARCH_QUESTIONNAIRE_LIST_DATA_LOADING });
  let body = {};
  if (searchValue) {
    body = getQuestionnaireListDataWithNameBody(searchValue);
  } else {
    body = getQuestionnaireListDataBody();
  }
  try {
    const res = await axios.post(`/assessment/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SEARCH_QUESTIONNAIRE_LIST_DATA_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_QUESTIONNAIRE_LIST_DATA_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_QUESTIONNAIRE_LIST_DATA_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const PUT_QUESTIONNAIRE_LOADING = '@@vendorQuestionnaireResponse/PUT_QUESTIONNAIRE_LOADING';
export const PUT_QUESTIONNAIRE_SUCCESS = '@@vendorQuestionnaireResponse/PUT_QUESTIONNAIRE_SUCCESS';
export const PUT_QUESTIONNAIRE_FAILURE = '@@vendorQuestionnaireResponse/PUT_QUESTIONNAIRE_FAILURE';

export const saveQuestionnaire =
  (
    formData,
    assessmentId,
    assessmentQuestionnaireId,
    questionId,
    assessmentQuestionnaireQuestionResponseId,
    questionResponseCategoryId,
    questionnaireTemplateQuestionId
  ) =>
    async (dispatch) => {
      dispatch({ type: PUT_QUESTIONNAIRE_LOADING });
      const body = JSON.stringify({
        ...formData,
        Active: true,
        IsNew: true,
        AssessmentQuestionnaireQuestionResponseId: assessmentQuestionnaireQuestionResponseId,
        AssessmentQuestionnaireId: assessmentQuestionnaireId,
        QuestionnaireTemplateQuestionId: questionnaireTemplateQuestionId,
        QuestionId: questionId,
        QuestionResponseCategoryId: questionResponseCategoryId,
      });
      try {
        const res = await axios.put(
          `assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/question/${questionId}/response/${assessmentQuestionnaireQuestionResponseId}`,
          body
        );
        if (res?.data?.ErrorCount > 0) {
          dispatch(setErrors(res?.data?.Errors));
          dispatch({
            type: PUT_QUESTIONNAIRE_FAILURE,
          });
        } else {
          dispatch({
            type: PUT_QUESTIONNAIRE_SUCCESS,
            payload: res?.data?.Data,
          });
        }
      } catch (err) {
        dispatch({
          type: PUT_QUESTIONNAIRE_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const POST_QUESTIONNAIRE_STATUS_LOADING = '@@vendorQuestionnaire/POST_QUESTIONNAIRE_STATUS_LOADING';
export const POST_QUESTIONNAIRE_STATUS_SUCCESS = '@@vendorQuestionnaire/POST_QUESTIONNAIRE_STATUS_SUCCESS';
export const POST_QUESTIONNAIRE_STATUS_FAILURE = '@@vendorQuestionnaire/POST_QUESTIONNAIRE_STATUS_FAILURE';

export const changeQuestionnaireStatus = (assessmentId, statusId, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({ type: POST_QUESTIONNAIRE_STATUS_LOADING });
  let body = changeQuestionnaireStatusBody(assessmentId, statusId);
  try {
    const res = await axios.post(`/assessment/${assessmentId}/action/updatestatus`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_QUESTIONNAIRE_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: POST_QUESTIONNAIRE_STATUS_SUCCESS,
        payload: res?.data,
      });
      if (statusId === SUBMITTED) {
        dispatch(changeFinalQuestionnaireStatus(assessmentQuestionnaireId, statusId, assessmentId));
      }
    }
  } catch (err) {
    dispatch({
      type: POST_QUESTIONNAIRE_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_FINAL_QUESTIONNAIRE_STATUS_LOADING = '@@vendorQuestionnaire/POST_FINAL_QUESTIONNAIRE_STATUS_LOADING';
export const POST_FINAL_QUESTIONNAIRE_STATUS_SUCCESS = '@@vendorQuestionnaire/POST_FINAL_QUESTIONNAIRE_STATUS_SUCCESS';
export const POST_FINAL_QUESTIONNAIRE_STATUS_FAILURE = '@@vendorQuestionnaire/POST_FINAL_QUESTIONNAIRE_STATUS_FAILURE';

export const changeFinalQuestionnaireStatus = (assessmentQuestionnaireId, statusId, assessmentId) => async (dispatch) => {
  dispatch({ type: POST_FINAL_QUESTIONNAIRE_STATUS_LOADING });
  let body = changeFinalQuestionnaireStatusBody(assessmentQuestionnaireId, statusId);
  try {
    const res = await axios.post(`/assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/action/updatestatus`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_FINAL_QUESTIONNAIRE_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: POST_FINAL_QUESTIONNAIRE_STATUS_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: POST_FINAL_QUESTIONNAIRE_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_SELECTED_QUESTIONNAIRE_LIST_LOADING = '@@selectedProductGroup/SET_SELECTED_QUESTIONNAIRE_LIST_LOADING';
export const SET_SELECTED_QUESTIONNAIRE_LIST_SUCCESS = '@@selectedProductGroup/SET_SELECTED_QUESTIONNAIRE_LIST_SUCCESS';
export const SET_SELECTED_QUESTIONNAIRE_LIST_FAILURE = '@@selectedProductGroup/SET_SELECTED_QUESTIONNAIRE_LIST_FAILURE';

export const setSelectedQuestionnaireList =
  (assessmentId = null) =>
    async (dispatch) => {
      dispatch({
        type: SET_SELECTED_QUESTIONNAIRE_LIST_LOADING,
      });
      let body = getQuestionnaireListDataBody(assessmentId);
      try {
        const res = await axios.post(`/assessment/search`, body);
        if (res?.data?.ErrorCount > 0) {
          dispatch(setErrors(res?.data?.Errors));
          dispatch({
            type: SET_SELECTED_QUESTIONNAIRE_LIST_FAILURE,
          });
        } else {
          dispatch({
            type: SET_SELECTED_QUESTIONNAIRE_LIST_SUCCESS,
            payload: res?.data?.Data?.Rows[0],
          });
        }
      } catch {
        dispatch({
          type: SET_SELECTED_QUESTIONNAIRE_LIST_FAILURE,
        });
      }
    };

export const SET_FINAL_SUBMIT_REDIRECTION_LOADING = '@@selectedProductGroup/SET_FINAL_SUBMIT_REDIRECTION_LOADING';
export const SET_FINAL_SUBMIT_REDIRECTION_SUCCESS = '@@selectedProductGroup/SET_FINAL_SUBMIT_REDIRECTION_SUCCESS';
export const SET_FINAL_SUBMIT_REDIRECTION_FAILURE = '@@selectedProductGroup/SET_FINAL_SUBMIT_REDIRECTION_FAILURE';

export const setFinalSubmitRedirection = (payload) => async (dispatch) => {
  dispatch({
    type: SET_FINAL_SUBMIT_REDIRECTION_LOADING,
  });
  try {
    dispatch({
      type: SET_FINAL_SUBMIT_REDIRECTION_SUCCESS,
      payload: payload,
    });
  } catch {
    dispatch({
      type: SET_FINAL_SUBMIT_REDIRECTION_FAILURE,
    });
  }
};
