import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { getSlaInformationListSearchBody, getSlaStatementListSearchBody } from '../utils/vendorSlaUtil';
import { isNil } from 'lodash';

export const GET_INCIDENT_SLA_SECTION_LIST_LOADING = '@@slaInformation/GET_INCIDENT_SLA_SECTION_LIST_LOADING';
export const GET_INCIDENT_SLA_SECTION_LIST_SUCCESS = '@@slaInformation/GET_INCIDENT_SLA_SECTION_LIST_SUCCESS';
export const GET_INCIDENT_SLA_SECTION_LIST_FAILURE = '@@slaInformation/GET_INCIDENT_SLA_SECTION_LIST_FAILURE';

export const getIncidentSlaSectionList = (entityName) => async (dispatch) => {
  dispatch({ type: GET_INCIDENT_SLA_SECTION_LIST_LOADING });
  try {
    const response = await axios.get(`/app/lookup/e=${entityName}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: GET_INCIDENT_SLA_SECTION_LIST_SUCCESS,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_INCIDENT_SLA_SECTION_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_SLA_INFORMATIONL_LIST_LOADING = '@@slaInformation/SEARCH_SLA_INFORMATIONL_LIST_LOADING';
export const SEARCH_SLA_INFORMATIONL_LIST_SUCCESS = '@@slaInformation/SEARCH_SLA_INFORMATIONL_LIST_SUCCESS';
export const SEARCH_SLA_INFORMATIONL_LIST_FAILURE = '@@slaInformation/SEARCH_SLA_INFORMATIONL_LIST_FAILURE';

export const getSLAInformationList = (customerVendorId, productVendorId) => async (dispatch) => {
  dispatch({ type: SEARCH_SLA_INFORMATIONL_LIST_LOADING });
  try {
    let body = getSlaInformationListSearchBody(customerVendorId, productVendorId);
    let response = await axios.post('/vendor/slaresponse/search', body);
    if (response?.data?.ErrorCount > 0) {
      let slastmtbody = getSlaStatementListSearchBody();
      let slaresponse = await axios.post('/vendor/slaresponse/search', slastmtbody);
      if (slaresponse?.data?.ErrorCount > 0) {
        dispatch(setErrors(slaresponse?.data?.Errors));
      } else {
        dispatch({
          type: SEARCH_SLA_INFORMATIONL_LIST_SUCCESS,
          payload: slaresponse.data,
        });
      }
    } else {
      if (response?.data?.Data.Rows.length > 0) {
        dispatch({
          type: SEARCH_SLA_INFORMATIONL_LIST_SUCCESS,
          payload: response.data,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_SLA_INFORMATIONL_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_SLA_RESPONSE_LOADING = '@@slaInformation/POST_SLA_RESPONSE_LOADING';
export const POST_SLA_RESPONSE_SUCCESS = '@@slaInformation/POST_SLA_RESPONSE_SUCCESS';
export const POST_SLA_RESPONSE_FAILURE = '@@slaInformation/POST_SLA_RESPONSE_FAILURE';

export const createSlaResponse = (formData, selectedCustomerAndProduct) => async (dispatch) => {
  dispatch({
    type: POST_SLA_RESPONSE_LOADING,
  });

  const reqData = formData?.map((item) => {
    return {
      SlaText: item.SlaText,
      IncidentSlaSectionId: item.IncidentSlaSectionId,
      TimeOffsetText: item.TimeOffsetText,
      ResponseTimeUnit: item.ResponseTimeUnit,
      ResponseEventText: item.ResponseEventText,
      RequiresDocument: item.SlaStatement.RequiresDocument,
      QuestionResponseTypeId: item.QuestionResponseTypeId,
      IsNotApplicable: item.IsNotApplicable,
      IsUnknown: item.IsUnknown,
      VendorSlaResponseId: item.VendorSlaResponseId,
      ProductVendorId: item.ProductVendorId,
      CustomerVendorProductId: item.CustomerVendorProductId,
      QuestionResponseText: item.QuestionResponseText,
      VendorOrgId: item.VendorOrgId,
      CustomerVendorId: item.CustomerVendorId,
      SlaStatementId: item.SlaStatementId,
      ResponseTime: item.ResponseTime,
      SlaResponseDocumentList: item.SlaResponseDocumentList,
      QuestionResponseCategoryId: item.QuestionResponseCategoryId,
      QuestionResponseCategoryName: item.QuestionResponseCategoryName,
      Value: item.Value,
      Label: item.Label,
      Active: item.Active,
      IsNew: item.IsNew,
    };
  });

  try {
    let response = null;
    response = await axios.post('/vendor/slaresponse/action/bulk', reqData);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_SLA_RESPONSE_FAILURE,
        payload: response?.data,
      });
    } else {
      dispatch({
        type: POST_SLA_RESPONSE_SUCCESS,
        payload: response?.data,
      });

      dispatch(getSLAInformationList(selectedCustomerAndProduct.CustomerVendorId, selectedCustomerAndProduct.ProductVendorId));
    }
  } catch {
    dispatch({
      type: POST_SLA_RESPONSE_FAILURE,
    });
  }
};

export const SET_SLA_REPONSE_ERROR_LIST = '@@slaInformation/SET_SLA_REPONSE_ERROR_LIST';

export const setSlaResponseErrorList =
  (list = []) =>
  async (dispatch) => {
    dispatch({
      type: SET_SLA_REPONSE_ERROR_LIST,
      payload: list,
    });
  };
