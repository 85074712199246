import {
  RESET_REMEDIATION_KEYS,
  SEARCH_REMEDIATION_OBJECTIVE_DETAILS_FAILURE,
  SEARCH_REMEDIATION_OBJECTIVE_DETAILS_LOADING,
  SEARCH_REMEDIATION_OBJECTIVE_DETAILS_SUCCESS,
  SEARCH_REMEDIATION_OBJECTIVE_FAILURE,
  SEARCH_REMEDIATION_OBJECTIVE_LOADING,
  SEARCH_REMEDIATION_OBJECTIVE_SUCCESS,
  SEARCH_SELECTED_REMEDIATION_OBJECTIVE_FAILURE,
  SEARCH_SELECTED_REMEDIATION_OBJECTIVE_LOADING,
  SEARCH_SELECTED_REMEDIATION_OBJECTIVE_SUCCESS,
  SUBMIT_MILESTONE_RESPONSE_FAILURE,
  SUBMIT_MILESTONE_RESPONSE_LOADING,
  SUBMIT_MILESTONE_RESPONSE_SUCCESS
} from 'actions/remediation';
import { ERROR_MESSAGE, SUBMITTED_SUCCESSFULLY } from 'constants/errorMessage';
import { toast } from "react-toastify";
import { convertToLocalDateTime, convertToLocalTime } from 'utils/dateAndTimeUtils';
import { isEmpty, isNil } from 'lodash';
import { DUE_IN_NEXT_30_DAYS, DUE_IN_NEXT_90_DAYS, DUE_IN_NEXT_YEAR, DUE_IN_OVER_YEAR } from 'pages/Remediation/constants';
import { DATE_FORMAT } from 'pages/CreateCompanionAssessment/constant';

const initialState = {
  loading: false,
  remediationObjective30DaysList: [],
  remediationObjective90DaysList: [],
  remediationObjectiveYearList: [],
  remediationObjectiveOverYearList: [],
  milestoneList: [],
  selectedObjective: {},
  savedMilestone: {},
  formattedRemediationObjectiveList: {},
};

const createRemediationObjectivesFormattedList = (list) => {
  if (!list || list?.length === 0) return [];

  return list.map(obj => ({
    "Client Name": obj?.ClientOrgName,
    'Requirement Number': obj?.RequirementNumber,
    'Requirement Name': obj?.RequirementName,
    'Control Number': obj?.ControlNumber,
    'Default Tier Name': obj?.DefaultTierName,
    'Severity': obj?.Severity,
    'Inadequacy Reason Name': obj?.InadequacyReasonName,
    'Remediation Submitted Date': obj?.RemediationSubmittedDate,
    'Remediation Submitted By': obj?.RemediationSubmittedBy,
    'Assessment Count': obj?.AssessmentCount,
    'Product Name': obj?.ProductName,
    'Completion Date': obj?.CompletionDate,
    'Remediation Objective Status Name': obj?.RemediationObjectiveStatusName,
  }));
}

const getSavedMilestone = (data) => {
  let savedMilestone = {};
  data.forEach((obj, index) => {
    if (!isEmpty(obj['RemediationResponseIPIComment'])) {
      savedMilestone[`QuestionResponseComment${index}`] = obj['RemediationResponseIPIComment'];
    } else {
      savedMilestone[`QuestionResponseComment${index}`] = null;
    }
  });
  return savedMilestone;
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_REMEDIATION_OBJECTIVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_REMEDIATION_OBJECTIVE_SUCCESS: {
      const tempRemediationObjective30DaysList = !isEmpty(payload?.DueInNext30DaysList)
        ? payload?.DueInNext30DaysList?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            RemediationSubmittedDate: !isNil(item.RemediationSubmittedDate) && convertToLocalTime(item.RemediationSubmittedDate),
            CompletionDate: !isNil(item.CompletionDate) && convertToLocalTime(item.CompletionDate)
          };
        })
        : [];

      const tempRemediationObjective90DaysList = !isEmpty(payload?.DueInNext90DaysList)
        ? payload?.DueInNext90DaysList?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            RemediationSubmittedDate: !isNil(item.RemediationSubmittedDate) && convertToLocalTime(item.RemediationSubmittedDate),
            CompletionDate: !isNil(item.CompletionDate) && convertToLocalTime(item.CompletionDate)
          };
        })
        : [];

      const tempRemediationObjectiveYearList = !isEmpty(payload?.DueInNextYearList)
        ? payload?.DueInNextYearList?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            RemediationSubmittedDate: !isNil(item.RemediationSubmittedDate) && convertToLocalTime(item.RemediationSubmittedDate),
            CompletionDate: !isNil(item.CompletionDate) && convertToLocalTime(item.CompletionDate)
          };
        })
        : [];

      const tempRemediationObjectiveOverYearList = !isEmpty(payload?.DueInOverYearList)
        ? payload?.DueInOverYearList?.map((item, index) => {
          return {
            ...item,
            Id: index + 1,
            RemediationSubmittedDate: !isNil(item.RemediationSubmittedDate) && convertToLocalTime(item.RemediationSubmittedDate),
            CompletionDate: !isNil(item.CompletionDate) && convertToLocalTime(item.CompletionDate)
          };
        })
        : [];

      const tempRemediationList = {
        [DUE_IN_NEXT_30_DAYS]: tempRemediationObjective30DaysList,
        [DUE_IN_NEXT_90_DAYS]: tempRemediationObjective90DaysList,
        [DUE_IN_NEXT_YEAR]: tempRemediationObjectiveYearList,
        [DUE_IN_OVER_YEAR]: tempRemediationObjectiveOverYearList,
      };

      return {
        ...state,
        loading: false,
        remediationObjective30DaysList: tempRemediationObjective30DaysList,
        remediationObjective90DaysList: tempRemediationObjective90DaysList,
        remediationObjectiveYearList: tempRemediationObjectiveYearList,
        remediationObjectiveOverYearList: tempRemediationObjectiveOverYearList,
        formattedRemediationObjectiveList:
          Object.fromEntries(Object.entries(tempRemediationList).map(([key, value]) => [key, createRemediationObjectivesFormattedList(value)]))
      };
    }

    case SEARCH_REMEDIATION_OBJECTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        remediationObjective30DaysList: [],
        remediationObjective90DaysList: [],
        remediationObjectiveYearList: [],
        remediationObjectiveOverYearList: [],
        formattedRemediationObjectiveList: {},
      };

    case SEARCH_SELECTED_REMEDIATION_OBJECTIVE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_SELECTED_REMEDIATION_OBJECTIVE_SUCCESS: {
      let selectedObjective;
      Object.keys(payload).forEach((key) => {
        if (!isEmpty(payload[key])) {
          return selectedObjective = {
            ...payload[key][0],
            RemediationSubmittedDate: convertToLocalDateTime(payload[key][0]?.RemediationSubmittedDate, DATE_FORMAT),
            RemediationObjectiveLastUpdated: convertToLocalDateTime(payload[key][0]?.RemediationObjectiveLastUpdated, DATE_FORMAT),
          };
        }
      });
      return {
        ...state,
        loading: false,
        selectedObjective: selectedObjective,
      };
    }

    case SEARCH_SELECTED_REMEDIATION_OBJECTIVE_FAILURE: {
      return {
        ...state,
        loading: false,
        selectedObjective: {},
      };
    }

    case SEARCH_REMEDIATION_OBJECTIVE_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_REMEDIATION_OBJECTIVE_DETAILS_SUCCESS: {
      let milestoneList = payload?.map((item) => {
        return {
          ...item,
          RemediationObjectiveIPIDate: !isNil(item.RemediationObjectiveIPIDate) ? convertToLocalDateTime(item.RemediationObjectiveIPIDate, DATE_FORMAT) : null,
        }
      });
      return {
        ...state,
        loading: false,
        milestoneList: milestoneList,
        savedMilestone: getSavedMilestone(payload),
      };
    }

    case SEARCH_REMEDIATION_OBJECTIVE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        milestoneList: [],
        savedMilestone: {},
      };
    }

    case SUBMIT_MILESTONE_RESPONSE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SUBMIT_MILESTONE_RESPONSE_SUCCESS: {
      toast.success(SUBMITTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case SUBMIT_MILESTONE_RESPONSE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case RESET_REMEDIATION_KEYS: {
      return {
        ...state,
        loading: false,        
        ...payload,
      };
    }

    default:
      return state;
  }
}
