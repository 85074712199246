import axios from "axios";
import { NO_DATA_FOUND } from "constants/errorMessage";
import { getErrorObject } from "utils/apiUtils";
import { setErrors } from "./errorHandler";
import { getRemediationObjectiveDetailsListBody, getRemediationObjectiveListBody } from "utils/remediationUtils";
import { isEmpty } from "lodash";
import { EVIDENCE_ATTACH } from "pages/AssessmentQuestionnaire/constants";

export const SEARCH_REMEDIATION_OBJECTIVE_LOADING = '@@remediation/SEARCH_REMEDIATION_OBJECTIVE_LOADING';
export const SEARCH_REMEDIATION_OBJECTIVE_SUCCESS = '@@remediation/SEARCH_REMEDIATION_OBJECTIVE_SUCCESS';
export const SEARCH_REMEDIATION_OBJECTIVE_FAILURE = '@@remediation/SEARCH_REMEDIATION_OBJECTIVE_FAILURE';

export const searchRemediationObjectiveList = (searchData = null) => async (dispatch) => {
  dispatch({ type: SEARCH_REMEDIATION_OBJECTIVE_LOADING });
  let body = getRemediationObjectiveListBody(searchData);
  try {
    const response = await axios.post('assessment/remediationobjective/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_REMEDIATION_OBJECTIVE_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_OBJECTIVE_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_OBJECTIVE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_SELECTED_REMEDIATION_OBJECTIVE_LOADING = '@@remediation/SEARCH_SELECTED_REMEDIATION_OBJECTIVE_LOADING';
export const SEARCH_SELECTED_REMEDIATION_OBJECTIVE_SUCCESS = '@@remediation/SEARCH_SELECTED_REMEDIATION_OBJECTIVE_SUCCESS';
export const SEARCH_SELECTED_REMEDIATION_OBJECTIVE_FAILURE = '@@remediation/SEARCH_SELECTED_REMEDIATION_OBJECTIVE_FAILURE';

export const searchSelectedRemediationObjective = (searchData = null) => async (dispatch) => {
  dispatch({ type: SEARCH_SELECTED_REMEDIATION_OBJECTIVE_LOADING });
  let body = getRemediationObjectiveListBody(searchData);
  try {
    const response = await axios.post('assessment/remediationobjective/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_SELECTED_REMEDIATION_OBJECTIVE_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_SELECTED_REMEDIATION_OBJECTIVE_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_SELECTED_REMEDIATION_OBJECTIVE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REMEDIATION_OBJECTIVE_DETAILS_LOADING = '@@remediation/SEARCH_REMEDIATION_OBJECTIVE_DETAILS_LOADING';
export const SEARCH_REMEDIATION_OBJECTIVE_DETAILS_SUCCESS = '@@remediation/SEARCH_REMEDIATION_OBJECTIVE_DETAILS_SUCCESS';
export const SEARCH_REMEDIATION_OBJECTIVE_DETAILS_FAILURE = '@@remediation/SEARCH_REMEDIATION_OBJECTIVE_DETAILS_FAILURE';

export const searchRemediationObjectiveDetailsList = (searchData = null) => async (dispatch) => {
  dispatch({ type: SEARCH_REMEDIATION_OBJECTIVE_DETAILS_LOADING });
  let body = getRemediationObjectiveDetailsListBody(searchData);
  try {
    const response = await axios.post('assessment/remediationresponse/inadequacyreason/ipi/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_REMEDIATION_OBJECTIVE_DETAILS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_REMEDIATION_OBJECTIVE_DETAILS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REMEDIATION_OBJECTIVE_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SUBMIT_MILESTONE_RESPONSE_LOADING = '@@remediation/SUBMIT_MILESTONE_RESPONSE_LOADING';
export const SUBMIT_MILESTONE_RESPONSE_SUCCESS = '@@remediation/SUBMIT_MILESTONE_RESPONSE_SUCCESS';
export const SUBMIT_MILESTONE_RESPONSE_FAILURE = '@@remediation/SUBMIT_MILESTONE_RESPONSE_FAILURE';

export const submitMilestoneResponse = (formData = null, searchData = {}, isFinalMilestone = false) => async (dispatch) => {
  dispatch({ type: SUBMIT_MILESTONE_RESPONSE_LOADING });

  let tempFormData = {
    ...formData,
  };

  delete tempFormData["UploadedFile"];

  let body = new FormData();

  Object.keys(tempFormData)?.forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });

  formData?.UploadedFileList?.forEach((item) => {
    body.append(`UploadedFileList`, item);
  });

  try {
    const response = await axios.post('assessment/remediationobjective/response/action/submit', body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SUBMIT_MILESTONE_RESPONSE_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SUBMIT_MILESTONE_RESPONSE_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (!isEmpty(searchData)) {
        dispatch(searchRemediationObjectiveDetailsList(searchData));

        if (isFinalMilestone) {
          dispatch(
            searchSelectedRemediationObjective({
              RemediationGuidanceDetailId: searchData?.RemediationGuidanceDetailId,
            })
          );
        }
      }
    }
  } catch (err) {
    dispatch({
      type: SUBMIT_MILESTONE_RESPONSE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const RESET_REMEDIATION_KEYS = '@@remediation/RESET_REMEDIATION_KEYS';

export const resetRemediationKeys = (data = {}) => async (dispatch) => {
  dispatch({
    type: RESET_REMEDIATION_KEYS,
    payload: data
  });
};
