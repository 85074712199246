import React from 'react';
import { clearErrors } from '../../actions/errorHandler';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal, Button, Typography } from '@mui/material';
import { modalStyle, buttonStyle, errorData } from './errorHandlerModalStyles';

const ErrorHandlerModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const errorList = useSelector((state) => state.errorHandler.list);

  useEffect(() => {
    setOpen(errorList.length > 0);
  }, [errorList]);

  const onClose = () => {
    setOpen(false);
    dispatch(clearErrors());
  };

  return (
    <Box>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <ul>
            {errorList.length > 1
              ? errorList.map((error, index) => (
                  <li key={index}>
                    <Typography variant="body1" sx={(theme) => errorData(theme)}>
                      {error.Message.replaceAll('!', '.')}
                    </Typography>
                  </li>
                ))
              : errorList.map((error, index) => (
                  <Typography key={index} variant="body1" sx={(theme) => errorData(theme)}>
                    {error.Message.replaceAll('!', '.')}
                  </Typography>
                ))}
          </ul>
          <Box sx={buttonStyle}>
            <Button variant="contained" color="info" onClick={onClose}>
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ErrorHandlerModal;
