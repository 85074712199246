export const COMPANY_PROFILE_LINK = '/vendor-profile';
export const CORL_CLEARED_LINK = 'https://corltech.com/corlcleared/';

//assessment list
export const REMEDIATION_STAGE = 'Remediation';
export const ACTIVE_STAGE = 'Active';
export const COMPLETED_STAGE = 'Completed';

//Pagination Constants
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_ROWS_PER_PAGE = 5;

export const CORL_CLEARED_PROCESS_STARTED_ID = 1;

export const NOT_CORL_CLEARED_STATUS_ID = 1;
export const CORL_CLEARED_PARTICIPANT_STATUS_ID = 2;
export const CORL_CLEARED_STATUS_ID = 3;

export const ASSESSMENT_REQUEST_STATUS_SUBMITTED = 4;

export const ASSESSMENT_REQUEST_TYPE_CORL_CLEARED = 1;

export const ASSESSMENT_TYPE_CORL_CLEARED = 2;
