import {
  POST_CONTACT_TO_LIST_LOADING,
  POST_CONTACT_TO_LIST_SUCCESS,
  POST_CONTACT_TO_LIST_FAILURE,
  DELETE_CONTACT_FROM_LIST_LOADING,
  DELETE_CONTACT_FROM_LIST_SUCCESS,
  DELETE_CONTACT_FROM_LIST_FAILURE,
  PUT_CONTACT_TO_LIST_LOADING,
  PUT_CONTACT_TO_LIST_SUCCESS,
  PUT_CONTACT_TO_LIST_FAILURE,
  SET_CONTACTS_LOADING,
  SET_CONTACTS_SUCCESS,
  SET_CONTACTS_FAILURE,
  GET_VENDOR_PROFILE_CONTACTS_LOADING,
  GET_VENDOR_PROFILE_CONTACTS_SUCCESS,
  GET_VENDOR_PROFILE_CONTACTS_FAILURE,
  SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING,
  SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
  SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE,
  SET_UNSAVED_CONTACTS,
} from '../actions/vendorProfileContacts';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE } from '../components/Common/AlertMessages';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY } from '../constants/errorMessage';

const initialState = {
  loading: false,
  contactDetails: {},
  contactToBeSelected: null,
  existingEmailCheck: null,
  existingEmail: [],
  unsavedContactsChanges: false,
};

export const vendorProfileContact = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VENDOR_PROFILE_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_VENDOR_PROFILE_CONTACTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          vendorContactDetailsList: payload,
        },
      };
    }
    case GET_VENDOR_PROFILE_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          vendorContactDetailsList: [],
        },
      };
    }
    case SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS: {
      const { checkerValue } = action;

      if (checkerValue === 'FirstName') {
        return {
          ...state,
          loading: false,
          existingEmailCheck: null,
          contactDetails: {
            ...state.contactDetails,
            vendorContactDetailsList: payload,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          existingEmail: payload,
          existingEmailCheck: payload.length > 0 ? true : false,
        };
      }
    }
    case SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE: {
      return {
        ...state,
        loading: false,
        contactDetails: {
          ...state.contactDetails,
          vendorContactDetailsList: [],
        },
      };
    }
    case POST_CONTACT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_CONTACT_TO_LIST_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        contactToBeSelected: payload.VendorContactId,
      };
    }
    case POST_CONTACT_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_CONTACT_FROM_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_CONTACT_FROM_LIST_SUCCESS: {
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        contactToBeSelected: null,
      };
    }
    case DELETE_CONTACT_FROM_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_CONTACT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_CONTACT_TO_LIST_SUCCESS: {
      toast.success(SAVED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        contactToBeSelected: payload?.Success?.VendorContactId,
      };
    }
    case PUT_CONTACT_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case SET_CONTACTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CONTACTS_SUCCESS: {
      return {
        ...state,
        existingEmailCheck: null,
        contactDetails: {
          ...state.contactDetails,
          ...payload,
        },
      };
    }
    case SET_CONTACTS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_UNSAVED_CONTACTS: {
      return {
        ...state,
        unsavedContactsChanges: payload,
      };
    }
    default:
      return state;
  }
};
