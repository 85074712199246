import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { setErrors } from './errorHandler';
import { getErrorObject } from 'utils/apiUtils';
import { TRUE } from 'constants/constants';
import { getProductRequirementsListBody, getProductRequirementsQuestionsBody, getRequirementsCountBody } from 'utils/vendorProductRequirementUtils';
import { searchVendorProductById } from './vendorProduct';
import { changeFinalQuestionnaireStatusBody } from 'utils/incidentResponseUtils';

export const UPDATE_PRODUCT_CLEARED_STATUS_LOADING = '@@vendorProductRequirement/UPDATE_PRODUCT_CLEARED_STATUS_LOADING';
export const UPDATE_PRODUCT_CLEARED_STATUS_SUCCESS = '@@vendorProductRequirement/UPDATE_PRODUCT_CLEARED_STATUS_SUCCESS';
export const UPDATE_PRODUCT_CLEARED_STATUS_FAILURE = '@@vendorProductRequirement/UPDATE_PRODUCT_CLEARED_STATUS_FAILURE';

export const updateProductClearedStatus =
  (id, assessmentId = 0) =>
    async (dispatch) => {
      dispatch({ type: UPDATE_PRODUCT_CLEARED_STATUS_LOADING });
      try {
        const body = JSON.stringify({
          ProductVendorId: id,
          IsClearedProcessStarted: TRUE,
          AssessmentId: assessmentId,
        });
        const response = await axios.post('vendor/product/action/updateClearedProcessStatus', body);
        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
          dispatch({
            type: UPDATE_PRODUCT_CLEARED_STATUS_FAILURE,
          });
          dispatch(setErrors(errors));
        } else {
          dispatch({
            type: UPDATE_PRODUCT_CLEARED_STATUS_SUCCESS,
            payload: response?.data,
          });
          dispatch(searchVendorProductById(id));
        }
      } catch (err) {
        dispatch({
          type: UPDATE_PRODUCT_CLEARED_STATUS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_PRODUCT_REQUIREMENTS_LIST_LOADING = '@@vendorProductRequirement/SEARCH_PRODUCT_REQUIREMENTS_LIST_LOADING';
export const SEARCH_PRODUCT_REQUIREMENTS_LIST_SUCCESS = '@@vendorProductRequirement/SEARCH_PRODUCT_REQUIREMENTS_LIST_SUCCESS';
export const SEARCH_PRODUCT_REQUIREMENTS_LIST_FAILURE = '@@vendorProductRequirement/SEARCH_PRODUCT_REQUIREMENTS_LIST_FAILURE';

export const searchProductRequirements = (id, status, requirementId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_REQUIREMENTS_LIST_LOADING });
  let body = getProductRequirementsListBody(id, status, requirementId);
  try {
    const response = await axios.post('vendor/product/questionnairetemplate/requirement/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_PRODUCT_REQUIREMENTS_LIST_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_REQUIREMENTS_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_REQUIREMENTS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_REQUIREMENTS_COUNT_LOADING = '@@vendorProductRequirement/GET_REQUIREMENTS_COUNT_LOADING';
export const GET_REQUIREMENTS_COUNT_SUCCESS = '@@vendorProductRequirement/GET_REQUIREMENTS_COUNT_SUCCESS';
export const GET_REQUIREMENTS_COUNT_FAILURE = '@@vendorProductRequirement/GET_REQUIREMENTS_COUNT_FAILURE';

export const getRequirementsCount = (id) => async (dispatch) => {
  dispatch({ type: GET_REQUIREMENTS_COUNT_LOADING });
  let body = getRequirementsCountBody(id);
  try {
    const response = await axios.post('vendor/product/questionnairetemplate/requirement/count/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_REQUIREMENTS_COUNT_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_REQUIREMENTS_COUNT_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_REQUIREMENTS_COUNT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_LOADING = '@@products/SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_LOADING';
export const SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_SUCCESS = '@@products/SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_SUCCESS';
export const SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_FAILURE = '@@products/SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_FAILURE';

export const getProductRequirementsQuestions = (assessmentQuestionnaireId, assessmentId, requirementId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_LOADING });
  let body = getProductRequirementsQuestionsBody(requirementId);
  try {
    const res = await axios.post(`/assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/response/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_REQUIREMENTS_QUESTIONS_FAILURE,
    });
  }
};

export const PUT_PRODUCT_REQUIREMENTS_RESPONSES_LOADING = '@@products/PUT_PRODUCT_REQUIREMENTS_RESPONSES_LOADING';
export const PUT_PRODUCT_REQUIREMENTS_RESPONSES_SUCCESS = '@@products/PUT_PRODUCT_REQUIREMENTS_RESPONSES_SUCCESS';
export const PUT_PRODUCT_REQUIREMENTS_RESPONSES_FAILURE = '@@products/PUT_PRODUCT_REQUIREMENTS_RESPONSES_FAILURE';

export const saveProductRequirementsResponses =
  (
    formData,
    assessmentId,
    assessmentQuestionnaireId,
    questionId,
    assessmentQuestionnaireQuestionResponseId,
    questionResponseCategoryId,
    questionnaireTemplateQuestionId
  ) =>
    async (dispatch) => {
      dispatch({ type: PUT_PRODUCT_REQUIREMENTS_RESPONSES_LOADING });
      const body = JSON.stringify({
        ...formData,
        Active: true,
        IsNew: true,
        AssessmentQuestionnaireQuestionResponseId: assessmentQuestionnaireQuestionResponseId,
        AssessmentQuestionnaireId: assessmentQuestionnaireId,
        QuestionnaireTemplateQuestionId: questionnaireTemplateQuestionId,
        QuestionId: questionId,
        QuestionResponseCategoryId: questionResponseCategoryId,
      });
      try {
        const res = await axios.put(
          `assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/question/${questionId}/response/${assessmentQuestionnaireQuestionResponseId}`,
          body
        );
        if (res?.data?.ErrorCount > 0) {
          dispatch(setErrors(res?.data?.Errors));
          dispatch({
            type: PUT_PRODUCT_REQUIREMENTS_RESPONSES_FAILURE,
          });
        } else {
          dispatch({
            type: PUT_PRODUCT_REQUIREMENTS_RESPONSES_SUCCESS,
            payload: res?.data?.Data,
          });
        }
      } catch (err) {
        dispatch({
          type: PUT_PRODUCT_REQUIREMENTS_RESPONSES_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING = '@@products/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING';
export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS = '@@products/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS';
export const PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE = '@@products/PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE';

export const updateAssessmentQuestionnaireRequirement = (formData, callback) => async (dispatch) => {
  dispatch({ type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING });
  const body = {
    ...formData,
  };
  try {
    const res = await axios.put(`/assessment/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
        payload: formData?.RequirementStatusId,
      });
      dispatch(searchselectedProductRequirements(formData?.AssessmentId, formData?.RequirementId));
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: PUT_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_LOADING = '@@vendorProductRequirement/SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_LOADING';
export const SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_SUCCESS = '@@vendorProductRequirement/SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_SUCCESS';
export const SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_FAILURE = '@@vendorProductRequirement/SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_FAILURE';

export const searchselectedProductRequirements =
  (id, requirementId, status = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_LOADING });
      let body = getProductRequirementsListBody(id, status, requirementId);
      try {
        const response = await axios.post('vendor/product/questionnairetemplate/requirement/search', body);
        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
          dispatch({
            type: SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_FAILURE,
          });
          dispatch(setErrors(errors));
        } else {
          dispatch({
            type: SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_SUCCESS,
            payload: response?.data?.Data?.Rows,
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_SELECTED_PRODUCT_REQUIREMENTS_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };
export const SHOW_SAVED_PRODUCT_REQUIREMENT_STATUS = '@vendorProductRequirement/SHOW_SAVED_PRODUCT_REQUIREMENT_STATUS';

export const showSaveProductRequirementStatus = (assessmentQuestionnaireId, assessmentId, requirementId) => async (dispatch) => {
  dispatch({
    type: SHOW_SAVED_PRODUCT_REQUIREMENT_STATUS,
    payload: true,
  });
  dispatch(getProductRequirementsQuestions(assessmentQuestionnaireId, assessmentId, requirementId));
};

export const DELETE_EVIDENCE_ATTACHMENTS_LOADING = '@@vendorProductRequirement/DELETE_EVIDENCE_ATTACHMENTS_LOADING';
export const DELETE_EVIDENCE_ATTACHMENTS_SUCCESS = '@@vendorProductRequirement/DELETE_EVIDENCE_ATTACHMENTS_SUCCESS';
export const DELETE_EVIDENCE_ATTACHMENTS_FAILURE = '@@vendorProductRequirement/DELETE_EVIDENCE_ATTACHMENTS_FAILURE';

export const deleteEvidenceDocument = (evidenceFile, assessmentQuestionnaireId, assessmentId, requirementId) => async (dispatch) => {
  dispatch({ type: DELETE_EVIDENCE_ATTACHMENTS_LOADING });

  try {
    const response = await axios.delete(
      `/vendor/product/assessment/assessmentquestionnairequestionresponse/${evidenceFile.AssessmentQuestionnaireQuestionResponseId}/attachement/evidence/${evidenceFile.AssessmentQuestionnaireQuestionResponseAttachmentId}`
    );

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: DELETE_EVIDENCE_ATTACHMENTS_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: DELETE_EVIDENCE_ATTACHMENTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(getProductRequirementsAttachments(assessmentQuestionnaireId, assessmentId, requirementId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_EVIDENCE_ATTACHMENTS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_LOADING = '@@products/SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_LOADING';
export const SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_SUCCESS = '@@products/SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_SUCCESS';
export const SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_FAILURE = '@@products/SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_FAILURE';

export const getProductRequirementsAttachments = (assessmentQuestionnaireId, assessmentId, requirementId) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_LOADING });
  let body = getProductRequirementsQuestionsBody(requirementId);
  try {
    const res = await axios.post(`/assessment/${assessmentId}/questionnaire/${assessmentQuestionnaireId}/response/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_FAILURE,
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_REQUIREMENTS_ATTACHMENTS_FAILURE,
    });
  }
};

export const RESET_PRODUCT_REQUIREMENT_ANSWERS = '@vendorProductRequirement/RESET_PRODUCT_REQUIREMENT_ANSWERS';

export const resetProductRequirementsAnswers = () => async (dispatch) => {
  dispatch({
    type: RESET_PRODUCT_REQUIREMENT_ANSWERS,
    payload: true,
  });
};

export const SHOW_PRODUCT_REQUIREMENT_ANSWERS = '@vendorProductRequirement/SHOW_PRODUCT_REQUIREMENT_ANSWERS';

export const showProductRequirementsAnswers =
  (isRequirementAnswersEditable = false) =>
    async (dispatch) => {
      dispatch({
        type: SHOW_PRODUCT_REQUIREMENT_ANSWERS,
        payload: isRequirementAnswersEditable,
      });
    };
