export default function ToggleButton(theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected,&.Mui-selected:hover': {
            color: 'white',
            backgroundColor: theme.palette.secondary.main,
          },
          backgroundColor: theme.palette.grey[300],
          color: '#727476',
        },
        sizeSmall: {
          padding: '8px 10px 8px 10px',
          height: 27,
          width: 30,
          borderRadius: '5px',
          minWidth: 'unset',
        },
      },
    },
  };
}
