import axios from 'axios';
import { setErrors } from './errorHandler';
import {
  getVendorProfileContactsBody,
  searchVendorProfileCheckDuplicateEmailContactsBody,
  searchVendorProfileSpecificContactsBody,
} from '../utils/vendorProfileContactsUtils';
import { FORCE_DELETE_CONTACT_API_CONFIRMATION } from '../components/VendorProfile/constants';
import { setCustomErrors } from './customErrorHandler';
import { VALIDATIONS } from 'constants/validations';

export const GET_VENDOR_PROFILE_CONTACTS_LOADING = '@@vendorProfileContact/GET_VENDOR_PROFILE_CONTACTS_LOADING';
export const GET_VENDOR_PROFILE_CONTACTS_SUCCESS = '@@vendorProfileContact/GET_VENDOR_PROFILE_CONTACTS_SUCCESS';
export const GET_VENDOR_PROFILE_CONTACTS_FAILURE = '@@vendorProfileContact/GET_VENDOR_PROFILE_CONTACTS_FAILURE';

export const getVendorProfileContacts = () => async (dispatch) => {
  dispatch({
    type: GET_VENDOR_PROFILE_CONTACTS_LOADING,
  });

  var body = getVendorProfileContactsBody();

  try {
    const response = await axios.post(`/vendor/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: GET_VENDOR_PROFILE_CONTACTS_FAILURE,
      });
    } else {
      dispatch({
        type: GET_VENDOR_PROFILE_CONTACTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch {
    dispatch({
      type: GET_VENDOR_PROFILE_CONTACTS_FAILURE,
    });
  }
};

export const SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING = '@@vendorProfileContact/SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING';
export const SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS = '@@vendorProfileContact/SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS';
export const SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE = '@@vendorProfileContact/SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE';

export const getVendorProfileSpecificContacts = (searchValue) => async (dispatch) => {
  dispatch({
    type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_LOADING,
  });
  let body;

  !VALIDATIONS.emailValidation.value.test(searchValue)
    ? (body = searchVendorProfileSpecificContactsBody(searchValue))
    : (body = searchVendorProfileCheckDuplicateEmailContactsBody(searchValue));

  try {
    const response = await axios.post(`/vendor/contact/search`, body);
    if (response?.data?.ErrorCount > 0) {
      if (!VALIDATIONS.emailValidation.value.test(searchValue)) {
        dispatch(setErrors(response?.data?.Errors));
        dispatch({
          type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE,
        });
      } else {
        dispatch({
          type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
          payload: response?.data?.Data,
          checkerValue: 'Email',
        });
      }
    } else {
      dispatch({
        type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_SUCCESS,
        payload: response?.data?.Data?.Rows,
        checkerValue: !VALIDATIONS.emailValidation.value.test(searchValue) ? 'FirstName' : 'Email',
      });
    }
  } catch {
    dispatch({
      type: SEARCH_VENDOR_PROFILE_SPECIFIC_CONTACTS_FAILURE,
    });
  }
};

export const POST_CONTACT_TO_LIST_LOADING = '@@vendorProfileContact/POST_CONTACT_TO_LIST_LOADING';
export const POST_CONTACT_TO_LIST_SUCCESS = '@@vendorProfileContact/POST_CONTACT_TO_LIST_SUCCESS';
export const POST_CONTACT_TO_LIST_FAILURE = '@@vendorProfileContact/POST_CONTACT_TO_LIST_FAILURE';

export const addContactToList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_CONTACT_TO_LIST_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    const response = await axios.post(`/vendor/contact`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_CONTACT_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: POST_CONTACT_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: POST_CONTACT_TO_LIST_FAILURE,
    });
  }
};

export const PUT_CONTACT_TO_LIST_LOADING = '@@vendorProfileContact/PUT_CONTACT_TO_LIST_LOADING';
export const PUT_CONTACT_TO_LIST_SUCCESS = '@@vendorProfileContact/PUT_CONTACT_TO_LIST_SUCCESS';
export const PUT_CONTACT_TO_LIST_FAILURE = '@@vendorProfileContact/PUT_CONTACT_TO_LIST_FAILURE';

export const updateContactToList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: PUT_CONTACT_TO_LIST_LOADING,
  });
  let id = formData.vendorContactId;
  const body = JSON.stringify(formData.updatedFormData);
  try {
    const response = await axios.put(`/vendor/contact/${id}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_CONTACT_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_CONTACT_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(getVendorProfileContacts());
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: PUT_CONTACT_TO_LIST_FAILURE,
    });
  }
};

export const DELETE_CONTACT_FROM_LIST_LOADING = '@@vendorProfileContact/DELETE_CONTACT_FROM_LIST_LOADING';
export const DELETE_CONTACT_FROM_LIST_SUCCESS = '@@vendorProfileContact/DELETE_CONTACT_FROM_LIST_SUCCESS';
export const DELETE_CONTACT_FROM_LIST_FAILURE = '@@vendorProfileContact/DELETE_CONTACT_FROM_LIST_FAILURE';

export const deleteContactFromList =
  (formData, callback, forcedState = false, deleteValue = '') =>
  async (dispatch) => {
    dispatch({
      type: DELETE_CONTACT_FROM_LIST_LOADING,
    });

    const id = formData.vendorContactId;

    let url = `/vendor/contact/${id}`;
    if (forcedState) {
      url = `${url}?forceDelete=true`;
    }

    try {
      const response = await axios.delete(url);
      if (response?.data?.ErrorCount > 0) {
        let errorList = response?.data?.Errors;
        let errorItem = errorList.find((item) => item.Message.includes(`${FORCE_DELETE_CONTACT_API_CONFIRMATION}`));
        if (errorItem) {
          errorItem.Message = `"${deleteValue}" ${FORCE_DELETE_CONTACT_API_CONFIRMATION}`;
        }
        dispatch(setCustomErrors(errorList));
        dispatch({
          type: DELETE_CONTACT_FROM_LIST_FAILURE,
        });
      } else {
        dispatch({
          type: DELETE_CONTACT_FROM_LIST_SUCCESS,
          payload: {
            response: response?.data?.Data,
            formData,
          },
        });
        dispatch(getVendorProfileContacts());
        if (callback) {
          callback();
        }
      }
    } catch {
      dispatch({
        type: DELETE_CONTACT_FROM_LIST_FAILURE,
      });
    }
  };

export const SET_CONTACTS_LOADING = '@@vendorProfileContact/SET_CONTACTS_LOADING';
export const SET_CONTACTS_SUCCESS = '@@vendorProfileContact/SET_CONTACTS_SUCCESS';
export const SET_CONTACTS_FAILURE = '@@vendorProfileContact/SET_CONTACTS_FAILURE';

export const setContacts = (payload, callback) => async (dispatch) => {
  dispatch({
    type: SET_CONTACTS_LOADING,
  });
  try {
    dispatch({
      type: SET_CONTACTS_SUCCESS,
      payload: payload,
    });
    if (callback) {
      callback();
    }
  } catch {
    dispatch({
      type: SET_CONTACTS_FAILURE,
    });
  }
};

export const SET_UNSAVED_CONTACTS = '@@vendorProfileContact/SET_UNSAVED_CONTACTS';

export const setUnsavedContacts = (value) => async (dispatch) => {
  dispatch({
    type: SET_UNSAVED_CONTACTS,
    payload: value,
  });
};
