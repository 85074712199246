import { isNil } from 'lodash';

const ACCESS_TOKEN = 'accessToken';
export const SESSION_DETAILS = 'CORL_sessionDetails';
const ISAUTHENTICATED = 'isAuthenticated';
const CORL_TOKEN = 'corl-token';
const SKIP_COPILOT_INTRO_PREFIX = 'skipCopilotIntro-';

export const setAccessToken = (token) => {
  window.localStorage.setItem(ACCESS_TOKEN, token);
};

export const getAccessToken = () => {
  const token = window.localStorage.getItem(ACCESS_TOKEN);
  if (isNil(token) || token === 'undefined') {
    window.localStorage.removeItem(ACCESS_TOKEN);
    return '';
  } else {
    return token || '';
  }
};

export const setSessionDetails = (session) => {
  window.localStorage.setItem(SESSION_DETAILS, JSON.stringify(session));
};

export const getSessionDetails = () => {
  const session = JSON.parse(window.localStorage.getItem(SESSION_DETAILS));
  if (isNil(session)) {
    window.localStorage.removeItem(SESSION_DETAILS);
    return null;
  }
  return session;
};

export const setIsAuthenticated = (value) => {
  window.localStorage.setItem(ISAUTHENTICATED, value);
};

export const getIsAuthenticated = () => {
  const isAuthenticated = window.localStorage.getItem(ISAUTHENTICATED);
  if (isNil(isAuthenticated)) {
    window.localStorage.removeItem(ISAUTHENTICATED);
    return '';
  } else {
    return isAuthenticated || false;
  }
};

const getSkipCopilotIntroKey = (vendorId) => {
  return SKIP_COPILOT_INTRO_PREFIX + vendorId;
};

export const setSkipCopilotIntro = (value) => {
  window.localStorage.setItem(getSkipCopilotIntroKey(getSessionDetails().OrgName), value);
};

export const getSkipCopilotIntro = () => {
  return window.localStorage.getItem(getSkipCopilotIntroKey(getSessionDetails().OrgName)) === 'true';
};

export const removeSession = () => {
  window.localStorage.removeItem(SESSION_DETAILS);
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(ISAUTHENTICATED);
  window.localStorage.removeItem(CORL_TOKEN);
};
