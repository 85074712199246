import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ASSESSMENT_PAUSED } from 'constants/constants';

export const ASSESSMENT_LABEL_TYPE = {
  ESCALATED: 'escalated',
  EXPEDITED: 'expedited',
  PAUSED: 'paused',
}

export const assessmentLabels = [
  {
    field: 'IsEscalated',
    text: 'Escalated',
    type: ASSESSMENT_LABEL_TYPE.ESCALATED,
  },
  {
    field: 'IsExpedite',
    text: 'Expedited',
    type: ASSESSMENT_LABEL_TYPE.EXPEDITED,
  },
  {
    field: 'IsPaused',
    text: 'Paused',
    type: ASSESSMENT_LABEL_TYPE.PAUSED,
  },
];

export const labelContainer = (theme) => {
  return {
    '&.MuiBox-root': {
      width: 'fit-content',
      padding: '0px 6px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
    },
    '&.escalated': {
      borderColor: `${theme.palette.orange[600]}`,
      color: `${theme.palette.orange[600]}`,
    },
    '&.expedited': {
      borderColor: `${theme.palette.primary.contrastText}`,
      color: `${theme.palette.primary.contrastText}`,
    },
    '&.paused': {
      borderColor: `${theme.palette.text.default}`,
      color: `${theme.palette.text.default}`,
    },
  };
};

export const AssessmentLabel = ({ type }) => {
  const labelInfo = assessmentLabels.find((item) => item.type === type);
  
  if (!labelInfo) return null;
  return (
    <Box sx={labelContainer} className={labelInfo?.type} data-testid="assessment-label">
      <Typography
        variant="p"
        sx={{
          fontSize: '9px !important',
          fontWeight: 700,
          lineHeight: 'normal',
          textTransform: 'uppercase',
          letterSpacing: '0.18px',
        }}
      >
        {labelInfo?.text}
      </Typography>
    </Box>
  );
};

AssessmentLabel.propTypes = {
  type: PropTypes.oneOf(Object.values(ASSESSMENT_LABEL_TYPE)),
};
