import { SEARCH_APP_SETTING_FAILURE, SEARCH_APP_SETTING_LOADING, SEARCH_APP_SETTING_SUCCESS } from "actions/appSettings";

const initialState = {
    loading: false,
    FooterLegalContactUsEmail:[],
    FooterTechnicalContactUsEmail:[],
    NonDisclosureAgreementURL : [],
    AboutUsURL : [],
    PrivacyPolicyURL : [],
    TermsOfServiceURL: []
  };
  
export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case SEARCH_APP_SETTING_LOADING: {
        return {
        ...state,
        loading: true,
        };
    }
    case SEARCH_APP_SETTING_SUCCESS: {
        return {
        ...state,
        loading: false,
        FooterTechnicalContactUsEmail : payload?.FooterTechnicalContactUsEmail,
        FooterLegalContactUsEmail:payload?.FooterLegalContactUsEmail,
        NonDisclosureAgreementURL : payload?.NonDisclosureAgreementURL,
        AboutUsURL : payload?.AboutUsURL,
        PrivacyPolicyURL : payload?.PrivacyPolicyURL,
        TermsOfServiceURL: payload?.TermsOfServiceURL
        };
    }
    case SEARCH_APP_SETTING_FAILURE: {
        return {
        ...state,
        loading: false,
        FooterTechnicalContactUsEmail: [],
        FooterLegalContactUsEmail:[],
        NonDisclosureAgreementURL : [],
        AboutUsURL : [],
        PrivacyPolicyURL : [],
        TermsOfServiceURL: []
        };
    }
    default:
    return state;
    }
}