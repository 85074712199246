import { APP_CONFIG_KEYS } from 'constants/constants';
import { GET_CONFIG_VALUE_FAILURE, GET_CONFIG_VALUE_REQUEST, GET_CONFIG_VALUE_SUCCESS } from '../actions/config';

const initialState = {
  loading: false,
  error: '',
  [APP_CONFIG_KEYS.DISPLAY_ZENDESK_STARTED_GUIDE]: '',
  [APP_CONFIG_KEYS.DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS]: '',
  [APP_CONFIG_KEYS.DISPLAY_ZENDESK_VIDEO_WALKTHROUGHS]: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIG_VALUE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CONFIG_VALUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case GET_CONFIG_VALUE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
