import { toast } from 'react-toastify';
import { GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS, GET_CLIENT_FAIL } from '../actions/client';

const initialState = {
  loading: false,
  error: '',
  clientId: '',
  clientName: '',
  taxIdNumber: '',
  domainName: '',
  registeredBusinessName: '',
  registeredBusinessAddress: '',
  primaryContactFirstName: '',
  primaryContactLastName: '',
  primaryContactEmail: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case GET_CLIENT_FAIL: {
      toast.error(payload.message);
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
