import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

export const VENDOR_ONBOARDING_WIZARD_TAB_OPTIONS = {
  INTRODUCTION: 'INTRODUCTION',
  USER_CONFIRMATION: 'USER CONFIRMATION',
  ORGANIZATION_IDENTIFICATION: 'ORGANIZATION IDENTIFICATION',
  ORGANIZATION_PROFILE: 'ORGANIZATION PROFILE',
  PRODUCT_CATALOG: 'PRODUCT CATALOG',
  WHAT_NEXT: 'WHAT NEXT',
};

export const VENDOR_ONBOARDING_WIZARD_TAB_VALUES = {
  INTRODUCTION: '1',
  USER_CONFIRMATION: '2',
  ORGANIZATION_IDENTIFICATION: '3',
  ORGANIZATION_PROFILE: '4',
  PRODUCT_CATALOG: '5',
  WHAT_NEXT: '6',
};

export const vendorOnboardingWizardTabList = [
  {
    label: VENDOR_ONBOARDING_WIZARD_TAB_OPTIONS.USER_CONFIRMATION,
    value: VENDOR_ONBOARDING_WIZARD_TAB_VALUES.USER_CONFIRMATION,
    tabIcon: faCircleCheck,

    disabled: true
  },
  {
    label: VENDOR_ONBOARDING_WIZARD_TAB_OPTIONS.ORGANIZATION_IDENTIFICATION,
    value: VENDOR_ONBOARDING_WIZARD_TAB_VALUES.ORGANIZATION_IDENTIFICATION,
    tabIcon: faCircleCheck,
    disabled: true
  },
  {
    label: VENDOR_ONBOARDING_WIZARD_TAB_OPTIONS.ORGANIZATION_PROFILE,
    value: VENDOR_ONBOARDING_WIZARD_TAB_VALUES.ORGANIZATION_PROFILE,
    tabIcon: faCircleCheck,
    disabled: true
  },
  {
    label: VENDOR_ONBOARDING_WIZARD_TAB_OPTIONS.PRODUCT_CATALOG,
    value: VENDOR_ONBOARDING_WIZARD_TAB_VALUES.PRODUCT_CATALOG,
    tabIcon: faCircleCheck,
    disabled: true
  },
  {
    label: VENDOR_ONBOARDING_WIZARD_TAB_OPTIONS.WHAT_NEXT,
    value: VENDOR_ONBOARDING_WIZARD_TAB_VALUES.WHAT_NEXT,
    tabIcon: faCircleCheck,
    disabled: true
  },
];
export const userConfirmationPageNumber = '1';
export const aboutYourselfPageNumber = '2';
export const WhatNextPageNumber = '1';
export const organizationIdentificationPageNumber = '1';
export const IS_CORLCLEARED_ENABLED = '2';


export const DEFAULT_PAGE_NUMBER = 1;
export const SINGLE_SELECT = '1';

export const ORGANIZATION_IDENTIFICATION_FIRST_PAGE = 1;
export const ORGANIZATION_IDENTIFICATION_SECOND_PAGE = 2;
export const ORGANIZATION_IDENTIFICATION_THIRD_PAGE = 3;
export const DESC_FOR_NAVIGATE_TO_DASHBOARD = 'Get familiar with the dashboard and see upcoming steps.';
export const DESC_FOR_NAVIGATE_TO_ASSESSMENT = 'Dive into answering your pending assessment';
export const DESC_FOR_NAVIGATE_TO_PRODUCTS = "Setup your company's products in a few easy steps";

export const ORGANIZATION_PROFILE_FIRST_PAGE = '1';
export const ORGANIZATION_PROFILE_SECOND_PAGE = '2';
export const ORGANIZATION_PROFILE_THIRD_PAGE = '3';
export const ORGANIZATION_PROFILE_FOURTH_PAGE = '4';
export const ORGANIZATION_PROFILE_FIFTH_PAGE = '5';
export const ORGANIZATION_PROFILE_SIXTH_PAGE = '6';


export const orgProfile4 = {
  "Dscr": "Can you confirm your organization’s domains?",
  "Title": "",
  "Inputs": [
    {
      "Label": "Add domain",
      "InputType": "Free Text",
      "ObjectField": "DomainName"
    }
  ],
  "OnBack": "",
  "OnNext": [
    {
      "Action": {
        "WizardId": "5",
        "PageNumber": "1"
      },
      "OptionLabel": ""
    }
  ],
  "OnSave": "",
  "OnClose": "",
  "ToolTip": "By providing your domains, we can better restrict access to your organization’s Portal instance. You can adjust this list at a later time. A typical example would be “corltech.com” for CORL Technologies."
}

export const CONGRATS_WHAT_NEXT_DESCRIPTION = [
  DESC_FOR_NAVIGATE_TO_DASHBOARD,
  DESC_FOR_NAVIGATE_TO_ASSESSMENT,
  DESC_FOR_NAVIGATE_TO_PRODUCTS
];
export const ONE_PERSON_PER_PRODUCT='One person(s) per product';
export const ONE_PERSON_PER_PRODUCT_DESCRIPTION ='You selected “One person(s) per product.”  Can you provide us the contact information for this person(s)?';

export const WIZARD_PAGE_USER_PROFILE = 'USER_PROFILE';

export const ONLY_ALPHABET = /[A-Za-z]/g;
export const ONLY_NUMBER = /[0-9]/g;

export const QUESTION_RESPONSE = 'QuestionResponse';