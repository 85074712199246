import { toast } from 'react-toastify';
import {
  SEARCH_COPILOT_PAGE_DETAILS_LOADING,
  SEARCH_COPILOT_PAGE_DETAILS_SUCCESS,
  SEARCH_COPILOT_PAGE_DETAILS_FAILURE,
  SEARCH_COPILOT_ASSESSMENT_DETAILS_LOADING,
  SEARCH_COPILOT_ASSESSMENT_DETAILS_SUCCESS,
  SEARCH_COPILOT_ASSESSMENT_DETAILS_FAILURE,
  INITIALISE_CORL_COPILOT_PROCESS_LOADING,
  INITIALISE_CORL_COPILOT_PROCESS_SUCCESS,
  INITIALISE_CORL_COPILOT_PROCESS_FAILURE,
  SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING,
  SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS,
  SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
  SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING,
  SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS,
  SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
  UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING,
  UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS,
  UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
  UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING,
  UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS,
  UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
  DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING,
  DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS,
  DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
  DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING,
  DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS,
  DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
  SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_LOADING,
  SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_FAILURE,
  SET_REVIEW_SUMMARY_TAB_VALUE,
  SET_CURRENT_PAGE,
  SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_SUCCESS,
  SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_FAILURE,
  SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_LOADING,
  SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_LOADING,
  SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_SUCCESS,
  SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_FAILURE,
  GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_LOADING,
  GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_SUCCESS,
  GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_FAILURE,
  SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING,
  SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS,
  SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
  SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_LOADING,
  SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_SUCCESS,
  SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_FAILURE,
  SET_SELECTED_PRODUCT,
  CO_PILOT_UPLOAD_EVIDENCE_LOADING,
  CO_PILOT_UPLOAD_EVIDENCE_SUCCESS,
  CO_PILOT_UPLOAD_EVIDENCE_FAILURE,
  SEARCH_VENDOR_COPILOT_IMAGES_LOADING,
  SEARCH_VENDOR_COPILOT_IMAGES_FAILURE,
  SEARCH_VENDOR_COPILOT_IMAGES_SUCCESS,
  GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_LOADING,
  GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_SUCCESS,
  GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_FAILURE,
  SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING,
  SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS,
  SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
  SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_LOADING,
  SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_SUCCESS,
  SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_FAILURE,
  SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_LOADING,
  SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_SUCCESS,
  SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_FAILURE,
  SET_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST,
  SET_PRODUCT_REVIEW_REQUIREMENT_LIST,
  SET_ASSESSMENT_QUESTIONNAIRE_PAGE_ROUTE,
  SEARCH_COPILOT_DRU_LOADING,
  SEARCH_COPILOT_DRU_SUCCESS,
  SEARCH_COPILOT_DRU_FAILURE,
  UPDATE_COPILOT_STATUS_LOADING,
  UPDATE_COPILOT_STATUS_SUCCESS,
  UPDATE_COPILOT_STATUS_FAILURE,
  TURN_OFF_LOADING_GENERATED_RESPONSES,
  START_PRODUCT_ORG_LEVEL_FAILURE,
  START_PRODUCT_ORG_LEVEL_LOADING,
  START_PRODUCT_ORG_LEVEL_SUCCESS,
  GET_PRODUCT_BY_ID_LOADING,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID_FAILURE,
} from '../actions/vendorCopilot';
import { COPILOT_RESPONSE_GENERATED_ERROR_MESSAGE, ERROR_MESSAGE, FAILED_TO_UPDATE_STATUS_MESSAGE, UPLOADED_SUCCESSFULLY } from 'constants/errorMessage';
import {
  COPILOT_PROCESS_STARTED_SUCCESSFULLY_MESSAGE,
  COPILOT_RESPONSE_GENERATED_SUCCESSFULLY_MESSAGE,
  COPILOT_STATUS_UPDATED_SUCCESSFULLY_MESSAGE,
  FILE_DELETED_SUCCESSFULLY_MESSAGE,
  FILE_DESCRIPTION_EDITED_SUCCESSFULLY_MESSAGE,
  ORG_LEVEL_ASSESSMENT_CREATION_MESSAGE,
} from 'components/Common/AlertMessages';
import { REVIEW_SUMMARY_TAB_OPTIONS } from 'pages/Products/VendorCoPilot/OrganizationalRequirements/ReviewSummary/constants';
import { ANSWERED_QUESTIONS, DEFAULT_PAGE_NO, UN_ANSWERED_QUESTIONS, VENDOR_COPILOT_PAGE_TYPE } from 'pages/Products/VendorCoPilot/constants';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { CORL_CLEARED_ASSESSMENT_TYPE_ID } from 'pages/AssessmentDetails/constants';
import { isNil } from 'lodash';

const initialState = {
  loading: false,
  assessmentDetails: [],
  organitsationalSecurityEvidenceRequirementList: [],
  productSecurityEvidenceRequirementList: [],
  productSpecificRequirementProductList: [],
  reviewSummarytabValue: REVIEW_SUMMARY_TAB_OPTIONS.ALL,
  currentPage: DEFAULT_PAGE_NO,
  productSpecificRequirementSummaryQuestionList: [],
  organisationalReviewSummaryResponse: [],
  organisationalReviewProgressPercentage: 0,
  organisationalReviewRequirementList: [],
  selectedProduct: {},
  productReviewSummaryResponse: [],
  productReviewProgressPercentage: 0,
  productReviewRequirementList: [],
  images: {},
  assessmentQuestionnaireRoutePageNo: null,
  druList: [],
  submitQuestionnaireLoading: false,
  generateLoading: false,
  currentProduct: null,
};

export const vendorCopilot = (state = initialState, action) => {
  const { type, payload, assessmentType, questionType, pageType } = action;

  switch (type) {
    case SEARCH_COPILOT_PAGE_DETAILS_LOADING:
    case SEARCH_COPILOT_ASSESSMENT_DETAILS_LOADING:
    case INITIALISE_CORL_COPILOT_PROCESS_LOADING:
    case SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING:
    case SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING:
    case UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING:
    case UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING:
    case DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING:
    case DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING:
    case SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_LOADING:
    case SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_LOADING:
    case SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_LOADING:
    case SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING:
    case SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_LOADING:
    case SEARCH_VENDOR_COPILOT_IMAGES_LOADING:
    case GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_LOADING:
    case SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING:
    case SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_LOADING:
    case SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_LOADING:
    case SEARCH_COPILOT_DRU_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_LOADING: {
      return {
        ...state,
        loading: true,
        generateLoading: true,
      }
    }


    case UPDATE_COPILOT_STATUS_LOADING: {
      return {
        ...state,
        submitQuestionnaireLoading: true,
      };
    }

    case SEARCH_COPILOT_PAGE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_COPILOT_ASSESSMENT_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        assessmentDetails: [],
      };
    }

    case SEARCH_COPILOT_PAGE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentPage: payload?.Data?.InitialVendorProfileSetupCurrentPage ? payload?.Data?.InitialVendorProfileSetupCurrentPage : DEFAULT_PAGE_NO,
      };
    }

    case SEARCH_COPILOT_ASSESSMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        assessmentDetails: payload?.Data?.Rows,
      };
    }

    case INITIALISE_CORL_COPILOT_PROCESS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case INITIALISE_CORL_COPILOT_PROCESS_SUCCESS: {
      toast.success( 
        pageType === VENDOR_COPILOT_PAGE_TYPE ? 
        COPILOT_PROCESS_STARTED_SUCCESSFULLY_MESSAGE :
        ORG_LEVEL_ASSESSMENT_CREATION_MESSAGE
      );
      return {
        ...state,
        loading: false,
      };
    }

    case START_PRODUCT_ORG_LEVEL_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case START_PRODUCT_ORG_LEVEL_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case START_PRODUCT_ORG_LEVEL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_PRODUCT_BY_ID_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PRODUCT_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentProduct: payload,
      };
    }

    case GET_PRODUCT_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        currentProduct: null,
      };
    }

    case SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        organitsationalSecurityEvidenceRequirementList: [],
      };
    }

    case SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        organitsationalSecurityEvidenceRequirementList: payload?.Data?.Rows,
      };
    }

    case SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productSecurityEvidenceRequirementList: [],
      };
    }

    case SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productSecurityEvidenceRequirementList: payload?.Data?.Rows,
      };
    }

    case UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS: {
      toast.success(FILE_DESCRIPTION_EDITED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS: {
      toast.success(FILE_DESCRIPTION_EDITED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS: {
      toast.success(FILE_DELETED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS: {
      toast.success(FILE_DELETED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productSpecificRequirementProductList: [],
      };
    }

    case SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productSpecificRequirementProductList: payload?.Data?.Rows,
      };
    }

    case SET_REVIEW_SUMMARY_TAB_VALUE: {
      return {
        ...state,
        reviewSummarytabValue: payload || REVIEW_SUMMARY_TAB_OPTIONS.ALL,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      };
    }

    case SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        organisationalReviewSummaryResponse: [],
      };
    }

    case SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        organisationalReviewSummaryResponse: payload?.Data?.Rows,
      };
    }

    case SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        organisationalReviewProgressPercentage: 0,
      };
    }

    case SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS: {
      const totalCountOfQuestion = payload?.Data?.Rows[0]?.TotalCountOfQuestion ?? 0;
      const totalCountOfGeneratedQuestion = payload?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion ?? 0;

      let percentageCount = totalCountOfQuestion > 0 ? (totalCountOfGeneratedQuestion / totalCountOfQuestion).toFixed(2) : 0;
      return {
        ...state,
        loading: false,
        organisationalReviewProgressPercentage: {
          totalCountOfGeneratedQuestion: totalCountOfQuestion,
          totalCountOfQuestion: totalCountOfQuestion,
          percentageCount: percentageCount,
        },
      };
    }

    case SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        organisationalReviewRequirementList: [],
      };
    }

    case SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        organisationalReviewRequirementList: payload?.Data?.Rows,
      };
    }

    case SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productSpecificRequirementSummaryQuestionList: [],
        corlClearedRequirementList: [],
      };
    }

    case SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productSpecificRequirementSummaryQuestionList: payload,
      };
    }

    case SEARCH_VENDOR_COPILOT_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        images: {
          ...state.images,
          ...payload,
        },
      };
    }

    case CO_PILOT_UPLOAD_EVIDENCE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case CO_PILOT_UPLOAD_EVIDENCE_SUCCESS: {
      toast.success(UPLOADED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }

    case CO_PILOT_UPLOAD_EVIDENCE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_VENDOR_COPILOT_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SET_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: payload,
      };
    }

    case SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        productReviewProgressPercentage: 0,
      };
    }

    case SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS: {
      const totalCountOfQuestion = payload?.Data?.Rows[0]?.TotalCountOfQuestion ?? 0;
      const totalCountOfGeneratedQuestion = payload?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion ?? 0;

      let percentageCount = totalCountOfQuestion > 0 ? (totalCountOfGeneratedQuestion / totalCountOfQuestion).toFixed(2) : 0;
      return {
        ...state,
        loading: false,
        productReviewProgressPercentage: {
          totalCountOfGeneratedQuestion: totalCountOfQuestion,
          totalCountOfQuestion: totalCountOfQuestion,
          percentageCount: percentageCount,
        },
      };
    }

    case SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        productReviewRequirementList: [],
      };
    }

    case SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_SUCCESS: {
      let corlClearedRequirementList = [];
      if (assessmentType === CORL_CLEARED_ASSESSMENT_TYPE_ID) {
        payload?.Data?.Rows.forEach(requirement => {
          if (questionType === UN_ANSWERED_QUESTIONS || questionType === ANSWERED_QUESTIONS) {
            requirement.AssessmentQuestionnaireQuestionResponseList = requirement.AssessmentQuestionnaireQuestionResponseList.filter(question => question.QuestionResponseCategoryId === questionType);
          }
          requirement.AssessmentQuestionnaireQuestionResponseList.forEach(question => {
            corlClearedRequirementList.push(question);
          });
        });
      }

      return {
        ...state,
        loading: false,
        productReviewRequirementList: payload?.Data?.Rows,
        corlClearedRequirementList: corlClearedRequirementList
      };
    }

    case SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        productReviewSummaryResponse: [],
      };
    }

    case SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        productReviewSummaryResponse: payload?.Data?.Rows,
      };
    }

    case GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_FAILURE: {
      toast.error(COPILOT_RESPONSE_GENERATED_ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        generateLoading: false,
      };
    }

    case TURN_OFF_LOADING_GENERATED_RESPONSES: {
      return {
        ...state,
        loading: false,
        generateLoading: false,
      };
    }

    case GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_SUCCESS: {
      toast.success(COPILOT_RESPONSE_GENERATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        generateLoading: false,
      };
    }

    case GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_FAILURE: {
      toast.error(COPILOT_RESPONSE_GENERATED_ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_SUCCESS: {
      toast.success(COPILOT_RESPONSE_GENERATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SET_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST: {
      return {
        ...state,
        organisationalReviewRequirementList: payload,
      };
    }

    case SET_PRODUCT_REVIEW_REQUIREMENT_LIST: {
      return {
        ...state,
        productReviewRequirementList: payload,
      };
    }

    case SET_ASSESSMENT_QUESTIONNAIRE_PAGE_ROUTE: {
      return {
        ...state,
        assessmentQuestionnaireRoutePageNo: payload,
      };
    }

    case SEARCH_COPILOT_DRU_SUCCESS: {
      return {
        ...state,
        loading: false,
        druList: payload?.map((item) => ({
          label: isNil(item?.ProductName) ? item?.AssessmentName : `${item?.ProductName} on ${item?.AssessmentSubmittedDate}`,
          value: item?.AssessmentId
        }))
      };
    }

    case SEARCH_COPILOT_DRU_FAILURE: {
      return {
        ...state,
        loading: false,
        druList: []
      };
    }

    case UPDATE_COPILOT_STATUS_SUCCESS: {
      toast.success(COPILOT_STATUS_UPDATED_SUCCESSFULLY_MESSAGE)
      return {
        ...state,
        submitQuestionnaireLoading: false,
      };
    }

    case UPDATE_COPILOT_STATUS_FAILURE: {
      toast.error(FAILED_TO_UPDATE_STATUS_MESSAGE)
      return {
        ...state,
        submitQuestionnaireLoading: false,
      };
    }

    default:
      return state;
  }
};
