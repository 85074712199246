import { COPILOT_LABEL } from "constants/constants";

export const COPILOT_TAB_OPTIONS = {
  INTRODUCTION: 'INTRODUCTION',
  EVIDENCE_UPLOAD: 'EVIDENCE UPLOAD',
  ORGANIZATIONAL_REQUIREMENTS: 'ORGANIZATIONAL REQUIREMENTS',
  PRODUCT_SPECIFIC_REQUIREMENTS: 'PRODUCT SPECIFIC REQUIREMENTS',
};

export const INTRO_DESCRIPTION_ID_ONE = 1;
export const INTRO_DESCRIPTION_ID_TWO = 2;
export const INTRO_DESCRIPTION_ID_THREE = 3;

export const INTRO_DESCRIPTION = [
  {
    id: INTRO_DESCRIPTION_ID_ONE,
    text: 'Upload your most important security documents related to your organization and product(s).',
  },
  {
    id: INTRO_DESCRIPTION_ID_TWO,
    text: `CORL ${COPILOT_LABEL} will use AI technology to scan your documents and see which security requirements you meet in various security assessments.`,
  },
  {
    id: INTRO_DESCRIPTION_ID_THREE,
    text: 'Review and manage the AI-generated responses for quicker updates to future assessments.',
  },
];
export const REMOVE_SELECTED_FILE_MESSAGE = 'This action will remove the selected file. Are you sure ?';

//DRU CONSTANTS
export const DATA_REUSE_AVAILABLE_MODAL_TITLE = 'Data-Reuse is available for this Assessment.';
export const DATA_REUSE_AVAILABLE_MODAL_SUBTITLE = 'We have past answers that we can apply.';
export const DATA_REUSE_AVAILABLE_MODAL_DESCRIPTION = `Our records show that you have responded before to this assessment questionnaire. Using a previous response set to prep-populate your answers will help expedite your response time. You'll also have the opportunity to edit any reuse response before submitting.`;

export const USE_COPILOT_MODAL_TITLE = `${COPILOT_LABEL} is available for this Assessment.`;
export const USE_COPILOT_MODAL_SUBTITLE = `Upload your evidence and let ${COPILOT_LABEL} do the answering.`;
export const USE_COPILOT_MODAL_DESCRIPTION = `Reduce the time it takes to responds to this assessment questionnaire by leveraging our AI powered ${COPILOT_LABEL} feature. ${COPILOT_LABEL} scans your evidence to generate answers to the assessment questions. Once generated, you'll have the opportunity to adjust any ${COPILOT_LABEL} answers.`;

export const REQUIREMENT_EXAMPLES = [
  {
    id: 1,
    label: 'Requirement Example 1',
    result: 'MET REQUIREMENT',
  },
  {
    id: 2,
    label: 'Requirement Example 2',
    result: 'UNMET REQUIREMENT',
  },
  {
    id: 3,
    label: 'Requirement Example 3',
    result: 'PENDING REQUIREMENT',
  },
];
export const FIRST_REQURIEMENT_ID = 1;
export const SECOND_REQURIEMENT_ID = 2;

export const PRODUCT_REQUIREMENT_TUTORIAL_PRODUCT_TABLE_EXAMPLES = [
  {
    id: 1,
    name: 'Product Example 1',
    group: 'Cardiovascular',
    type: 'Medical Device',
    clients: 10,
    status: false,
  },
  {
    id: 2,
    name: 'Product Example 2',
    group: 'Cardiovascular',
    type: 'Medical Device',
    clients: 10,
    status: true,
  },
  {
    id: 3,
    name: 'Product Example 2',
    group: 'Cardiovascular',
    type: 'Medical Device',
    clients: 10,
    status: false,
  },
];

export const SECURITY_COMPLIACE_EVIDANCE_LIST = [
    { id: 1, label: "POC" },
    { id: 2, label: "SOC" },
    { id: 3, label: "Product-level Pen Test" },
    { id: 4, label: "Cloud Hosting Security Tools & Services" },
    { id: 5, label: "4th Party Disclosures" },
    { id: 6, label: "Offshore Resource Attestation" },
    { id: 7, label: "Code Scan Results" },
    { id: 8, label: "SBOM" },
  ];

  export const SECURITY_EVIDANCE_LIST = [
    { id: 1, label: "Security Certificate" },
    { id: 2, label: "Cybersecurity Insurance Policy" },
    { id: 3, label: "Organizational Structure" },
    { id: 4, label: "Breach Response Plan" },
    { id: 5, label: "Organizational PIN Plan" },
  ];

  export const DEFAULT_PAGE_NO = 1;
  export const ORGANISATIONAL_REQUIREMENT_TAB_INTRO_PAGE_NO = 2;
  export const PRODUCT_SPECIFIC_REQUIREMENT_TAB_INTRO_PAGE_NO = 9;

  export const ORGANIZATIONAL_INTRODUCTION_PAGE = 2;
  export const ORGANIZATIONAL_REQUIREMENT_TUTORIAL_PAGE = 3;
  export const ORGANIZATIONAL_UPLOAD_EVIDENCE_PAGE = 4;
  export const ORGANIZATIONAL_REVIEW_SUMMARY_PAGE = 5;
  export const ORGANIZATIONAL_COPILOT_REVIEW_SUMMARY_PAGE = 6;
  export const ORGANIZATIONAL_ASSESSMENT_QUESTIONNAIRE_PAGE = 7;
  export const ORGANIZATIONAL_QUESTIONAR_SUMMARY_PAGE = 8;
  export const PRODUCT_SPECIFIC_REQUIREMENT_INTRODUCTION_PAGE = 9;
  export const PRODUCT_SPECIFIC_REQUIREMENT_TUTORIAL_PAGE = 10;
  export const PRODUCT_LIST_PAGE = 11;
  export const PRODUCT_UPLOAD_EVIDENCE_PAGE = 12;
  export const PRODUCT_REVIEW_SUMMARY_PAGE = 13;
  export const PRODUCT_COPILOT_REVIEW_SUMMARY_PAGE = 14;
  export const PRODUCT_SPECIFIC_REQUIREMENT_ASSESSMENT_QUESTIONNAIRE_PAGE = 15;  
  export const PRODUCT_QUESTIONAIRE_SUMMARY_PAGE = 16;  
  export const END_OF_COPILOT_SETUP_PAGE = 17;  

  export const UN_ANSWERED_QUESTIONS =  0;
  export const ANSWERED_QUESTIONS = 3;
  export const UNANSWERED = 'UNANSWERED';
  export const ANSWERED = 'ANSWERED';
  export const ANSWERED_RESPONSES = 3;
  export const REGEX_TO_REMEOVE_HTML = /<\/?[^>]+(>|$)/g;
  export const MODE_MANUAL = 1;
  export const MODE_COPILOT = 3;
  export const MODE_DATA_REUSE = 2;
  export const DEFAULT_PARENT_QUESTIONNAIREID = 0;

  // IMGAE NAME FOR THE VENDOR COPILOT PAGES.
 
  export const IMAGE_WELCOME_PAGE = 'welcomePage.png';
  export const IMAGE_ORGANIZATION_INTRO_PAGE = 'introductionToOrgnizationalRequirement.png'
  export const IMAGE_SETUP_COMPLETED = 'copilotPageSetupCompleted.png';
  export const IMAGE_PRODUCT_INTRO_PAGE = 'productRequirementIntroduction.png';

  export const ORGANISATIONAL_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE = 'Organisational Specific Upload Evidence';
  export const PRODUCT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE = 'Product Specific Upload Evidence';
  export const ASSESSMENT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE = 'Assessment Specific Upload Evidence';

  export const CO_PILOT_ACTIVATED_TEXT = `${COPILOT_LABEL?.toUpperCase()} ACTIVATED`;
  export const ACTIVATE_CO_PILOT_TEXT = `ACTIVATE ${COPILOT_LABEL?.toUpperCase()}`;
  export const INDEPENDENT_RESPONSE_TEXT = 'INDEPENDENT RESPONSE';

  export const CO_PILOT_ACTIVATED = 1;
  export const INDEPENDENT_RESPONSE = 2;

  export const TAB_1_DATA_REUSE_MODAL = 1;
  export const TAB_2_COPILOT_MODAL = 2

  export const USE_DRU_MODAL_TITLE_GREETING = 'Good news!';
  export const USE_DRU_MODAL_TITLE = `Data-Reuse and ${COPILOT_LABEL} are available for this Questionnaire`;
  export const USE_DRU_MODAL_SUBTITLE = `You can combine answers from a previous questionnaire and use ${COPILOT_LABEL} to leverage AI to answer remaining questions.`;
  export const USE_DRU_MODAL_DESCRIPTION = `Our records show that you have responded before to this questionnaire. Using a previous response set to prep-populate your answers will help expedite your response time. You'll also have the opportunity to edit any reuse response before submitting.`;

  export const IS_DRU_ENABLED_INT = 2;

  export const IS_VENDOR_COPILOT_ORG_LEVEL = 1;
  export const IS_VENDOR_COPILOT_PRODUCT_LEVEL = 2;

  export const ORG_EVIDENCE_CATEGORY_TYPE_ID = "1";
  export const PRODUCT_EVIDENCE_CATEGORY_TYPE_ID = "2";

  export const VENDOR_COPILOT_PAGE_TYPE = "Vendor_Type_Copilot";

  export const STARTED_VENDOR_PROFILE_ASSESSMENT = 1;