export const NEW = 'pending';
export const ALREADY = 'already';
export const ACCEPTED = 'accepted';
export const DECLINED = 'declined';
export const COMPLETED = 'completed';

export const COMPLETED_MILESTONE = 'completed';
export const IN_PROGRESS_MILESTONE = 'in-progress';
export const UPCOMING_MILESTONE = 'upcoming';

export const RISKFINDING_PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};
export const RECOMMENDATION_ACCEPT_CONFIRMATION_MESSAGE = 'Are you sure you want to accept this recommendation?';
export const ACCEPT_RECOMMENDATION = 'accept';
export const REMEDIATION_RESPONSE_ACCEPT_ACTION_ID = 2;