import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import { setErrors } from './errorHandler';
import { searchCompanyProfilePercentageBody } from 'utils/companyProfileUtils';
import { VENDOR_ID_NOT_MAPPED_TO_VENDOR_ERROR_MESSAGE } from 'constants/errorMessage';

export const GET_COMPANY_INFO_LOADING = '@@org/GET_COMPANY_INFO_LOADING';
export const GET_COMPANY_INFO_SUCCESS = '@@org/GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_FAILURE = '@@org/GET_COMPANY_INFO_FAILURE';

export const getCompanyInformation = () => async (dispatch) => {
  dispatch({
    type: GET_COMPANY_INFO_LOADING,
  });
  let obtainCompanyInfoReqBody = {
    intent: '',
    fields: [
      'VendorOrgId',
      'VendorOrgName',
      'DomainName',
      'YearFounded',
      'NumberOfEmployeesRange',
      'RegisteredBusinessAddressLine1',
      'RegisteredBusinessAddressLine2',
      'RegisteredBusinessAddressCity',
      'RegisteredBusinessAddressState',
      'RegisteredBusinessAddressCountry',
      'RegisteredBusinessAddressZip',
      'Phone',
      'PhoneCode',
      'MainPhone',
      'PhoneExtension',
      'ZendeskOrgId',
      'IsCorlClearedCopilot',
      'IsVerified'
    ],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };

  const body = JSON.stringify(obtainCompanyInfoReqBody);

  try {
    const res = await axios.post(`/vendor/search`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_COMPANY_INFO_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_COMPANY_INFO_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMPANY_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_COMPANY_INFO_LOADING = '@@org/POST_COMPANY_INFO_LOADING';
export const POST_COMPANY_INFO_SUCCESS = '@@org/POST_COMPANY_INFO_SUCCESS';
export const POST_COMPANY_INFO_FAILURE = '@@org/POST_COMPANY_INFO_FAILURE';

export const addCompanyInformation = (id, formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_COMPANY_INFO_LOADING,
  });

  const body = {
    VendorOrgName: formData?.VendorOrgName,
    DomainName: formData?.DomainName,
    RegisteredBusinessAddressLine1: formData?.RegisteredBusinessAddressLine1,
    RegisteredBusinessAddressLine2: formData?.RegisteredBusinessAddressLine2,
    RegisteredBusinessAddressCity: formData?.RegisteredBusinessAddressCity,
    RegisteredBusinessAddressState: formData?.RegisteredBusinessAddressState,
    RegisteredBusinessAddressCountry: formData?.RegisteredBusinessAddressCountry,
    RegisteredBusinessAddressZip: formData?.RegisteredBusinessAddressZip,
    //Phone: formData?.Phone,
    PhoneCode: formData?.PhoneCode,
    MainPhone: formData?.MainPhone,
    PhoneExtension: formData?.PhoneExtension,
    NumberOfEmployeesRangeId: formData?.NumberOfEmployeesRangeId,
    YearFounded: formData?.YearFounded,
    LogoFileLocation: '',
    ZendeskOrgId: formData?.ZendeskOrgId,
  };

  try {
    const res = await axios.put(`/vendor/${id}`, body);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_COMPANY_INFO_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_COMPANY_INFO_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(getCompanyInformation());
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: POST_COMPANY_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING';
export const GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS';
export const GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE = '@@lookup/GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE';

export const getNumberOfEmployeeRange = (callback) => async (dispatch) => {
  dispatch({
    type: GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=NumberOfEmployeesRange`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS,
        payload: res.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_STATE_LIST_LOADING = '@@lookup/GET_STATE_LIST_LOADING';
export const GET_STATE_LIST_SUCCESS = '@@lookup/GET_STATE_LIST_SUCCESS';
export const GET_STATE_LIST_FAILURE = '@@lookup/GET_STATE_LIST_FAILURE';

export const getStatesList = (countryName, callback) => async (dispatch) => {
  dispatch({
    type: GET_STATE_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=region?param=` + countryName);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_STATE_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_STATE_LIST_SUCCESS,
        payload: res.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_STATE_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_COUNTRY_LIST_LOADING = '@@lookup/GET_COUNTRY_LIST_LOADING';
export const GET_COUNTRY_LIST_SUCCESS = '@@lookup/GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILURE = '@@lookup/GET_COUNTRY_LIST_FAILURE';

export const getCountriesList = (callback) => async (dispatch) => {
  dispatch({
    type: GET_COUNTRY_LIST_LOADING,
  });
  try {
    const res = await axios.get(`app/lookup/e=Country`);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: GET_COUNTRY_LIST_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_COUNTRY_LIST_SUCCESS,
        payload: res.data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_COUNTRY_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_COMPANY_PROFILE_PROGRESS_LOADING = '@@companyInformation/SEARCH_COMPANY_PROFILE_PROGRESS_LOADING';
export const SEARCH_COMPANY_PROFILE_PROGRESS_SUCCESS = '@@companyInformation/SEARCH_COMPANY_PROFILE_PROGRESS_SUCCESS';
export const SEARCH_COMPANY_PROFILE_PROGRESS_FAILURE = '@@companyInformation/SEARCH_COMPANY_PROFILE_PROGRESS_FAILURE';

export const searchCompanyProfileProgress = () => async (dispatch) => {
  dispatch({ type: SEARCH_COMPANY_PROFILE_PROGRESS_LOADING });

  let body = searchCompanyProfilePercentageBody();
  try {
    const response = await axios.post(`legacy/assessment/executivesummary/vendorcollaborationscore/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== VENDOR_ID_NOT_MAPPED_TO_VENDOR_ERROR_MESSAGE);
      dispatch({
        type: SEARCH_COMPANY_PROFILE_PROGRESS_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_COMPANY_PROFILE_PROGRESS_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COMPANY_PROFILE_PROGRESS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
