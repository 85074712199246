import { getErrorObject } from 'utils/apiUtils';
import { setErrors } from './errorHandler';
import axios from 'axios';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { isNil } from 'lodash';

export const GET_SUPPORT_SECTIONS_LOADING = '@@supportArticles/GET_SUPPORT_SECTIONS_LOADING';
export const GET_SUPPORT_SECTIONS_SUCCESS = '@@supportArticles/GET_SUPPORT_SECTIONS_SUCCESS';
export const GET_SUPPORT_SECTIONS_FAILURE = '@@supportArticles/GET_SUPPORT_SECTIONS_FAILURE';

export const getSupportSections = () => async (dispatch) => {
  dispatch({ type: GET_SUPPORT_SECTIONS_LOADING });
  try {
    const response = await axios.get(`vendor/helpcenter/support/sections`);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_SUPPORT_SECTIONS_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_SUPPORT_SECTIONS_SUCCESS,
        payload: response?.data?.Data?.sections,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SUPPORT_SECTIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_SUPPORT_ARTICLES_LOADING = '@@supportArticles/GET_SUPPORT_ARTICLES_LOADING';
export const GET_SUPPORT_ARTICLES_SUCCESS = '@@supportArticles/GET_SUPPORT_ARTICLES_SUCCESS';
export const GET_SUPPORT_ARTICLES_FAILURE = '@@supportArticles/GET_SUPPORT_ARTICLES_FAILURE';

export const getSupportArticles = (subSectionId, parentId) => async (dispatch) => {
  dispatch({ type: GET_SUPPORT_ARTICLES_LOADING });
  let sectionId = !isNil(subSectionId) ? subSectionId : parentId;
  try {
    const response = await axios.get(`vendor/helpcenter/support/sections/${sectionId}/articles`);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_SUPPORT_ARTICLES_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_SUPPORT_ARTICLES_SUCCESS,
        payload: response?.data?.Data?.articles,
        subSectionId: subSectionId,
        parentId: parentId,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SUPPORT_ARTICLES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_SPECIFIC_SUPPORT_ARTICLES_LOADING = '@@supportArticles/GET_SPECIFIC_SUPPORT_ARTICLES_LOADING';
export const GET_SPECIFIC_SUPPORT_ARTICLES_SUCCESS = '@@supportArticles/GET_SPECIFIC_SUPPORT_ARTICLES_SUCCESS';
export const GET_SPECIFIC_SUPPORT_ARTICLES_FAILURE = '@@supportArticles/GET_SPECIFIC_SUPPORT_ARTICLES_FAILURE';

export const getSpecificSupportArticles = (searchValue) => async (dispatch) => {
  dispatch({ type: GET_SPECIFIC_SUPPORT_ARTICLES_LOADING });
  try {
    const response = await axios.get(`vendor/helpcenter/support/articles/search?searcharticle=${searchValue}`);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: GET_SPECIFIC_SUPPORT_ARTICLES_FAILURE,
        payload: {},
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: GET_SPECIFIC_SUPPORT_ARTICLES_SUCCESS,
        payload: response?.data?.Data?.results,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SPECIFIC_SUPPORT_ARTICLES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SET_SUPPORT_SUBSECTION_SUCCESS = '@@supportArticles/SET_SUPPORT_SUBSECTION_SUCCESS';

export const setSupportSection = (subSection) => async (dispatch) => {
  dispatch({
    type: SET_SUPPORT_SUBSECTION_SUCCESS,
    payload: subSection,
  });
};
export const SET_SUPPORT_SUBSECTION_MORE_SUCCESS = '@@supportArticles/SET_SUPPORT_SUBSECTION_MORE_SUCCESS';

export const setSupportSubSectionMore = (moreSubsection) => async (dispatch) => {
  dispatch({
    type: SET_SUPPORT_SUBSECTION_MORE_SUCCESS,
    payload: moreSubsection,
  });
};

export const CLEAR_SUPPORT_ARTICLE_DETAILS = '@@supportArticles/CLEAR_SUPPORT_ARTICLE_DETAILS';

export const clearSupportArticleDetails = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SUPPORT_ARTICLE_DETAILS,
  });
};
