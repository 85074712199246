import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import {
  POST_VENDOR_PROFILE_TEMPLATE_LOADING,
  POST_VENDOR_PROFILE_TEMPLATE_SUCCESS,
  POST_VENDOR_PROFILE_TEMPLATE_FAILURE,
  POST_VENDOR_TO_LIST_QUESTION_LOADING,
  POST_VENDOR_TO_LIST_QUESTION_SUCCESS,
  POST_VENDOR_TO_LIST_QUESTION_FAILURE,
  PUT_VENDOR_TO_LIST_QUESTION_LOADING,
  PUT_VENDOR_TO_LIST_QUESTION_SUCCESS,
  PUT_VENDOR_TO_LIST_QUESTION_FAILURE,
  DELETE_VENDOR_FROM_LIST_QUESTION_LOADING,
  DELETE_VENDOR_FROM_LIST_QUESTION_SUCCESS,
  DELETE_VENDOR_FROM_LIST_QUESTION_FAILURE,
} from '../actions/vendorProfileTemplate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faAddressBook, faClock, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCircleUpRight } from '@fortawesome/pro-solid-svg-icons';
import { ERROR_MESSAGE } from '../components/Common/AlertMessages';

const navigationList = [
  {
    icon: <FontAwesomeIcon icon={faCircleInfo} />,
    label: 'COMPANY INFO',
  },
  {
    icon: <FontAwesomeIcon icon={faAddressBook} />,
    label: 'CONTACTS',
  },
  {
    icon: <FontAwesomeIcon icon={faUsers} />,
    label: 'CUSTOMERS',
  },
  {
    icon: <FontAwesomeIcon icon={faCircleUpRight} />,
    label: 'INCIDENT ESCALATION',
  },
  {
    icon: <FontAwesomeIcon icon={faClock} />,
    label: 'INCIDENT PREPAREDNESS',
  },
];

const initialState = {
  vendorProfiles: {
    navigationList,
  },
  loading: false,
  generalInformation: {},
};

export function vendorProfileReducer(state = initialState, action) {
  const { type, payload } = action;
  if (payload) {
    let key = Object.keys(payload);
    let value = Object.values(payload);
    if (action.type === 'SET_VENDOR_PROFILE') {
      return {
        ...state,
        vendorProfiles: {
          ...state.vendorProfiles,
          [key[0]]: value[0],
        },
      };
    } else {
      let vendorProfileDetails = cloneDeep(state.vendorProfiles);
      let vendorList = vendorProfileDetails.fourthPartyVendorFormData ? vendorProfileDetails.fourthPartyVendorFormData : [];

      switch (type) {
        case POST_VENDOR_TO_LIST_QUESTION_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }

        case POST_VENDOR_TO_LIST_QUESTION_SUCCESS: {
          vendorList.push(payload);
          return {
            ...state,
            loading: false,
            vendorProfiles: {
              ...state.vendorProfiles,
              fourthPartyVendorFormData: vendorList,
              selectedVendorIndex: vendorList.length,
            },
          };
        }

        case POST_VENDOR_TO_LIST_QUESTION_FAILURE: {
          toast.error(ERROR_MESSAGE);
          return {
            ...state,
            loading: false,
          };
        }

        case PUT_VENDOR_TO_LIST_QUESTION_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }

        case PUT_VENDOR_TO_LIST_QUESTION_SUCCESS: {
          vendorList[payload.itemToBeUpdatedIndex] = payload.fourthPartyVendorFormData;
          return {
            ...state,
            loading: false,
            vendorProfiles: {
              ...state.vendorProfiles,
              fourthPartyVendorFormData: vendorList,
            },
          };
        }

        case PUT_VENDOR_TO_LIST_QUESTION_FAILURE: {
          toast.error(ERROR_MESSAGE);
          return {
            ...state,
            loading: false,
          };
        }

        case DELETE_VENDOR_FROM_LIST_QUESTION_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }

        case DELETE_VENDOR_FROM_LIST_QUESTION_SUCCESS: {
          let updatedList = vendorList.filter((item, index) => index !== payload.itemToBeDeletedIndex);
          return {
            ...state,
            loading: false,
            vendorProfiles: {
              ...state.vendorProfiles,
              fourthPartyVendorFormData: updatedList,
            },
          };
        }

        case DELETE_VENDOR_FROM_LIST_QUESTION_FAILURE: {
          toast.error(ERROR_MESSAGE);
          return {
            ...state,
            loading: false,
          };
        }

        default:
          return state;
      }
    }
  }
  return state;
}

export function vendorProfileTemplate(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case POST_VENDOR_PROFILE_TEMPLATE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_VENDOR_PROFILE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        generalInformation: payload?.Data?.VendorProfileTemplate,
      };
    }

    case POST_VENDOR_PROFILE_TEMPLATE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
