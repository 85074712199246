export const getConfigValuesBody = (keys) => {
  return {
    intent: '',
    fields: keys,
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
