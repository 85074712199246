import { COPILOT_LABEL } from "./constants";

export const NO_DATA_FOUND = 'No data found';
export const NO_CONTACTS_FOUND = [
  {
    Type: 'I',
    Message: 'No Contacts found, please add Contacts.',
  },
];
export const NO_PRODUCTS_FOUND = [
  {
    Type: 'I',
    Message: 'No Products found, please add Products.',
  },
];
export const NO_IE_CONTACTS_FOUND = [
  {
    Type: 'I',
    Message: 'No contacts associated, please add Incident Response Point of Contact.',
  },
];
export const NO_CUSTOMERS_FOUND = [
  {
    Type: 'I',
    Message: 'No customers associated, please add Customers.',
  },
];
export const NO_USERS_FOUND = [
  {
    Type: 'I',
    Message: 'No Users found, please add Users.',
  },
];

export const SAVED_SUCCESSFULLY = 'Saved successfully';
export const SUBMITTED_SUCCESSFULLY = 'Submitted successfully';
export const DELETED_SUCCESSFULLY = 'Deleted successfully';
export const ERROR_MESSAGE = 'Something went wrong, Please try again';
export const PRODUCT_ADDED_SUCCESSFULLY = 'Product Added Successfully';
export const PRODUCT_UPDATED_SUCCESSFULLY = 'Product Updated Successfully';
export const PRODUCT_DELETED_SUCCESSFULLY = 'Product Deleted Successfully';
export const UPLOADED_SUCCESSFULLY = 'Uploaded Successfully';
export const COMMENT_ADDED_SUCCESSFULLY = 'Comment Added successfully';
export const PREFERENCE_SAVED_SUCCESSFULLY = 'Preference Saved Successfully';
export const FEEDBACK_PROVIDED_SUCCESSFULLY = 'Feedback Request Submitted Successfully';

export const CANCEL_PRODUCT_FORM_CONFIRMATION = 'This will close the page without saving the changes. Are you sure?';
export const DELETE_PRODUCT_GROUP_CONFIRMATION = 'This action will delete the Product Group. Are you sure?';
export const DELETE_PRODUCT_CONFIRMATION = 'This action will delete the Product. Are you sure?';
export const CANCEL_FILE_UPLOAD_CONFIRMATION = 'This action will not save the changes. Are you sure?';
export const EVIDENCE_MANAGEMENT_REMOVE_FILE_SELECTION_MESSAGE = 'This action will remove file from selection. Are you sure?';

export const REACT_TO_PDF_ERROR = 'Target ref must be used or informed.';

export const VENDOR_ID_NOT_MAPPED_TO_VENDOR_ERROR_MESSAGE = 'Vendor Id not mapped to the VendorOrg.';

export const COPILOT_RESPONSE_GENERATED_ERROR_MESSAGE = `Failed To Generate ${COPILOT_LABEL} Response.`

export const FAILED_TO_UPDATE_STATUS_MESSAGE = 'Failed To Update Status.'

export const FAILED_TO_GENERATE_RESPONSE_MESSAGE = 'Failed To Generate Response.'