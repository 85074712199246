import { TRUE } from 'constants/constants';
import { CORL_CLEARED_PARTICIPANT_STATUS_ID, CORL_CLEARED_PROCESS_STARTED_ID, CORL_CLEARED_STATUS_ID } from 'pages/Dashboard/constants';
import {
  ASSESSMENT_REQUEST_STATUS_SUBMITTED,
  ASSESSMENT_REQUEST_TYPE_CORL_CLEARED,
  PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE,
  OUTCOME_ADEQUATE,
  OUTCOME_INADEQUATE,
  ASSESSMENT_TYPE_CORL_CLEARED,
  ASSESSMENT_TYPE_CORL_CLEARED_INT ,
} from 'pages/Products/constants';
export const getProductGroupListBody = () => {
  return {
    intent: '',
    fields: ['ProductGroupName', 'VendorOrgId', 'Active'],
    criterion: [],
    sort: [
      {
        field: 'ProductGroupName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
export const getProductListBody = () => {
  return {
    intent: '',    
    fields: [
      "VendorOrgId",
      "VendorOrgName",
      "VendorUrl",
      "ProductName",
      "ProductType",
      "ProductVendorRole",
      "ProductId",
      "ProductGroup",
      "ProductNameForVendor",
      "IsClearedProcessStarted",
      "ExternalTicketId",
      "CCDynVRASId",
      "CORLClearedStatus",
      "ProductVendorAssessment",
      "ProductCategory",
      "CountOfConfirmAssociatedClient",
      "CountOfPendingApprovalAssociatedClient",
      "IsStartedVendorProfileAssessment",
    ],
    criterion: [],
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorProductByIdBody = (id) => {
  const criterion = [
    {
      field: 'ProductVendorId',
      operator: '=',
      value: id,
    },
  ];
  return {
    intent: '',
    fields: [
      'ProductName',
      'ProductType',
      'ProductVendorRole',
      'ProductId',
      'ProductGroup',
      'ProductNameForVendor',
      'IsClearedProcessStarted',
      'ProductVendorAssessment',
      'CCDynVRASId',
      'CORLClearedStatus',
      'ExternalTicketId',
      'ProductCategory',
      'IsPublished',
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchVendorContractListSearchBody = () => {
  return {
    intent: '',
    fields: ['IsCORLCustomer', 'IsCoPilot', 'IsCorlClearedCopilot', 'IsDataReuse'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchCorlClearedProductCountBody = () => {
  return {
    intent: '',
    fields: [
      'ProductName',
      'ProductType',
      'ProductVendorRole',
      'ProductId',
      'ProductGroup',
      'ProductNameForVendor',
      'IsClearedProcessStarted',
      'CCDynVRASId',
      'CORLClearedStatus',
      'CountOfClearedVendorProduct',
      'VendorOrgId',
    ],
    criterion: [
      {
        Field: 'IsClearedProcessStarted',
        Operator: '=',
        Value: CORL_CLEARED_PROCESS_STARTED_ID,
      },
      {
        Field: 'CORLClearedStatusId',
        Operator: '=',
        Value: CORL_CLEARED_STATUS_ID,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchCorlClearedInfoBody = () => {
  return {
    intent: '',
    fields: [
      'ProductName',
      'ProductType',
      'ProductVendorRole',
      'ProductId',
      'ProductGroup',
      'ProductNameForVendor',
      'IsClearedProcessStarted',
      'ProductVendorAssessment',
      'CCDynVRASId',
      'CORLClearedStatus',
      'VendorOrgId',
      'TotalCountOfRequirementsAndResponse',
    ],
    criterion: [
      {
        Field: 'IsClearedProcessStarted',
        Operator: '=',
        Value: CORL_CLEARED_PROCESS_STARTED_ID,
      },
      {
        Field: 'CORLClearedStatusId',
        Operator: '=',
        Value: CORL_CLEARED_PARTICIPANT_STATUS_ID,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchVendorProductPreferencesBody = (QuestionTypeId = PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE) => {
  return {
    intent: '',
    fields: [
      'QuestionText',
      'DisplayOrder',
      'QuestionResponseType',
      'ParentQuestionId',
      'ProductPreferenceQuestionResponseId',
      'QuestionResponseText',
      'ResponseOptionList',
      'ProductPreferenceId',
      'ImageFileName',
      'Description',
      'Status',
      'EncouragementText',
      'LookupEntityId',
      'IsRequired',
    ],
    criterion: [
      {
        Field: 'ParentQuestionId',
        Operator: '=',
        Value: '0',
      },
      {
        Field: 'QuestionTypeId',
        Operator: '=',
        Value: QuestionTypeId,
      },
      {
        Field: 'Active',
        Operator: '=',
        Value: TRUE,
      },
    ],
    sort: [
      {
        field: 'DisplayOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchProductProfileWizardImageBody = (questionTypeId = PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE) => {
  return {
    intent: '',
    fields: [],
    criterion: [
      {
        Field: 'QuestionTypeId',
        Operator: '=',
        Value: questionTypeId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorProductAssessmentBody = (id) => {
  const criterion = [
    {
      field: 'ProductVendorId',
      operator: '=',
      value: id,
    },
    {
      Field: 'AssessmentStatusId',
      Operator: '=',
      Value: `${ASSESSMENT_REQUEST_STATUS_SUBMITTED}`,
    },
    {
      Field: 'AssessmentRequestTypeId',
      Operator: '=',
      Value: `${ASSESSMENT_REQUEST_TYPE_CORL_CLEARED}`,
    },
    {
      Field: 'AssessmentTypeId',
      Operator: '=',
      Value: `${ASSESSMENT_TYPE_CORL_CLEARED}`,
    },
  ];
  return {
    intent: '',
    fields: [
      'AssessmentRequestId',
      'AssessmentName',
      'AssessmentStatus',
      'AssessmentRequestInsertUserId',
      'InsertUserName',
      'UserEmail',
      'LastUpdatedAssessment',
      'ReleasedToClientId',
      'ProductVendorId',
      'ProductName',
      'VendorOrgId',
      'VendorOrgName',
      'ClientOrgId',
      'ClientOrgName',
      'AssessmentRequestInsertTimestamp',
      'IsCorlClient',
      'ParentAssessmentId',
      'ChildAssessmentId',
    ],
    criterion: criterion,
    sort: [
      {
        Field: 'ReleasedToClientId',
        Order: 'ASC',
      },
      {
        Field: 'LastUpdatedAssessment',
        Order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorProductDetailsRecommendationsBody = (assessmentId, recommedationStatusId) => {
  return {
    intent: '',
    fields: [
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementStatus',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'RequirementGroup',
      'ControlNumber',
      'DefaultTier',
      'AssessmentId',
      'QuestionnaireTemplateId',
      'AssessmentQuestionnaireName',
      'AssessmentName',
      'ProductVendorId',
      'RecommendationStatus',
      'Severity',
      'QuestionnaireTemplateRequirementId',
      'InadequecyReasonIPIList',
      'CountOfClientsRequireRemediation',
      'AssessmentTicketId',
      'AssessmentId',
    ],
    criterion: [
      {
        field: 'AssessmentId',
        operator: '=',
        value: `${assessmentId}`,
      },
      {
        field: 'RecommendationStatusId',
        operator: '=',
        value: recommedationStatusId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorProductDetailsRecommendationsTotalBody = (assessmentId) => {
  return {
    intent: '',
    fields: [
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementStatus',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'RequirementGroup',
      'ControlNumber',
      'DefaultTier',
      'AssessmentId',
      'QuestionnaireTemplateId',
      'AssessmentQuestionnaireName',
      'AssessmentName',
      'ProductVendorId',
      'RecommendationStatus',
      'Severity',
      'InadequecyReasonIPIList',
      'TotalCountOfRecommendation',
      'QuestionnaireTemplateRequirementId',
      'AssessmentId',
    ],
    criterion: [
      {
        field: 'AssessmentId',
        operator: '=',
        value: assessmentId,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorProductDetailsUpcomingMaintenanceBody = (assessmentId) => {
  return {
    intent: '',
    fields: [
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementStatus',
      'RequirementNumber',
      'RequirementName',
      'AssessmentId',
      "AssessmentStatus",
      'QuestionnaireTemplateId',
      'AssessmentQuestionnaireName',
      'AssessmentName',
      'ProductVendorId',
      'RecommendationStatus',
      'AssessmentInsertTimestamp',
      'UpcomingMaintainanceList',
      'QuestionnaireTemplateRequirementId',
      'InadequecyReasonIPIList',
      'CumulativeDueDays',
      'Adequacy',
      'IsManaged',
      'IsTicketClosed',
    ],
    criterion: [
      {
        field: 'AssessmentId',
        operator: '=',
        value: assessmentId,
      },
      {
        Field: 'AdequacyId',
        Operator: 'in',
        Value: `${OUTCOME_INADEQUATE}, ${OUTCOME_ADEQUATE}`,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchVendorProductBody = (productName = '') => {
  return {
    intent: '',
    fields: ['ProductName', 'VendorOrgId'],
    criterion: [
      {
        Field: 'ProductName',
        Operator: '=',
        Value: productName,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getCorlClearedAssessmentRequestBody = (data) => {
  return {
    VendorOrgId: data?.VendorOrgId,
    VendorOrgName: data?.VendorOrgName,
    VendorUrl: data?.VendorUrl,
    VendorContactFirstName: data?.VendorContactFirstName,
    VendorContactLastName: data?.VendorContactLastName,
    VendorContactEmail: data?.VendorContactEmail,
    ProductVendorId: data?.ProductVendorId,
    ProductName: data?.ProductName,
    IsVendorCleared: true,
  };
};

export const getCorlClearedAssessmentBody = (data) => {
  return {
    RecipientOrgId: data?.VendorOrgId,
    ProductVendorId: data?.ProductVendorId,
    VendorOrgName: data?.VendorOrgName,
    ProductName: data?.ProductName,
    AssessmentTypeId: ASSESSMENT_TYPE_CORL_CLEARED_INT,
  };
};
