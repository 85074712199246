import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal, Button, Typography } from '@mui/material';
import { modalStyle, buttonStyle, errorData } from './customErrorHandlerModalStyles';
import { clearCustomErrors } from '../../actions/customErrorHandler';
import { NO, YES } from '../VendorProfile/constants';
import { buttonRightMargin, spaceBottom } from '../VendorProfile/vendorProfileStyles';

const CustomErrorHandlerModal = (props) => {
  const { No = NO, Yes = YES } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const errorList = useSelector((state) => state.customErrorHandler.list);

  useEffect(() => {
    if (errorList.length > 0) {
      setOpen(errorList.length > 0);
    } else {
      setOpen(false);
    }
  }, [errorList]);

  useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    }
  }, [props?.open]);

  const onClose = () => {
    setOpen(false);
    dispatch(clearCustomErrors());
    if (props.close) {
      props.close();
    }
  };

  const optionSelected = () => {
    if (props.optionSelected) {
      props.optionSelected();
    }
    onClose();
  };

  return (
    <Box>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <ul>
            {errorList?.length > 1
              ? errorList.map((error, index) => (
                  <li key={index}>
                    <Typography variant="body1" sx={(theme) => errorData(theme)}>
                      {error.Message.replaceAll('!', '.')}
                    </Typography>
                  </li>
                ))
              : errorList?.map((error, index) => (
                  <Typography key={index} variant="body1" sx={(theme) => errorData(theme)}>
                    {error.Message.replaceAll('!', '.')}
                  </Typography>
                ))}
          </ul>
          {props?.warning && (
            <Typography variant="body1" sx={() => spaceBottom('20')}>
              {props.warning}
            </Typography>
          )}
          {props?.question && (
            <Typography variant="body1" sx={() => spaceBottom('20')}>
              {props.question}
            </Typography>
          )}
          <Box sx={buttonStyle}>
            <Box sx={buttonStyle}>
              <Button sx={buttonRightMargin} color="info" onClick={onClose}>
                {No}
              </Button>
            </Box>
            <Box sx={buttonStyle}>
              <Button variant="contained" color="info" onClick={() => optionSelected()}>
                {Yes}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomErrorHandlerModal;
