import axios from 'axios';
import { getErrorObject } from 'utils/apiUtils';
import { addNewRDSConnectionBody, getRDSConfigurationDetailsSearchBody, saveRDSConnetionDetailsBody } from 'utils/rdsConfigurationUtils';
import { setErrors } from './errorHandler';

export const SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING = '@rdsConfiguration/SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING';
export const SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS = '@rdsConfiguration/SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS';
export const SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE = '@rdsConfiguration/SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE';

export const searchRDSConfigurationDetails = () => async (dispatch) => {
  dispatch({ type: SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING });
  try {
    const body = getRDSConfigurationDetailsSearchBody();
    const response = await axios.post(`/vendor/rdsconfiguration/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_RDS_CONNECTION_LOADING = '@rdsConfiguration/ADD_RDS_CONNECTION_LOADING';
export const ADD_RDS_CONNECTION_SUCCESS = '@rdsConfiguration/ADD_RDS_CONNECTION_SUCCESS';
export const ADD_RDS_CONNECTION_FAILURE = '@rdsConfiguration/ADD_RDS_CONNECTION_FAILURE';

export const addRDSConnection = (formData) => async (dispatch) => {
  dispatch({ type: ADD_RDS_CONNECTION_LOADING });
  try {
    const body = addNewRDSConnectionBody(formData);
    const response = await axios.post(`/vendor/rdsconfiguration`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: ADD_RDS_CONNECTION_FAILURE,
        payload: [],
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: ADD_RDS_CONNECTION_SUCCESS,
        payload: response.data || [],
      });
      dispatch(searchRDSConfigurationDetails());
    }
  } catch (err) {
    dispatch({
      type: ADD_RDS_CONNECTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING = '@rdsConfiguration/UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING';
export const UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS = '@rdsConfiguration/UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS';
export const UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE = '@rdsConfiguration/UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE';

export const updateRDSConfigurationDetails = (formData, callback) => async (dispatch) => {
  dispatch({ type: UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_LOADING });
  try {
    const body = saveRDSConnetionDetailsBody(formData);
    const response = await axios.put(`/vendor/rdsconfiguration/${formData?.RDSConfigurationId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_SUCCESS,
        payload: response.data || [],
      });
      dispatch(searchRDSConfigurationDetails());
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: UPDATE_RDS_CONFIGURATION_CONNECTION_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
