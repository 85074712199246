export const LIKELIHOOD_DEFINITIONS = [
  {
    grade: '(A) Managed',
    description: `The assessment identified a security program that is implemented and aligned with reasonable industry practice. The likelihood of a breach is minimized to a level at which no remediation is necessary.`,
    gradeColor: '#2C9543',
    impactColor: '#B3E2BE',
  },
  {
    grade: '(B) Low',
    description: `The assessment identified a security program that is implemented and mostly aligned with reasonable industry practice. Some remediation may be necessary, but there is a low likelihood of an immediate breach.`,
    gradeColor: '#0083E9',
    impactColor: '#9EC9EB',
  },
  {
    grade: '(C) Medium',
    description: `The assessment identified a security program that is partially implemented and partially aligned with reasonable industry practice. Remediation activities should be identified and planned with corrections implemented in the near term to avoid a potential breach in the future.`,
    gradeColor: '#FFC107',
    impactColor: '#FFE16A',
  },
  {
    grade: '(D) High',
    description: `The assessment identified a security program that is minimally implemented and minimally aligned with industry best practice. Remediation activities should be identified and planned with corrections implemented as there is a high likelihood of an imminent breach`,
    gradeColor: '#F78D2A',
    impactColor: '#F7DAA2',
  },
  {
    grade: '(F) Very High',
    description: `The assessment identified a vulnerability that indicates an existing or imminent breach. Incident response activities should be activated.`,
    gradeColor: '#9D2727',
    impactColor: '#F2B5B5',
  },
];

export const IMPACT_DEFINITIONS = [
  {
    grade: 'Very High',
    description: `A vendor who receives all regulated or sensitive data on patients, employees, or internal company operations or who provides mission-critical or life-critical services. The compromise, failure, or disruption of the vendor's products or services would have an extreme impact on the operations, finances, or reputation of the client's organization.`,
    gradeColor: '#9D2727',
    impactColor: '#F2B5B5',
  },
  {
    grade: 'High',
    description: `A vendor who receives most regulated or sensitive data on patients, employees, or internal company operations or who provides highly business-critical or highly patient-care-critical services. The compromise, failure, or disruption of the vendor's products or services would have a significant impact on the operations, finances, or reputation of the client’s organization.`,
    gradeColor: '#F78D2A',
    impactColor: '#F7DAA2',
  },
  {
    grade: 'Medium',
    description: `A vendor who receives a sub-set of regulated or sensitive data on patients, employees, or internal company operations or who provides moderately business-critical or moderately patient-care-critical services. The compromise, failure, or disruption of the vendor's products or services would cause some inconvenience or disruption for the client, but it would not necessarily cause significant impact on the operations, finances, or reputation of the client’s organization. `,
    gradeColor: '#FFC107',
    impactColor: '#FFE16A',
  },
  {
    grade: 'Low',
    description: `A vendor who receives only non-regulated or non-sensitive client data or who provides low business-critical or low patient-care-critical services. The compromise, failure, or disruption of the vendor's products or services would have minimal impact on the operations, finances, or reputation of the client’s organization and could likely be easily replaced or worked around. `,
    gradeColor: '#0083E9',
    impactColor: '#9EC9EB',
  },
  {
    grade: 'Very Low',
    description: `A vendor who does not receive data or who provides non-essential business services or services that have no patient care impact. The compromise, failure, or disruption of the vendor's products or services would have no material impact on the operations, finances, or reputation of the client’s organization and could be easily replaced or worked around.`,
    gradeColor: '#2C9543',
    impactColor: '#B3E2BE',
  },
];

export const VALIDATION_OPTIONS = {
  ALL: 'ALL',
  MET: 'MET',
  UNMET: 'UNMET',
  NOT_APPLICABLE: 'N/A',
};

export const VALIDATION_FILTER_LIST = [
  {
    label: VALIDATION_OPTIONS.ALL,
    value: VALIDATION_OPTIONS.ALL,
  },
  {
    label: VALIDATION_OPTIONS.MET,
    value: VALIDATION_OPTIONS.MET,
  },
  {
    label: VALIDATION_OPTIONS.UNMET,
    value: VALIDATION_OPTIONS.UNMET,
  },
  {
    label: VALIDATION_OPTIONS.NOT_APPLICABLE,
    value: VALIDATION_OPTIONS.NOT_APPLICABLE,
  },
];

export const RISK_RATING_SUMMARY_OPTIONS = {
  ALL: 'ALL',
  MET: 'MET',
  UNMET: 'UNMET',
};

export const RISK_RATING_SUMMARY_LIST = [
  {
    label: RISK_RATING_SUMMARY_OPTIONS.ALL,
    value: RISK_RATING_SUMMARY_OPTIONS.ALL,
  },
  {
    label: RISK_RATING_SUMMARY_OPTIONS.MET,
    value: RISK_RATING_SUMMARY_OPTIONS.MET,
  },
  {
    label: RISK_RATING_SUMMARY_OPTIONS.UNMET,
    value: RISK_RATING_SUMMARY_OPTIONS.UNMET,
  },
];

export const RISK_RATING_SUMMARY_MET_STATUS_LIST = ['Control Satisfied', 'Control Not Applicable'];

export const RISK_RATING_SUMMARY_UNMET_STATUS_LIST = [
  'Vendor Nonresponsive',
  'Control Not Satisfied',
  'Remediation Not in Progress',
  'Not Accepted',
  'Sent to Vendor',
  'Evidence Requested',
  'Open',
  'Accepted',
  'Vendor Follow Up',
];

//These constants will be used in Assessment Results - Risk Rating Overview section
export const REPORT_IN_EXECUTIVE_SUMMARY = 1;
export const THRESHOLD_DESCRIPTION = 'CORL Pre-Assessment';

export const RATING_GOOD = 'GOOD';
export const RATING_FAIR = 'FAIR';
export const RATING_POOR = 'POOR';

export const MIN_GOOD_GRADE_LIMIT = '0.75';
export const MAX_POOR_GRADE_LIMIT = '0.50';

export const RISK_FINDING_SUMMARY_PRIORITY_DETAILS = {
  HIGH_PRIORITY: 1,
  MEDIUM_PRIORITY: 2,
  LOW_PRIORITY: 3,
};

export const VENDOR_COLLABORATION_SCORE_ENABLED = 2;

export const ASSESSMENT_RESPONSES = 'Assessment Responses';
export const EVIDENCE_PROVIDED = 'Evidence Provided';
export const REMEDIATION_GUIDANCE = 'Remediation Guidance';
export const HISTORICAL_COMMENTS = 'View Historical Results';
export const SUBMIT_FEEDBACK = 'Submit Feedback';

//AssessmentType
export const ASSESSMENT_TYPE_STANDARD = 'standard';
export const ASSESSMENT_TYPE_CORL_CLEARED = 'corl cleared';

// Risk Rating Overview Assessment Results
export const DATA_ACCESS = 'OFFSHORE ACCESS';
export const DATA_STORAGE = 'OFFSHORE STORAGE';
export const FOURTH_PARTY_SUBCONTRACTOR = '4TH-PARTY SUBCONTRACTOR';

//Validation Evidence Review Assessment Results
export const ADEQUATE = 'adequate';
export const INADEQUATE = 'inadequate';

//Assessment Results
export const DISCLAIMER = `This document is PROPRIETARY and CONFIDENTIAL Information. It may not be used, disclosed or reproduced, in whole or in part, without the express
written permission of CORL Technologies, LLC. While every care has been taken in preparing the information published in this document, CORL
Technologies LLC (CORL) does not guarantee the accuracy or currency of the content researched. The research is provided on an as-is basis without
warranties of any kind, either expressed or implied. CORL shall not be held responsible for any errors or omissions and accepts no liability whatsoever for
any loss or damage howsoever arising. The use of this document is subject to the Terms of Service located at`;

// Risk Rating Overview - Impact level pills
export const PII = 'Personally Identifiable Information (PII)';
export const PHI = 'Protected Health Information (PHI)';
export const PCI = 'Payment Card Industry (PCI)';
export const PROPRIETARY = 'Employee/Proprietary Information';

export const NOT_APPLICABLE = 'N/A'
export const NO_VALIDATION_EVIDENCE_REVIEWS = 'No Validation Evidence Reviews';

export const AGREED_UPON_DATE_TITLE = 'Agreed Upon Date';
