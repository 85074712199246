import { toast } from 'react-toastify';
import {
  GET_COMPANY_INFO_FAILURE,
  GET_COMPANY_INFO_LOADING,
  GET_COMPANY_INFO_SUCCESS,
  GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE,
  GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING,
  GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS,
  GET_STATE_LIST_FAILURE,
  GET_STATE_LIST_LOADING,
  GET_STATE_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_COUNTRY_LIST_LOADING,
  GET_COUNTRY_LIST_SUCCESS,
  POST_COMPANY_INFO_FAILURE,
  POST_COMPANY_INFO_LOADING,
  POST_COMPANY_INFO_SUCCESS,
  SEARCH_COMPANY_PROFILE_PROGRESS_LOADING,
  SEARCH_COMPANY_PROFILE_PROGRESS_SUCCESS,
  SEARCH_COMPANY_PROFILE_PROGRESS_FAILURE,
} from '../actions/companyInformation';
import { ERROR_MESSAGE, SAVE_SUCCESSFULLY_MESSAGE } from '../components/Common/AlertMessages';

const initialState = {
  companyProfile: {},
  numberOfEmployeesRange: [],
  statesList: [],
  countriesList: [],
  contractDetails: {},
  corlClearedProductCount: 0,
  corlClearedInfo: [],
  companyProgressPercentage: 0,
  orgId: null,
  loading: false,
};

const getRoundedCompanyProgressPercentage = (percentage) => {
  if (percentage) {
    let tempPercentage = Math.round(percentage * 100) / 100;
    return Math.min(tempPercentage, 100);
  }
  return 0;
}

export function companyProfileData(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPANY_INFO_LOADING: {
      return {
        ...state,
        loading: true,
        companyProfile: {},
        orgId: null,
      };
    }

    case GET_COMPANY_INFO_SUCCESS: {
      if (payload.length > 0) {
        var profile = {
          ...payload[0],
          YearFounded: payload[0]?.YearFounded?.toString(),
        };
        return {
          ...state,
          loading: false,
          companyProfile: profile,
          orgId: payload[0]?.VendorOrgId,
        };
      } else {
        return {
          ...state,
          loading: false,
          companyProfile: profile,
        };
      }
    }

    case GET_COMPANY_INFO_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        companyProfile: {},
        orgId: null,
      };
    }

    case POST_COMPANY_INFO_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_COMPANY_INFO_SUCCESS: {
      let formData = {
        VendorOrgName: payload?.OrgName,
        DomainName: payload?.DomainName,
        RegisteredBusinessAddressLine1: payload?.RegisteredBusinessAddressLine1,
        RegisteredBusinessAddressLine2: payload?.RegisteredBusinessAddressLine2,
        RegisteredBusinessAddressCity: payload?.RegisteredBusinessAddressCity,
        RegisteredBusinessAddressState: payload?.RegisteredBusinessAddressState,
        RegisteredBusinessAddressCountry: payload?.RegisteredBusinessAddressCountry,
        RegisteredBusinessAddressZip: payload?.RegisteredBusinessAddressZip,
        //Phone: payload?.Phone,
        PhoneCode: payload?.PhoneCode,
        MainPhone: payload?.MainPhone,
        PhoneExtension: payload?.PhoneExtension,
        NumberOfEmployeesRangeId: payload?.NumberOfEmployeesRangeId,
        YearFounded: payload?.YearFounded?.toString(),
        ZendeskOrgId: payload?.ZendeskOrgId,
      };
      toast.success(SAVE_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        companyProfile: formData,
      };
    }

    case POST_COMPANY_INFO_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case GET_NUMBER_OF_EMPLOYEE_RANGE_LOADING: {
      return {
        ...state,
        loading: true,
        numberOfEmployeesRange: [],
      };
    }

    case GET_NUMBER_OF_EMPLOYEE_RANGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        numberOfEmployeesRange: payload?.Data,
      };
    }

    case GET_NUMBER_OF_EMPLOYEE_RANGE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        numberOfEmployeesRange: [],
        companyProfile: {},
        orgId: null,
      };
    }

    case GET_STATE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        statesList: [],
      };
    }

    case GET_STATE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        statesList: payload?.Data,
      };
    }

    case GET_STATE_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        statesList: [],
      };
    }

    case GET_COUNTRY_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        countriesList: [],
      };
    }

    case GET_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        countriesList: payload?.Data,
      };
    }

    case GET_COUNTRY_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        countriesList: [],
      };
    }

    case SEARCH_COMPANY_PROFILE_PROGRESS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_COMPANY_PROFILE_PROGRESS_SUCCESS: {
      let percentageCount = 0;
      payload.map((item) => {
        percentageCount += item.TPIRProfileCompleteness;
      });
      return {
        ...state,
        loading: false,
        companyProgressPercentage: getRoundedCompanyProgressPercentage(percentageCount),
      };
    }

    case SEARCH_COMPANY_PROFILE_PROGRESS_FAILURE: {
      return {
        ...state,
        loading: false,
        companyProgressPercentage: 0,
      };
    }

    default:
      return state;
  }
}
