import {
    GET_DECRYPTED_TEXT_LOADING,
    GET_DECRYPTED_TEXT_SUCCESS,
    GET_DECRYPTED_TEXT_FAILURE,
} from 'actions/publicApi';

const initialState = {
    loading: false,
    decryptedText: "",
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DECRYPTED_TEXT_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case GET_DECRYPTED_TEXT_SUCCESS: {
            return {
                ...state,
                loading: false,
                decryptedText: payload?.Data,
            };
        }

        case GET_DECRYPTED_TEXT_FAILURE: {
            return {
                ...state,
                loading: false,
                decryptedText: "",
            };
        }

        default: return state;
    }
}