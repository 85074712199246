import { merge } from 'lodash';
import Card from './Card';
import Lists from './List';
import Paper from './Paper';
import Input from './Input';
import Button from './Button';
import Tooltip from './Tooltip';
import Backdrop from './Backdrop';
import Typography from './Typography';
import IconButton from './IconButton';
import Autocomplete from './Autocomplete';
import Chip from './Chips';
import Tab from './Tab';
import Divider from './Divider';
import ToggleButton from './ToggleButton';
import List from './List';
import Select from './Select';
import TextField from './TextField';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return merge(
    Card(theme),
    List(theme),
    Paper(theme),
    Input(theme),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    IconButton(theme),
    Autocomplete(theme),
    Chip(theme),
    Tab(theme),
    ToggleButton(theme),
    Divider(theme),
    Select(theme),
    TextField(theme)
  );
}
