export const logoutIconStyle = () => {
  return {
    marginRight: '5px',
    transform: 'translateY("1px")',
    width: '22px',
    height: '16px',
  };
};
export const loaderBackDropStyle = (theme) => {
  return {
    color: '#fff',
    zIndex: theme.zIndex.modal + 1,
  };
};
