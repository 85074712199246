import { GET_CUSTOMERS_PRODUCTS_LIST_LOADING, GET_CUSTOMERS_PRODUCTS_LIST_SUCCESS, GET_CUSTOMERS_PRODUCTS_LIST_FAILURE } from '../actions/customersAndProducts';

const initialState = {
  loading: false,
  customersAndProductsList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CUSTOMERS_PRODUCTS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CUSTOMERS_PRODUCTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        customersAndProductsList: payload?.Data,
      };
    }

    case GET_CUSTOMERS_PRODUCTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
