export const getVendorProfileContactsBody = () => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'ContactId', 'IsTeamContact', 'FullName'],
    criterion: [],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchVendorProfileSpecificContactsBody = (searchValue) => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'ContactId', 'IsTeamContact', 'FullName'],
    criterion: [
      {
        field: 'FullName',
        operator: 'startswith',
        value: searchValue,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchVendorProfileCheckDuplicateEmailContactsBody = (searchValue) => {
  return {
    intent: '',
    fields: ['FirstName', 'LastName', 'Title', 'Email', 'CellPhone', 'OfficePhone', 'ContactId', 'IsTeamContact', 'FullName'],
    criterion: [
      {
        field: 'Email',
        operator: '=',
        value: searchValue,
      },
    ],
    sort: [
      {
        field: 'FullName',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
