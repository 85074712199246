import {
  DELETE_EVIDENCE_DOCUMENTS_FAILURE,
  DELETE_EVIDENCE_DOCUMENTS_LOADING,
  DELETE_EVIDENCE_DOCUMENTS_SUCCESS,
  SEARCH_EVIDENCE_DETAILS_FAILURE,
  SEARCH_EVIDENCE_DETAILS_LOADING,
  SEARCH_EVIDENCE_DETAILS_SUCCESS,
  UPLOAD_EVIDENCE_FILE_FAILURE,
  UPLOAD_EVIDENCE_FILE_LOADING,
  UPLOAD_EVIDENCE_FILE_SUCCESS,
  ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE,
  ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING,
  ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS,
  POST_EVIDENCE_CLEAR_COMMENT_LOG_FAILURE,
  POST_EVIDENCE_CLEAR_COMMENT_LOG_LOADING,
  POST_EVIDENCE_CLEAR_COMMENT_LOG_SUCCESS,
  SEARCH_EVIDENCE_START_DATE_LOADING,
  SEARCH_EVIDENCE_START_DATE_SUCCESS,
  SEARCH_EVIDENCE_START_DATE_FAILURE,
  SEARCH_EVIDENCE_END_DATE_LOADING,
  SEARCH_EVIDENCE_END_DATE_SUCCESS,
  SEARCH_EVIDENCE_END_DATE_FAILURE,
  SET_SELECTED_EVIDENCE,
} from 'actions/evidenceManagement';
import { COMMENT_ADDED_SUCCESSFULLY, DELETED_SUCCESSFULLY, ERROR_MESSAGE, UPLOADED_SUCCESSFULLY } from 'constants/errorMessage';
import { format } from 'date-fns';
import { isNil, uniqBy } from 'lodash';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  uploadedFiles: [],
  evidenceList: [],
  startDateList: [],
  endDateList: [],
  selectedEvidence: {},
  listLoading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_EVIDENCE_FILE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPLOAD_EVIDENCE_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        uploadedFiles: payload,
      };
    }
    case UPLOAD_EVIDENCE_FILE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEARCH_EVIDENCE_DETAILS_LOADING: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case SEARCH_EVIDENCE_DETAILS_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        evidenceList: payload,
      };
    }
    case SEARCH_EVIDENCE_DETAILS_FAILURE: {
      return {
        ...state,
        listLoading: false,
        evidenceList: [],
      };
    }
    case DELETE_EVIDENCE_DOCUMENTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_EVIDENCE_DOCUMENTS_SUCCESS: {
      toast.success(DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_EVIDENCE_DOCUMENTS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_SUCCESS: {
      toast.success(UPLOADED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case ATTACH_EVIDENCE_FILE_TO_REQUIREMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_EVIDENCE_CLEAR_COMMENT_LOG_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_EVIDENCE_CLEAR_COMMENT_LOG_SUCCESS: {
      toast.success(COMMENT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
      };
    }
    case POST_EVIDENCE_CLEAR_COMMENT_LOG_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
      };
    }

    case SEARCH_EVIDENCE_START_DATE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_EVIDENCE_START_DATE_SUCCESS: {

      let dateList = [];
      payload?.forEach((item) => {
        if (!isNil(item?.ValidStartDate)) {
          dateList.push({
            label: format(new Date(item?.ValidStartDate), "MM-dd-yyyy"),
            value: format(new Date(item?.ValidStartDate), "yyyy-MM-dd'T'HH:mm:ss'Z'")
          });
        }
      });

      return {
        ...state,
        loading: false,
        startDateList: dateList,
        startDateList: uniqBy(dateList, (item) => item?.label),
      };
    }

    case SEARCH_EVIDENCE_START_DATE_FAILURE: {
      return {
        ...state,
        loading: false,
        startDateList: [],
      };
    }

    case SEARCH_EVIDENCE_END_DATE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEARCH_EVIDENCE_END_DATE_SUCCESS: {
      let dateList = [];
      payload?.forEach((item) => {
        if (!isNil(item?.ValidExpiryDate)) {
          dateList.push({
            label: format(new Date(item?.ValidExpiryDate), "MM-dd-yyyy"),
            value: format(new Date(item?.ValidExpiryDate), "yyyy-MM-dd'T'HH:mm:ss'Z'")
          });
        }
      });

      return {
        ...state,
        loading: false,
        endDateList: uniqBy(dateList, (item) => item?.label),
      };
    }

    case SEARCH_EVIDENCE_END_DATE_FAILURE: {
      return {
        ...state,
        loading: false,
        endDateList: [],
      };
    }

    case SET_SELECTED_EVIDENCE: {
      return {
        ...state,
        selectedEvidence: payload,
      }
    }

    default:
      return state;
  }
}
