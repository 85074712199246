import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { headerLogo, logo } from './NavBarStyles';
import React from 'react';
import headerLogoImg from '../../assets/images/corl-logo-color.svg';
import { useSelector } from 'react-redux';
import { DEFAULT_PAGE_NUMBER, VENDOR_ONBOARDING_WIZARD_TAB_VALUES } from 'pages/VendorOnboarding/constants';

const Branding = () => {  
  const hasAcceptedTerms = useSelector((state) => state.session.hasAcceptedTerms);

  return (
    <>
      <Box sx={headerLogo}>
        <Link to={!hasAcceptedTerms ? `/vendor-onboarding-wizard/?tab=${VENDOR_ONBOARDING_WIZARD_TAB_VALUES.INTRODUCTION}&page=${DEFAULT_PAGE_NUMBER}` : '/'}>
          <Box component="img" alt="CORL logo" src={headerLogoImg} sx={(theme) => logo(theme)} />
        </Link>
      </Box>
    </>
  );
};

export default Branding;
