import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';

export const GET_CLIENT_REQUEST = '@@client/GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = '@@client/GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAIL = '@@client/GET_CLIENT_FAIL';

export const getClient = (id) => async (dispatch) => {
  dispatch({ type: GET_CLIENT_REQUEST });
  try {
    const res = await axios.get(`/client/${id}`);
    dispatch({
      type: GET_CLIENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CLIENT_FAIL,
      payload: getErrorObject(err),
    });
  }
};
